import React from 'react';
import Box from '../single_component/Box';
import Header from '../Header/Header';
import { Outlet } from 'react-router-dom';
import BreadCrumb from '../single_component/BreadCrumb';

function Layout() {
  return (
    <Box>
      <Header />
      <BreadCrumb />
      <Outlet />
    </Box>
  );
}

export default Layout;
