import React from 'react';
import Box from '../../components/single_component/Box';
import ViewDeveloperGroupContainer from '../Container/ViewDeveloperGroupContainer';
import SpvProvider from '../../providers/SpvProvider';
import { Outlet } from 'react-router-dom';

function ViewDeveloperGroup() {
  return (
    <SpvProvider>
      <Outlet />
      <Box
        style={{
          backgroundColor: '#F2FAFE',
          paddingTop: 5,
          paddingBottom: 10,
          minHeight: '100vh',
        }}>
        <ViewDeveloperGroupContainer />
      </Box>
    </SpvProvider>
  );
}

export default ViewDeveloperGroup;
