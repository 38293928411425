import React from 'react';
import Card from '../single_component/Card';
import Props from '../../types/props';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import { useNavigate } from 'react-router-dom';

function CardContainer({ ...props }: Props) {
  const { data } = props.extra;
  const navigate = useNavigate();
  return (
    <Box
      {...props}
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 4,
        marginBottom: 4,
        justifyContent: 'space-between',
      }}>
      <Card
        style={{
          width: '32%',
          padding: 3,
          boxShadow: '0px 2px 10px 0px #10496030',
          border: '1px solid #EDEDED',
          cursor: 'pointer',
        }}
        onClick={() => navigate('/active')}>
        <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
          Active Transactions
        </Text>
        <Text style={{ fontSize: 28, fontWeight: 'bold', marginTop: 10 }}>
          {data && data.activeTransaction}
        </Text>
      </Card>
      <Card
        style={{
          width: '32%',
          padding: 3,
          boxShadow: '0px 2px 10px 0px #10496030',
          border: '1px solid #EDEDED',
          cursor: 'pointer',
        }}
        onClick={() => navigate('/investors')}>
        <Text style={{ fontSize: 16, fontWeight: 'bold' }}>Investors</Text>
        <Text style={{ fontSize: 28, fontWeight: 'bold', marginTop: 10 }}>
          {data && data.totalInvestor}
        </Text>
      </Card>
      <Card
        style={{
          width: '32%',
          padding: 3,
          boxShadow: '0px 2px 10px 0px #10496030',
          border: '1px solid #EDEDED',
          cursor: 'pointer',
        }}
        onClick={() => navigate('/completed')}>
        <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
          Completed Transactions
        </Text>
        <Text style={{ fontSize: 28, fontWeight: 'bold', marginTop: 10 }}>
          {data && data.completedTransaction}
        </Text>
      </Card>
    </Box>
  );
}

export default CardContainer;
