import React from 'react';
import Box from '../single_component/Box';
import TwoButton from '../common_component/TwoButton';
import Dpicker from '../single_component/Dpicker';
import Props from '../../types/props';

function TeamFilter({ ...props }: Props) {
  const { date, setDate, handleSubmit, btnDisableLoader } = props.extra;
  const HandleClearFilter = () => {
    setDate('select date');
  };
  return (
    <Box style={{ width: '90%', margin: 'auto', marginTop: 2 }}>
      <Dpicker
        label="Date"
        value={date}
        extra={{
          setDate,
          placeholder: 'Choose date',
        }}
      />
      <TwoButton
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bottom: 10,
          position: 'absolute',
          width: '90%',
        }}
        extra={{
          onclickFirst: HandleClearFilter,
          OnClickSecond: handleSubmit,
          firstVariant: 'outlined',
          secondVariant: 'contained',
          firstButtonText: 'Clear Filter',
          secondButtonText: 'Apply',
          btnDisableLoader: btnDisableLoader,
        }}
      />
    </Box>
  );
}

export default TeamFilter;
