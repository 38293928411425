import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#104960',
    },
    secondary: {
      main: '#25a9e0',
      light: '#F2FAFE',
      dark: ' #D4BF00',
    },
    tertiary: {
      main: '#667085',
    },
  },
  typography: {
    fontFamily: ['Inter'].join(','),
    button: {
      textTransform: 'none',
    },
  },
});
export default theme;
