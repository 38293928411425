import React from 'react';
import ViewSpvContainer from '../Container/ViewSpvContainer';
import Box from '../../components/single_component/Box';
import ProjectProvider from '../../providers/ProjectProvider';

function ViewSpv() {
  return (
    <ProjectProvider>
      <Box
        style={{
          backgroundColor: '#F2FAFE',
          paddingTop: 5,
          paddingBottom: 10,
          minHeight: '100vh',
        }}>
        <ViewSpvContainer />
      </Box>
    </ProjectProvider>
  );
}

export default ViewSpv;
