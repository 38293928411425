import React, { useState, useContext } from 'react';
import Box from '../components/single_component/Box';
import Image from '../components/single_component/Image';
import login from '../assets/images/login.png';
import LoginForm from '../components/Forms/LoginForm';
import VerifyOtp from '../components/Forms/VerifyOtp';
import { ToastContainer, toast } from 'react-toastify';
import AuthService from '../services/AuthService';
import AuthContext from '../context/AuthContext';
import { useFormik } from 'formik';
import { loginValidation } from '../components/Validation/Login';
import ProfileContext from '../context/ProfileContext';

function LoginContainer() {
  const [Verify, setVerify] = useState(false);
  const [serverError, setServerError] = useState('');
  const [Otp, setOtp] = useState(['', '', '', '']);
  const [loading, setLoading] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const { getProfile } = useContext(ProfileContext);

  const initialValues = {
    email: '',
  };
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: loginValidation,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: async values => {
        setLoading(true);
        const apiResponse = await authService.sendOtp({
          email: values.email.trim(),
        });
        const message = apiResponse.getMessage();
        const success = apiResponse.getSuccess();
        if (success == true) {
          console.error(apiResponse.getData());
          toast.success(message);
          setVerify(true);
          setServerError('');
          setLoading(false);
        } else {
          toast.error(message);
          setServerError(message);
          setLoading(false);
        }
      },
    });

  const { authorized, role, token, masterData, notificationToken } =
    useContext(AuthContext);
  const authService = new AuthService();
  const onVerifyOTP = async () => {
    setLoadingOtp(true);
    const enteredOTP = Otp.join('').replace(/\s/g, ''); // Remove spaces
    const apiResponse = await authService.verifyOtp({
      email: values.email.trim(),
      code: enteredOTP,
      token: notificationToken?.value() ?? '',
    });
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    const data = apiResponse.getData(); // Type assertion
    const tempToken: string = (data.accessToken ?? '').toString();

    if (success === true) {
      toast.success(message);
      authorized?.setValue(true);
      const roleId = (
        (data.profile as Record<string, unknown>).user as Record<
          string,
          unknown
        >
      ).roleId as number | undefined;
      role?.setValue(roleId ?? 5);
      token?.setValue(tempToken);
      const mData = await authService.getMasterData();
      masterData?.setValue(mData);
      getProfile(`/profile/get`);
      setLoadingOtp(false);
    } else {
      toast.error(message);
      setLoadingOtp(false);
    }
  };

  return (
    <Box style={{ display: 'flex', height: '99vh' }}>
      <ToastContainer />
      <Image
        src={login}
        style={{ width: '50%', objectFit: 'cover', height: '100vh' }}
      />

      <Box style={{ height: '100%', width: '50%' }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            background: 'white',
          }}>
          {!Verify ? (
            <LoginForm
              extra={{
                values,
                handleBlur,
                handleChange,
                handleSubmit,
                errors,
                touched,
                serverError,
                setServerError,
                loading,
              }}
            />
          ) : (
            <VerifyOtp
              extra={{
                onVerifyOTP,
                setOtp,
                values,
                Otp,
                handleSubmit,
                setVerify,
                loadingOtp,
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default LoginContainer;
