import React, { useCallback, useState } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import Props from '../../types/props';
import Image from './Image';
import DownloadIcon from '../../assets/images/uploadIcon.png';
import Box from './Box';
import TextLabel from './TextLabel';

const FileDropzone = (props: Props = {}) => {
  const { onchange } = props?.extra ?? {};
  const [fileName, setFileName] = useState<string | null>(null);

  const dropzoneOptions: DropzoneOptions = {
    onDrop: useCallback((acceptedFiles: File[]) => {
      // Log information about the dropped files
      console.error('Dropped files:', acceptedFiles);

      // Assuming you want to display only the name of the first file
      if (acceptedFiles.length > 0) {
        setFileName(acceptedFiles[0].name);
        if (onchange) onchange(acceptedFiles);
      }
      // You can process acceptedFiles here if needed
    }, []),
    maxFiles: 1,
  };

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneOptions);

  return (
    <Box style={{ width: '100%', marginTop: 0 }}>
      <TextLabel> {props?.label ?? ''}</TextLabel>
      <div
        {...getRootProps()}
        className={`dropzone ${
          isDragActive || fileName ? 'FileDropzoneDrop' : 'FileDropzone'
        }`}>
        <Image
          src={DownloadIcon}
          className="uploadImage"
          style={{
            width: 20,
            height: 20,
            objectFit: 'cover',
            alignSelf: 'flex-start',
          }}
        />
        <input
          {...getInputProps({ onChange: onchange })}
          className="FileDropzone"
          name={props?.name ?? ''}
          id={props?.id ?? ''}
          onBlur={props?.onBlur}
        />
        {fileName ? (
          <p
            style={{
              color: 'black',
              width: '80%',
              fontFamily: 'revert',
              fontSize: 13,
            }}>
            {fileName}
          </p>
        ) : (
          <p
            style={{
              color: 'rgb(178 178 178)',
              width: '80%',
              fontFamily: 'revert',
              fontSize: 13,
            }}>
            {'Click here to upload Excel File'}{' '}
            <span style={{ fontSize: 12, fontWeight: 600, color: '#737373' }}>
              {' '}
            </span>
          </p>
        )}
      </div>
    </Box>
  );
};

export default FileDropzone;
