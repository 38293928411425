import Axios from '../config/axios';
import ApiResponse from '../utils/ApiResponse';
import DeveloperGroupTable from '../models/DeveloperGroupTable';

class DeveloperGroupService extends Axios {
  constructor() {
    super();
  }

  async addDeveloperGroup(query: string, data: any) {
    const response = await this.post<JSON>(`${query}`, data);
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
  async editDeveloperGroup(query: string, data: any) {
    const response = await this.patch<JSON>(`${query}`, data);
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
  async addDeveloperFormat(query: string, data: any) {
    const response = await this.post<JSON>(`${query}`, data);
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }

  async fetchDeveloperGroup(query: string, data: Record<string, unknown>) {
    const response = await this.post<JSON>(`${query}`, data);
    const apiResponse = new ApiResponse(response);
    const responseData = DeveloperGroupTable.fromJson(apiResponse.getData());

    return responseData;
  }
  async deleteDevPromoterById(id: any) {
    const response = await this.delete<JSON>(`developer/promoter/${id}`);
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
  async deleteDeveloperGroupDocs(id: any, name: string) {
    const response = await this.delete<JSON>(`developer/docs/${id}/${name}`);
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
  async deleteDevPromoterDocId(id: any) {
    const response = await this.delete<JSON>(`developer/promoter/doc/${id}`);
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
}

export default DeveloperGroupService;
