import React from 'react';
import Props from '../../types/props';
import {
  Radio as RadioButton,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import TextLabel from '../single_component/TextLabel';

function RadioWithLabel(props: Props = {}) {
  const { Selected, setSelected, options } = props?.extra ?? {};
  return (
    <Box style={props?.style}>
      <TextLabel>{props?.label ?? ''}</TextLabel>
      <RadioGroup
        aria-label="gender"
        name="gender"
        value={Selected}
        onChange={e => {
          setSelected(e.target.value);
        }}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: 10,
          columnGap: 5,
        }}>
        {options.map((value: any, index: React.Key | null | undefined) => {
          return (
            <FormControlLabel
              style={{
                width: '48%',
                backgroundColor: 'white',
                padding: '5px 10px',
                border: '1px solid #F1F1F1',
                borderRadius: 5,
              }}
              key={index}
              value={value.id}
              disabled={props?.disabled && Selected !== value.id}
              control={<RadioButton size="small" />}
              label={
                <Text style={{ fontSize: 13, fontWeight: 600 }}>
                  {value.name}
                </Text>
              }
            />
          );
        })}
      </RadioGroup>
    </Box>
  );
}

export default RadioWithLabel;
