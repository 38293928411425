import React, { useState } from 'react';
import Props from '../types/props';
import ProjectContext from '../context/ProjectContext';
import ProjectServices from '../services/ProjectService';

export default function ProjectProvider(props: Props) {
  const [ProjectData, setProjectData] = useState({});
  const [Projects, setProjects] = useState([]);
  const [projectGalleryImage, setProjectGalleryImage] = useState([]);
  const [spvId, setspvId] = useState<number>(0);
  const [documents, setDocuments] = useState<
    Record<string, Record<string, unknown>[]>
  >({});
  const [loading, setLoading] = useState(true);

  const getProjects = async (id: number) => {
    setLoading(true);
    const data = await new ProjectServices().getProject(`project/get/${id}`);
    setspvId(id);
    setLoading(false);
    setProjects(data as any);
    console.error(spvId);
  };

  const editProject = async (id: number, data: any) => {
    setLoading(true);
    const response = await new ProjectServices().editProject(id, data);
    setLoading(false);
    return response;
  };
  const deleteMoreDocument = async (docId: number) => {
    setLoading(true);
    const response = await new ProjectServices().deleteMoreDocument(docId);
    setLoading(false);
    return response;
  };
  const addProject = async (data: any) => {
    setLoading(true);
    const response = await new ProjectServices().addProject(data);
    setLoading(false);
    return response;
  };
  const deleteProjectDocument = async (
    projectId: number,
    deletedoc: string
  ) => {
    setLoading(true);
    const response = await new ProjectServices().deleteProjectDocument(
      projectId,
      deletedoc
    );
    setLoading(false);
    return response;
  };
  const deleteSpocs = async (spocsId: number) => {
    setLoading(true);
    const response = await new ProjectServices().deleteSpocs(spocsId);
    setLoading(false);
    return response;
  };
  const deleteProjectImage = async (ImageId: number) => {
    setLoading(true);
    const response = await new ProjectServices().deleteProjectImage(ImageId);
    setLoading(false);
    return response;
  };
  const addMis = async (data: any) => {
    setLoading(true);
    const response = await new ProjectServices().addMis(data);
    console.error(response, 'misRespsnce');

    setLoading(false);
    return response;
  };
  const addProjectBanner = async (data: any) => {
    setLoading(true);
    const response = await new ProjectServices().addProjectBanner(data);
    console.error(response, 'misRespsnce');

    setLoading(false);
    return response;
  };

  const getProjectImagesById = async (query: any) => {
    setLoading(true);
    const data = await new ProjectServices().getProjectImagesById(query);
    setLoading(false);
    setProjectGalleryImage(data as any);
  };

  const getProject = async (id: number) => {
    const data = await new ProjectServices().getProject(
      `project/getproject/${id}`
    );
    setProjectData(data as any);
    const projDocs = Array.isArray(data?.documents) ? data?.documents : [];
    const docs: any = [];

    for (let i = 0; i < projDocs.length; i++) {
      const index = (projDocs[i]['category'] ?? '').toString();
      docs[index] != undefined
        ? docs[index].push(projDocs[i])
        : (docs[index] = [projDocs[i]]);
    }
    setDocuments({ ...docs });
  };

  console.error('doxcuemtn', documents);

  return (
    <ProjectContext.Provider
      value={{
        getProjects,
        addProject,
        ProjectData,
        getProject,
        setLoading,
        loading,
        Projects,
        documents,
        deleteSpocs,
        spvId,
        editProject,
        addMis,
        deleteProjectImage,
        addProjectBanner,
        getProjectImagesById,
        projectGalleryImage,
        deleteProjectDocument,
        deleteMoreDocument,
      }}>
      {props.children}
    </ProjectContext.Provider>
  );
}
