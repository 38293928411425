import React, { useContext, useState } from 'react';
import Props from '../../types/props';
import Box from '../single_component/Box';
// import Button from "../single_component/Button";
import AuthContext from '../../context/AuthContext';
import InvestorContactForm from './investorContactForm';
import InvestorInvestmentForm from './investorInvestmentForm';
import InvetsorLocationForm from './InvestorLocationForm';
import InvestorContext from '../../context/InvestorContext';
import { toast } from 'react-toastify';
import { ContactPerson } from '../../types/types';

function AddInvestor(props: Props) {
  const { setSidebarTogle } = props.extra;

  const [activeStep, setActiveStep] = useState<number>(0);

  const { masterData, init } = useContext(AuthContext);

  const { addInvestor } = useContext(InvestorContext);

  const masterDataValue = masterData?.value();

  const [formState, setFormState] = useState<any>();

  const [loading, setLoading] = useState(false);

  const [contactPersons, setContacPerson] = useState<ContactPerson[]>([
    {
      contPerName: '',
      contPerEmail: '',
      contPerPhone: '',
    },
  ]);

  const [investorType, setinvestorType] = useState('');

  const investorTypes =
    masterDataValue?.investorTypes?.map((item: any, index: number) => {
      return {
        id: index + 1,
        name: item,
      };
    }) ?? [];

  const [investmentTypes, setinvestmentTypes] = useState(
    masterDataValue?.investmentTypes?.map((item: any) => {
      return {
        id: item.id,
        name: item.name,
        selected: false,
      };
    }) ?? []
  );

  const handleInvestmentType = (value: boolean, index: number) => {
    const temp = [...investmentTypes];
    if (value) {
      temp[index]['selected'] = !temp[index]['selected'];
    }
    setinvestmentTypes([...temp]);
  };

  const contactFormState = {
    investorName: '',
    investorType: '',
    investmentType: '',
    contactPersons: [
      {
        contPerName: '',
        contPerEmail: '',
        contPerPhone: '',
      },
    ],
  };

  const onNext = (values: any) => {
    const curentFormState = { ...formState, ...values };
    console.error(curentFormState);
    setFormState(curentFormState);
    if (activeStep >= 2) {
      submitForm(curentFormState);
    } else {
      setActiveStep(step => step + 1);
    }
  };

  const onPrevious = () => {
    if (activeStep <= 0) {
      setSidebarTogle(false);
    } else {
      setActiveStep(step => step - 1);
    }
  };

  const submitForm = async (curentFormState: any) => {
    setLoading(true);
    try {
      const response = await addInvestor(curentFormState);
      setLoading(false);
      if (response.success) {
        setSidebarTogle(false);
        toast.success(response.message);
        init();
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      setSidebarTogle(false);
      setLoading(false);
      console.error(e);
    }
  };

  return (
    <Box style={{ width: '90%', margin: 'auto' }}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          gap: 2,
          marginTop: 1,
        }}>
        <Box
          style={{
            width: '32%',
            height: '5px',
            borderRadius: 5,
            background: activeStep >= 0 ? '#104960' : '#C2C2C2',
          }}></Box>
        <Box
          style={{
            width: '32%',
            height: '5px',
            borderRadius: 5,
            background: activeStep >= 1 ? '#104960' : '#C2C2C2',
          }}></Box>
        <Box
          style={{
            width: '32%',
            height: '5px',
            borderRadius: 5,
            background: activeStep == 2 ? '#104960' : '#C2C2C2',
          }}></Box>
      </Box>
      <Box>
        <Box
          style={{
            display: activeStep === 0 ? 'block' : 'none',
          }}>
          <InvestorContactForm
            extra={{
              masterData: masterDataValue,
              onNext,
              onPrevious,
              contactPersons,
              setContacPerson,
              contactFormState,
              investorType,
              setinvestorType,
              handleInvestmentType,
              investmentTypes,
              setinvestmentTypes,
              investorTypes,
            }}
          />
        </Box>
        <Box
          style={{
            display: activeStep === 1 ? 'block' : 'none',
          }}>
          <InvestorInvestmentForm
            extra={{
              masterData: masterDataValue,
              onNext,
              onPrevious,
            }}
          />
        </Box>
        <Box
          style={{
            display: activeStep === 2 ? 'block' : 'none',
          }}>
          <InvetsorLocationForm
            extra={{
              masterData: masterDataValue,
              onNext,
              onPrevious,
              loading,
            }}
          />
        </Box>
      </Box>

      {/* <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bottom: 0,
          position: 'absolute',
          width: '99%',
          marginLeft: -2.5,
          background: 'white',
          paddingTop: '12px',
          paddingBottom: '8px',
          borderTopRightRadius: 3,
        }}>
        <Button
            extra={{ variant: 'outlined' }}
            onClick={onPrevious}
            style={{ width: '43%', marginRight: '2%', paddingBlock: 1 }}>
            {activeStep >= 1 ? 'Back' : 'Cancel'}
          </Button>
        <Button
          extra={{ variant: 'contained', btnDisableLoader:loading }}
          onClick={onNext}
          style={{ width: '43%', marginLeft: '2%', paddingBlock: 1 }}>
          {activeStep >= 2
            ? 'Done'
            : 'Continue'}
        </Button>
      </Box> */}
    </Box>
  );
}

export default AddInvestor;
