import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import {
  // PREFERENCEKEYS,
  getAuthToken,
  setAuthToken,
} from '../services/PreferenceService';
import { ROUTES } from '../types/routeObject';

class Axios {
  protected axiosInstance: AxiosInstance;
  // private baseURL: string = 'http://localhost:5000';
  private baseURL: string = 'https://api.klaxonadvisory.co.in';

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: this.baseURL,
    });

    this.setupInterceptors();
  }

  get getBaseUrl() {
    return this.baseURL;
  }

  private setupInterceptors() {
    this.axiosInstance.interceptors.request.use(
      config => {
        const userToken = getAuthToken();
        if (userToken) {
          config.headers['Authorization'] = `Bearer ${userToken}`;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use(
      response => {
        // Add any common response logic here
        console.error('from resonse', response);
        return response;
      },
      (error: AxiosError) => {
        console.error('from error', error?.response?.status ?? 500);
        if ((error?.response?.status ?? 500) == 401) {
          if (window.location.pathname != ROUTES.LOGIN) {
            setAuthToken('');
            window.location.replace(ROUTES.LOGIN);
          }
        }

        return error;
      }
    );
  }

  async get<T>(
    url: string,
    params?: object,
    contentType?: string
  ): Promise<AxiosResponse<T>> {
    return this.axiosInstance.get(url, {
      params,
      headers: {
        'Content-Type': contentType,
      },
    });
  }

  async patch<T>(url: string, data?: object): Promise<AxiosResponse<T>> {
    return this.axiosInstance.patch(url, data);
  }

  async post<T>(url: string, data: object): Promise<AxiosResponse<T>> {
    return this.axiosInstance.post(url, data);
  }

  async put<T>(url: string, data: object): Promise<AxiosResponse<T>> {
    return this.axiosInstance.put(url, data);
  }

  async delete<T>(url: string): Promise<AxiosResponse<T>> {
    return this.axiosInstance.delete(url);
  }
}

export default Axios;
