import React, { useEffect, useState } from 'react';
import Props from '../types/props';
import SalesExecutiveContext from '../context/SalesExecutiveContext';
import SalesExecutiveService from '../services/SalesExecutiveService';

export default function SalesExecutiveProvider(props: Props) {
  const [SalesExecutive, setSalesExecutive] = useState({});
  const [SalesData, setSalesData] = useState({});
  const [loading, setLoading] = useState(false);

  const getSalesExecutive = async (page: number, size: number, filter: any) => {
    setLoading(true);
    const data = await new SalesExecutiveService().getAllSalesExecutive(
      page,
      size,
      filter
    );
    setLoading(false);
    setSalesExecutive(data);
  };

  const getSalesExecutiveById = async (
    id: number,
    page: number,
    size: number
  ) => {
    const data = await new SalesExecutiveService().getById(
      `admin/getSalesById?userId=${id}&page=${page}&size=${size}`
    );
    setSalesData(data);
  };

  const addSalesExecutive = async (data: any) => {
    setLoading(true);
    const response = await new SalesExecutiveService().addSalesExecutive(data);
    setLoading(false);

    if (response.success) {
      await getSalesExecutive(1, 10, {});
    }

    return response;
  };

  useEffect(() => {
    getSalesExecutive(1, 10, {});
  }, []);

  return (
    <SalesExecutiveContext.Provider
      value={{
        SalesExecutive,
        getSalesExecutiveById,
        SalesData,
        setLoading,
        loading,
        getSalesExecutive,
        addSalesExecutive,
      }}>
      {props.children}
    </SalesExecutiveContext.Provider>
  );
}
