import React, { useEffect, useState } from 'react';
import Props from '../types/props';
import DashboardService from '../services/DashboardService';
import DashboardContext from '../context/Dashboard';

export default function DashBoardProvider(props: Props) {
  const [DashBoardData, setDashBoardData] = useState({});
  const [loading, setLoading] = useState(true);


  const getDashboardData = async (
    query: string,
    filter: Record<string, unknown>
  ) => {
    try {
      setLoading(true);
      const data = await new DashboardService().fetchUserData(query, filter);
      setLoading(false);
      setDashBoardData(data as any);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };

  useEffect(() => {
    getDashboardData(`/admin/dashboard`, {});
  }, []);

  if (Object.keys(DashBoardData).length === 0) {
    return null; // or any loading indicator if needed
  }

  return (
    <DashboardContext.Provider value={{ DashBoardData, getDashboardData, setLoading,
      loading, }}>
      {props.children}
    </DashboardContext.Provider>
  );
}
