import React from 'react';
import Box from '../single_component/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Text from '../single_component/Text';
import Props from '../../types/props';

function FormHead({ ...props }: Props) {
  const { page } = props.extra;
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 2,
        borderBottom: '1px solid #f0f0f0',
        padding: '16px 24px',
        background: page == 'ProjectDetails' ? '#E4F7FF' : 'white',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
      }}>
      <InfoOutlinedIcon style={{ fontSize: 20 }} />
      <Text style={{ fontSize: 14 }}>{props.label}</Text>
    </Box>
  );
}

export default FormHead;
