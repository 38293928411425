import React from 'react';
import Props from '../../types/props';
import Box from '../single_component/Box';
import TextLabel from '../single_component/TextLabel';
import DropdownSelectBold from '../single_component/DropdownSelectBold';

function DropdownlBold({ ...props }: Props = {}) {
  const { options, placeholder } = props.extra ?? {};
  return (
    <Box style={{ width: '100%', backgroundColor: 'white' }}>
      <TextLabel> {props.label}</TextLabel>
      <DropdownSelectBold
        value={props.value}
        name={props.name}
        id={props.id}
        onBlur={props.onBlur}
        onChange={props.onChange}
        extra={{
          options,
          placeholder,
        }}
        style={{ width: '100%', height: 43 }}
      />
    </Box>
  );
}

export default DropdownlBold;
