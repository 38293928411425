import React from 'react';
import userIcon from '../../assets/images/user.png';
import Text from '../single_component/Text';
import Image from '../single_component/Image';
import Box from '../single_component/Box';
import Circle from '@mui/icons-material/Circle';
import Priority from './Priority';
import Props from '../../types/props';
import calenderIcon from '../../assets/calendar.png';
import StringUtils from '../../utils/stringUtil';
import moment from 'moment';

function ToDoCompleteCreation({ ...props }: Props) {
  const { value } = props.extra;
  return (
    <Box
      {...props}
      style={{
        gap: 2,
        borderBottom: '1px solid #EDEDED ',
        padding: '12px 14px',
      }}>
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box style={{ display: 'flex', gap: 2 }}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 1,
              }}>
              <Circle sx={{ fontSize: 8 }} color="primary" />
              <Text style={{ fontSize: 14, fontWeight: 'bolder' }}>
                {StringUtils.capitalizeFirstLetter(value?.subject)}
              </Text>
            </Box>
          </Box>
          <Priority label={value?.priority} />
        </Box>

        <Box style={{ marginTop: 0.7 }}>
          <Text style={{ fontSize: 13, color: '#667085' }}>
            {StringUtils.capitalizeFirstLetter(value?.note)}
          </Text>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            marginTop: 0.7,
            color: '#104960',
          }}>
          <Image
            src={calenderIcon}
            style={{ width: 20, height: 20, objectFit: 'cover' }}
          />
          <Text style={{ color: '#104960', fontWeight: 500, fontSize: 13 }}>
            {moment(value.startDate, 'YYYY-MM-DD').format('MMM DD, YYYY')} -{' '}
            {moment(value.endDate, 'YYYY-MM-DD').format('MMM DD, YYYY')}
          </Text>
          <Circle sx={{ fontSize: 8, color: 'gray' }} />
          <Image src={userIcon} style={{ marginRight: -12 }} />
          <Text style={{ color: '#104960', fontSize: 14 }}>
            {StringUtils.capitalizeFirstLetter(value?.assignedTo?.name)}
          </Text>
          <Box style={{ display: 'flex', alignItems: 'center', gap: 2 }}></Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ToDoCompleteCreation;
