import { useState } from 'react';
import { StatePair } from '../types/StatePair';

export function useStateManager<T>(
  initialValue: T,
  localKey?: string,
  strigify: boolean = false
): StatePair<T> {
  const [currentValue, setValue] = useState<T>(() => {
    let storedValue = localStorage.getItem(localKey ?? '');
    if (strigify) {
      try {
        storedValue = JSON.parse(localStorage.getItem(localKey ?? '') ?? '');
      } catch (e) {
        console.error(e);
      }
    }
    return storedValue ? (storedValue as T) : initialValue;
  });

  const setValueAndStore = (newValue: T) => {
    setValue(newValue);
    if (localKey) {
      try {
        strigify
          ? localStorage.setItem(localKey, JSON.stringify(newValue))
          : localStorage.setItem(localKey, newValue as string);
      } catch (e) {
        localStorage.setItem(localKey, newValue as string);
      }
    }
  };

  const removeValue = () => {
    setValue(initialValue);
    if (localKey) {
      localStorage.removeItem(localKey);
    }
  };

  return {
    value: () => currentValue,
    setValue: setValueAndStore,
    removeValue: removeValue,
  };
}
