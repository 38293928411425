import React, { useState } from 'react';
import Props from '../../types/props';
import Box from '../../components/single_component/Box';
import Text from '../../components/single_component/Text';
import Button from '../../components/single_component/Button';
import Sidebar from '../../components/common_component/Sidebar';
import AddOutlined from '@mui/icons-material/AddOutlined';
import UploadFile from '../../components/Forms/UploadFile';

function DashTableTop({ ...props }: Props = {}) {
  const { label, description } = props.extra;
  const [AddGroup, setAddGroup] = useState(false);
  const [errorSub, seterrorSub] = useState('');
  const [errorUpload, seterrorUpload] = useState('');
  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '12px 24px 0px 24px ',
          background: 'white',
          borderRadius: '5px 10px 0  0',
        }}>
        <Box>
          <Text style={{ fontSize: 15, fontWeight: 'bold' }}>{label}</Text>
          <Text style={{ fontSize: 14, color: '#667085' }}>{description}</Text>
        </Box>

        <Box style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Button
            onClick={() => setAddGroup(!AddGroup)}
            style={{ padding: '8px 24px' }}
            extra={{
              variant: 'contained',
              color: 'primary',
            }}
            icons={<AddOutlined />}>
            <Text style={{ color: 'white', fontSize: 13 }}>
              Add Data format
            </Text>
          </Button>
        </Box>

        <Sidebar
          label="Developer data format"
          extra={{
            anchor: 'right',
            Toggle: AddGroup,
            setToggle: setAddGroup,
            description: 'Add developer data format here.',
            width: 400,
          }}>
          <UploadFile
            extra={{
              setToggle: setAddGroup,
              seterrorUpload,
              seterrorSub,
              errorSub,
              errorUpload,
            }}
          />
        </Sidebar>
      </Box>
    </Box>
  );
}

export default DashTableTop;
