import { toast } from 'react-toastify';
import Axios from '../config/axios';
import ApiResponse from '../utils/ApiResponse';
import Todo from '../models/Todo';

class TodoService extends Axios {
  constructor() {
    super();
  }

  async addTask(data: any) {
    const response = await this.post<JSON>(`/todo/add`, data);

    const finalResponse = new ApiResponse(response);
    const success = finalResponse.getSuccess();
    const message = finalResponse.getMessage();
    const error = finalResponse.getMessage();
    return { success, message, error };
  }

  async getTodo(page: number, size: number, filter: Record<string, unknown>) {
    const response = await this.post<JSON>(
      `/todo/get?page=${page}&size=${size}`,
      filter
    );
    if (response.status != 200) {
      toast.error('Request failed. Please try again later.');
    }
    const finalResponse = new ApiResponse(response);
    const success = finalResponse.getSuccess();
    const data = Todo.fromJson(finalResponse.getData() as any);
    return { data, success };
  }

  async filterTodo(data: any) {
    const response = await this.post<JSON>(`/todo/filter`, data);
    const finalResponse = new ApiResponse(response);
    const parsedData = finalResponse.getData();
    const success = finalResponse.getSuccess();
    const message = finalResponse.getMessage();
    return { parsedData, success, message };
  }
}

export default TodoService;
