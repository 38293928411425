import Axios from '../config/axios';
import Profile from '../models/Profile';
import ApiResponse from '../utils/ApiResponse';

class ProfileService extends Axios {
  constructor() {
    super();
  }

  async fetchProfile(query: string) {
    const response = await this.get<JSON>(`${query}`);
    const apiResponse = new ApiResponse(response);
    const parsedData = new Profile(apiResponse.getData() as any);
    return parsedData;
  }

  async updateProfile(query: string, body: any) {
    const response = await this.patch<JSON>(`${query}`, body);
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
  async updateSalesExcetiveProfile(userId: number, body: any) {
    const response = await this.patch<JSON>(`/admin/edit/${userId}`, body);
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }

  async deleteSalesExecutiveProfileImg(userId: number) {
    const response = await this.delete<JSON>(`/admin/user/img/${userId}`);
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
  async deleteProfileImage() {
    const response = await this.delete<JSON>(`/profile/remove`);
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
}

export default ProfileService;
