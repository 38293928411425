import React, { useContext } from 'react';
import Box from '../single_component/Box';
import DeveloperTableTop from './DeveloperTableTop';
import FileChip from '../single_component/FileChip';
import RemoveRedEyeOutlined from '@mui/icons-material/RemoveRedEyeOutlined';
import DeveloperCard from './DeveloperCard';
import Sidebar from '../common_component/Sidebar';
import DeveloperGroup from '../Action/DeveloperGroup';
import DevContacatDetails from './DevGroupContactDetails';
import { TableCell, TableRow } from '@mui/material';
import DeveloperGroupContext from '../../context/DeveloperGroupContext';
import Text from '../single_component/Text';
import { useNavigate } from 'react-router-dom';
import Props from '../../types/props';
import StringUtils from '../../utils/stringUtil';
import AuthContext from '../../context/AuthContext';
import { ROLE, ROUTES } from '../../types/routeObject';
import NotfoundPage from '../../components/single_component/NotfoundPage';
import Loader from '../../components/single_component/Loader';
import SalesExecutiveContext from '../../context/SalesExecutiveContext';
import Tables from '../single_component/Tables';

function DeveloperGroupTable({ ...props }: Props) {
  const { DeveloperGroupData } = useContext(DeveloperGroupContext);

  const [Toggle, setToggle] = React.useState(false);
  const {
    setdeveloperGroupcurrPage,
    developerGroupcurrPage,
    setdeveloperGroupPrevious,
    developerGroupPrevious,
    setdeveloperGroupNext,
    developerGroupNext,
    setdeveloperGrouprowsPerPage,
    setdeveloperGroupPage,
    developerGroupPage,
    developerGrouprowsPerPage,
  } = props.extra;
  const [SidebarData, setSidebarData] = React.useState({});
  const { role } = useContext(AuthContext);
  const navigate = useNavigate();
  const { loading } = useContext(SalesExecutiveContext);
  const data = DeveloperGroupData ?? props.extra.data;
  setdeveloperGroupPage(data?.totalPages);
  setdeveloperGroupNext(data?.nextPage);
  setdeveloperGroupPrevious(data?.prevPage);
  const TableRowComponet = (props: any) => {
    const { row } = props;
    return (
      <TableRow>
        <TableCell>
          <DeveloperCard
            label={StringUtils?.capitalizeFirstLetter(row?.name)}
            disabled={(role?.value() ?? ROLE.ADMIN) == ROLE.EXECUTIVE}
            onClick={() =>
              navigate(
                `${ROUTES.DEVELOPER_GROUP}/${ROUTES.VIEW_DEVELOPER_GROUP.replace(
                  ':id',
                  row.developerId
                )}`,
                {
                  state: {
                    groupName: row.name,
                    promoterName: row.promoter_name,
                    promoterEmail: row.email,
                  },
                }
              )
            }
            extra={{
              groupLogo: row?.groupLogo,
            }}
          />
        </TableCell>
        <TableCell>
          <Text>
            {StringUtils?.capitalizeFirstLetter(row?.city?.name ?? 'N/A')}
          </Text>
        </TableCell>
        <TableCell>
          <Text>
            {StringUtils?.capitalizeFirstLetter(
              row?.promoters[0]?.promoterName ?? 'N/A'
            )}
          </Text>
        </TableCell>

        <TableCell>
          {row?.promoters[0]?.promoterPhone &&
          row?.promoters[0]?.promoterEmail ? (
            <DevContacatDetails
              extra={{
                phone: row?.promoters[0]?.promoterPhone,
                email: row?.promoters[0]?.promoterEmail,
              }}
            />
          ) : (
            <Text>N/A</Text>
          )}
        </TableCell>
        <TableCell>
          <FileChip
            label="GroupProfile"
            extra={{ url: row?.groupProfile ?? 'N/A' }}
          />
        </TableCell>
        <TableCell>
          <RemoveRedEyeOutlined
            onClick={() => {
              setSidebarData(row);
              setToggle(true);
            }}
          />
        </TableCell>
      </TableRow>
    );
  };

  const tableHeaders = [
    { id: 'Group', name: 'Group' },
    {
      id: 'Location',
      name: 'Location',
    },
    {
      id: 'ContactPerson',
      name: 'Contact Person',
    },
    {
      id: 'ContactDetails',
      name: 'Contact Details',
    },
    {
      id: 'GroupProfile',
      name: 'Group Profile',
    },
    {
      id: 'Action',
      name: 'Action',
    },
  ];

  return (
    <Box
      style={{
        width: '85%',
        margin: 'auto',
        boxShadow: ' 0px 2px 10px 0px #10496030',
        borderRadius: 2,
      }}>
      <DeveloperTableTop
        extra={{
          label: 'Developer Group',
          description:
            'Here you can create developer data format by uploading files',
        }}
      />

      {loading ? (
        <Loader />
      ) : data.length !== 0 ? (
        <Tables
          extra={{
            rows: data.records ?? [],
            columns: tableHeaders,
            RowComponent: TableRowComponet,
            page: developerGroupPage,
            setPage: setdeveloperGroupPage,
            rowsPerPage: developerGrouprowsPerPage,
            setrowsPerPage: setdeveloperGrouprowsPerPage,
            Next: developerGroupNext,
            Previous: developerGroupPrevious,
            currPage: developerGroupcurrPage,
            setcurrPage: setdeveloperGroupcurrPage,
            setNext: setdeveloperGroupNext,
            setPrevious: setdeveloperGroupPrevious,
          }}
        />
      ) : (
        <div>
          <NotfoundPage />
        </div>
      )}

      <Sidebar
        label="Group Details"
        extra={{
          anchor: 'right',
          Toggle,
          setToggle,
          description: 'Add group details here to view in the dashboard',
          width: 420,
        }}>
        <DeveloperGroup extra={{ setToggle, data: SidebarData }} />
      </Sidebar>
    </Box>
  );
}

export default DeveloperGroupTable;
