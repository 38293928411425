import React, { useEffect, useState } from 'react';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import Button from '../single_component/Button';
import Props from '../../types/props';
import { TextField } from '@mui/material';
import theme from '../../utils/Theme';

function VerifyOtp({ ...props }: Props) {
  const {
    onVerifyOTP,
    values,
    setOtp,
    Otp,
    handleSubmit,
    setVerify,
    loadingOtp,
  } = props.extra;
  const [timeLeft, setTimeLeft] = useState(30);

  const EditHandle = () => {
    setVerify(false);
  };
  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Backspace' && index > 0 && !Otp[index]) {
      const updatedOTPValues = [...Otp];
      updatedOTPValues[index - 1] = '';
      setOtp(updatedOTPValues);
      document.getElementById(`otp-input-${index - 1}`)?.focus();
    }
  };

  const handleOTPChange = (index: number, value: string) => {
    if (value.match(/^\d*$/) && value.length <= 1) {
      const updatedOTPValues = [...Otp];
      updatedOTPValues[index] = value;
      setOtp(updatedOTPValues);
      if (index < Otp.length - 1 && value !== '') {
        document.getElementById(`otp-input-${index + 1}`)?.focus();
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  return (
    <Box style={{ width: 470 }}>
      <Text style={{ fontSize: 42, fontWeight: 'bold' }}>
        Verify and log in
      </Text>
      <Text style={{ fontSize: 14, color: '#98A2B3', maxWidth: 380 }}>
        Sign in to access your account and manage your investment portfolio with
        ease
      </Text>

      <Box
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          marginTop: 3,
        }}>
        <Text style={{ fontSize: 14, color: '#104960', fontWeight: 'normal' }}>
          {values.email}
        </Text>
        <Text
          onClick={EditHandle}
          style={{
            fontSize: 14,
            color: theme.palette.secondary.main,
            fontWeight: 'normal',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}>
          Edit
        </Text>
      </Box>

      <Box
        style={{
          marginTop: 2,
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        {Otp.map((value: number, index: number) => (
          <Box style={{ width: 60 }} key={index}>
            <TextField
              id={`otp-input-${index}`}
              style={{ backgroundColor: '#fff' }}
              placeholder="0"
              sx={{
                width: '100%',
                textAlign: 'center',
                marginBlock: 2,
                fontWeight: 'bold',
              }}
              inputProps={{
                maxLength: 1,
                style: { textAlign: 'center', height: 10 },
              }}
              value={value}
              onChange={(e: any) => handleOTPChange(index, e.target.value)}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyDown(index, e)
              }
            />
          </Box>
        ))}
      </Box>

      <Box style={{ margin: '15px 0' }}>
        <Button
          style={{ borderRadius: 1, width: '100%', padding: 1.5 }}
          extra={{
            variant: 'contained',
            color: 'primary',
            btnDisableLoader: loadingOtp,
          }}
          onClick={onVerifyOTP}>
          <Text style={{ fontSize: 14 }}>Confirm OTP</Text>
        </Button>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 5,
        }}>
        {timeLeft === 0 ? (
          <Text
            onClick={() => {
              handleSubmit();
              setTimeLeft(30);
            }}
            style={{
              fontSize: 14,
              color: theme.palette.secondary.main,
              fontWeight: 'normal',
              cursor: 'pointer',
            }}>
            Resend Otp
          </Text>
        ) : (
          <Text
            style={{
              fontSize: 14,
              color: theme.palette.secondary.main,
              fontWeight: 'normal',
            }}>
            Resend Otp in 00: {timeLeft > 9 ? timeLeft : `0${timeLeft}`} sec
          </Text>
        )}
      </Box>
    </Box>
  );
}

export default VerifyOtp;
