import React from 'react';
import SalesDashContainer from '../Container/SalesDashContainer';
import Box from '../../components/single_component/Box';
import DeveloperGroupProvider from '../../providers/DeveloperGroupProvider';
import SalesDashBoardProvider from '../../providers/SalesDashboardProvider';

function SalesDashboard() {
  return (
    <DeveloperGroupProvider>
      <SalesDashBoardProvider>
        <Box
          style={{
            backgroundColor: '#F2FAFE',
            minHeight: '100vh',
          }}>
          <SalesDashContainer />
        </Box>
      </SalesDashBoardProvider>
    </DeveloperGroupProvider>
  );
}

export default SalesDashboard;
