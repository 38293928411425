import { Moment } from 'moment';
import DateUtils from '../utils/dateUtils';

class Location {
  id: number;
  name: string;
  isActive: boolean;
  createdAt: Moment | null;
  updatedAt: Moment | null;

  constructor(data: Record<string, unknown>) {
    this.id = parseInt((data.cityId ?? 'N/A').toString());
    this.name = (data.cityName ?? 'N/A').toString();
    this.isActive = Boolean(data.isActive);
    this.createdAt = DateUtils.parseDate((data.createdAt ?? 'N/A').toString());
    this.updatedAt = DateUtils.parseDate((data.updatedAt ?? 'N/A').toString());
  }

  static fromJson(json: Record<string, unknown>): Location {
    return new Location(json);
  }
  static fromIds(json: string[]): Location[] {
    // return new Location(json);
    JSON.parse(json.toString());
    return [];
  }

  toJson(stringify: boolean = false): Record<string, unknown> | string {
    const jsonData = {
      cityId: this.id,
      cityName: this.name,
      isActive: this.isActive,
      createdAt: this.createdAt?.toISOString(),
      updatedAt: this.updatedAt?.toISOString(),
    };

    return stringify ? JSON.stringify(jsonData) : jsonData;
  }
}

export default Location;
