import * as Yup from 'yup';
import Message from '../../utils/message';
const emailRegex = /^[\w-/.]+@([\w-]+\.)+[\w-]{2,4}$/g;
const CheckPhoneTen = /^[1-9][0-9]{9}$/;

{
  /* .replace(/^[/d-]|[-+]-|[^0-9-]+|^[\d]+[-][\d]+[-+]/, ''); */
}

//Accept digit and dacimal numbers
export const digitRexAcceptDecimal =
  /^[/d-]|[-+]-|[^0-9.-]+|^[\d]+[-][\d]+[-+]/;

const Type = {
  String: Yup.string(),
  Mixed: Yup.mixed(),
};
const messageObj = new Message();

const AddTaskSchema = Yup.object().shape({
  subject: Yup.string().required(messageObj.required('Subject')),
  notes: Yup.string().optional(),
  selectedOption: Yup.string().optional(),
  selectedSalesExecutive: Yup.string().test(
    'required',
    messageObj.required('Sales executive'),
    function (value) {
      const { selectedOption } = this.parent;
      return !(selectedOption === 'Sales Executive' && !value);
    }
  ),
  startDate: Yup.string().optional(),
  endDate: Yup.string().optional(),
  priority: Yup.string().optional(),
});

const AddExecutive = Yup.object({
  name: Type.String.required(messageObj.required('Name')).min(
    3,
    messageObj.length('Name', 3)
  ),
  phone: Type.String.min(
    3,
    messageObj.length('Phone number', 3, undefined)
  ).matches(CheckPhoneTen, messageObj.phone('Phone number')),
  email: Type.String.matches(emailRegex, messageObj.invalid('Email')).min(
    3,
    messageObj.length('Email', 3, undefined)
  ),
  location: Type.String,
});

const AddGroup = Yup.object({
  Name: Type.String.optional()
    .min(3, messageObj.length('Name', 3, undefined))
    .matches(/[a-zA-Z]/, 'Name should have atleast one character')
    .optional(),
  location: Type.String.optional(),
  GroupLogo: Type.Mixed.optional(),
  promoters: Yup.array().of(
    Yup.object().shape({
      promoterName: Type.String.optional().matches(
        /^\D/,
        'Cannot start with a number'
      ),
      promoterEmail: Type.String.email(messageObj.invalid('Promoter email'))
        .matches(emailRegex, messageObj.invalid('Promoter email'))
        .optional(),
      promoterPhone: Type.String.matches(
        CheckPhoneTen,
        messageObj.phone('Promoter')
      ).optional(),
    })
  ),
  GroupProfile: Type.Mixed.optional(),
});

const AddSpvFormSchema = Yup.object().shape({
  Name: Type.String.min(
    3,
    messageObj.length('SPV name', 3, undefined)
  ).optional(),
  AuthorizedShareCapital: Type.String.optional(),
  DirectorName: Type.Mixed.optional(),

  Value: Type.String.optional(),
  Gst: Type.Mixed.optional(),
  Pan: Type.Mixed.optional(),
  Incorporation: Type.Mixed.optional(),
  Director: Type.Mixed.optional(),
  Shareholder: Type.Mixed.optional(),
});

const holdTransactionSchema = Yup.object().shape({
  note: Type.String.required(messageObj.required('reason')),
});
const invoiceValidationSchema = Yup.object().shape({
  date: Yup.string(),
  invoiceNumber: Yup.string(),
  company: Yup.string(),
  companygst: Yup.string(),
  companyAddress: Yup.string(),
  subject: Yup.string(),
  invoiceDate: Yup.string(),
  description: Yup.string(),
  amount: Yup.string(),
  cgst: Yup.string(),
  sgst: Yup.string(),
  igst: Yup.string(),
  pan: Yup.string(),
  holderName: Yup.string(),
  accountId: Yup.string(),
  partnerId: Yup.string(),
});

const contactPersonValidation = Yup.object({
  name: Yup.string(),
  email: Yup.string().email('Invalid email address'),
  phone: Yup.string(),
});

const investmentThesisValidation = Yup.object({
  investmentThesis: Yup.string().required('Investment thesis is required'),
  meetingDate: Yup.string(),
});

const activeMarketsValidation = Yup.object({
  activeLocation: Yup.string().required('Acitve Market Location is required'),
});

const addInvestorThesisValidation = Yup.object({
  text: Yup.string().required('Investment thesis is required'),
  meetingDate: Yup.string(),
});

const addTransactionValidation = Yup.object({
  document: Yup.string(),
  transcBrief: Yup.string(),
  date: Yup.string(),
  ['Deal size']: Yup.string().test('Invalid Deal size range', function (value) {
    if (!value) return true;
    const parts = value.split('-').map(Number);
    if (parts.length === 1) return true;
    const [min, max] = parts;
    return max >= min;
  }),
  remark: Yup.string(),
  investors: Yup.string(),
});

const addInvestorContactFormSchema = Yup.object().shape({
  investorName: Type.String.min(
    3,
    messageObj.length('Investor name', 3, undefined)
  ),
  investorType: Type.String,
  investmentType: Type.String,
  contactPersons: Yup.array().of(
    Yup.object().shape({
      contPerName: Type.String.min(
        3,
        messageObj.length('Contact person name', 3, undefined)
      ),
      contPerEmail: Type.String.matches(emailRegex, messageObj.invalid('Email'))
        .email(messageObj.invalid('Email'))
        .min(3, messageObj.length('Email ID', 3, undefined)),
      contPerPhone: Type.String.trim().matches(
        CheckPhoneTen,
        messageObj.phone('Phone number')
      ),
    })
  ),
});
const addInvestorInvestmentSchema = Yup.object().shape({
  dealSizePreference: Yup.string().test(
    'dryPowderValidation',
    'Invalid dry powder range',
    function (value) {
      if (!value) return true;
      const parts = value.split('-').map(Number);
      if (parts.length === 1) return true;
      const [min, max] = parts;
      return max >= min;
    }
  ),
  dryPowder: Yup.string().test(
    'dryPowderValidation',
    'Invalid dry powder range',
    function (value) {
      if (!value) return true; // If value is empty, let required validator handle it
      const parts = value.split('-').map(Number);
      if (parts.length === 1) return true; // If only one value is entered, no error
      const [min, max] = parts;
      return max >= min;
    }
  ),
  dealType: Yup.string(),
  investmentCriteria: Yup.string(),
  interestRange: Yup.string().test(
    'dryPowderValidation',
    'Invalid dry powder range',
    function (value) {
      if (!value) return true;
      const parts = value.split('-').map(Number);
      if (parts.length === 1) return true;
      const [min, max] = parts;
      return max >= min;
    }
  ),
  remark: Type.String,
});

const addInvestorLocationSchema = Yup.object().shape({
  activeMarkets: Type.String,
  locationPreference: Type.String,
});

const investorFormSchema = Yup.object().shape({});
const InvestorDataFormateSchema = Yup.object().shape({
  GroupLogo: Yup.mixed(),
});

export {
  AddExecutive,
  AddGroup,
  AddSpvFormSchema,
  holdTransactionSchema,
  invoiceValidationSchema,
  AddTaskSchema,
  contactPersonValidation,
  investmentThesisValidation,
  activeMarketsValidation,
  addInvestorThesisValidation,
  addInvestorInvestmentSchema,
  addTransactionValidation,
  investorFormSchema,
  InvestorDataFormateSchema,
  addInvestorContactFormSchema,
  addInvestorLocationSchema,
};
