import React, { useState, useContext } from 'react';
import Box from '../single_component/Box';
import ToDoCompleteCreation from '../common_component/ToDoCompleteCreation';
import Text from '../single_component/Text';
import Sidebar from '../common_component/Sidebar';
// import AddTask from '../Forms/AddTask';
import TodoFilter from '../Filter/SalesTodoFilter';
import Button from '../single_component/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FilterList from '@mui/icons-material/FilterList';
import Props from '../../types/props';
import NotfoundPage from '../../components/single_component/NotfoundPage';
import Loader from '../../components/single_component/Loader';
import SalesExecutiveContext from '../../context/SalesExecutiveContext';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { MenuItem, Pagination, Select, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AuthContext from '../../context/AuthContext';
import TodoContext from '../../context/TodoContext';
import DateUtils from '../../utils/dateUtils';
import SalesTodoAddTask from '../Forms/SalesTodoAddTask';

function TodoSales({ ...props }: Props) {
  const [Toggle, setToggle] = useState(false);
  const [FilterSidebar, setFilterSidebar] = useState(false);
  const { loading } = useContext(SalesExecutiveContext);

  const useStyles = makeStyles((theme: Theme) => ({
    pageBar: {
      '& .MuiPaginationItem-page.Mui-selected': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  }));
  const classes = useStyles();

  const {
    TodoData,
    userId,
    username,
    previous,
    setcurrPage,
    currPage,
    page,
    rowsPerPage,
    setrowsPerPage,
    options,
    next,
  } = props.extra;
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const { masterData } = useContext(AuthContext);
  const { getTodo } = useContext(TodoContext);
  const data = masterData?.value();
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);

  const [selectedChip, setSelectedChip] = React.useState(-1);

  const onCancel = () => {
    setStartDate('select start date');
    setEndDate('select end date');
    setSelectedChip(-1);
  };

  const handleChipClick = (index: number) => {
    setSelectedChip(index);
  };

  const handleExecutiveFilterTodo = async () => {
    setbtnDisableLoader(true);
    const assignedTo = userId;
    const prioritize = data?.priorities[selectedChip];

    const startDate = DateUtils.formatDate(StartDate, 'YYYY-MM-DD');
    const endDate = DateUtils.formatDate(EndDate, 'YYYY-MM-DD');

    try {
      const responce = await getTodo(1, 10, {
        ...(assignedTo && { assignedTo }),
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
        ...(prioritize && { priority: prioritize }),
      });
      if (responce.success) {
        setbtnDisableLoader(false);
        setFilterSidebar(false);
      } else {
        setbtnDisableLoader(false);
      }
    } catch (e) {
      setbtnDisableLoader(false);
      console.error(e);
    }
  };
  return (
    <Box
      style={{
        width: '85%',
        margin: 'auto',
        backgroundColor: 'white',
        padding: 2,
        boxShadow: ' 0px 2px 10px 0px #10496030',
        borderRadius: 2,
      }}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 1.5,
            justifyContent: 'center',
          }}>
          <Text style={{ fontWeight: 'bold' }}>To-Do’s</Text>
          <Text style={{ fontSize: 14, fontWeight: 400, color: '#667085' }}>
            Here you can add and view all your tasks.
          </Text>
        </Box>

        <Box style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Button
            onClick={() => setFilterSidebar(!FilterSidebar)}
            extra={{
              variant: 'outlined',
              color: 'tertiary',
            }}
            style={{ padding: '8px 24px', borderRadius: 1.5 }}
            icons={<FilterList />}>
            <Text style={{ color: 'black', fontSize: 13 }}>Add Filters</Text>
          </Button>

          <Button
            onClick={() => setToggle(!Toggle)}
            style={{ padding: '8px 24px', borderRadius: 1.5 }}
            extra={{
              variant: 'contained',
            }}
            icons={<AddOutlinedIcon />}>
            <Text style={{ color: 'white', fontSize: 13 }}>Add Task</Text>
          </Button>
        </Box>
      </Box>

      <Sidebar
        label="Add Task"
        extra={{
          anchor: 'right',
          Toggle,
          setToggle,
          description: 'Add your task here for any work',
          width: 400,
        }}>
        <SalesTodoAddTask
          extra={{
            setToggle,
            page: 'sales-executive',
            userId: userId,
            username: username,
          }}
        />
      </Sidebar>

      <Sidebar
        label="Filter"
        extra={{
          anchor: 'right',
          Toggle: FilterSidebar,
          setToggle: setFilterSidebar,
          description: 'Filter the data as per your need',
          width: 400,
        }}>
        <TodoFilter
          extra={{
            setToggle: setFilterSidebar,
            selectedChip,
            handleFilter: handleExecutiveFilterTodo,
            handleChipClick,
            onCancel,
            btnDisableLoader,
            EndDate,
            setEndDate,
            StartDate,
            setStartDate,
          }}
        />
      </Sidebar>

      {loading ? (
        <Loader />
      ) : (
        <Box style={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {TodoData.length != 0 ? (
            TodoData.map((value: any, index: number) => {
              return <ToDoCompleteCreation extra={{ value }} key={index} />;
            })
          ) : (
            <NotfoundPage />
          )}
        </Box>
      )}
      <Box
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          paddingInline: 3,
        }}>
        {previous != null ? (
          <Button
            icons={<WestIcon style={{ fontSize: 14, color: 'black' }} />}
            onClick={() => setcurrPage(currPage == 0 ? currPage : currPage - 1)}
            extra={{
              variant: 'outlined',
              color: 'tertiary',
            }}>
            <Text style={{ fontSize: 12, fontWeight: 500, color: 'black' }}>
              Previous
            </Text>
          </Button>
        ) : (
          <Box></Box>
        )}

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Pagination
            count={page}
            page={currPage}
            shape="rounded"
            color="secondary"
            variant="text"
            hidePrevButton
            hideNextButton
            onChange={(e, page) => {
              setcurrPage(page);
            }}
            className={classes.pageBar}
          />
          <Select
            value={rowsPerPage}
            sx={{ width: 100, height: 35, marginLeft: 5 }}
            defaultValue={options[0].name}
            onChange={(e: any) => {
              setrowsPerPage(e.target.value);
            }}>
            {options.map((value: any, index: React.Key | null | undefined) => {
              return (
                <MenuItem value={value.name} key={index}>
                  <Text style={{ fontSize: 13, fontWeight: 'normal' }}>
                    {value.name} / Page
                  </Text>
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        {next != null ? (
          <Button
            icons2={<EastIcon style={{ fontSize: 14, color: 'black' }} />}
            onClick={() => {
              setcurrPage(currPage + 1);
            }}
            extra={{
              variant: 'outlined',
              color: 'tertiary',
            }}>
            <Text style={{ fontSize: 12, fontWeight: 500, color: 'black' }}>
              Next
            </Text>
          </Button>
        ) : (
          <Box></Box>
        )}
      </Box>
    </Box>
  );
}

export default TodoSales;
