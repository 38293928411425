import Axios from '../config/axios';
import ApiResponse from '../utils/ApiResponse';

class TransactionService extends Axios {
  constructor() {
    super();
  }

  async getAllTransaction(page: number, size: number, filters: any) {
    console.error(filters);
    const response = await this.post<JSON>(
      `/transaction/getAll?page=${page}&size=${size}`,
      filters
    );
    const finalResponse = new ApiResponse(response);
    const success = finalResponse.getMessage();
    const data = finalResponse.getData();
    return { data, success };
  }

  async getTransactionById(
    transactionId: string
  ): Promise<Record<string, unknown> | null> {
    const response = await this.get<JSON>(`/transaction/get/${transactionId}`);
    const finalResponse = new ApiResponse(response);
    const data = finalResponse.getData()['transaction'] ?? null;
    return finalResponse.getSuccess()
      ? (data as Record<string, unknown>)
      : null;
  }

  async addTransaction(data: any) {
    const response = await this.post<JSON>(`/transaction/add`, data);
    const finalResponse = new ApiResponse(response);
    const success = finalResponse.getSuccess();
    const message = finalResponse.getMessage();
    console.error('finalResponse', response);
    return { success, message };
  }
  async deleteTransactionDocument(
    transactionId: number,
    deleteMandate: boolean
  ) {
    const response = await this.patch<JSON>(
      `/transaction/edit/${transactionId}`,
      { deleteMandate: deleteMandate }
    );
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
  async addTransactionMandate(transactionId: number, mandate: any) {
    const response = await this.patch<JSON>(
      `/transaction/edit/${transactionId}`,
      mandate
    );
    const apiResponse = new ApiResponse(response);
    const success = apiResponse.getSuccess();
    const message = apiResponse.getMessage();
    return { success, message };
  }
  async TransactionStatus(transactionId: number, transcStatus: string) {
    const response = await this.patch<JSON>(
      `/transaction/edit/${transactionId}`,
      { transcStatus: transcStatus }
    );
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
  async getInvoiceMetaData(transactionId: string) {
    const response = await this.get<JSON>(
      `invoice/masterData/${transactionId}`
    );
    const finalResponse = new ApiResponse(response);
    return finalResponse.getData();
  }

  async holdTransaction(transactionId: string, reason: string) {
    const response = await this.patch<JSON>(
      `/transaction/hold/${transactionId}`,
      { reasonToHold: reason }
    );
    const finalResponse = new ApiResponse(response);
    const success = finalResponse.getSuccess();
    const message = finalResponse.getMessage();
    return { success, message };
  }
  async approveTransaction(transactionId: string, editData: any) {
    const response = await this.patch<JSON>(
      `transaction/approve/${transactionId}`,
      editData
    );
    console.error('editData', editData);
    const finalResponse = new ApiResponse(response);
    const success = finalResponse.getSuccess();
    const message = finalResponse.getMessage();
    return { success, message };
  }
  async cancelTransaction(transactionId: string) {
    const response = await this.patch<JSON>(
      `/transaction/cancel/${transactionId}`
    );
    const finalResponse = new ApiResponse(response);
    const success = finalResponse.getSuccess();
    const message = finalResponse.getMessage();
    return { success, message };
  }

  async addInvoice(data: any) {
    const response = await this.post<JSON>(`/invoice/add`, data);
    const finalResponse = new ApiResponse(response);
    const success = finalResponse.getSuccess();
    const message = finalResponse.getMessage();
    return { success, message };
  }
  async getInvoice(transactionId: string) {
    const response = await this.get<JSON>(`/invoice/get/${transactionId}`);
    const finalResponse = new ApiResponse(response);
    const success = finalResponse.getSuccess();
    const message = finalResponse.getMessage();
    const dataResponse = finalResponse.getData();
    return { success, message, dataResponse };
  }
}

export default TransactionService;
