import React from 'react';
import { Checkbox } from '@mui/material';
import Props from '../../types/props';

function Check({ ...props }: Props) {
  return (
    <Checkbox
      checked={props.checked}
      size={props.extra.size}
      id={props.id}
      onChange={props.onChange}
      sx={props.style}
    />
  );
}

export default Check;
