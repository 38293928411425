import React, { useState, useContext, useEffect } from 'react';
import Box from '../single_component/Box';
import { Grid } from '@mui/material';
import TextInput from '../common_component/TextInput';
import Dpicker from '../single_component/Dpicker';
import UploadInput from '../single_component/UploadInput';
import UploadExcell from '../single_component/UploadExcell';
import Radio from '../common_component/Radio';
import FormHeadIcon from '../common_component/FormHeadIcon';
import PillarIcon from '../../assets/images/pillar.png';
import CourtIcon from '../../assets/images/courthouse.png';
import TextInputDropdown from '../common_component/TextInputDropdown';
import DropdownlBold from '../common_component/DropdownBold';
import AuthContext from '../../context/AuthContext';
import Button from '../single_component/Button';
import Props from '../../types/props';
import { useFormik } from 'formik';
import { constructionDetailSchema } from '../Validation/AddProjectSchema';
import ErrorText from '../common_component/ErrorText';
import DateUtils from '../../utils/dateUtils';
import { useLocation } from 'react-router';
import DocumentBox from '../common_component/DocumentBox';
import ProjectContext from '../../context/ProjectContext';
import { toast } from 'react-toastify';

function AddProject2({ ...props }: Props) {
  const { masterData } = useContext(AuthContext);
  const { deleteProjectDocument, editProject, getProject } =
    useContext(ProjectContext);
  const location = useLocation();
  const getMeasure = masterData?.value();
  const measure = getMeasure?.units ?? [];
  const isEdit: boolean = location?.state?.edit;
  const [forminitialValues, setforminitialValues] = useState({});
  const handleDeleteProjectDocument = async (
    projectId: number,
    deleteDoc: string
  ) => {
    try {
      const response = await deleteProjectDocument(projectId, deleteDoc);
      if (response?.success) {
        toast.success(response?.message);
        getProject(projectId);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('Error occurred while adding file:', error);
    }
  };
  const handleUpdateProjectDocument = async (
    projectId: number,
    file: any,
    name: string
  ) => {
    const bodyFormData = new FormData();
    bodyFormData.append(name, file);
    if (file && projectId) {
      try {
        const response = await editProject(projectId, bodyFormData);
        if (response?.success) {
          toast.success(response?.message);
          getProject(projectId);
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        console.error('Error occurred while adding file:', error);
      }
    } else {
      setFieldValue(name, file);
      setforminitialValues({
        ...forminitialValues,
        [name]: file,
      });
    }
  };
  const [selectLandUnit, setselectLandUnit] = useState(
    (measure?.length ?? 0) > 0 ? 0 : -1
  );
  const [selectBuiltUnit, setselectBuiltUnit] = useState(
    (measure?.length ?? 0) > 0 ? 0 : -1
  );
  const [selecthandleFSIUnit, setselecthandleFSIUnit] = useState(
    (measure?.length ?? 0) > 0 ? 0 : -1
  );

  const handleLandUnit = (indexOfDuration: any) => {
    setselectLandUnit(indexOfDuration);
    setforminitialValues({
      ...forminitialValues,
      landAreaUnit:
        selectLandUnit || measure ? measure[indexOfDuration].unitId : '',
    });
  };
  const handleBuiltUnit = (indexOfDuration: any) => {
    setselectBuiltUnit(indexOfDuration);
    setforminitialValues({
      ...forminitialValues,
      builtUpAreaUnit:
        selectBuiltUnit || measure ? measure[indexOfDuration].unitId : '',
    });
  };
  const handleFSI = (indexOfDuration: any) => {
    setselecthandleFSIUnit(indexOfDuration);
    setforminitialValues({
      ...forminitialValues,
      fsiAreaUnit:
        selecthandleFSIUnit || measure ? measure[indexOfDuration].unitId : '',
    });
  };
  const options = [
    {
      id: 1,
      name: 'Yes',
    },
    {
      id: 2,
      name: 'No',
    },
  ];

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      landArea: '',
      fsiArea: '',
      builtUpArea: '',
      noOfTowers: '',
      constStartDate: '',
      constEndDate: '',
      promotorReraNo: '',
      promotorReraCert: '',
      projectReraNo: '',
      projectReraCert: '',
      revisedReraNo: '',
      revisedReraCert: '',
      reraRegDate: '',
      propPossDate: '',
      propPossExtDate: '',
      configuration: '',
      revisedRera: 'No',
    },
    validationSchema: constructionDetailSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async values => {
      console.error('form initials', values, forminitialValues);
      props?.extra?.onNext(
        isEdit
          ? forminitialValues
          : {
              ...values,
              revisedRera: values.revisedRera.toLowerCase() === 'yes' ? 1 : 0,
              constStartDate: DateUtils.formatDate(
                values.constStartDate,
                'YYYY-MM-DD HH:MM:SS'
              ),
              constEndDate: DateUtils.formatDate(
                values.constEndDate,
                'YYYY-MM-DD HH:MM:SS'
              ),
              propPossDate:
                values.revisedRera.toLowerCase() === 'yes'
                  ? DateUtils.formatDate(
                      values.propPossDate,
                      'YYYY-MM-DD HH:MM:SS'
                    )
                  : '',
              reraRegDate:
                values.revisedRera.toLowerCase() === 'yes'
                  ? DateUtils.formatDate(
                      values.reraRegDate,
                      'YYYY-MM-DD HH:MM:SS'
                    )
                  : '',
              propPossExtDate:
                values.revisedRera.toLowerCase() === 'yes'
                  ? DateUtils.formatDate(
                      values.propPossExtDate,
                      'YYYY-MM-DD HH:MM:SS'
                    )
                  : '',
              landAreaUnit:
                selectLandUnit || measure ? measure[selectLandUnit].unitId : '',
              builtUpAreaUnit:
                selectBuiltUnit || measure
                  ? measure[selectBuiltUnit].unitId
                  : '',
              fsiAreaUnit:
                selecthandleFSIUnit || measure
                  ? measure[selecthandleFSIUnit].unitId
                  : '',
            }
      );
    },
  });

  const projectData = location?.state?.edit ? props?.extra?.projectData : {};

  useEffect(() => {
    if (!location?.state?.edit) {
      setforminitialValues({
        ...forminitialValues,
        builtUpAreaUnit:
          selectBuiltUnit || (measure ? measure[selectBuiltUnit].unitId : ''),
        fsiAreaUnit:
          selecthandleFSIUnit ||
          (measure ? measure[selecthandleFSIUnit].unitId : ''),
        landAreaUnit:
          selectLandUnit || (measure ? measure[selectLandUnit].unitId : ''),
      });
    }
  }, []);
  useEffect(() => {
    if (location?.state?.edit) {
      setValues({
        landArea: projectData?.landArea ?? '',
        fsiArea: projectData?.fsiArea ?? '',
        builtUpArea: projectData?.builtUpArea ?? '',
        noOfTowers: projectData?.noOfTowers ?? '',
        promotorReraNo: projectData?.promotorReraNo ?? '',
        promotorReraCert: projectData?.promotorReraCert ?? '',
        projectReraNo: projectData?.projectReraNo ?? '',
        projectReraCert: projectData?.projectReraCert ?? '',
        revisedRera:
          (projectData?.revisedReraNo != '' && projectData?.revisedReraNo) ||
          (projectData?.revisedReraCert && projectData?.revisedReraCert != '')
            ? 'Yes'
            : 'No',
        revisedReraNo: projectData?.revisedReraNo ?? '',
        revisedReraCert: projectData?.revisedReraCert ?? '',
        reraRegDate: '',
        constStartDate: '',
        constEndDate: '',
        propPossDate: '',
        propPossExtDate: '',
        configuration: projectData?.configuration ?? '',
      });
      // setFieldValue('constStartDate', new Date(projectData?.constStartDate));
      // setFieldValue('constEndDate', new Date(projectData?.constEndDate));
      // setFieldValue('propPossDate', new Date(projectData?.propPossDate));
      // setFieldValue('reraRegDate', new Date(projectData?.reraRegDate));
      // setFieldValue('propPossExtDate', new Date(projectData?.propPossExtDate));
      setFieldValue(
        'constStartDate',
        projectData?.constStartDate === null
          ? ''
          : new Date(projectData.constStartDate)
      );
      setFieldValue(
        'constEndDate',
        projectData?.constEndDate === null
          ? ''
          : new Date(projectData.constEndDate)
      );
      setFieldValue(
        'propPossDate',
        projectData?.propPossDate === null
          ? ''
          : new Date(projectData.propPossDate)
      );
      setFieldValue(
        'reraRegDate',
        projectData?.reraRegDate === null
          ? ''
          : new Date(projectData.reraRegDate)
      );
      setFieldValue(
        'propPossExtDate',
        projectData?.propPossExtDate === null
          ? ''
          : new Date(projectData.propPossExtDate)
      );

      setselectLandUnit(
        measure
          ? measure.findIndex(
              (measure: any) =>
                measure.unitId == (projectData?.landAreaUnit ?? '1')
            )
          : 0
      );
      setselectBuiltUnit(
        measure
          ? measure.findIndex(
              (measure: any) =>
                measure.unitId == (projectData?.builtUpAreaUnit ?? '1')
            )
          : 0
      );
      setselecthandleFSIUnit(
        measure
          ? measure.findIndex(
              (measure: any) =>
                measure.unitId == (projectData?.fsiAreaUnit ?? '1')
            )
          : 0
      );
    }
  }, [projectData]);
  const edithandleChange = (e: any, formData?: string) => {
    const { name, value } = e.target;
    setforminitialValues({ ...forminitialValues, [name]: formData ?? value });
  };
  // const handleDateChange = (e: any, formData?: string) => {
  //   edithandleChange(e, formData);
  // };
  return (
    <Box style={{ marginTop: 5 }}>
      <Box
        style={{
          backgroundColor: 'white',
          boxShadow: ' 0px 2px 10px 0px #10496030',
          borderRadius: 2,
          marginTop: 5,
        }}>
        <FormHeadIcon
          label="Construction Details"
          extra={{ LeftIcon: PillarIcon }}></FormHeadIcon>
        <Grid
          container
          columnSpacing={2}
          style={{ paddingInline: 22, paddingBlock: 20 }}>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInputDropdown
              label="Land area"
              placeholder="Enter Land Area"
              value={values.landArea}
              onChange={(e: any) => {
                handleChange(e);
                edithandleChange(e);
              }}
              onBlur={handleBlur}
              name="landArea"
              id="landArea">
              <DropdownlBold
                value={(getMeasure?.units[selectLandUnit] ?? {})['name'] ?? ''}
                name="duration"
                id="duration"
                onChange={(e: any) => {
                  handleLandUnit(e.target.value);
                }}
                extra={{
                  options: measure ?? {},
                }}
              />
            </TextInputDropdown>
            {touched.landArea && errors.landArea ? (
              <ErrorText>{errors.landArea}</ErrorText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInputDropdown
              label="FSI Area"
              placeholder="Enter FSI / FAR area"
              value={values.fsiArea}
              onChange={e => {
                handleChange(e);
                edithandleChange(e);
              }}
              onBlur={handleBlur}
              name="fsiArea"
              id="fsiArea">
              <DropdownlBold
                value={
                  (getMeasure?.units[selecthandleFSIUnit] ?? {})['name'] ?? ''
                }
                name="duration"
                id="duration"
                onChange={(e: any) => {
                  handleFSI(e.target.value);
                }}
                extra={{
                  options: measure ?? {},
                }}
              />
            </TextInputDropdown>
            {touched.fsiArea && errors.fsiArea ? (
              <ErrorText>{errors.fsiArea}</ErrorText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInputDropdown
              label="Built-up area"
              placeholder="Enter Built-up area"
              value={values.builtUpArea}
              onChange={e => {
                handleChange(e);
                edithandleChange(e);
              }}
              onBlur={handleBlur}
              name="builtUpArea"
              id="builtUpArea">
              <DropdownlBold
                value={(getMeasure?.units[selectBuiltUnit] ?? {})['name'] ?? ''}
                name="duration"
                id="duration"
                onChange={(e: any) => {
                  handleBuiltUnit(e.target.value);
                }}
                extra={{
                  options: measure ?? {},
                }}
              />
            </TextInputDropdown>
            {touched.builtUpArea && errors.builtUpArea ? (
              <ErrorText>{errors.builtUpArea}</ErrorText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInput
              label="No. of Towers"
              placeholder="Enter No. of tower"
              value={values.noOfTowers}
              onChange={e => {
                handleChange(e);
                edithandleChange(e);
              }}
              onBlur={handleBlur}
              name="noOfTowers"
              id="noOfTowers"
              // onInput={(e: any) => {
              //   e.target.value = e.target.value.trim();
              // }}
            />
            {touched.noOfTowers && errors.noOfTowers ? (
              <ErrorText>{errors.noOfTowers}</ErrorText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <Dpicker
              label="Construction Start Date"
              name="constStartDate"
              value={values.constStartDate}
              // disablePast={true}
              extra={{
                setDate: (e: any) => {
                  setFieldValue('constStartDate', e);
                  setforminitialValues({
                    constStartDate:
                      DateUtils.formatDate(
                        e,
                        'YYYY-MM-DD HH:MM:SS'
                      )?.toString() ?? '',
                  });
                },
                maxDate: values.constEndDate,
                placeholder: 'Enter Construction Start date',
              }}
            />
            {touched.constStartDate && errors.constStartDate ? (
              <ErrorText>{errors.constStartDate}</ErrorText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <Dpicker
              label="Construction End Date"
              name="constEndDate"
              // disablePast={true}
              value={values.constEndDate}
              extra={{
                setDate: (e: any) => {
                  setFieldValue('constEndDate', e);
                  setforminitialValues({
                    ...forminitialValues,
                    constEndDate:
                      DateUtils.formatDate(
                        e,
                        'YYYY-MM-DD HH:MM:SS'
                      )?.toString() ?? '',
                  });
                },
                minDate: values.constStartDate,
                placeholder: 'Enter expected completion date',
              }}
            />
            {touched.constEndDate && errors.constEndDate ? (
              <ErrorText>{errors.constEndDate}</ErrorText>
            ) : null}
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: 'white',
          boxShadow: ' 0px 2px 10px 0px #10496030',
          borderRadius: 2,
          marginTop: 5,
        }}>
        <FormHeadIcon
          label="RERA details"
          extra={{ LeftIcon: CourtIcon }}></FormHeadIcon>
        <Grid
          container
          columnSpacing={2}
          style={{ paddingInline: 22, paddingBlock: 20 }}>
          <Grid item xs={12} sm={6} md={4} lg={6} mr={2}>
            <TextInput
              label="Promoter RERA ID"
              placeholder="Enter Promoter RERA ID"
              name="promotorReraNo"
              id="promotorReraNo"
              value={values.promotorReraNo}
              onChange={e => {
                handleChange(e);
                edithandleChange(e);
              }}
              onBlur={handleBlur}
            />
            {touched.promotorReraNo && errors.promotorReraNo ? (
              <ErrorText>{errors.promotorReraNo}</ErrorText>
            ) : null}
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInput
              label="Project RERA No."
              placeholder="Enter Project RERA No."
              name="projectReraNo"
              id="projectReraNo"
              value={values.projectReraNo}
              onChange={e => {
                handleChange(e);
                edithandleChange(e);
              }}
              onBlur={handleBlur}
            />
            {touched.projectReraNo && errors.projectReraNo ? (
              <ErrorText>{errors.projectReraNo}</ErrorText>
            ) : null}
          </Grid>
          {projectData.projectReraCert && isEdit ? (
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <DocumentBox
                extra={{
                  type: 'editMandate',
                  textlabel: 'Project RERA Certificate',
                  chiplabel: 'Project RERA Certificate',
                  url: values.projectReraCert,
                  handleDeleteDocuments: () =>
                    handleDeleteProjectDocument(
                      location.state.id,
                      'projectReraCert'
                    ),
                  component: (
                    <UploadInput
                      name="mandate"
                      extra={{
                        isModify: true,
                        onchange: (e: any) => {
                          // handleMandateFileupload(data.transcId, e[0]);
                          handleUpdateProjectDocument(
                            location.state.id,
                            e[0],
                            'projectReraCert'
                          );
                        },
                      }}
                    />
                  ),
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <UploadInput
                label="Project RERA Certificate"
                id="projectReraCert"
                name="projectReraCert"
                extra={{
                  onchange: (e: any) => {
                    handleUpdateProjectDocument(
                      location.state.id,
                      e[0],
                      'projectReraCert'
                    );
                  },
                }}
                onBlur={handleBlur}
              />
              {touched.projectReraCert && errors.projectReraCert ? (
                <ErrorText>{errors.projectReraCert}</ErrorText>
              ) : null}
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Radio
              label="Revised RERA"
              name="revisedRera"
              id="revisedRera"
              extra={{
                Selected: values.revisedRera,
                setSelected: (e: any) => {
                  console.error('revisedRera', e);
                  setFieldValue('revisedRera', e);
                  setforminitialValues({
                    ...forminitialValues,
                    revisedRera: e == 'Yes' ? '1' : '0',
                  });
                },
                options,
              }}
            />
            {touched.revisedRera && errors.revisedRera ? (
              <ErrorText>{errors.revisedRera}</ErrorText>
            ) : null}
          </Grid>
          {values.revisedRera == 'No' ? null : (
            <>
              <Grid container item xs={12} sm={6} md={4} lg={6}>
                <TextInput
                  label="Revised RERA No."
                  placeholder="Enter revised RERA No."
                  name="revisedReraNo"
                  id="revisedReraNo"
                  value={values.revisedReraNo}
                  onChange={e => {
                    handleChange(e);
                    edithandleChange(e);
                  }}
                  onBlur={handleBlur}
                />
                {touched.revisedReraNo && errors.revisedReraNo ? (
                  <ErrorText>{errors.revisedReraNo}</ErrorText>
                ) : null}
              </Grid>

              {projectData.revisedReraCert && isEdit ? (
                <Grid item xs={12} sm={6} md={4} lg={6}>
                  <DocumentBox
                    extra={{
                      type: 'editMandate',
                      textlabel: 'Revised RERA Certificate',
                      chiplabel: 'Revised RERA Certificate',
                      url: values.revisedReraCert,
                      handleDeleteDocuments: () =>
                        handleDeleteProjectDocument(
                          location.state.id,
                          'revisedReraCert'
                        ),
                      component: (
                        <UploadInput
                          name="revisedReraCert"
                          extra={{
                            isModify: true,
                            onchange: (e: any) => {
                              handleUpdateProjectDocument(
                                location.state.id,
                                e[0],
                                'revisedReraCert'
                              );
                            },
                          }}
                        />
                      ),
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} md={4} lg={6}>
                  <UploadInput
                    label="Revised RERA Certificate"
                    id="revisedReraCert"
                    name="revisedReraCert"
                    extra={{
                      onchange: (e: any) => {
                        handleUpdateProjectDocument(
                          location.state.id,
                          e[0],
                          'revisedReraCert'
                        );
                      },
                    }}
                    onBlur={handleBlur}
                  />
                  {touched.projectReraCert && errors.projectReraCert ? (
                    <ErrorText>{errors.projectReraCert}</ErrorText>
                  ) : null}
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4} lg={3.8}>
                <Dpicker
                  label="Date of RERA Registration"
                  value={values.reraRegDate}
                  name="reraRegDate"
                  extra={{
                    setDate: (e: any) => {
                      setFieldValue('reraRegDate', e);
                      setforminitialValues({
                        ...forminitialValues,
                        reraRegDate:
                          DateUtils.formatDate(
                            e,
                            'YYYY-MM-DD HH:MM:SS'
                          )?.toString() ?? '',
                      });
                    },
                    placeholder: 'Enter Date of RERA Registration',
                  }}
                />
                {touched.reraRegDate && errors.reraRegDate ? (
                  <ErrorText>{errors.reraRegDate}</ErrorText>
                ) : null}
              </Grid>
              <Grid ml={3} item xs={12} sm={6} md={4} lg={3.8}>
                <Dpicker
                  label="Proposed Possession date"
                  name="propPossDate"
                  value={values.propPossDate}
                  extra={{
                    setDate: (e: any) => {
                      setFieldValue('propPossDate', e);
                      setforminitialValues({
                        ...forminitialValues,
                        propPossDate:
                          DateUtils.formatDate(
                            e,
                            'YYYY-MM-DD HH:MM:SS'
                          )?.toString() ?? '',
                      });
                    },
                    placeholder: 'Enter Proposed Possession date',
                  }}
                />
                {touched.propPossDate && errors.propPossDate ? (
                  <ErrorText>{errors.propPossDate}</ErrorText>
                ) : null}
              </Grid>
              <Grid ml={3} item xs={12} sm={6} md={4} lg={3.8}>
                <Dpicker
                  label="Proposed Possession date extension (if any)"
                  value={values.propPossExtDate}
                  name="propPossExtDate"
                  extra={{
                    setDate: (e: any) => {
                      setFieldValue('propPossExtDate', e);
                      setforminitialValues({
                        ...forminitialValues,
                        propPossExtDate:
                          DateUtils.formatDate(
                            e,
                            'YYYY-MM-DD HH:MM:SS'
                          )?.toString() ?? '',
                      });
                    },
                    placeholder: 'Enter Proposed Possession date',
                  }}
                />
                {touched.propPossExtDate && errors.propPossExtDate ? (
                  <ErrorText>{errors.propPossExtDate}</ErrorText>
                ) : null}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: 'white',
          boxShadow: ' 0px 2px 10px 0px #10496030',
          borderRadius: 2,
          marginTop: 5,
        }}>
        <FormHeadIcon
          label="Configuration"
          extra={{ LeftIcon: CourtIcon }}></FormHeadIcon>
        <Grid
          container
          columnSpacing={2}
          style={{ paddingInline: 22, paddingBlock: 20 }}>
          {(projectData.configuration ?? '') != '' && isEdit ? (
            <Grid item xs={12} sm={6} md={4} lg={6}>
              <DocumentBox
                extra={{
                  type: 'editMandate',
                  chiplabel: 'Configuration',
                  url: values.configuration,
                  handleDeleteDocuments: () =>
                    handleDeleteProjectDocument(
                      location.state.id,
                      'configuration'
                    ),
                  component: (
                    <UploadInput
                      name="Configuration"
                      extra={{
                        isModify: true,
                        onchange: (e: any) => {
                          handleUpdateProjectDocument(
                            location.state.id,
                            e[0],
                            'configuration'
                          );
                        },
                      }}
                    />
                  ),
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <UploadExcell
                // label="Click here to upload Excel File"
                id="configuration"
                name="configuration"
                extra={{
                  onchange: (e: any) => {
                    handleUpdateProjectDocument(
                      location.state.id,
                      e[0],
                      'configuration'
                    );
                  },
                }}
                onBlur={handleBlur}
              />
              {touched.configuration && errors.configuration ? (
                <ErrorText>{errors.configuration}</ErrorText>
              ) : null}
            </Grid>
          )}
        </Grid>
      </Box>
      <Box style={{ marginTop: 4 }}>
        <Button
          extra={{ variant: 'outlined' }}
          onClick={props?.extra?.onPrevious}
          style={{ width: '43%', marginRight: '2%', paddingBlock: 1 }}>
          Back
        </Button>
        <Button
          extra={{ variant: 'contained' }}
          onClick={handleSubmit}
          style={{ width: '43%', marginLeft: '2%', paddingBlock: 1 }}>
          Continue
        </Button>
      </Box>
    </Box>
  );
}

export default AddProject2;
