import React from 'react';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import Button from '../single_component/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Sidebar from './Sidebar';
import InvestorFilter from '../Filter/InvestorFilter';
// import Stepper from '../Stepper/Stepper';
import InvestorSearchFilterCon from '../single_component/InvestorSearchFilterCon';
import DownloadActive from '../single_component/DownloadActive';
import Props from '../../types/props';
import AddInvestor from '../Investor/addInvestor';

function InvestTableTop(props: Props = {}) {
  const {
    Toggle,
    setToggle,
    selected,
    setselected,
    setinvestment,
    setinvestor,
    btnDisableLoader,
    selectinvestment,
    selectinvestor,
    onCancel,
    handleFilter,
  } = props?.extra || {};

  const [SidebarTogle, setSidebarTogle] = React.useState(false);
  const { checked, onSearchKeyChange, selectedLabel, onDownload, loading } =
    props?.extra ?? {};
  const handleClick = () => {
    setSidebarTogle(!SidebarTogle);
  };

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 24px 0px 24px ',
          background: 'white',
        }}>
        <Box>
          <Text style={{ fontSize: 16, fontWeight: 'bold' }}>Investors</Text>
          <Text style={{ fontSize: 14, color: '#667085' }}>
            Here you can view all the investors and their details.
          </Text>
        </Box>
        <Box style={{ display: 'flex' }}>
          <Button
            extra={{ variant: 'contained', color: 'primary' }}
            style={{ padding: '10px 24px', borderRadius: 1.5 }}
            icons={<AddOutlinedIcon />}
            onClick={handleClick}>
            <Text style={{ color: 'white', fontSize: 13, fontWeight: 500 }}>
              Add Investor
            </Text>
          </Button>
        </Box>
      </Box>
      <InvestorSearchFilterCon
        extra={{ Toggle, setToggle, onSearchKeyChange }}
      />
      {checked && (
        <DownloadActive
          label={selectedLabel}
          onClick={onDownload}
          extra={{ loading }}
        />
      )}
      <Sidebar
        label="Filter"
        extra={{
          anchor: 'right',
          Toggle,
          setToggle,
          description: 'Filter the data as per your need',
          width: 400,
        }}>
        <InvestorFilter
          extra={{
            setToggle,
            selected,
            setselected,
            setinvestment,
            setinvestor,
            selectinvestment,
            selectinvestor,
            btnDisableLoader,
            onCancel,
            handleFilter,
          }}
        />
      </Sidebar>

      <Sidebar
        label="Add Investor"
        extra={{
          anchor: 'right',
          Toggle: SidebarTogle,
          setToggle: setSidebarTogle,
          description: 'You can add investor here by filling the details',
          width: 400,
        }}>
        <AddInvestor
          label="Investor"
          extra={{
            setSidebarTogle,
          }}
        />
      </Sidebar>
    </Box>
  );
}

export default InvestTableTop;
