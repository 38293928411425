import React, { useState } from 'react';
import DeveloperTableTop from './DeveloperTableTop';
import Props from '../../types/props';
import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import Box from '../../components/single_component/Box';
import FileChip from '../../components/single_component/FileChip';
import StatusChip from '../../components/common_component/StatusChip';
import EyeAction from '../../components/single_component/EyeAction';
import Text from '../../components/single_component/Text';
import PluseTwo from '../../components/common_component/PluseTwo';
import Tables from '../../components/single_component/Tables';
import StringUtils from '../../utils/stringUtil';

function TransactionTables(props: Props = {}) {
  const {
    page,
    Page,
    setPage,
    rowsPerPage,
    setrowsPerPage,
    Next,
    setNext,
    Previous,
    setPrevious,
    currPage,
    setcurrPage,
  } = props.extra;

  const [AddGroup, setAddGroup] = useState(false);

  const TableRowComponet = (props: any) => {
    const { row } = props ?? {};
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: '80%' }}>
        <TableCell>
          {row?.date == undefined || row?.date == null || row?.date == '' ? (
            <Text>N/A</Text>
          ) : (
            <Text style={{ fontSize: 14 }}>
              {moment(row?.date).format('DD MMM YYYY')}
            </Text>
          )}
        </TableCell>

        <TableCell>
          {row?.dealSize == undefined ||
          row?.dealSize == null ||
          row?.dealSize == '' ? (
            <Text>N/A</Text>
          ) : (
            <Text> ₹ {row?.dealSize}</Text>
          )}
        </TableCell>

        <TableCell>
          {row?.transcBrief == undefined ||
          row?.transcBrief == null ||
          row?.transcBrief == '' ? (
            <Text>N/A</Text>
          ) : (
            <Text> {StringUtils.capitalizeFirstLetter(row.transcBrief)}</Text>
          )}
        </TableCell>

        <TableCell>
          {row?.investors == undefined ||
          row?.investors == null ||
          row?.investors == '' ? (
            <Text>N/A</Text>
          ) : (
            <Box
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
              }}>
              <Text style={{ fontSize: 14 }}>
                {StringUtils.capitalizeFirstLetter(
                  (row?.investors ?? [])[0]?.name
                )}
              </Text>
              {row?.investors?.length > 1 && (
                <PluseTwo
                  extra={{ OverPluse: `+${row?.investors?.length - 1}` }}
                />
              )}
            </Box>
          )}
        </TableCell>

        <TableCell>
          {row?.mandate ? (
            <FileChip
              label={row?.documentName}
              extra={{ url: row?.mandate ?? '' }}
            />
          ) : (
            <Text>N/A</Text>
          )}
        </TableCell>

        <TableCell>
          {row?.remark == undefined ||
          row?.remark == null ||
          row?.remark == '' ? (
            <Text>N/A</Text>
          ) : (
            <Text> {StringUtils.capitalizeFirstLetter(row?.remark)}</Text>
          )}
        </TableCell>
        <TableCell>
          <StatusChip label={row?.transcStatus} />
        </TableCell>
        <TableCell>
          <EyeAction
            extra={{
              label: row?.transcStatus,
              data: row ?? {},
              page: page,
            }}
          />
        </TableCell>
      </TableRow>
    );
  };

  const tableHeaders = [
    { id: 'Date', name: 'Date' },
    {
      id: 'DealSize',
      name: 'Deal Size',
    },
    {
      id: 'Brief',
      name: 'Brief',
    },
    {
      id: 'PotentialInvestors',
      name: 'Potential Investors',
    },
    {
      id: 'Documents',
      name: 'Documents',
    },
    {
      id: 'Remarks',
      name: 'Remarks',
    },
    {
      id: 'Status',
      name: 'Status',
    },
    { id: 'Action', name: 'Action' },
  ];
  return (
    <Box style={{ width: '100%', margin: 'auto' }}>
      <DeveloperTableTop
        extra={{
          label: 'Transactions',
          description:
            'Here you can view all the transactions that has been done till date',
          AddGroup,
          setAddGroup,
        }}
      />

      <Tables
        extra={{
          rows: props.extra.data ?? [],
          columns: tableHeaders,
          RowComponent: TableRowComponet,
          page: Page,
          setPage: setPage,
          rowsPerPage: rowsPerPage,
          setrowsPerPage: setrowsPerPage,
          Next: Next,
          Previous: Previous,
          currPage: currPage,
          setcurrPage: setcurrPage,
          setNext: setNext,
          setPrevious: setPrevious,
        }}
      />
    </Box>
  );
}

export default TransactionTables;
