import React, { useState } from 'react';
import MuiAutoComplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from './Box';
import TextLabel from './TextLabel';

interface Option {
  name: string;
  value: string;
}

interface AutoCompleteProps {
  options: Option[];
  onSelect: (value: string) => void;
  label?: string;
  placeholder?: string;
  name?: string;
  id?: string;
}

const AutoComplete: React.FC<AutoCompleteProps> = props => {
  const { options, onSelect, label, placeholder } = props;
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const handleInputChange = (
    event: React.ChangeEvent<object>,
    newValue: string
  ) => {
    // Check if the entered value exists in the options
    const matchedOption = options.find(
      option => option.value.toLowerCase() === newValue.toLowerCase()
    );
    if (matchedOption) {
      // If a match is found, update the selected option
      setSelectedOption(matchedOption);
    } else {
      // If no match is found, reset the selected option
      setSelectedOption({ name: newValue, value: newValue });
    }
  };
  const handleSelectChange = (
    event: React.ChangeEvent<object>,
    newValue: Option | null
  ) => {
    if (newValue) {
      // If an option is selected, call the onSelect callback with its value and index
      onSelect(newValue.value);
    } else {
      // If no option is selected, call the onSelect callback with null values
      // onSelect('-1');
    }
  };

  return (
    <Box style={{ width: '100%', marginTop: 0, marginBottom: 1.5 }}>
      {label && <TextLabel> {label ?? ''}</TextLabel>}
      <MuiAutoComplete
        options={options}
        getOptionLabel={option => option.name}
        value={selectedOption}
        className="customStyles"
        onChange={handleSelectChange}
        onInputChange={handleInputChange}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={placeholder}
            name={props.name}
            id={props.id}
          />
        )}
      />
    </Box>
  );
};

export default AutoComplete;
