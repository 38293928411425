import React, { useState, useContext, useEffect } from 'react';
import Box from '../components/single_component/Box';
import StatusChip from '../components/common_component/StatusChip';
import EyeAction from '../components/single_component/EyeAction';
import Tables from '../components/single_component/Tables';
import TextInput from '../components/common_component/TextInput';
import FilterListIcon from '@mui/icons-material/FilterList';
import ActiveFilter from '../components/Filter/ActiveFilter';
import Button from '../components/single_component/Button';
import Sidebar from '../components/common_component/Sidebar';
import Text from '../components/single_component/Text';
import PluseTwo from '../components/common_component/PluseTwo';
import TransactionsContex from '../context/TransactionsContex';
import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import StringUtils from '../utils/stringUtil';
import DateUtils from '../utils/dateUtils';
import DashboardContext from '../context/Dashboard';

function CompletedContainer() {
  const [SearchKey, setSearchKey] = useState('');
  const duration = parseInt(sessionStorage.getItem('duration') as string);
  const { getTransaction, Transaction } = useContext(TransactionsContex);
  const [Page, setPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [Next, setNext] = useState();
  const [Previous, setPrevious] = useState();
  const [currPage, setcurrPage] = useState(1);
  const [compFilterToggle, setCompFilterToggle] = useState(false);
  const [DealSize, setDealSize] = useState('');
  const [selectedChip, setSelectedChip] = useState(-1);
  const [Date, setDate] = useState('');
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);
  const { DashBoardData, getDashboardData } = useContext(DashboardContext);
  console.error(
    'DashBoardData?.completedTransaction',
    DashBoardData?.completedTransaction
  );
  useEffect(() => {
    getDashboardData(`/admin/dashboard`, {
      filter: {
        duration,
      },
    });
  }, []);
  useEffect(() => {
    getTransaction(1, 10, {
      filter: {
        transcStatus: ['Approved'],
        duration: duration,
      },
    });
  }, []);
  //if page is Completed transactions filter chip is hide
  const page = 'Complete';

  useEffect(() => {
    setPage(Transaction?.totalPages);
    setNext(Transaction?.nextPage);
    setPrevious(Transaction?.prevPage);
  }, [Transaction]);

  useEffect(() => {
    getTransaction(currPage, rowsPerPage, {
      filter: {
        transcStatus: ['Approved'],
        duration: duration,
      },
      search_key: SearchKey,
    });
  }, [currPage, rowsPerPage, SearchKey]);

  const TableRowComponet = (props: any) => {
    const { row } = props;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: '80%' }}>
        <TableCell>
          <Text style={{ fontSize: 14, width: 100 }}>
            {moment(row.joiningDate).format('DD MMM YYYY')}
          </Text>
        </TableCell>

        <TableCell>
          {row.dealSize ? <Text> ₹ {row.dealSize}</Text> : <Text>N/A</Text>}
        </TableCell>

        <TableCell>
          <Text>
            {' '}
            {StringUtils.capitalizeFirstLetter(row.transcBrief ?? 'N/A')}
          </Text>
        </TableCell>
        <TableCell>
          <Box
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <Text style={{ fontSize: 14 }}>
              {(row?.investors ?? []).length > 0
                ? StringUtils.capitalizeFirstLetter(
                    row?.investors[0]?.name ?? 'N/A'
                  )
                : 'N/A'}
            </Text>
            {row?.investors?.length > 1 && (
              <PluseTwo
                extra={{ OverPluse: `+${row?.investors?.length - 1}` }}
              />
            )}
          </Box>
        </TableCell>

        <TableCell>
          {row.remark ? (
            <Text> {StringUtils.capitalizeFirstLetter(row.remark)}</Text>
          ) : (
            <Text> N/A</Text>
          )}
        </TableCell>
        <TableCell>
          <StatusChip label={row.transcStatus} />
        </TableCell>
        <TableCell>
          <EyeAction
            extra={{
              label: row?.transcStatus ?? '',
              data: row ?? {},
            }}
          />
        </TableCell>
      </TableRow>
    );
  };

  const tableHeaders = [
    { id: 'Date', name: 'Date' },
    {
      id: 'DealSize',
      name: 'Deal Size',
    },
    {
      id: 'Brief',
      name: 'Brief',
    },
    {
      id: 'PotentialInvestors',
      name: 'Potential Investors',
    },
    {
      id: 'Remarks',
      name: 'Remarks',
    },
    {
      id: 'Status',
      name: 'Status',
    },
    { id: 'Action', name: 'Action' },
  ];

  const onCancel = () => {
    setCompFilterToggle(false);
  };
  const transactionStatus = ['Initiated', 'Raised'];
  console.error('transactionStatus', transactionStatus);
  const transcStatus = transactionStatus?.[selectedChip] ?? '';

  const handleSubmit = async () => {
    setbtnDisableLoader(true);
    const dealsize = DealSize;
    const date = DateUtils.formatDate(Date, 'YYYY-MM-DD');
    console.error('[transcStatus] ', [transcStatus]);

    try {
      const response = await getTransaction(1, 10, {
        filter: {
          ...(transcStatus
            ? { transcStatus: [transcStatus] }
            : { transcStatus: ['Approved'] }),
          ...(date && date != 'Invalid date' && { date }),
          ...(dealsize && { dealsize }),
          duration: duration,
        },
      });
      setbtnDisableLoader(false);
      if (response.success) {
        setCompFilterToggle(false);
      } else {
        setCompFilterToggle(false);
      }
    } catch (e) {
      setbtnDisableLoader(false);
      console.error(e);
    }
  };
  const handleChipClick = (index: number) => {
    setSelectedChip(index);
  };
  return (
    <Box
      style={{
        width: '85%',
        margin: 'auto',
        boxShadow: ' 0px 2px 10px 0px #10496030',
      }}>
      <Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '12px 24px 0px 24px ',
            background: 'white',
          }}>
          <Box>
            <Box style={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
                Completed transactions
              </Text>
              <Text
                style={{
                  background: '#E5F7FF',
                  padding: '5px 8px',
                  borderRadius: 18,
                  fontSize: 12,
                  fontWeight: 600,
                }}>
                {DashBoardData?.completedTransaction +
                  ' Completed transactions'}
              </Text>
            </Box>
            <Text style={{ fontSize: 14, color: '#667085' }}>
              Here you can see all the Completed transactions in your system.
            </Text>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '12px 24px 0px 24px ',
            background: 'white',
          }}>
          <Box style={{ width: 452, height: 48 }}>
            <TextInput
              placeholder="Search"
              value={SearchKey}
              onChange={(e: any) => setSearchKey(e.target.value)}
            />
          </Box>
          <Button
            extra={{
              variant: 'outlined',
              color: 'tertiary',
            }}
            icons={<FilterListIcon />}
            style={{ padding: '8px 24px' }}
            onClick={() => setCompFilterToggle(true)}>
            <Text style={{ color: 'black', fontSize: 13 }}>Add Filters</Text>
          </Button>
        </Box>

        <Sidebar
          label="Filter"
          extra={{
            anchor: 'right',
            Toggle: compFilterToggle,
            setToggle: setCompFilterToggle,
            description: 'Filter the data as per your need ',
            width: 400,
          }}>
          <ActiveFilter
            extra={{
              page,
              handleSubmit,
              onCancel,
              transactionStatus,
              handleChipClick,
              DealSize,
              setDealSize,
              selectedChip,
              setSelectedChip,
              Date,
              setDate,
              btnDisableLoader,
            }}
          />
        </Sidebar>
      </Box>

      <Tables
        extra={{
          rows: Transaction.records,
          columns: tableHeaders,
          RowComponent: TableRowComponet,
          page: Page,
          setPage: setPage,
          rowsPerPage: rowsPerPage,
          setrowsPerPage: setrowsPerPage,
          Next: Next,
          Previous: Previous,
          currPage: currPage,
          setcurrPage: setcurrPage,
          setNext: setNext,
          setPrevious: setPrevious,
        }}
      />
    </Box>
  );
}

export default CompletedContainer;
