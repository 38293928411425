import React from 'react';
import TextLabel from './TextLabel';
import TextField from '@mui/material/TextField';
import Box from './Box';
import Props from '../../types/props';

function SubjectInput(props: Props = {}) {
  return (
    <Box style={{ width: '100%' }}>
      <TextLabel>{props?.label ?? ''}</TextLabel>
      <TextField
        type={props?.type ?? ''}
        placeholder={props?.placeholder ?? ''}
        style={{ width: '100%' }}
        onChange={props?.onChange}
        onBlur={props?.onBlur}
        disabled={props?.disabled}
        id={props?.id ?? ''}
        name={props?.name ?? ''}
        value={props?.value ?? ''}
        multiline
        inputProps={{
          readOnly: props?.readOnly ?? '',
          style: {
            minHeight: '3rem',
            color: 'black',
            fontSize: 13,
            fontWeight: 'normal',
          },
        }}
        InputLabelProps={{
          style: { color: 'red' },
        }}
      />
    </Box>
  );
}
export default SubjectInput;
