import React from 'react';
import Props from '../../types/props';
import {
  Radio as RadioButton,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import TextLabel from '../single_component/TextLabel';

function RadioWithBox({ ...props }: Props) {
  const { Selected, setSelected, options, disabledEdit } = props.extra ?? {};
  return (
    <Box style={props.style}>
      <TextLabel>{props.label}</TextLabel>
      <RadioGroup
        aria-label="gender"
        name="gender"
        value={Selected}
        onChange={e => {
          if (setSelected) setSelected(e.target.value);
        }}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: 10,
          columnGap: 5,
        }}>
        {options?.map((value: any, index: React.Key | null | undefined) => {
          return (
            <FormControlLabel
              style={{
                width: '48%',
                backgroundColor: 'white',
                padding: '5px 10px',
                border: '1px solid #F1F1F1',
                borderRadius: 5,
              }}
              key={index}
              value={index}
              control={
                <RadioButton
                  size="small"
                  disabled={disabledEdit && Selected !== index}
                  style={{
                    alignSelf: 'flex-start',
                  }}
                />
              }
              label={
                <Box>
                  <Box style={{ width: '90%', margin: 'auto' }}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <Box style={{ width: '100%' }}>
                        <TextLabel>Account No.</TextLabel>
                        <Text style={{ fontSize: 13 }}>
                          {value?.accountNumber}
                        </Text>
                      </Box>
                      <Box style={{ width: 200 }}>
                        <TextLabel>Name of Bank.</TextLabel>
                        <Text style={{ fontSize: 13 }}>{value?.bankName}</Text>
                      </Box>
                    </Box>

                    <Box style={{ width: '100%' }}>
                      <TextLabel>IFSC Code</TextLabel>
                      <Text style={{ fontSize: 13 }}>{value?.ifscCode}</Text>
                    </Box>
                    <Box style={{ width: '100%' }}>
                      <TextLabel>Branch Address</TextLabel>
                      <Text style={{ fontSize: 13 }}>
                        {value?.branchAddress}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              }
            />
          );
        })}
      </RadioGroup>
    </Box>
  );
}

export default RadioWithBox;
