import React, { useContext } from 'react';
import Box from '../single_component/Box';
import DeveloperTableTop from './DeveloperTableTop';
import FileChip from '../single_component/FileChip';
import Image from '../single_component/Image';
import Props from '../../types/props';
import { TableCell, TableRow } from '@mui/material';
import Text from '../single_component/Text';
import moment from 'moment';
import downloadIcon from '../../assets/images/download.png';
import TablesWithoutPagination from '../common_component/TablesWithoutPagination';
import StringUtils from '../../utils/stringUtil';
import NotfoundPage from '../../components/single_component/NotfoundPage';
import Loader from '../../components/single_component/Loader';
import SalesExecutiveContext from '../../context/SalesExecutiveContext';

function DeveloperDataTable({ ...props }: Props) {
  const [Page, setPage] = React.useState(1);
  const [rowsPerPage, setrowsPerPage] = React.useState(10);
  const [Next, setNext] = React.useState();
  const [Previous, setPrevious] = React.useState();
  const [currPage, setcurrPage] = React.useState(1);
  const { loading } = useContext(SalesExecutiveContext);

  const { data } = props.extra;

  const TableRowComponet = (props: any) => {
    const { row } = props;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: '80%' }}>
        <TableCell>
          <Text style={{ fontSize: 14 }}>
            {moment(row.createdAt).format('DD MMM YYYY')}
          </Text>
        </TableCell>
        <TableCell>
          <Text style={{ fontSize: 14 }}>
            {StringUtils.capitalizeFirstLetter(row.subject ?? 'N/A')}
          </Text>
        </TableCell>
        <TableCell>
          <FileChip
            label={row?.fileName ?? 'data format'}
            extra={{ url: row?.documentFile ?? 'N/A' }}
          />
        </TableCell>
        <TableCell>
          <a href={row?.documentFile ?? 'N/A'} rel="noreferrer">
            <Image
              src={downloadIcon}
              style={{ width: 32, height: 32, objectFit: 'cover' }}
            />
          </a>
        </TableCell>
      </TableRow>
    );
  };

  const tableHeaders = [
    { name: 'Date', id: 'Sales Executive' },
    { name: 'Subject', id: 'Contact' },
    { name: 'Documents', id: 'PhoneNumber' },
    { name: 'Action', id: 'DOJ' },
  ];

  return (
    <Box style={{ width: '100%', margin: 'auto' }}>
      <DeveloperTableTop
        extra={{
          label: 'Developer data format',
          description:
            'Here you can create developer data format by uploading files',
        }}
      />

      {loading ? (
        <Loader />
      ) : data.length !== 0 ? (
        <TablesWithoutPagination
          extra={{
            rows: data,
            columns: tableHeaders,
            RowComponent: TableRowComponet,
            page: Page,
            setPage: setPage,
            rowsPerPage: rowsPerPage,
            setrowsPerPage: setrowsPerPage,
            Next: Next,
            Previous: Previous,
            setPrevious: setPrevious,
            setNext: setNext,
            currPage: currPage,
            setcurrPage: setcurrPage,
          }}
        />
      ) : (
        <div>
          <NotfoundPage />
        </div>
      )}
    </Box>
  );
}

export default DeveloperDataTable;
