import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Loader() {
  return (
    <Box sx={{ display: 'flex',alignItems: 'center', justifyContent: 'center', color: 'rgb(16, 73, 96)', padding: '10px' }}>
      <CircularProgress />
    </Box>
  );
};