import React, { useContext } from 'react';
// import React, { useContext, useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import PublicRoutes from './public';
import AuthContext from '../context/AuthContext';
import AuthRoutes from './auth';
import { ROLE } from '../types/routeObject';
// import { getToken, onMessage } from 'firebase/messaging';
// import { toast } from 'react-toastify';
// import { firebaseApp } from '../services/FirebaseService';

export function Klaxon() {
  const routes = (isLoggedIn: boolean, role: number) =>
    createBrowserRouter([
      ...AuthRoutes(isLoggedIn, role),
      ...PublicRoutes(isLoggedIn),
    ]);

  const { token, role } = useContext(AuthContext);
  // const { token, role, notificationToken } = useContext(AuthContext);

  const routeConfig = routes(
    (token?.value() ?? '') != '',
    role?.value() ?? ROLE.PUBLIC
  );

  // useEffect(() => {
  //   initFirebaseMessaging();
  // }, []);

  // const initFirebaseMessaging = async () => {
  //   Notification.requestPermission().then(permission => {
  //     if (permission === 'granted') {
  //       getToken(firebaseApp, {
  //         vapidKey:
  //           'BEs43ZSpA7MsRJ6HSTf04ngROh0AZ7YZSLTDH9KoudU7sJeAT71DRiZBR76Gln4mp6do3TJ60-Y67KkhdnTbTiM',
  //       }).then(token => {
  //         console.error('FCM Token:', token);
  //         notificationToken?.setValue(token);
  //       });
  //     }
  //   });
  //   onMessage(firebaseApp, payload => {
  //     console.error('Message received. ', payload);
  //     const notification = payload?.notification;
  //     // ...
  //     if (notification?.title ?? '' != '') toast.info(notification?.title);
  //   });
  // };

  return <RouterProvider router={routeConfig} />;
}
