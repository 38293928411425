import React from 'react';
import MyTeamContainer from '../container/MyTeamContainer';
import Box from '../components/single_component/Box';
import SalesExecutiveProvider from '../providers/SalesExecutiveProvider';

function MyTeam() {
  return (
    <SalesExecutiveProvider>
      <Box
        style={{ backgroundColor: '#F2FAFE', paddingTop: 6, paddingBottom: 5 }}>
        <MyTeamContainer />
      </Box>
    </SalesExecutiveProvider>
  );
}

export default MyTeam;
