import React from 'react';
import Image from '../single_component/Image';
import Box from '../single_component/Box';
import Props from '../../types/props';
import Text from '../single_component/Text';

function DeveloperCard({ ...props }: Props) {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 3,
        cursor: props?.disabled ? 'auto' : 'pointer',
      }}
      onClick={props?.disabled ? () => {} : props.onClick}>
      <Image
        src={props?.extra?.groupLogo}
        style={{ width: 32, height: 32, borderRadius: '100%' }}
      />
      <Text style={{ fontSize: 16 }}>{props.label}</Text>
    </Box>
  );
}

export default DeveloperCard;
