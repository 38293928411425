import React from 'react';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import Props from '../../types/props';

function ContactDetails(props: Props) {
  const { phoneNumber, email } = props.extra;
  return (
    <Box>
      <Text style={{ fontWeight: 600, fontSize: 14, color: '#101828' }}>
        {phoneNumber}
      </Text>
      <Text style={{ fontWeight: 400, fontSize: 14, color: '#667085' }}>
        {email}
      </Text>
    </Box>
  );
}

export default ContactDetails;
