import React from 'react';
import Box from './Box';
import Button from './Button';
import Text from './Text';
import Props from '../../types/props';
import Loader from './Loader';

function DownloadActive(props: Props = {}) {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 20px',
        margin: '18px 18px 0px',
        background: '#E0FFD4',
      }}>
      <Text style={{ fontSize: 20, fontWeight: 500 }}>{props?.label}</Text>
      {!props?.extra?.loading ? (
        <Button
          onClick={props?.onClick}
          extra={{ variant: 'contained', color: 'primary' }}>
          <Text style={{ color: 'white', fontSize: 13, fontWeight: 500 }}>
            Download
          </Text>
        </Button>
      ) : (
        <Loader></Loader>
      )}
    </Box>
  );
}

export default DownloadActive;
