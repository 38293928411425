import { Moment } from 'moment';
import DateUtils from '../utils/dateUtils';

class InvestmentCriteria {
  id: number;
  name: string;
  isActive: boolean;
  createdBy: number;
  updatedBy: number | null;
  createdAt: Moment | null;
  updatedAt: Moment | null;

  constructor(data: Record<string, unknown>) {
    this.id = parseInt((data.ic_id ?? 'N/A').toString());
    this.name = (data.name ?? 'N/A').toString();
    this.isActive = Boolean(data.isActive);
    this.createdBy = parseInt((data.createdBy ?? '0').toString());
    this.updatedBy = parseInt((data.updatedBy ?? 'N/A').toString());
    this.createdAt = DateUtils.parseDate((data.createdAt ?? 'N/A').toString());
    this.updatedAt = DateUtils.parseDate((data.updatedAt ?? 'N/A').toString());
  }

  static fromJson(json: Record<string, unknown>): InvestmentCriteria {
    return new InvestmentCriteria(json);
  }

  toJson(stringify: boolean = false): Record<string, unknown> | string {
    const jsonData = {
      ic_id: this.id,
      name: this.name,
      isActive: this.isActive,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      createdAt: this.createdAt?.toISOString(),
      updatedAt: this.updatedAt?.toISOString(),
    };

    return stringify ? JSON.stringify(jsonData) : jsonData;
  }
}

export default InvestmentCriteria;
