import React from 'react';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import Props from '../../types/props';
import TextLabelGray from '../single_component/TextLabelGray';

function TextWithLabelGray({ ...props }: Props) {
  return (
    <Box>
      <TextLabelGray style={{ fontSize: 14 }}>{props.label}</TextLabelGray>
      <Text style={{ fontSize: 14, marginBottom: 10 }}>
        {props.extra.description}
      </Text>
    </Box>
  );
}

export default TextWithLabelGray;
