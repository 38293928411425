import React, { useState } from 'react';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import TextInput from '../common_component/TextInput';
import Check from '../single_component/Check';
import Button from '../single_component/Button';
import Props from '../../types/props';
import Image from '../single_component/Image';
import HiHand from '../../assets/HiHand.png';
import ErrorText from '../common_component/ErrorText';
function LoginForm({ ...props }: Props) {
  const [Checked, setChecked] = useState(false);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    serverError,
    setServerError,
    loading,
  } = props.extra;

  return (
    <Box style={{ width: 470 }}>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Text style={{ fontSize: 42, fontWeight: 'bold' }}>Welcome</Text>
        <Image
          src={HiHand}
          style={{
            width: 40,
            height: 40,
            objectFit: 'cover',
            marginLeft: '10px',
            marginBottom: '4px',
            transform: 'scaleX(-1)',
          }}
        />
      </Box>
      <Text style={{ fontSize: 14, color: '#98A2B3', maxWidth: 380 }}>
        Sign in to access your account and manage your investment portfolio with
        ease
      </Text>
      <Box style={{ margin: '30px 0px' }}>
        <TextInput
          label="Email"
          name="email"
          id="email"
          placeholder="Enter your Email"
          value={values.email}
          onBlur={handleBlur}
          onChange={(e: any) => {
            handleChange(e);
            setServerError();
          }}
        />
        {touched.email && errors.email ? (
          <ErrorText>{errors.email}</ErrorText>
        ) : null}

        {serverError ? <ErrorText>{serverError}</ErrorText> : null}
      </Box>

      <Box
        style={{
          justifyContent: 'start',
          alignItems: 'center',
          display: 'flex',
        }}>
        <Check
          extra={{
            Checked,
            setChecked,
            size: 'small',
          }}
        />
        <Text style={{ fontSize: 14 }}>Remember Me</Text>
      </Box>

      <Box style={{ marginTop: 3 }}>
        <Button
          style={{ borderRadius: 1, width: '100%', padding: 1.5 }}
          extra={{
            variant: 'contained',
            color: 'primary',
            btnDisableLoader: loading,
          }}
          onClick={handleSubmit}>
          <Text style={{ fontSize: 14 }}>Send OTP</Text>
        </Button>
      </Box>
    </Box>
  );
}

export default LoginForm;
