import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from './Box';
import Text from './Text';
import Button from './Button';
import { Pagination } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import Props from '../../types/props';
import { MenuItem, Select } from '@mui/material';

const options = [
  {
    id: 2,
    name: 10,
  },
  {
    id: 3,
    name: 20,
  },
  {
    id: 4,
    name: 30,
  },
];

export default function Tables({ ...props }: Props = {}) {
  const {
    rows,
    columns,
    RowComponent,
    page,
    rowsPerPage,
    setrowsPerPage,
    Next,
    Previous,
    setcurrPage,
    currPage,
  } = props.extra ?? {};

  return (
    <Box
      style={{
        backgroundColor: 'white',
        paddingTop: 1,
        paddingBottom: 2,
      }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table" sx={{ p: '18px' }}>
          <TableHead>
            <TableRow>
              {(columns ?? []).map((column: any) => (
                <TableCell
                  key={column.id}
                  style={{
                    minWidth: 100,
                    fontWeight: 'bold',
                    backgroundColor: '#daedff',
                  }}>
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              (rows ?? []).map(
                (row: any, index: React.Key | null | undefined) => (
                  <RowComponent row={row} key={index} index={index} />
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          paddingInline: 3,
        }}>
        {Previous != null ? (
          <Button
            icons={<WestIcon style={{ fontSize: 14, color: 'black' }} />}
            onClick={() => setcurrPage(currPage == 0 ? currPage : currPage - 1)}
            extra={{
              variant: 'outlined',
              color: 'tertiary',
            }}>
            <Text style={{ fontSize: 12, fontWeight: 500, color: 'black' }}>
              Previous
            </Text>
          </Button>
        ) : (
          <Box></Box>
        )}

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Pagination
            count={page}
            page={currPage}
            shape="rounded"
            color="secondary"
            variant="text"
            sx={{
              '& .MuiPaginationItem-page.Mui-selected': {
                color: '#FFFFFF',
                backgroundColor: '#104960 !important',
              },
            }}
            hidePrevButton
            hideNextButton
            onChange={(e, page) => {
              setcurrPage(page);
            }}
          />
          <Select
            value={rowsPerPage}
            sx={{ width: 100, height: 35, marginLeft: 5 }}
            defaultValue={options[0].name}
            onChange={(e: any) => {
              setrowsPerPage(e.target.value);
            }}>
            {options.map((value: any, index: React.Key | null | undefined) => {
              return (
                <MenuItem value={value.name} key={index}>
                  <Text style={{ fontSize: 13, fontWeight: 'normal' }}>
                    {value.name} / Page
                  </Text>
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        {Next != null ? (
          <Button
            icons2={<EastIcon style={{ fontSize: 14, color: 'black' }} />}
            onClick={() => {
              setcurrPage(currPage + 1);
            }}
            extra={{
              variant: 'outlined',
              color: 'tertiary',
            }}>
            <Text style={{ fontSize: 12, fontWeight: 500, color: 'black' }}>
              Next
            </Text>
          </Button>
        ) : (
          <Box></Box>
        )}
      </Box>
    </Box>
  );
}
