import React, { useContext, useEffect, useState } from 'react';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import Props from '../../types/props';
import moment from 'moment';
import EditIcon from '@mui/icons-material/BorderColor';
import UploadInput from '../single_component/UploadInput';
import StringUtils from '../../utils/stringUtil';
import Button from '../single_component/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Image from '../single_component/Image';
import { Grid } from '@mui/material';
import TextInput from '../common_component/TextInput';
import Dropdown from '../common_component/Dropdown';
import ProfileContext from '../../context/ProfileContext';
import { toast } from 'react-toastify';
import SalesExecutiveContext from '../../context/SalesExecutiveContext';

function ProfileCard(props: Props) {
  const { data, developerCount } = props.extra;
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isChange, setIsChange] = useState(true);
  const [numcheck, setnumcheck] = useState(true);
  const [selectedRole, setSelectedRole] = useState(0);
  const [initialValues, setinitialValues] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    city: '',
  });
  const { updateSalesExcetiveProfile, deleteSalesExecutiveProfileImg } =
    useContext(ProfileContext);
  const { getSalesExecutiveById } = useContext(SalesExecutiveContext);
  const dropdownOption = [{ name: 'Admin' }, { name: 'Sales-Executive' }];
  const handleSubmit = async () => {
    if (numcheck) {
      setLoading(true);
      const response = await updateSalesExcetiveProfile(data?.userId, {
        name: initialValues?.name,
        phoneNumber: initialValues?.phoneNumber,
        email: initialValues?.email,
        city: initialValues?.city,
        role: selectedRole + 1,
      });
      setLoading(false);
      if (response.success) {
        toast.success(response.message);
        getSalesExecutiveById(data.userId, 1, 10);
        setEdit(false);
      } else {
        toast.error(response.message);
      }
    }
  };

  const handleChangeProfile = async (file: any) => {
    setIsChange(false);
    const body = new FormData();
    body.append('profileImg', file);
    const response = await updateSalesExcetiveProfile(data?.userId, body);
    setLoading(false);
    if (response.success) {
      toast.success(response.message);
      getSalesExecutiveById(data.userId, 1, 10);
    } else {
      toast.error(response.message);
    }
    setIsChange(true);
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber' && value.length > 10) setnumcheck(false);
    else setnumcheck(true);
    setinitialValues({
      ...initialValues,
      [name]: value,
    });
  };
  const handleDeleteSalesExecutiveProfileImg = async () => {
    const response = await deleteSalesExecutiveProfileImg(data?.userId);
    setLoading(false);
    if (response.success) {
      toast.success(response.message);
      getSalesExecutiveById(data.userId, 1, 10);
    } else {
      toast.error(response.message);
    }
  };
  useEffect(() => {
    setinitialValues({
      name: StringUtils.capitalizeFirstLetter(data?.name ?? ''),
      phoneNumber: data?.phoneNumber,
      email: data?.email,
      city: data?.city?.name,
    });
    setSelectedRole(data?.roleId - 1);
  }, [data]);
  return (
    <Box
      style={{
        width: '85%',
        margin: 'auto',
        boxShadow: ' 0px 2px 10px 0px #10496030',
        borderRadius: 2,
      }}>
      {!edit ? (
        <Box style={{ backgroundColor: '#FFF', borderRadius: 1.5 }}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingInline: 6,
              paddingBlock: 1.5,
              borderBottom: 1,
              borderColor: '#E7E7E7',
            }}>
            <Text style={{ fontSize: 16, fontWeight: 600 }}>
              {StringUtils.capitalizeFirstLetter(data?.name ?? '')}{' '}
            </Text>
            <Button
              extra={{ variant: 'outlined', color: 'primary' }}
              style={{ paddingBlock: 1, paddingInline: 2, borderRadius: 1 }}
              icons={<EditIcon style={{ fontSize: 16 }} />}
              onClick={() => setEdit(true)}>
              <Text style={{ color: '#104960', fontWeight: 500, fontSize: 13 }}>
                Edit Details
              </Text>
            </Button>
          </Box>

          <Box
            style={{
              display: 'flex',
              padding: 6,
              columnGap: 7,
              alignItems: 'center',
            }}>
            <Box
              style={{
                width: 236,
                height: 236,
                borderRadius: 2,
                backgroundColor: '#E5F7FF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {data?.profileImg ? (
                <Image
                  alt={data?.name}
                  src={data?.profileImg}
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                />
              ) : (
                <Text
                  style={{
                    fontSize: 64,
                    color: '#104960',
                    fontWeight: 600,
                  }}>
                  {StringUtils.getInitials(data?.name ?? '')}
                </Text>
              )}
            </Box>
            <Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  marginTop: 1.5,
                }}>
                <Text
                  style={{
                    width: 220,
                    fontSize: 14,
                    color: '#667085',
                    fontWeight: 'lighter',
                    textTransform: 'uppercase',
                  }}>
                  Email
                </Text>
                <Text>:</Text>
                <Text
                  style={{
                    color: 'black',
                    fontWeight: 'normal',
                  }}>
                  {data && data.email}
                </Text>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  marginTop: 1.5,
                }}>
                <Text
                  style={{
                    width: 220,
                    fontSize: 14,
                    color: '#667085',
                    fontWeight: 'lighter',
                    textTransform: 'uppercase',
                  }}>
                  Phone
                </Text>
                <Text>:</Text>
                <Text
                  style={{
                    color: 'black',
                    fontWeight: 'normal',
                  }}>
                  +91 {data && data.phoneNumber}
                </Text>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  marginTop: 1.5,
                }}>
                <Text
                  style={{
                    width: 220,
                    fontSize: 14,
                    color: '#667085',
                    fontWeight: 'lighter',
                    textTransform: 'uppercase',
                  }}>
                  Location
                </Text>
                <Text>:</Text>
                <Text
                  style={{
                    color: 'black',
                    fontWeight: 'normal',
                  }}>
                  {StringUtils.capitalizeFirstLetter(
                    data?.city && data?.city?.name
                  )}
                </Text>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  marginTop: 1.5,
                }}>
                <Text
                  style={{
                    width: 220,
                    fontSize: 14, // Change the font size to a valid unit, e.g., "14px"
                    color: '#667085',
                    fontWeight: 'lighter',
                    textTransform: 'uppercase',
                  }}>
                  Joining Date
                </Text>
                <Text>:</Text>
                <Text
                  style={{
                    // Change the font size to a valid unit, e.g., "14px"
                    color: 'black',
                    fontWeight: 'normal',
                  }}>
                  {moment(data.joiningDate).format('DD MMM,YYYY')}
                </Text>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  marginTop: 1.5,
                }}>
                <Text
                  style={{
                    width: 220,
                    fontSize: 14, // Change the font size to a valid unit, e.g., "14px"
                    color: '#667085',
                    fontWeight: 'lighter',
                    textTransform: 'uppercase',
                  }}>
                  Role
                </Text>
                <Text>:</Text>
                <Text
                  style={{
                    color: 'black',
                    fontWeight: 'normal',
                  }}>
                  Sales Executive
                </Text>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  marginTop: 1.5,
                }}>
                <Text
                  style={{
                    width: 220,
                    fontSize: 14,
                    color: '#667085',
                    fontWeight: 'lighter',
                    textTransform: 'uppercase',
                  }}>
                  number of developers
                </Text>
                <Text>:</Text>
                <Text
                  style={{
                    color: 'black',
                    fontWeight: 'normal',
                  }}>
                  {developerCount && developerCount}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          style={{
            boxShadow: ' 0px 1px 3px 0px #1018281A',
            border: 1,
            borderRadius: 1,
            borderColor: '#E7E7E7',
            background: '#FFFFFF',
          }}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingInline: 6,
              paddingBlock: 2,
              borderBottom: 1,
              borderColor: '#E7E7E7',
            }}>
            <Text style={{ fontSize: 16, fontWeight: 600 }}>
              {StringUtils.capitalizeFirstLetter(data?.name)}
            </Text>
          </Box>
          <Box
            style={{
              display: 'flex',
              gap: '60px',
              padding: '32px 40px',
            }}>
            <Box>
              {/* <Image
            alt="DK"
            style={{
              height: 236,
              minWidth: 236,
              background: '#E5F7FF',
              boxShadow: '0px 2px 2px 0px #1049601A',
              borderRadius: '8px',
              marginTop: 12,
            }}
          /> */}
              <Box
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 2,
                  backgroundColor: '#E5F7FF',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}>
                {isChange && (
                  <div style={{ position: 'absolute', right: 5, top: 5 }}>
                    <UploadInput
                      name={'documentFile'}
                      id={'documentFile'}
                      extra={{
                        padding: '1px',
                        isModify: true,
                        onchange: (e: any) => {
                          handleChangeProfile(e[0]);
                        },
                        style: { marginTop: '-6px' },
                      }}
                    />
                  </div>
                )}
                <DeleteIcon
                  onClick={() => handleDeleteSalesExecutiveProfileImg()}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 40,
                    color: '#104960',
                  }}
                />
                {data?.profileImg ? (
                  <Image
                    alt={data?.name}
                    src={data?.profileImg}
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  />
                ) : (
                  <Text
                    style={{
                      fontSize: 64,
                      color: '#104960',
                      fontWeight: 600,
                    }}>
                    {StringUtils.getInitials(data?.name ?? '')}
                  </Text>
                )}
              </Box>
            </Box>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                {/* <TextInput
                  label="Role"
                  placeholder="Admin"
                  value={data?.roleId == 1 ? 'Admin' : 'Sales-Executive'}
                  readOnly={true}
                /> */}
                <Dropdown
                  label="Role"
                  value={(dropdownOption[selectedRole] ?? {})['name'] ?? ''}
                  extra={{
                    options: dropdownOption,
                    placeholder: 'Select Role',
                  }}
                  onChange={(e: any) => {
                    setSelectedRole(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Joining Date"
                  placeholder="Admin"
                  readOnly={true}
                  disabled={true}
                  value={moment(data?.joiningDate).format('DD-MM-YYYY')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Name"
                  name="name"
                  id="name"
                  onChange={handleChange}
                  placeholder="Enter Name"
                  value={initialValues?.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Location"
                  placeholder="Enter location"
                  value={initialValues?.city}
                  name="city"
                  id="city"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Email ID"
                  placeholder="Enter Email ID"
                  value={initialValues?.email}
                  name="email"
                  id="email"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Phone Number"
                  placeholder="Enter Phone Number"
                  value={initialValues?.phoneNumber}
                  name="phoneNumber"
                  id="phoneNumber"
                  onChange={handleChange}
                />
                {!numcheck && (
                  <Text style={{ color: 'red', fontSize: '10px' }}>
                    Phone No. should be of 10 digits
                  </Text>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  extra={{
                    variant: 'outlined',
                    color: 'primary',
                  }}
                  style={{
                    borderRadius: 1,
                    width: '100%',
                    padding: 1.5,
                    marginTop: 2,
                  }}
                  onClick={() => {
                    setinitialValues({
                      name: StringUtils.capitalizeFirstLetter(data?.name ?? ''),
                      phoneNumber: data?.phoneNumber,
                      email: data?.email,
                      city: data?.city?.name,
                    });
                    setEdit(false);
                  }}>
                  <Text
                    style={{ color: '#104960', fontWeight: 500, fontSize: 13 }}>
                    Cancel
                  </Text>
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  extra={{
                    variant: 'contained',
                    color: 'primary',
                    btnDisableLoader: loading,
                  }}
                  style={{
                    borderRadius: 1,
                    width: '100%',
                    padding: 1.5,
                    marginTop: 2,
                  }}
                  onClick={handleSubmit}>
                  <Text
                    style={{ color: 'white', fontWeight: 500, fontSize: 13 }}>
                    Save
                  </Text>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ProfileCard;
