import React, { useContext, useState } from 'react';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import Props from '../../types/props';
import Button from '../single_component/Button';
import FilterList from '@mui/icons-material/FilterList';
import Sidebar from '../common_component/Sidebar';
import ActiveFilter from '../Filter/ActiveFilter';
import AddSpvForm from '../Forms/AddSpvForm';
import AddOutlined from '@mui/icons-material/AddOutlined';
import SpvContext from '../../context/SpvContext';

function DeveloperTableTop({ ...props }: Props) {
  const { label, description, id } = props.extra;
  const [FilterSidebar, setFilterSidebar] = useState(false);
  const { addSPV, setAddSpv } = useContext(SpvContext);
  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '12px 24px 0px 24px ',
          backgroundColor: 'white',
          borderRadius: 1.5,
        }}>
        <Box>
          <Text style={{ fontSize: 15, fontWeight: 'bold' }}>{label}</Text>
          <Text style={{ fontSize: 14, color: '#667085' }}>{description}</Text>
        </Box>

        {label == 'Transactions' ? (
          <Box style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Button
              onClick={() => setFilterSidebar(!FilterSidebar)}
              extra={{
                variant: 'outlined',
                color: 'tertiary',
              }}
              style={{ padding: '8px 24px', borderRadius: 1.5 }}
              icons={<FilterList />}>
              <Text style={{ color: 'black', fontSize: 13 }}>Add Filters</Text>
            </Button>
          </Box>
        ) : null}
        {label == 'Developer Group 1' ? (
          <Box style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Button
              onClick={() => setAddSpv(!addSPV)}
              style={{ padding: '8px 24px', borderRadius: 2 }}
              extra={{
                variant: 'contained',
                color: 'primary',
              }}
              icons={<AddOutlined />}>
              <Text style={{ color: 'white', fontSize: 13 }}>Add SPV</Text>
            </Button>
          </Box>
        ) : null}

        <Sidebar
          label="Filter"
          extra={{
            anchor: 'right',
            Toggle: FilterSidebar,
            setToggle: setFilterSidebar,
            description: 'Filter the data as per your need ',
            width: 400,
          }}>
          <ActiveFilter extra={{ setToggle: setFilterSidebar }} />
        </Sidebar>

        <Sidebar
          label="Add SPV"
          extra={{
            anchor: 'right',
            Toggle: addSPV,
            setToggle: setAddSpv,
            description: 'Add SPV details here to view in the dashboard',
            width: 400,
          }}>
          <AddSpvForm extra={{ setToggle: setAddSpv, id }} />
        </Sidebar>
      </Box>
    </Box>
  );
}

export default DeveloperTableTop;
