import React, { useState } from 'react';
import Props from '../../types/props';
import Button from '../single_component/Button';
import TextInput from '../common_component/TextInput';
import Box from '../single_component/Box';
import DeletableChip from '../single_component/DeletableChip';
import Image from '../single_component/Image';
import deleteIcon from '../../assets/close.png';
import { useFormik } from 'formik';
// import ErrorText from '../common_component/ErrorText';
// import { addInvestorLocationSchema } from '../Validation/AddTask';

const InvetsorLocationForm = (props: Props = {}) => {
  console.error(props);

  ////////////////////////////////market chips

  const [activeLocValues, setActiveLocation] = useState({
    activeLocation: '',
  });
  const [activeLocChips, setActiveLocChips] = useState<any>([]);
  console.error('activeLocChips', activeLocChips);

  const handleActiveLocation = (e: any) => {
    setActiveLocation({
      ...activeLocValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddActiveLocaChip = () => {
    if (activeLocValues.activeLocation.trim() !== '') {
      setActiveLocChips([...activeLocChips, activeLocValues.activeLocation]);
      setActiveLocation({
        ...activeLocValues,
        activeLocation: '',
      });
    }
  };

  const handleDeleteActiveLocChip = (index: any) => {
    const newChips = [...activeLocChips];
    newChips.splice(index, 1);
    setActiveLocChips(newChips);
  };

  ////////////////////////// location chips
  const [locationValues, setLocation] = useState({
    location: '',
  });
  const [locationChips, setLocationChips] = useState<any>([]);
  console.error('locationChips', locationChips);

  const handleLocation = (e: any) => {
    setFieldValue('location', e);
    setLocation({
      ...locationValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddLocaChip = () => {
    if (locationValues.location.trim() !== '') {
      setLocationChips([...locationChips, locationValues.location]);
      setLocation({
        ...locationValues,
        location: '',
      });
    }
  };

  const handleDeleteLocChip = (index: any) => {
    const newChips = [...locationChips];
    newChips.splice(index, 1);
    setLocationChips(newChips);
  };

  const formState = {
    activeMarkets: '',
    locationPreference: '',
  };

  const { values, handleBlur, handleSubmit, setFieldValue } = useFormik({
    initialValues: formState,
    // validationSchema: addInvestorLocationSchema,
    onSubmit: async () => {
      console.error(values, locationChips, activeLocChips, 'locationChips');
      props?.extra?.onNext({
        activeMarkets: activeLocChips,
        locationPref: locationChips,
      });
    },
  });

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
        }}>
        <TextInput
          label="Active markets of investor"
          placeholder="Enter Active markets"
          id="activeLocation"
          name="activeLocation"
          onBlur={handleBlur}
          value={activeLocValues.activeLocation}
          onChange={handleActiveLocation}
        />
        {/* {touched.activeMarkets &&
          errors.activeMarkets && activeLocChips.length == 0 ? (
          <ErrorText>{errors.activeMarkets}</ErrorText>
        ) : null} */}

        <Button
          extra={{ variant: 'contained' }}
          style={{ marginTop: 2.5, padding: '8px 30px' }}
          onClick={handleAddActiveLocaChip}>
          Add
        </Button>
      </Box>
      <Box>
        {activeLocChips.map((chip: any, index: number) => (
          <DeletableChip
            key={index}
            label={chip}
            onClick={() => handleDeleteActiveLocChip(index)}
            style={{ margin: '4px', background: '#E5F7FF' }}
            extra={{
              variant: 'outlined',
              icons: (
                <Image
                  src={deleteIcon}
                  style={{ cursor: 'pointer', width: '15px' }}
                />
              ),
            }}
          />
        ))}
      </Box>

      {/* location chips */}
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
        }}>
        <TextInput
          label="Location preference"
          placeholder="Enter Location preference"
          id="location"
          name="location"
          onBlur={handleBlur}
          value={locationValues.location}
          onChange={handleLocation}
        />
        <Button
          extra={{ variant: 'contained' }}
          style={{ marginTop: 2.5, padding: '8px 30px' }}
          onClick={handleAddLocaChip}>
          Add
        </Button>
      </Box>
      <Box>
        {locationChips.map((chip: any, index: number) => (
          <DeletableChip
            key={index}
            label={chip}
            onClick={() => handleDeleteLocChip(index)}
            style={{ margin: '4px', background: '#E5F7FF' }}
            extra={{
              variant: 'outlined',
              icons: (
                <Image
                  src={deleteIcon}
                  style={{ cursor: 'pointer', width: '15px' }}
                />
              ),
            }}
          />
        ))}
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bottom: 0,
          position: 'absolute',
          width: '99%',
          marginLeft: -2.5,
          background: 'white',
          paddingTop: '12px',
          paddingBottom: '8px',
          borderTopRightRadius: 3,
        }}>
        <Button
          extra={{ variant: 'outlined' }}
          onClick={props?.extra?.onPrevious}
          style={{ width: '43%', marginRight: '2%', paddingBlock: 1 }}>
          Back
        </Button>
        <Button
          extra={{
            variant: 'contained',
            btnDisableLoader: props?.extra?.loading,
          }}
          onClick={handleSubmit}
          style={{ width: '43%', marginLeft: '2%', paddingBlock: 1 }}>
          Done
        </Button>
      </Box>
    </Box>
  );
};

export default InvetsorLocationForm;
