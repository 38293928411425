import React from 'react';
import FileChip from '../../components/single_component/FileChip';
import Box from '../../components/single_component/Box';
import { TableCell, TableRow } from '@mui/material';
import Tables from '../../components/single_component/Tables';
import Text from '../../components/single_component/Text';
import Props from '../../types/props';
import StringUtils from '../../utils/stringUtil';
import DateUtils from '../../utils/dateUtils';
import DashFilterTop from '../../components/TableHeader/DashFilterTop';
import PluseTwo from '../../components/common_component/PluseTwo';
import StatusChip from '../../components/common_component/StatusChip';
import EyeAction from '../../components/single_component/EyeAction';
import NotfoundPage from '../../components/single_component/NotfoundPage';
import Loader from '../../components/single_component/Loader';
// import TransactionsContex from '../../context/TransactionsContex';

function TransactionDataTable({ ...props }: Props = {}) {
  const {
    data,
    Page,
    setPage,
    rowsPerPage,
    setrowsPerPage,
    Next,
    setNext,
    Previous,
    setPrevious,
    currPage,
    setcurrPage,
    Date,
    setDate,
    filterToggle,
    setFilterToggle,
    selectedChip,
    setSelectedChip,
    fetchData,
    loading,
  } = props.extra ?? {};

  const TableRowComponet = (props: any) => {
    const { row } = props ?? {};
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: '80%' }}>
        <TableCell>
          <Text style={{ fontSize: 14 }}>
            {row?.date == undefined || row?.date == null || row?.date == '' ? (
              <Text>N/A</Text>
            ) : (
              DateUtils.formatDate(row?.date ?? '', 'DD MMM YYYY')
            )}
          </Text>
        </TableCell>

        <TableCell>
          <Text style={{ fontSize: 14 }}>
            {row?.project?.projectName ?? 'N/A'}
          </Text>
        </TableCell>
        <TableCell>
          <Text style={{ fontSize: 14 }}>
            {row?.project?.spv?.developer?.name ?? 'N/A'}
          </Text>
        </TableCell>
        <TableCell>
          {row?.dealSize == undefined ||
          row?.dealSize == null ||
          row?.dealSize == '' ? (
            <Text>N/A</Text>
          ) : (
            <Text> ₹ {row?.dealSize}</Text>
          )}
        </TableCell>

        <TableCell>
          {row?.investors == undefined ||
          row?.investors == null ||
          row?.investors == '' ? (
            <Text>N/A</Text>
          ) : (
            <Box
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
              }}>
              <Text style={{ fontSize: 14 }}>
                {StringUtils.capitalizeFirstLetter(row?.investors[0]?.name)}
              </Text>
              {row?.investors?.length > 1 && (
                <PluseTwo
                  extra={{ OverPluse: `+${row?.investors?.length - 1}` }}
                />
              )}
            </Box>
          )}
        </TableCell>
        <TableCell>
          {row?.mandate == undefined ||
          row?.mandate == null ||
          row?.mandate == '' ? (
            <Text>N/A</Text>
          ) : (
            <FileChip
              label={row?.documentName ?? 'Mandate'}
              extra={{ url: row?.mandate ?? '' }}
            />
          )}
        </TableCell>
        <TableCell>
          <StatusChip label={row?.transcStatus ?? ''} />
        </TableCell>
        <TableCell>
          <EyeAction
            extra={{
              label: row?.transcStatus ?? '',
              data: row ?? {},
              page: 'Sales-Dashboard',
            }}
          />
        </TableCell>
      </TableRow>
    );
  };

  const tableHeaders = [
    { name: 'Date', id: 'Date' },
    { name: 'Project Name', id: 'ProjectName' },
    { name: 'Developer Name', id: 'DeveloperName' },
    { name: 'Deal Size', id: 'DealSize' },
    { name: 'Potential Investors', id: 'PotentialInvestor' },
    { name: 'Documents', id: 'Documents' },
    { name: 'Status', id: 'Status' },
    { name: 'Action', id: 'Action' },
  ];

  console.error('loading', loading);

  return (
    <Box style={{ marginTop: 6, boxShadow: '0px 2px 10px 0px #1049601A' }}>
      <DashFilterTop
        extra={{
          Date,
          setDate,
          filterToggle,
          setFilterToggle,
          selectedChip,
          setSelectedChip,
          fetchData,
        }}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          {data.records && data.records.length > 0 ? (
            <Tables
              extra={{
                rows: data.records,
                columns: tableHeaders,
                RowComponent: TableRowComponet,
                page: Page,
                setPage: setPage,
                rowsPerPage: rowsPerPage,
                setrowsPerPage: setrowsPerPage,
                Next: Next,
                Previous: Previous,
                currPage: currPage,
                setcurrPage: setcurrPage,
                setNext: setNext,
                setPrevious: setPrevious,
              }}
            />
          ) : (
            <NotfoundPage />
          )}
        </>
      )}
    </Box>
  );
}

export default TransactionDataTable;
