import React, { useContext, useEffect } from 'react';
import Box from '../../components/single_component/Box';
import SpvContainer from '../../container/SpvContainer';
import { useLocation, useParams } from 'react-router-dom';
import SpvContext from '../../context/SpvContext';
import Text from '../../components/single_component/Text';
import Button from '../../components/single_component/Button';
import Sidebar from '../../components/common_component/Sidebar';
import AddSpvForm from '../../components/Forms/AddSpvForm';
import AddOutlined from '@mui/icons-material/AddOutlined';
import BreadCumbContext from '../../context/BreadCrumbContext';
import Loader from '../../components/single_component/Loader';
import StringUtils from '../../utils/stringUtil';

function ViewDeveloperGroupContainer() {
  const { getSpv, SpvData } = useContext(SpvContext);
  const { id } = useParams();
  const location = useLocation();
  const { setPathParams } = useContext(BreadCumbContext);
  const { loading, addSPV, setAddSpv, setisEditSpv } = useContext(SpvContext);

  useEffect(() => {
    getSpv(id);
    setPathParams({ id: location?.state?.groupName ?? 'N/A' });
  }, []);
  return (
    <Box>
      <Box
        style={{
          width: '85%',
          margin: 'auto',
          borderRadius: 2,
          background: 'white',
          boxShadow: '0px 2px 10px 0px #1049601A',
        }}>
        <Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '12px 24px 0px 24px ',
              backgroundColor: 'white',
              borderRadius: 1.5,
            }}>
            <Box>
              <Text
                style={{
                  fontSize: 15,
                  fontWeight: 'bold',
                  display: 'flex',
                  gap: 10,
                }}>
                {StringUtils.capitalizeFirstLetter(
                  location?.state?.groupName ?? ''
                )}
                {/* <Text style={{ fontSize: 14 }}>-</Text> */}
                <Text
                  style={{ display: 'flex', fontSize: 14, color: '#667085' }}>
                  {StringUtils.capitalizeFirstLetter(
                    location?.state?.promoterName ?? ''
                  )}
                  <Text style={{ fontSize: 14, color: '#667085' }}>
                    {/* ({location?.state?.promoterEmail ?? ''}) */}
                  </Text>
                </Text>
              </Text>
              <Text style={{ fontSize: 14, color: '#667085' }}>
                {`Here you can create and view SPV under   ${StringUtils.capitalizeFirstLetter(
                  location?.state?.groupName ?? ''
                )}`}
              </Text>
            </Box>

            <Box style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Button
                onClick={() => {
                  setAddSpv(!addSPV);
                  setisEditSpv(false);
                }}
                style={{ padding: '8px 24px', borderRadius: 2 }}
                extra={{
                  variant: 'contained',
                  color: 'primary',
                }}
                icons={<AddOutlined />}>
                <Text style={{ color: 'white', fontSize: 13 }}>Add SPV</Text>
              </Button>
            </Box>

            <Sidebar
              label="Add SPV"
              extra={{
                anchor: 'right',
                Toggle: addSPV,
                setToggle: setAddSpv,
                description: 'Add SPV details here to view in the dashboard',
                width: 400,
              }}>
              <AddSpvForm extra={{ setToggle: setAddSpv, id }} />
            </Sidebar>
          </Box>
        </Box>

        <Box
          style={{
            padding: '12px 24px',
            display: 'flex',
            gap: 2,
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            marginTop: 2,
          }}>
          {loading ? (
            <Loader />
          ) : (
            Array.isArray(SpvData) &&
            SpvData?.map((value: any, index: number) => (
              <SpvContainer
                key={index}
                extra={{
                  value: value,
                  id: id,
                  groupName: location?.state?.groupName ?? '',
                }}
              />
            ))
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ViewDeveloperGroupContainer;
