import { NavLink } from 'react-router-dom';
import React from 'react';
import Props from '../../types/props';
import { ROUTES } from '../../types/routeObject';
import Text from '../single_component/Text';

function Links({ children, ...props }: Props) {
  return (
    <NavLink
      to={props.href ?? ROUTES.NOTFOUND}
      style={{
        borderBottomColor: 'black',
        textDecorationLine: 'none',
        paddingLeft: 10,
        paddingRight: 10,
        cursor: 'pointer',
        fontSize: 14,
      }}
      className={props.className}>
      <Text style={{ fontWeight: 600, fontSize: 14, fontFamily: 'Inter' }}>
        {children}
      </Text>
    </NavLink>
  );
}

export default Links;
