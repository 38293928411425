import React, { useState, useContext, useEffect, useRef } from 'react';
import Card from '../../components/single_component/Card';
import TextLabelWithDesc from '../../components/common_component/TextLabelWithDesc';
import Sidebar from '../../components/common_component/Sidebar';
import InitiateTransactions from '../../components/Forms/InitiateTransactions';
import CircleCheckBox from '../../components/common_component/CircleCheckBox';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Box from '../../components/single_component/Box';
import Text from '../../components/single_component/Text';
import Button from '../../components/single_component/Button';
import Image from '../../components/single_component/Image';
import BuildingIcon from '../../assets/images/building.png';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import theme from '../../utils/Theme';
import FormHead from '../../components/common_component/FormHead';
import piller from '../../assets/images/pillar.png';
import CourtIcon from '../../assets/images/courthouse.png';
import editProject from '../../assets/images/editProject.png';
import uploadMIS from '../../assets/images/uploadMis.png';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../../src/index.css';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Modal,
  Paper,
} from '@mui/material';
import UploadDoucuments from '../../components/single_component/UploadDocuments';
import ProjectDetailsCard from '../../components/single_component/ProjectDetailsCard';
import FormHeadIcon from '../../components/common_component/FormHeadIcon';
import moment from 'moment';
import Props from '../../types/props';
import StringUtils from '../../utils/stringUtil';
import { ROUTES } from '../../types/routeObject';
import AuthContext from '../../context/AuthContext';
import ProjectContext from '../../context/ProjectContext';
import { toast } from 'react-toastify';
import UploadInput from '../../components/single_component/UploadInput';
import { makeStyles } from '@mui/styles';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DocumentBox from '../../components/common_component/DocumentBox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function ProjectDetails({ ...props }: Props) {
  type SelectedOption = {
    name: any;
    url: any;
  };
  const { masterData } = useContext(AuthContext);
  const getMeasure = masterData?.value();
  const {
    addMis,
    addProjectBanner,
    getProjectImagesById,
    getProject,
    projectGalleryImage,
    deleteProjectImage,
    deleteMoreDocument,
  } = useContext(ProjectContext);
  const [Toggle, setToggle] = useState(false);
  const [selectedOptionsIndex, setSelectedOptionsIndex] = useState<number[]>(
    []
  );
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);
  const [Open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [Attech, setAttech] = useState(false);
  const [cover, setcover] = useState(true);
  const [documentModal, setDocumentModal] = useState(false);

  const { ProjectData, documents, projectId, groupName } = props.extra;
  const [misFile, setMISFile] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };
  const AttachOpen = () => {
    setOpen(false);
    setAttech(true);
    handleAttchMIS();
  };
  const handleButtonClick = () => {
    // if (fileInputRef.current) {
    fileInputRef?.current?.click(); // Trigger click on hidden file input
    // }
  };
  const handleChange = (event: any, name: any) => {
    const url = event.target.name;
    setSelectedOptions(prev => {
      const exists = prev.some(option => option.url === url);

      if (exists) {
        return prev.filter(option => option.url !== url);
      } else {
        return [...prev, { name, url }];
      }
    });
  };
  const attechClose = () => {
    setAttech(false);
    window.location.reload();
  };
  const handleAttchMIS = async () => {
    const bodyFormData = new FormData();
    bodyFormData.append('mis', misFile[0]);
    bodyFormData.append('projectId', projectId);
    try {
      const response = await addMis(bodyFormData);
      console.error(response, 'misFile');
      if (response?.success) {
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('Error occurred while adding MIS:', error);
    }
  };
  const handleDeleteMisDocument = async (docId: number, projectId: number) => {
    try {
      const response = await deleteMoreDocument(docId);
      if (response?.success) {
        toast.success(response?.message);
        getProject(projectId);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('Error occurred while adding file:', error);
    }
  };

  const useStyles = makeStyles(() => ({
    rectangleStyle: {
      borderRadius: '4px',
      backgroundColor: '#E4F7FF !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'auto',
      height: '150px',
    },
    modalContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '12vh',
      position: 'absolute',
      width: '80%',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  }));
  const handleAddNewGalleryBanner = async (isCover: any, file: any) => {
    setcover(false);
    const bodyFormData = new FormData();
    bodyFormData.append('projectId', projectId);
    bodyFormData.append('imagePath', file);
    bodyFormData.append('isCover', isCover);
    if (file) {
      try {
        const response = await addProjectBanner(bodyFormData);
        if (response?.success) {
          getProjectImagesById(projectId);
          toast.success(response?.message);
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        console.error('Error occurred while adding MIS:', error);
      }
    }
    setcover(true);
  };

  useEffect(() => {
    getProjectImagesById(projectId);
  }, []);
  const coverImage = projectGalleryImage?.ProjectGallery?.find(
    (item: any) => item?.isCover === 1
  );
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleModalOpen = (index: any) => {
    setSelectedImageIndex(index);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handlePreviousImage = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };
  const handleProjectImagedelete = async (imageId: number) => {
    try {
      const response = await deleteProjectImage(imageId);
      if (response?.success) {
        toast.success(response?.message);
        getProjectImagesById(projectId);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('Error occurred while adding file:', error);
    }
  };
  const handleNextImage = () => {
    if (selectedImageIndex < projectGalleryImage?.ProjectGallery.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };
  const renderImages = () => {
    const maxImages = 5;
    const visibleImages = projectGalleryImage?.ProjectGallery?.slice(
      0,
      maxImages
    );
    const imagePath =
      projectGalleryImage?.ProjectGallery?.[selectedImageIndex]?.imagePath;

    if (projectGalleryImage?.ProjectGallery?.length <= maxImages) {
      return (
        <>
          {visibleImages?.map((image: any, index: any) => (
            <ImageListItem key={image.img} sx={{ overflow: 'hidden' }}>
              <div
                style={{
                  height: '150px',
                  width: '150px',
                  marginBottom: '10px',
                }}>
                <img
                  onClick={() => handleModalOpen(index)}
                  srcSet={image.img}
                  style={{ width: '100%', cursor: 'pointer', height: '100%' }}
                  src={image?.imagePath}
                  alt={'No image here'}
                  loading="lazy"
                />
              </div>
            </ImageListItem>
          ))}
          <Modal open={modalOpen} onClose={handleModalClose}>
            <div className={classes.modalContent}>
              <Box
                style={{ position: 'absolute', top: '-20px', right: '40px' }}
                onClick={handleModalClose}>
                <HighlightOffIcon
                  sx={{ height: '50px', width: '50px', color: 'red' }}
                />
              </Box>
              <Box
                style={{ position: 'absolute', top: '35px', right: '45px' }}
                onClick={() =>
                  handleProjectImagedelete(
                    projectGalleryImage?.ProjectGallery[selectedImageIndex]
                      .galleryId
                  )
                }>
                <DeleteIcon
                  sx={{
                    height: '40px',
                    width: '40px',
                    color: '#fff',
                    backgroundColor: 'red',
                    padding: '7px',
                    borderRadius: '20px',
                  }}
                />
              </Box>
              <IconButton
                onClick={handlePreviousImage}
                disabled={selectedImageIndex === 0}>
                <NavigateBeforeIcon
                  style={{ background: '#104960', borderRadius: 4 }}
                />
              </IconButton>
              <Box style={{ width: '80%', height: '80vh' }}>
                <img
                  src={imagePath ?? ''}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    cursor: 'pointer',
                    userSelect: 'none',
                  }}
                  alt={`Image ${selectedImageIndex}`}
                />
              </Box>
              <IconButton
                onClick={handleNextImage}
                disabled={
                  selectedImageIndex ===
                  projectGalleryImage?.ProjectGallery?.length - 1
                }>
                <NavigateNextIcon
                  style={{ background: '#104960', borderRadius: 4 }}
                />
              </IconButton>
            </div>
          </Modal>
        </>
      );
    } else {
      return (
        <>
          {visibleImages?.map((image: any, index: any) => (
            <ImageListItem key={image.img} sx={{ overflow: 'hidden' }}>
              <div
                style={{
                  width: '150px',
                  height: '150px',
                  marginBottom: '10px',
                }}>
                <img
                  onClick={() => handleModalOpen(index)}
                  srcSet={image.img}
                  style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                  src={image?.imagePath}
                  alt={'No image here'}
                  loading="lazy"
                />
              </div>
            </ImageListItem>
          ))}
          <Grid item xs={12} sm={4}>
            <Paper
              className={classes.rectangleStyle}
              onClick={() => handleModalOpen(maxImages)}
              style={{ cursor: 'pointer' }}>
              <div>
                +{projectGalleryImage?.ProjectGallery?.length - maxImages}
              </div>
            </Paper>
          </Grid>
          <Modal open={modalOpen} onClose={handleModalClose}>
            <div className={classes.modalContent}>
              <Box
                style={{ position: 'absolute', top: '-20px', right: '40px' }}
                onClick={handleModalClose}>
                <HighlightOffIcon
                  sx={{ height: '50px', width: '50px', color: 'red' }}
                />
              </Box>
              <Box
                style={{ position: 'absolute', top: '35px', right: '45px' }}
                onClick={() =>
                  handleProjectImagedelete(
                    projectGalleryImage?.ProjectGallery[selectedImageIndex]
                      .galleryId
                  )
                }>
                <DeleteIcon
                  sx={{
                    height: '40px',
                    width: '40px',
                    color: '#fff',
                    backgroundColor: 'red',
                    padding: '7px',
                    borderRadius: '20px',
                  }}
                />
              </Box>
              <IconButton
                onClick={handlePreviousImage}
                disabled={selectedImageIndex === 0}>
                <NavigateBeforeIcon
                  style={{ background: '#104960', borderRadius: 4 }}
                />
              </IconButton>
              <Box style={{ width: '80%', height: '80vh' }}>
                <img
                  src={imagePath ?? ''}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    cursor: 'pointer',
                    userSelect: 'none',
                  }}
                  alt={`Image ${selectedImageIndex}`}
                />
              </Box>
              <IconButton
                onClick={handleNextImage}
                disabled={
                  selectedImageIndex ===
                  projectGalleryImage?.ProjectGallery?.length - 1
                }>
                <NavigateNextIcon
                  style={{ background: '#104960', borderRadius: 4 }}
                />
              </IconButton>
            </div>
          </Modal>
        </>
      );
    }
  };
  const handleChangeIndex = (index: number) => {
    const indexExists = selectedOptionsIndex.includes(index);
    if (indexExists) {
      setSelectedOptionsIndex(prev => prev.filter(item => item !== index));
    } else {
      setSelectedOptionsIndex(prev => [...prev, index]);
    }
  };
  const handleEmail = () => {
    if (selectedOptions?.length > 0) {
      const recipient = '';
      const subject = 'Documents';
      const body = `Please find attached the following documents:\n\n${selectedOptions
        .map((doc: any) => `${doc.name}: ${doc.url}`)
        .join('\n')}`;
      const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
        recipient
      )}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.open(gmailUrl, '_blank');
      setDocumentModal(false);
    }
  };

  return (
    <>
      <Box style={{ filter: modalOpen ? 'blur(10px)' : '' }}>
        <Box style={{ padding: 2 }}>
          <Box style={{ padding: 2 }}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <Box>
                <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                  {StringUtils.capitalizeFirstLetter(ProjectData?.projectName)}
                </Text>
                <Text style={{ fontSize: 14, color: '#667085', marginTop: 8 }}>
                  Here you can view the complete details and transactions of the
                  project.
                </Text>
              </Box>

              <Button
                style={{ padding: '8px 24px' }}
                onClick={() => setToggle(true)}
                extra={{
                  variant: 'contained',
                }}>
                <Text style={{ fontSize: 13 }}>Initiate Transactions</Text>
              </Button>
            </Box>

            <Box style={{ marginTop: 2, position: 'relative' }}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: 10,
                  right: 15,
                  gap: 2,
                }}>
                <Button
                  extra={{ variant: 'contained', color: 'inherit' }}
                  icons={
                    <Image
                      src={editProject}
                      style={{ width: 20, height: 20, objectFit: 'cover' }}
                    />
                  }
                  onClick={() =>
                    navigate(ROUTES.EDIT_PROJECT, {
                      state: {
                        edit: true,
                        id: projectId,
                        groupName: groupName,
                      },
                    })
                  }
                  style={{
                    background: 'white',
                    color: theme.palette.primary.main,
                    padding: '8px 24px',
                  }}>
                  <Text style={{ fontSize: 13 }}>Edit Project</Text>
                </Button>
                <Button
                  extra={{ variant: 'contained' }}
                  icons={
                    <Image
                      src={uploadMIS}
                      style={{ width: 20, height: 20, objectFit: 'cover' }}
                    />
                  }
                  style={{ padding: '8px 24px' }}
                  onClick={handleOpen}>
                  <Text style={{ fontSize: 13 }}>Upload MIS</Text>
                </Button>
              </Box>
              <Box style={{ position: 'absolute' }}>
                {cover && (
                  // <UploadInput
                  //   name={'documentFile'}
                  //   id={'documentFile'}
                  //   extra={{
                  //     padding: '4px',
                  //     isModify: true,
                  //     onchange: (e: any) => {
                  //       handleAddNewGalleryBanner(true, e[0]);
                  //     },
                  //     style: { marginTop: '-6px' },
                  //   }}
                  // />
                  <>
                    <AddAPhotoIcon
                      onClick={handleButtonClick}
                      sx={{ margin: 1, color: '#fff', cursor: 'pointer' }}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                      onChange={(e: any) => {
                        handleAddNewGalleryBanner(true, e.target.files?.[0]);
                      }}
                    />
                  </>
                )}
              </Box>

              <Image
                src={
                  coverImage?.imagePath ? coverImage?.imagePath : BuildingIcon
                }
                style={{
                  width: '100%',
                  height: 400,
                  objectFit: 'fill',
                  borderRadius: 10,
                }}
              />

              <Box
                style={{
                  background: theme.palette.secondary.main,
                  padding: 2,
                  position: 'absolute',
                  bottom: 3,
                  borderRadius: 2,
                  width: '100%',
                  opacity: 0.7,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: 4,
                    alignItems: 'center',
                  }}>
                  <ApartmentOutlinedIcon style={{ color: 'white' }} />
                  <Text
                    style={{ fontSize: 19, color: 'white', fontWeight: 700 }}>
                    {StringUtils.capitalizeFirstLetter(
                      ProjectData?.projectName
                    )}
                  </Text>
                </Box>

                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: 4,
                    alignItems: 'center',
                  }}>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      gap: 1,
                      alignItems: 'center',
                    }}>
                    <LocationOnIcon style={{ color: 'white' }} />
                    <Text style={{ fontSize: 13, color: 'white' }}>
                      {StringUtils.capitalizeFirstLetter(
                        ProjectData?.address ?? 'N/A'
                      )}
                    </Text>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      gap: 1,
                      alignItems: 'center',
                    }}>
                    <CalendarMonthOutlinedIcon style={{ color: 'white' }} />
                    <Text style={{ fontSize: 13, color: 'white' }}>
                      {moment(ProjectData?.createdAt).format('DD-MM-YYYY')}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                gap: 1,
                rowGap: 1,
                paddingTop: 2,
                justifyContent: 'space-between',
              }}>
              <Box
                style={{
                  display: 'flex',
                  gap: 1,
                  rowGap: 1,
                  flexWrap: 'wrap',
                  maxWidth: '61%',
                }}>
                <ImageList sx={{ width: '70%' }} cols={3}>
                  {renderImages()}
                </ImageList>
                <Box
                  style={{
                    background: '#E4F7FF',
                    width: '150px',
                    height: '150px',
                    borderRadius: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  {cover && (
                    <UploadInput
                      name={'documentFile'}
                      id={'documentFile'}
                      extra={{
                        padding: '4px',
                        isModify: true,
                        onchange: (e: any) => {
                          handleAddNewGalleryBanner(false, e[0]);
                        },
                        style: { marginTop: '-6px' },
                      }}
                    />
                  )}
                </Box>
                {/* <AddOutlined onClick={() => handleAddNewDoc()} /> */}
              </Box>
              <ProjectDetailsCard user={ProjectData?.user ?? {}} />
            </Box>

            <Box style={{ marginTop: 2 }}>
              <FormHead
                label="Project Description"
                extra={{ page: 'ProjectDetails' }}
              />

              <Card style={{ padding: 2 }}>
                <Text style={{ fontSize: 14 }}>
                  {StringUtils.capitalizeFirstLetter(
                    ProjectData?.projectDesc ?? 'N/A'
                  )}
                </Text>
              </Card>
            </Box>

            <Box style={{ marginTop: 2 }}>
              <FormHead
                label="Basic Informations"
                extra={{ page: 'ProjectDetails' }}
              />

              <Card style={{ padding: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={2} lg={4}>
                    <TextLabelWithDesc
                      extra={{
                        label: 'Project Name :',
                        desc: StringUtils.capitalizeFirstLetter(
                          ProjectData?.projectName ?? 'N/A'
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2} lg={4}>
                    <TextLabelWithDesc
                      extra={{
                        label: 'Project SPV name:',
                        desc: StringUtils.capitalizeFirstLetter(
                          ProjectData?.spv?.spvName ?? 'N/A'
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2} lg={4}>
                    <TextLabelWithDesc
                      extra={{
                        label: 'Group name / Brand Name:',
                        desc: StringUtils.capitalizeFirstLetter(
                          groupName ?? 'N/A'
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2} lg={4}>
                    <TextLabelWithDesc
                      extra={{
                        label: 'Existing Lender:',
                        desc: StringUtils.capitalizeFirstLetter(
                          ProjectData?.projectLender ?? 'N/A'
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} sm={2} lg={4}>
                    <TextLabelWithDesc
                      extra={{
                        label: 'Project type:',
                        desc: StringUtils.capitalizeFirstLetter(
                          ProjectData?.projectType ?? 'N/A'
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <TextLabelWithDesc
                      extra={{
                        label: 'Address:',
                        desc: StringUtils.capitalizeFirstLetter(
                          ProjectData?.address ?? 'N/A'
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Box>

            <Box style={{ marginTop: 2 }}>
              <FormHeadIcon
                label="Construction Details"
                extra={{
                  LeftIcon: piller,
                  page: 'ProjectDetails',
                }}></FormHeadIcon>
              <Card style={{ padding: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={2} lg={4}>
                    <TextLabelWithDesc
                      extra={{
                        label: 'Land area :',
                        desc: `${ProjectData?.landArea} ${
                          (getMeasure?.units[ProjectData?.landAreaUnit - 1] ??
                            {})['name'] ?? 'N/A'
                        }`,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2} lg={4}>
                    <TextLabelWithDesc
                      extra={{
                        label: 'FSI area :',
                        desc: ` ${ProjectData?.fsiArea} ${
                          (getMeasure?.units[ProjectData?.fsiAreaUnit - 1] ??
                            {})['name'] ?? 'N/A'
                        }`,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2} lg={4}>
                    <TextLabelWithDesc
                      extra={{
                        label: 'Builtup area :',
                        desc: ` ${ProjectData?.builtUpArea}  ${
                          (getMeasure?.units[
                            ProjectData?.builtUpAreaUnit - 1
                          ] ?? {})['name'] ?? 'N/A'
                        }`,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2} lg={4}>
                    <TextLabelWithDesc
                      extra={{
                        label: 'Construction Start Date:',
                        desc: ProjectData?.constStartDate
                          ? moment(ProjectData?.constStartDate).format(
                              'DD-MM-YYYY'
                            )
                          : 'N/A',
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2} lg={4}>
                    <TextLabelWithDesc
                      extra={{
                        label: 'Construction End date:',
                        desc: ProjectData?.constEndDate
                          ? moment(ProjectData?.constEndDate).format(
                              'DD-MM-YYYY'
                            )
                          : 'N/A',
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2} lg={4}>
                    <TextLabelWithDesc
                      extra={{
                        label: 'No. of towers:',
                        desc: ProjectData?.noOfTowers ?? 'N/A',
                      }}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Box>

            <Box style={{ marginTop: 2 }}>
              <FormHeadIcon
                label="Project SPOC"
                extra={{
                  LeftIcon: piller,
                  page: 'ProjectDetails',
                }}></FormHeadIcon>
              <Card style={{ padding: 2 }}>
                {ProjectData.Spocs &&
                  ProjectData.Spocs.map((value: any, index: number) => {
                    return (
                      <Grid
                        container
                        spacing={2}
                        key={index}
                        sx={{ marginBottom: 3 }}>
                        <Grid item xs={6} sm={2} lg={4}>
                          <TextLabelWithDesc
                            extra={{
                              label: 'SPOC Name:',
                              desc: StringUtils.capitalizeFirstLetter(
                                value.spocName ?? 'N/A'
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} sm={2} lg={4}>
                          <TextLabelWithDesc
                            extra={{
                              label: 'Email ID :',
                              desc: value.spocEmail ?? 'N/A',
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} sm={2} lg={4}>
                          <TextLabelWithDesc
                            extra={{
                              label: 'Phone number :',
                              desc: value.spocPhone ?? 'N/A',
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} sm={2} lg={4}>
                          <TextLabelWithDesc
                            extra={{
                              label: 'Designation of SPOC:',
                              desc: StringUtils.capitalizeFirstLetter(
                                value.spocDesignation ?? 'N/A'
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
              </Card>
            </Box>

            <Box style={{ marginTop: 2 }}>
              <FormHeadIcon
                label="RERA details"
                extra={{
                  LeftIcon: CourtIcon,
                  page: 'ProjectDetails',
                }}></FormHeadIcon>
              <Card style={{ padding: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={2} lg={4}>
                    <TextLabelWithDesc
                      extra={{
                        label: 'Promoter RERA No.:',
                        desc: ProjectData?.promotorReraNo ?? 'N/A',
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2} lg={4}>
                    <TextLabelWithDesc
                      extra={{
                        label: 'Project RERA No.:',
                        desc: ProjectData?.projectReraNo ?? 'N/A',
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2} lg={4}>
                    <TextLabelWithDesc
                      extra={{
                        label: 'Project RERA certificate:',
                        desc: ProjectData?.projectReraCert ? ' ' : 'N/A',
                      }}
                    />
                    {ProjectData?.projectReraCert && (
                      <DocumentBox
                        extra={{
                          url: ProjectData?.projectReraCert,
                          chiplabel: 'Project RERA certificate',
                          GrayColor: 'Grayforform',
                        }}
                      />
                    )}
                  </Grid>
                  {(ProjectData?.revisedReraCert ||
                    ProjectData.revisedReraNo) && (
                    <Grid item xs={6} sm={2} lg={4}>
                      <TextLabelWithDesc
                        extra={{
                          label: 'Revised RERA No.:',
                          desc: ProjectData?.revisedReraNo ?? 'N/A',
                        }}
                      />
                    </Grid>
                  )}
                  {(ProjectData?.revisedReraCert ||
                    ProjectData.revisedReraNo) && (
                    <Grid item xs={6} sm={2} lg={4}>
                      <TextLabelWithDesc
                        extra={{
                          label: 'Date of RERA Registration:',
                          desc: moment(ProjectData?.reraRegDate).format(
                            'DD-MM-YYYY'
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  {(ProjectData?.revisedReraCert ||
                    ProjectData.revisedReraNo) && (
                    <Grid item xs={6} sm={2} lg={4}>
                      <TextLabelWithDesc
                        extra={{
                          label: 'Date of Project Possession:',
                          desc: moment(ProjectData?.propPossDate).format(
                            'DD-MM-YYYY'
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  {(ProjectData?.revisedReraCert ||
                    ProjectData.revisedReraNo) && (
                    <Grid item xs={6} sm={2} lg={12}>
                      <TextLabelWithDesc
                        extra={{
                          label: 'Proposed Possession date extension (if any):',
                          desc: moment(ProjectData?.propPossExtDate).format(
                            'DD-MM-YYYY'
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6} sm={2} lg={4}>
                    <TextLabelWithDesc
                      extra={{
                        label: 'Revised RERA certificate:',
                        desc: ProjectData?.revisedReraCert ? ' ' : 'N/A',
                      }}
                    />
                    {ProjectData?.revisedReraCert && (
                      <DocumentBox
                        extra={{
                          url: ProjectData?.revisedReraCert,
                          chiplabel: 'Revised RERA certificate',
                          GrayColor: 'Grayforform',
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Box>

            <Box style={{ marginTop: 2 }}>
              <FormHeadIcon
                label="Uploaded documents"
                extra={{
                  LeftIcon: piller,
                  page: 'ProjectDetails',
                }}></FormHeadIcon>

              {ProjectData?.configuration && (
                <Card style={{ padding: 2, marginBlock: 2 }}>
                  <CircleCheckBox
                    label={StringUtils.capitalizeFirstLetter('Configuration')}
                  />
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6}>
                      <DocumentBox
                        extra={{
                          textlabel: 'Configuration doc',
                          url: ProjectData?.configuration,
                          chiplabel:
                            ProjectData?.configurationName ?? 'Configuration',
                          GrayColor: 'Grayforform',
                        }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              )}
              {Object.keys(documents ?? {}).map((e, index) => {
                return (
                  <Card
                    key={`documents-${index}`}
                    style={{ padding: 2, marginBlock: 2 }}>
                    <CircleCheckBox
                      label={StringUtils.capitalizeFirstLetter(e ?? '')}
                    />
                    <Grid container columnSpacing={2}>
                      {documents[e].map(
                        (doc: Record<string, unknown>, docIndex: number) => {
                          return (
                            <Grid
                              key={`docname-${e}-${docIndex}`}
                              item
                              xs={12}
                              sm={6}>
                              <DocumentBox
                                extra={{
                                  textlabel: doc['documentName'],
                                  chiplabel:
                                    doc['documentName'] === 'mis'
                                      ? doc['fileName']
                                      : doc['documentName'],
                                  url: doc['documentFile'],
                                  GrayColor: 'Grayforform',
                                }}
                              />
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                  </Card>
                );
              })}

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={() => setDocumentModal(true)}
                    extra={{ variant: 'contained' }}
                    style={{ padding: 1.5, marginTop: 3, width: '100%' }}>
                    <Text style={{ fontSize: 14 }}>Share via mail</Text>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <Dialog
          onClose={() => setOpen(false)}
          aria-labelledby="customized-dialog-title"
          open={Open}>
          <DialogTitle
            sx={{ m: 0, p: '16px 24px 0px' }}
            id="customized-dialog-title">
            <Text style={{ fontSize: 14, color: 'black', fontWeight: 'bold' }}>
              Upload MIS
            </Text>
            <Text style={{ fontSize: 12, color: 'gray' }}>
              Here you can add MIS for the project.
            </Text>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}>
            <HighlightOffOutlinedIcon
              style={{
                position: 'absolute',
                right: 15,
                top: 10,
                color: 'black',
              }}></HighlightOffOutlinedIcon>
          </IconButton>
          <DialogContent style={{ padding: '8px 24px' }}>
            <UploadDoucuments
              extra={{ onChange: (e: any) => setMISFile(e) }}
              style={{
                width: 500,
                height: 30,
                background: theme.palette.secondary.light,
                borderColor: theme.palette.secondary.main,
                justifyContent: 'center',
              }}
            />
            {documents?.MIS?.map((val: any) => (
              <DocumentBox
                key={val.documentFile}
                extra={{
                  type: 'edit',
                  textlabel: val.documentName,
                  chiplabel: val.fileName ?? val.documentName,
                  url: val.documentFile,
                  GrayColor: 'Grayforform',
                  handleDeleteDocuments: () =>
                    handleDeleteMisDocument(val.documentId, val.projectId),
                }}
              />
            ))}
          </DialogContent>
          <DialogActions>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                bottom: 10,
                width: '100%',
                paddingBottom: 2,
              }}>
              <Button
                extra={{ variant: 'outlined' }}
                onClick={() => setOpen(false)}
                style={{ width: '45%', paddingBlock: 1 }}>
                Cancel
              </Button>
              <Button
                extra={{ variant: 'contained' }}
                onClick={AttachOpen}
                style={{ width: '45%', paddingBlock: 1 }}>
                Attach files
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={() => setAttech(false)}
          aria-labelledby="customized-dialog-title"
          open={Attech}>
          <DialogTitle
            sx={{ m: 0, p: '16px 24px 0px' }}
            id="customized-dialog-title">
            <Text style={{ fontSize: 14, color: 'black', fontWeight: 'bold' }}>
              Upload MIS
            </Text>
            <Text style={{ fontSize: 12, color: 'gray' }}>
              Here you can add MIS for the project.
            </Text>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setAttech(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}>
            <HighlightOffOutlinedIcon
              style={{
                position: 'absolute',
                right: 15,
                top: 10,
                color: 'black',
              }}></HighlightOffOutlinedIcon>
          </IconButton>
          <DialogContent style={{ padding: '8px 24px' }}>
            <DocumentBox
              style={{ width: 500 }}
              extra={{
                textlabel: 'Uploaded file',
                chiplabel: 'Mis',
                url:
                  documents?.MIS?.length > 0
                    ? documents?.MIS[documents?.MIS?.length - 1]?.documentFile
                    : '',
              }}
            />
          </DialogContent>
          <DialogActions>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                bottom: 10,
                width: '100%',
                paddingBottom: 2,
              }}>
              <Button
                extra={{ variant: 'outlined' }}
                onClick={() => setAttech(false)}
                style={{ width: '45%', paddingBlock: 1 }}>
                Cancel
              </Button>
              <Button
                extra={{ variant: 'contained' }}
                onClick={attechClose}
                style={{ width: '45%', paddingBlock: 1 }}>
                Done
              </Button>
            </Box>
          </DialogActions>
        </Dialog>

        <Sidebar
          label="Initiate Transaction"
          extra={{
            anchor: 'right',
            Toggle,
            setToggle,
            description: 'Initiate your transaction here by filling the form',
            width: 420,
          }}>
          <InitiateTransactions extra={{ setToggle, projectId }} />
        </Sidebar>
      </Box>
      {documentModal && (
        <Modal
          open={documentModal}
          onClose={() => {
            setDocumentModal(false),
              setSelectedOptions([]),
              setSelectedOptionsIndex([]);
          }}>
          <div className={classes.modalContent}>
            <Box
              style={{
                height: '80vh',
                background: '#FFF',
                width: '1000px',
                position: 'relative',
              }}>
              <Box style={{ padding: '1rem' }}>
                <Box
                  style={{
                    position: 'absolute',
                    right: 20,
                    cursor: 'pointer',
                    zIndex: 5,
                  }}>
                  <CloseIcon
                    onClick={() => {
                      setDocumentModal(false),
                        setSelectedOptions([]),
                        setSelectedOptionsIndex([]);
                    }}
                  />
                </Box>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Select Documents</FormLabel>
                  <FormGroup>
                    <Box
                      style={{
                        maxHeight: '50vh',
                        overflowY: 'scroll',
                        margin: '1rem 0',
                      }}>
                      {ProjectData?.configuration && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedOptions.some(
                                option =>
                                  option.url === ProjectData?.configuration
                              )}
                              onChange={e => {
                                handleChange(e, 'configuration');
                              }}
                              name={ProjectData?.configuration}
                            />
                          }
                          label={
                            <DocumentBox
                              style={{ width: 500 }}
                              extra={{
                                textlabel: 'configuration',
                                chiplabel: 'configuration',
                                url: ProjectData?.configuration,
                              }}
                            />
                          }
                          key={ProjectData?.configuration}
                        />
                      )}
                      {ProjectData?.promotorReraCert && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedOptions.some(
                                option =>
                                  option.url === ProjectData?.promotorReraCert
                              )}
                              onChange={(e: any) => {
                                handleChange(e, 'promotorReraCert');
                              }}
                              name={ProjectData?.promotorReraCert}
                            />
                          }
                          label={
                            <DocumentBox
                              style={{ width: 500 }}
                              extra={{
                                textlabel: 'promotorReraCert',
                                chiplabel: 'promotorReraCert',
                                url: ProjectData?.promotorReraCert,
                              }}
                            />
                          }
                          key={ProjectData?.promotorReraCert}
                        />
                      )}
                      {ProjectData?.projectReraCert && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedOptions.some(
                                option =>
                                  option.url === ProjectData?.projectReraCert
                              )}
                              onChange={(e: any) => {
                                handleChange(e, 'projectReraCert');
                              }}
                              name={ProjectData?.projectReraCert}
                            />
                          }
                          label={
                            <DocumentBox
                              style={{ width: 500 }}
                              extra={{
                                textlabel: 'projectReraCert',
                                chiplabel: 'projectReraCert',
                                url: ProjectData?.promotorReraCert,
                              }}
                            />
                          }
                          key={ProjectData?.projectReraCert}
                        />
                      )}
                      {ProjectData?.revisedReraCert && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedOptions.includes(
                                ProjectData?.revisedReraCert
                              )}
                              onChange={(e: any) => {
                                handleChange(e, 'revisedReraCert');
                              }}
                              name={ProjectData?.revisedReraCert}
                            />
                          }
                          label={
                            <DocumentBox
                              style={{ width: 500 }}
                              extra={{
                                textlabel: 'revisedReraCert',
                                chiplabel: 'revisedReraCert',
                                url: ProjectData?.revisedReraCert,
                              }}
                            />
                          }
                          key={ProjectData?.revisedReraCert}
                        />
                      )}
                      {ProjectData?.documents?.map(
                        (option: any, index: number) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedOptionsIndex.includes(index)}
                                onChange={(e: any) => {
                                  handleChangeIndex(index),
                                    handleChange(
                                      e,
                                      option?.documentName ?? option.fileName
                                    );
                                }}
                                name={option?.documentFile}
                              />
                            }
                            label={
                              <DocumentBox
                                style={{ width: 500 }}
                                extra={{
                                  textlabel:
                                    option.fileName ?? option.documentName,
                                  chiplabel:
                                    option.fileName ??
                                    option.documentName ??
                                    'Mis',
                                  url:
                                    documents?.MIS?.length > 0
                                      ? documents?.MIS[
                                          documents?.MIS?.length - 1
                                        ]?.documentFile
                                      : '',
                                }}
                              />
                            }
                            key={option.fileName ?? option.documentName}
                          />
                        )
                      )}
                    </Box>
                  </FormGroup>
                </FormControl>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={() => handleEmail()}
                    extra={{ variant: 'contained' }}
                    style={{
                      padding: 1.5,
                      marginTop: 3,
                      width: '100%',
                      margin: '1rem 1rem',
                    }}>
                    <Text style={{ fontSize: 14 }}>Share via mail</Text>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Modal>
      )}
    </>
  );
}

export default ProjectDetails;
