import React, { useContext } from 'react';
import Box from '../components/single_component/Box';
import EditProfileDetail from '../components/single_component/EditProfileDetail';
import Button from '../components/single_component/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import Text from '../components/single_component/Text';
import AuthContext from '../context/AuthContext';
// import { ROLE } from '../types/routeObject';
import { useStateManager } from '../hooks/useStateManager';
import ProfileContext from '../context/ProfileContext';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../types/routeObject';

function EditProfile() {
  const myauthorized = useStateManager<string>('', 'authToken');
  const { authorized } = useContext(AuthContext);
  const navigate = useNavigate();

  const onLogout = () => {
    authorized?.setValue(false);
    myauthorized?.removeValue();
    navigate(ROUTES.LOGIN);
  };
  const { ProfileData } = useContext(ProfileContext);

  return (
    <Box
      style={{
        background: '#F2FAFE',
        paddingTop: 6,
        paddingBottom: 5,
        minHeight: '90vh',
      }}>
      <Box style={{ width: '85%', margin: 'auto' }}>
        <EditProfileDetail extra={{ ProfileData }} />
      </Box>

      <Box style={{ padding: 3, position: 'relative', top: '12px' }}>
        <Button
          extra={{
            variant: 'contained',
            color: 'primary',
          }}
          onClick={onLogout}
          style={{
            paddingInline: 3,
            borderRadius: 1,
            paddingBlock: 1,
            position: 'absolute',
            bottom: '1%',
            right: '7.5%',
          }}
          icons={<LogoutIcon />}>
          <Text style={{ fontSize: 13 }}>Log Out</Text>
        </Button>
      </Box>
    </Box>
  );
}

export default EditProfile;
