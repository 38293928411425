import React, { useContext, useEffect, useState } from 'react';
import Box from '../single_component/Box';
import Props from '../../types/props';
import TwoButton from '../common_component/TwoButton';
import TextInput from '../common_component/TextInput';
import InvestorContext from '../../context/InvestorContext';
import { useFormik } from 'formik';
import { contactPersonValidation } from '../Validation/AddTask';
import ErrorText from '../common_component/ErrorText';
import { toast } from 'react-toastify';

function AddContactPerson({ ...props }: Props) {
  const { setToggle, userId, data, selectedContactPerson } = props.extra;
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);
  const { addContactPerson } = useContext(InvestorContext);

  const onCancel = () => {
    setToggle(false);
  };
  // Initialize Formik
  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      Id: '',
    },
    validationSchema: contactPersonValidation,
    onSubmit: async values => {
      setbtnDisableLoader(true);
      try {
        const response = await addContactPerson(userId, {
          contPerName: values.name,
          contPerEmail: values.email,
          contPerPhone: values.phone,
          iContactPersonId: values.Id,
          investorId: userId,
        });
        setbtnDisableLoader(false);
        if (response.success) {
          setToggle(false);
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      } catch (e) {
        setbtnDisableLoader(false);
        console.error(e);
      }
    },
  });
  {
    selectedContactPerson >= 0 &&
      useEffect(() => {
        setValues({
          name: data[selectedContactPerson]?.contPerName,
          email: data[selectedContactPerson]?.contPerEmail,
          phone: data[selectedContactPerson]?.contPerPhone,
          Id: data[selectedContactPerson]?.iContactPersonId,
        });
      }, [data]);
  }
  return (
    <Box style={{ width: '90%', margin: 'auto', marginTop: 2 }}>
      <Box>
        <TextInput
          label="Name"
          id="name"
          name="name"
          placeholder="Enter name of contact person"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.name && touched.name && <ErrorText>{errors.name}</ErrorText>}

        <TextInput
          label="Email"
          id="email"
          name="email"
          placeholder="Enter email of contact person"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.email && touched.email && <ErrorText>{errors.email}</ErrorText>}
        <TextInput
          label="Phone Number"
          id="phone"
          name="phone"
          maxLength={10}
          placeholder="Enter Phone Number of contact person"
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.phone && touched.phone && <ErrorText>{errors.phone}</ErrorText>}
        <TwoButton
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bottom: 10,
            position: 'absolute',
            width: '90%',
          }}
          extra={{
            onclickFirst: onCancel,
            OnClickSecond: handleSubmit,
            firstVariant: 'outlined',
            secondVariant: 'contained',
            firstButtonText: 'Cancel',
            secondButtonText: selectedContactPerson !== null ? 'Save' : 'Add',
            btnDisableLoader: btnDisableLoader,
          }}
        />
      </Box>
    </Box>
  );
}

export default AddContactPerson;
