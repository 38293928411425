import React from 'react';
import Box from '../single_component/Box';
import Button from '../single_component/Button';
import Props from '../../types/props';
function TwoButton(props: Props = {}) {
  const {
    onclickFirst,
    OnClickSecond,
    firstVariant,
    secondVariant,
    firstButtonText,
    secondButtonText,
    iconfirstButton,
    btnDisableLoader,
  } = props?.extra ?? [];
  return (
    <Box style={props?.style}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bottom: '-1.6vh',
          position: 'absolute',
          width: '100%',
          padding: '10px 0px 8px',
          background: 'white',
        }}>
        <Button
          icons={iconfirstButton}
          onClick={onclickFirst}
          style={{ width: '48%', padding: 1 }}
          extra={{
            variant: firstVariant,
          }}>
          {firstButtonText}
        </Button>
        <Button
          onClick={OnClickSecond}
          style={{ width: '48%', padding: 1 }}
          extra={{
            variant: secondVariant,
            btnDisableLoader: btnDisableLoader,
          }}>
          {secondButtonText}
        </Button>
      </Box>
    </Box>
  );
}

export default TwoButton;
