import { AxiosResponse, AxiosError } from 'axios';

class ApiResponse {
  public statusCode: number;
  public messages: Record<string, unknown>;
  public message: string;
  public data: Record<string, unknown>;
  public success: boolean;

  constructor(response: AxiosResponse | AxiosError) {
    console.error('hellow', response);

    if ('response' in response) {
      // AxiosError (HTTP error)
      const axiosError = response as AxiosError;
      const errorDataObject = axiosError.response?.data as Record<
        string,
        unknown
      >;
      const parsedMessage =
        errorDataObject && errorDataObject['message']
          ? (errorDataObject['message'] as Record<string, string>)
          : {
              error: [axiosError.message],
            };

      this.success = false;
      this.messages = this.data =
        errorDataObject && errorDataObject['data']
          ? (errorDataObject['data'] as Record<string, string>)
          : (axiosError.response?.data as Record<string, unknown>);
      this.message = this.getFirstMessage(parsedMessage);
      this.statusCode = axiosError.response?.status ?? 500;
    } else {
      // AxiosResponse (HTTP success)
      console.error('hellow', response);

      const axiosResponse = response as AxiosResponse;
      const data = axiosResponse?.data;
      this.success = data?.success ?? false;
      this.messages = data?.message as Record<string, unknown>;
      this.message = this?.getFirstMessage(
        data?.message as Record<string, unknown>
      );
      this.data = data.data as Record<string, unknown>;
      this.statusCode = axiosResponse.status ?? 500;
    }
  }

  private getFirstMessage(messages: Record<string, any>): string {
    let message = '';
    const keys = Object?.keys(messages);
    try {
      if (keys?.length > 0) {
        const values = messages[keys[0]];
        if (Array.isArray(values) && values.length > 0) {
          message = values[0];
        }
      }
    } catch (error) {
      console.error('An error occurred while processing the messages:', error);
    }
    return message;
  }

  // Getter for statusCode
  getStatusCode(): number {
    return this.statusCode;
  }

  // Getter for messages
  getMessages(): Record<string, unknown> {
    return this.messages;
  }

  // Getter for message
  getMessage(): string {
    return this.message;
  }

  // Getter for data
  getData(): Record<string, unknown> {
    return this.data;
  }

  // Getter for success
  getSuccess(): boolean {
    return this.success;
  }
}

export default ApiResponse;
