export default class CommonUtils {
  static filterNonEmptyDocuments(
    inputObject: Record<string, Record<string, string>[]>
  ) {
    const result: Record<string, Record<string, string>[]> = {};

    for (const [key, value] of Object.entries(inputObject)) {
      const filteredDocuments = value.filter(
        doc => doc.documentName !== '' && doc.fileName !== ''
      );

      if (filteredDocuments.length > 0) {
        result[key] = filteredDocuments;
      }
    }

    return result;
  }
}
