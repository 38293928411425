import React, { useContext, useEffect, useState } from 'react';
import Props from '../../types/props';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import Dpicker from '../single_component/Dpicker';
import TextInput from '../common_component/TextInput';
import { Alert, Grid } from '@mui/material';
import SubjectInput from '../single_component/SubjectInput';
import RadioWithLabel from '../common_component/RadioWithLabel';
import RadioWithBox from '../common_component/RadioWithBox';
import Button from '../single_component/Button';
import Image from '../single_component/Image';
import DownloadIcon from '../../assets/images/download2.png';
import { useFormik } from 'formik';
import { invoiceValidationSchema } from '../Validation/AddTask';
import ErrorText from '../common_component/ErrorText';
import AuthContext from '../../context/AuthContext';
import TransactionsContex from '../../context/TransactionsContex';
import { toast } from 'react-toastify';
// import DateUtils from '../../utils/dateUtils';
import TransactionService from '../../services/TransactionService';
import { ROLE } from '../../types/routeObject';
import DownloadIconblack from '../../assets/images/import.png';
import SalesDashboardContext from '../../context/SalesDashBoardContext';
import DashboardContext from '../../context/Dashboard';

function KlaxonInvoice(props: Props = {}) {
  const { setToggle, label, transcId, roleType, page, projectId, userId } =
    props.extra ?? {};
  const { getDashboardData } = useContext(SalesDashboardContext);
  const { getDashboardData: adminDashBoardData } = useContext(DashboardContext);

  const { masterData } = useContext(AuthContext);
  const [Accounts, setAccounts] = useState<any>([]);
  const { addInvoice, getInvoice, getTransaction } =
    useContext(TransactionsContex);
  const [ReadOnly, setReadOnly] = useState(false);
  const [gst, setGst] = useState<Record<string, unknown>[]>([]);
  const [metaData, setMetaData] = useState<Record<string, unknown>>({});
  console.error('metaData', metaData);
  console.error(Accounts, 'Accounts');

  const masterdata = masterData?.value();

  const { designatedPartners, designatedAccount } = masterdata ?? {};

  const onCancel = () => {
    setToggle(false);
  };

  useEffect(() => {
    new TransactionService()
      .getInvoiceMetaData(transcId)
      .then(metaDataResponse => {
        setMetaData(metaDataResponse);
        setGst(
          Array.isArray(metaDataResponse['gsts'])
            ? metaDataResponse['gsts']
            : []
        );
      });
    setAccounts(designatedAccount);
  }, []);

  const {
    handleSubmit,
    handleBlur,
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: {
      date: '',
      invoiceNumber: '',
      companyName: '',
      companygst: '',
      address: '',
      subject: '',
      invoiceDate: Date.now(),
      description: '',
      amount: '',
      cgst: '',
      sgst: '',
      igst: '',
      holderName: 'Klaxon Advisory Partners LLP',
      pan: '',
      accountId: '',
      partnerId: '',
      gstId: '',
      contactPersonName: '',
    },
    validationSchema: invoiceValidationSchema,
    onSubmit: async values => {
      console.error(values);
    },
  });
  const companyName = values.companyName;
  const companyGst = values.companygst;
  const raiseInvoice = async () => {
    handleSubmit();
    const selectedGst = parseInt((values.gstId ?? '-1').toString());
    const selectedPartner = parseInt((values.partnerId ?? '-1').toString());
    // const selectedAccount = parseInt((values.accountId ?? '-1').toString());
    console.error(gst, selectedGst, 'selectedGst');

    const response = await addInvoice({
      transcId: transcId,
      invoiceNo: values.invoiceNumber,
      contactPersonName: values.contactPersonName ?? '',
      invoiceDate: values.invoiceDate,
      accountId: Accounts[0]['id'],
      ...(companyName && { companyName }),
      ...(companyGst && { companyGst }),
      companyAddress: values.address,
      subject: values.subject,
      paymentDate: values.date,
      paymentDesc: values.description,
      paymentAmount: values.amount,
      paymetGst: values.sgst,
      paymentCgst: values.cgst,
      paymentIgst: values.igst,
      regardsTxt: 'removed',
      partnerId:
        masterData?.value().designatedPartners[selectedPartner]?.partnerId,
      // accountId:
      //   masterData?.value().designatedPartners[selectedPartner]
      //     ?.designatedAccounts[selectedAccount]?.accountId,
      gstId: gst[selectedGst]?.partnerGstId ?? '',
      totalPayable: parseFloat(values.amount),
    });

    if (response.success) {
      if (page == 'Sales-Dashboard') {
        getDashboardData(`sales/dashboard`, {
          page: 1,
          size: 10,
        });
      }
      if (page == 'Project') {
        getTransaction(1, 10, {
          filter: {
            projectId,
          },
        });
      }
      if (page == 'Dashboard') {
        adminDashBoardData(`admin/dashboard`, {
          page: 1,
          size: 10,
        });
      }
      if (page == 'Sales-Executive') {
        getTransaction(1, 10, {
          filter: {
            createdBy: userId,
          },
        });
      }
      setToggle(false);
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };
  const partnerOptions = designatedPartners?.map((item: any, index: number) => {
    return {
      id: index,
      name: item.accountHolder,
    };
  });

  const getData = async () => {
    if (label !== 'Initiated' && label !== 'Hold') {
      getInvoice(transcId).then((res: any) => {
        console.error(res);
        if (res.success) {
          setReadOnly(true);
          const data = res.dataResponse;
          setFieldValue('date', data?.invoiceDate ?? '');
          setFieldValue('invoice', data?.invoiceNo ?? '');
          setFieldValue('contact', data?.contactPersonName ?? '');
          setFieldValue('companyName', data?.companyName ?? '');
          setFieldValue('companygst', data?.companyGst ?? '');
          setFieldValue('address', data?.companyAddress ?? '');
          setFieldValue('subject', data?.subject ?? '');
          setFieldValue('invoiceDate', data?.paymentDate ?? '');
          setFieldValue('description', data?.paymentDesc ?? '');
          setFieldValue('amount', data?.paymentAmount ?? '');
          setFieldValue('sgst', data?.paymetGst ?? '');
          setFieldValue('cgst', data?.paymentCgst ?? '');
          setFieldValue('igst', data?.paymentIgst ?? '');
          setFieldValue('accountId', data?.accountId ?? '');
          setFieldValue('partnerId', data?.partnerId ?? '');
          setFieldValue('gstId', data?.gstId ?? '');
          setAccounts(
            designatedPartners?.find(
              (item: any) => item.partnerId == data.partnerId
            )?.designatedAccounts
          );
        }
      });
    }
  };

  useEffect(() => {
    if (gst && gst.length > 0) {
      setFieldValue('pan', gst[0].panNumber ?? '');
    }
  }, [gst]);

  useEffect(() => {
    getData();
  }, []);
  const totalPaybleAmount =
    (isNaN(parseFloat(values.amount)) ? 0 : parseFloat(values.amount)) +
    (isNaN(parseFloat(values.igst))
      ? 0
      : (parseFloat(values.igst) *
          (isNaN(parseFloat(values.amount)) ? 0 : parseFloat(values.amount))) /
        100) +
    (isNaN(parseFloat(values.cgst))
      ? 0
      : (parseFloat(values.cgst) *
          (isNaN(parseFloat(values.amount)) ? 0 : parseFloat(values.amount))) /
        100) +
    ((isNaN(parseFloat(values.sgst)) ? 0 : parseFloat(values.sgst)) *
      (isNaN(parseFloat(values.amount)) ? 0 : parseFloat(values.amount))) /
      100;

  return (
    <Box style={{ width: '96%', margin: 'auto', paddingTop: 0.2 }}>
      {label == 'Raised' ? (
        <Alert
          severity="warning"
          style={{
            marginTop: 15,
            border: '1px solid #F2994A',
            borderRadius: 8,
            paddingBlock: 1,
          }}>
          <Text style={{ color: '#DD781E', fontSize: 14 }}>
            Awaiting approval from the admin for the transaction.
          </Text>
        </Alert>
      ) : null}
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          columns: 4,
        }}>
        <Box style={{ width: '31%', marginRight: '2%' }}>
          <Dpicker
            style={{ marginTop: '-9px' }}
            label="Date"
            name="invoiceDate"
            id="invoiceDate"
            onBlur={handleBlur}
            // readOnly={ReadOnly}
            // disabled={true}
            value={values.invoiceDate}
            extra={{
              setDate: (e: any) => setFieldValue('invoiceDate', e),
              placeholder: 'Choose date',
            }}
          />
        </Box>
        <Box style={{ width: '31%', marginRight: '2%' }}>
          <TextInput
            label="Invoice no"
            placeholder="Enter invoice number"
            value={values.invoiceNumber}
            name="invoiceNumber"
            id="invoiceNumber"
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={ReadOnly}
          />
          {touched.invoiceNumber && errors.invoiceNumber ? (
            <ErrorText>{errors.invoiceNumber}</ErrorText>
          ) : null}
        </Box>
        <Box style={{ width: '31%', marginRight: '2%' }}>
          <TextInput
            label="Contact person name"
            placeholder="Contact person name"
            // value={(metaData['promoterName'] ?? '').toString()}
            value={values.contactPersonName}
            name="contactPersonName"
            id="contactPersonName"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box style={{ width: '31%', marginRight: '2%' }}>
          <TextInput
            label="Company name"
            // value={values.companyName}
            value={
              values.companyName === ''
                ? (metaData['developerName'] ?? '').toString()
                : values.companyName
            }
            onChange={handleChange}
            onBlur={handleBlur}
            name="companyName"
            id="companyName"
            placeholder="Enter company name"
          />
        </Box>
        <Box style={{ width: '31%', marginRight: '2%' }}>
          <TextInput
            label="Company GST no."
            placeholder="Enter Company GST no."
            value={values.companygst}
            name="companygst"
            id="companygst"
            disabled={ReadOnly}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.companygst && errors.companygst ? (
            <ErrorText>{errors.companygst}</ErrorText>
          ) : null}
        </Box>
      </Box>
      <TextInput
        label="Subject"
        placeholder="Enter subject here."
        value={values.subject}
        name="subject"
        onBlur={handleBlur}
        onChange={handleChange}
      />
      {touched.subject && errors.subject ? (
        <ErrorText>{errors.subject}</ErrorText>
      ) : null}
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={6} sm={6} lg={6}>
          <SubjectInput
            label="Address of the Company"
            style={{ width: '100%' }}
            placeholder="Enter address here"
            name="address"
            id="address"
            disabled={ReadOnly}
            value={values.address}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={ReadOnly}
          />
          {touched.address && errors.address ? (
            <ErrorText>{errors.address}</ErrorText>
          ) : null}
        </Grid>
      </Grid>
      <Box
        style={{
          border: '1px solid #E0E0E0',
          borderRadius: 1,
          marginTop: 2,
          marginBottom: 2,
        }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #E0E0E0',
            background: '#FCFCFD',
          }}>
          <Box
            style={{
              width: '20%',
              borderRight: '1px solid #E0E0E0',
              padding: '5px 20px',
            }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: 'black',
                fontWeight: 500,
              }}>
              Date
            </Text>
          </Box>
          <Box
            style={{
              width: '50%',
              borderRight: '1px solid #E0E0E0',
              padding: '5px 20px',
            }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: 'black',
                fontWeight: 500,
              }}>
              Description
            </Text>
          </Box>
          <Box style={{ width: '20%', padding: '5px 20px' }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: 'black',
                fontWeight: 500,
              }}>
              Amount in ₹
            </Text>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '5px 20px 14px',
            borderBottom: '1px solid #E0E0E0',
          }}>
          <Box style={{ width: '20%' }}>
            <Dpicker
              value={values.date}
              name="date"
              id="date"
              disabled={ReadOnly}
              extra={{
                setDate: (e: any) => setFieldValue('date', e),
                placeholder: 'Choose date',
              }}
            />
            {touched.date && errors.date ? (
              <ErrorText>{errors.date}</ErrorText>
            ) : null}
          </Box>
          <Box style={{ width: '50%' }}>
            <SubjectInput
              placeholder="Enter description"
              value={values.description}
              name="description"
              id="description"
              disabled={ReadOnly}
              onChange={handleChange}
              onBlur={handleBlur}
              // readOnly={ReadOnly}
              style={{ width: '100%' }}
            />
            {touched.description && errors.description ? (
              <ErrorText>{errors.description}</ErrorText>
            ) : null}
          </Box>
          <Box style={{ width: '20%' }}>
            <TextInput
              placeholder="Enter Amount"
              value={values.amount}
              name="amount"
              id="amount"
              onInput={(e: any) => {
                e.target.value = e.target.value
                  .trim()
                  .replace(/^[/d-]|[-+]-|[^0-9-]+|^[\d]+[-][\d]+[-+]/, '');
              }}
              disabled={ReadOnly}
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={ReadOnly}
            />
            {touched.amount && errors.amount ? (
              <ErrorText>{errors.amount}</ErrorText>
            ) : null}
          </Box>
        </Box>

        <Grid
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 20px',
            borderRadius: 1,
            gap: 20,
          }}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '2px',
            }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: 'black',
                fontWeight: 500,
              }}>
              CGST
            </Text>
            <Box>
              <TextInput
                placeholder="Enter %"
                value={values.cgst}
                name="cgst"
                onInput={(e: any) => {
                  e.target.value = e.target.value
                    .trim()
                    .replace(/^[/d-]|[-+]-|[^0-9-]+|^[\d]+[-][\d]+[-+]/, '');
                }}
                onBlur={handleBlur}
                onChange={(e: any) => {
                  handleChange(e);
                  setFieldValue('cgst', parseFloat(e.target.value));
                }}
              />

              {touched.cgst && errors.cgst ? (
                <ErrorText>{errors.cgst}</ErrorText>
              ) : null}
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '2px',
            }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: 'black',
                fontWeight: 500,
              }}>
              SGST
            </Text>
            <Box>
              <TextInput
                placeholder="Enter %"
                value={values.sgst}
                name="sgst"
                onBlur={handleBlur}
                onInput={(e: any) => {
                  e.target.value = e.target.value
                    .trim()
                    .replace(/^[/d-]|[-+]-|[^0-9-]+|^[\d]+[-][\d]+[-+]/, '');
                }}
                onChange={(e: any) => {
                  handleChange(e);
                  setFieldValue('sgst', parseFloat(e.target.value));
                }}
              />
              {touched.sgst && errors.sgst ? (
                <ErrorText>{errors.sgst}</ErrorText>
              ) : null}
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '2px',
            }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: 'black',
                fontWeight: 500,
              }}>
              IGST
            </Text>
            <Box>
              <TextInput
                placeholder="Enter %"
                value={values.igst}
                onInput={(e: any) => {
                  e.target.value = e.target.value
                    .trim()
                    .replace(/^[/d-]|[-+]-|[^0-9-]+|^[\d]+[-][\d]+[-+]/, '');
                }}
                disabled={ReadOnly}
                onChange={(e: any) => {
                  handleChange(e);
                  setFieldValue('igst', parseFloat(e.target.value));
                }}
                onBlur={handleBlur}
                readOnly={ReadOnly}
                name="igst"
                id="igst"
              />
              {touched.igst && errors.igst ? (
                <ErrorText>{errors.igst}</ErrorText>
              ) : null}
            </Box>
          </Box>
        </Grid>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#FCFCFD',
            padding: '5px 20px',
            background: '#FCFCFD',
          }}>
          <Box>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: 'black',
                fontWeight: 500,
              }}>
              Total Payable Amount
            </Text>
          </Box>

          <Box style={{ width: '20%' }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: 'black',
                fontWeight: 500,
              }}>
              ₹ {totalPaybleAmount ?? 'N/A'}
            </Text>
          </Box>
        </Box>
      </Box>
      <RadioWithLabel
        label="Select GST no."
        disabled={ReadOnly}
        extra={{
          Selected: values.gstId,
          setSelected: (e: any) => {
            setFieldValue('gstId', e);
            setFieldValue('pan', gst[e]['panNumber'] ?? '');
          },
          options: gst.map((e, i) => ({
            id: i,
            name: e['gstNumber'] ?? '',
          })),
        }}
      />
      {touched.pan && errors.pan ? <ErrorText>{errors.pan}</ErrorText> : null}
      <TextInput
        label="PAN Number"
        // placeholder="ABCD1234TE"
        style={{ width: '50%' }}
        value={values.pan}
        name="pan"
        id="pan"
        disabled={true}
        // readOnly={true}
      />
      <Text
        style={{
          fontSize: 13,
          fontWeight: 600,
          marginBlock: 20,
          color: 'black',
        }}>
        Bank Details for electronic Transfer
      </Text>
      <RadioWithLabel
        label="Designated partner"
        disabled={ReadOnly}
        extra={{
          Selected: values.partnerId,
          setSelected: (e: any) => {
            setFieldValue('partnerId', e);
            // onChangePartner();
          },
          options: partnerOptions,
        }}
      />
      {touched.partnerId && errors.partnerId ? (
        <ErrorText>{errors.partnerId}</ErrorText>
      ) : null}

      <TextInput
        label="Name of Account Holder"
        placeholder="Klaxon Advisory Partners LLP"
        name="holderName"
        id="holderName"
        readOnly={true}
        value={values.holderName}
      />
      {touched.accountId && errors.accountId ? (
        <ErrorText>{errors.accountId}</ErrorText>
      ) : null}
      <RadioWithBox
        style={{ marginBottom: 5 }}
        label="Select Bank Account"
        disabled={ReadOnly}
        extra={{
          Selected: values.accountId,
          setSelected: (e: any) => {
            setFieldValue('accountId', e);
          },
          options: Accounts,
        }}
      />
      {roleType == ROLE.ADMIN ? (
        <Box style={{ width: '100%' }}>
          <Button
            extra={{ variant: 'contained' }}
            onClick={onCancel}
            style={{ width: '48%', marginLeft: '2%' }}>
            Approve
          </Button>
          <Button
            extra={{ variant: 'outlined' }}
            onClick={onCancel}
            icons={
              <Image
                src={DownloadIconblack}
                style={{ width: 20, height: 20, objectFit: 'cover' }}
              />
            }
            style={{ width: '48%', marginLeft: '2%' }}>
            Download
          </Button>
        </Box>
      ) : null}

      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bottom: 10,
          position: 'absolute',
          width: '50%',
          backgroundColor: 'white',
          paddingTop: 1,
        }}>
        {label == 'Approved' ? (
          <Button
            extra={{ variant: 'contained' }}
            onClick={onCancel}
            icons={
              <Image
                src={DownloadIcon}
                style={{ width: 20, height: 20, objectFit: 'cover' }}
              />
            }
            style={{ width: '48%', marginLeft: '2%' }}>
            Download
          </Button>
        ) : null}
        {label == 'Initiated' || label == 'Hold' ? (
          <Button
            extra={{ variant: 'contained' }}
            onClick={raiseInvoice}
            style={{ width: '48%', marginLeft: '2%' }}>
            Send for approval
          </Button>
        ) : null}
      </Box>
    </Box>
  );
}

export default KlaxonInvoice;
