import React from 'react';
import Box from '../components/single_component/Box';
import CompletedContainer from '../container/CompletedContainer';
import TransactionProvider from '../providers/TransactionProvider';
import DashBoardProvider from '../providers/DashboardProvider';

function CompletedTransactions() {
  return (
    <TransactionProvider>
      <Box
        style={{
          backgroundColor: '#F2FAFE',
          paddingTop: 6,
          paddingBottom: 6,
        }}>
        <DashBoardProvider>
          <CompletedContainer />
        </DashBoardProvider>
      </Box>
    </TransactionProvider>
  );
}

export default CompletedTransactions;
