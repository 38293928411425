import React, { useState } from 'react';
import Props from '../../types/props';
import Box from '../single_component/Box';
import TextInput from '../common_component/TextInput';
import ErrorText from '../common_component/ErrorText';
import Radio from '../common_component/Radio';
import { useFormik } from 'formik';
import { addInvestorInvestmentSchema } from '../Validation/AddTask';
import Button from '../single_component/Button';
import SubjectInput from '../single_component/SubjectInput';

function InvestorInvestmentForm(props: Props) {
  const [Selected, setSelected] = useState('');

  const dealTypes =
    props?.extra?.masterData?.dealTypes?.map((item: any, index: number) => {
      return {
        id: index + 1,
        name: item,
      };
    }) ?? [];

  const formState = {
    dealSizePreference: '',
    dryPowder: '',
    dealType: '',
    investmentCriteria: '',
    interestRange: '',
    remark: '',
  };
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: formState,
    validationSchema: addInvestorInvestmentSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async values => {
      const dealSizeMin = parseInt(values.dealSizePreference.split('-')[0]);
      const dealSizeMax = parseInt(values.dealSizePreference.split('-')[1]);

      const interestRangeMin = parseInt(values.interestRange.split('-')[0]);
      const interestRangeMinMax = parseInt(values.interestRange.split('-')[1]);

      props?.extra?.onNext({
        dryPowder: values.dryPowder.toString() ?? '',
        dealSizePrefMin: Number.isNaN(dealSizeMax) ? 0 : dealSizeMin,
        dealSizePrefMax: Number.isNaN(dealSizeMax) ? dealSizeMin : dealSizeMax,
        dealType:
          dealTypes
            .find((deal: any) => deal.name === values.dealType)
            ?.id.toString() ?? '',
        investmentCriteria: [values.investmentCriteria] ?? '',
        interestRangeMin: Number.isNaN(interestRangeMinMax)
          ? 0
          : interestRangeMin,
        interestRangeMax: Number.isNaN(interestRangeMinMax)
          ? interestRangeMin
          : interestRangeMinMax,
        remarks: values.remark.toString() ?? '',
      });
    },
  });

  return (
    <Box style={{ paddingBottom: '7em' }}>
      <Box>
        <TextInput
          label="Dry powder"
          placeholder="Enter amount"
          name="dryPowder"
          value={values.dryPowder}
          onChange={handleChange}
          onBlur={handleBlur}
          onInput={(e: any) => {
            e.target.value = e.target.value
              .trim()
              .replace(/^[/d-]|[-+]-|[^0-9-]+|^[\d]+[-][\d]+[-+]/, '');
          }}
        />
        {touched.dryPowder && errors.dryPowder ? (
          <ErrorText>{errors.dryPowder}</ErrorText>
        ) : null}
        <TextInput
          label="Deal size preference"
          placeholder="Enter amount"
          name="dealSizePreference"
          value={values.dealSizePreference}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.dealSizePreference && errors.dealSizePreference ? (
          <ErrorText>{errors.dealSizePreference}</ErrorText>
        ) : null}
        <Radio
          label="Deal type"
          name="dealType"
          id="dealType"
          value={values.dealType}
          onBlur={handleBlur}
          extra={{
            setSelected: (e: any) => {
              setFieldValue('dealType', e);
              setSelected(e);
            },
            Selected: Selected,
            options: dealTypes,
          }}
        />
        {touched.dealType && errors.dealType ? (
          <ErrorText>{errors.dealType}</ErrorText>
        ) : null}
        <TextInput
          label="Investment criteria"
          placeholder="Enter Investment criteria"
          name="investmentCriteria"
          value={values.investmentCriteria}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.investmentCriteria && errors.investmentCriteria ? (
          <ErrorText>{errors.investmentCriteria}</ErrorText>
        ) : null}

        <TextInput
          label="Interest range (% PA)"
          placeholder="Enter Interest range (% PA)"
          name="interestRange"
          value={values.interestRange}
          onChange={handleChange}
          onBlur={handleBlur}
          onInput={(e: any) => {
            e.target.value = e.target.value
              .trim()
              .replace(/^[/d-]|[-+]-|[^0-9-]+|^[\d]+[-][\d]+[-+]/, '');
          }}
        />
        {touched.interestRange && errors.interestRange ? (
          <ErrorText>{errors.interestRange}</ErrorText>
        ) : null}
        <SubjectInput
          label="Any Remark"
          placeholder="Enter the remarks here"
          value={values.remark}
          name="remark"
          id="remark"
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {touched.remark && errors.remark ? (
          <ErrorText>{errors.remark}</ErrorText>
        ) : null}
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bottom: 0,
          position: 'absolute',
          width: '99%',
          marginLeft: -2.5,
          background: 'white',
          paddingTop: '12px',
          paddingBottom: '8px',
          borderTopRightRadius: 3,
        }}>
        <Button
          extra={{ variant: 'outlined' }}
          onClick={props?.extra?.onPrevious}
          style={{ width: '43%', marginRight: '2%', paddingBlock: 1 }}>
          Back
        </Button>
        <Button
          extra={{ variant: 'contained' }}
          onClick={handleSubmit}
          style={{ width: '43%', marginLeft: '2%', paddingBlock: 1 }}>
          Continue
        </Button>
      </Box>
    </Box>
  );
}

export default InvestorInvestmentForm;
