import React, { useState, useContext } from 'react';
import Box from '../../components/single_component/Box';
import AddProject1 from '../../components/Forms/AddProject1';
import AddProject2 from '../../components/Forms/AddProject2';
import AddProject3 from '../../components/Forms/AddProject3';
import ProjectContext from '../../context/ProjectContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function AddProjectStepper() {
  const { addProject, spvId, getProjects, editProject } =
    useContext(ProjectContext);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [formState, setFormState] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(0);

  const onNext = (values: any) => {
    const curentFormState = { ...formState, ...values };
    console.error('addFormState', curentFormState);
    setFormState(curentFormState);
    if (activeStep >= 2) {
      submitForm(curentFormState);
    } else {
      submitForm(curentFormState);
      setActiveStep(step => step + 1);
    }
  };
  const submitForm = async (curentFormState: any) => {
    setLoading(true);
    try {
      const bodyFormData = new FormData();
      curentFormState?.docFiles &&
        Object.keys(curentFormState?.docFiles)?.forEach((doc: any) => {
          bodyFormData.append(doc, curentFormState.docFiles[doc]);
        });
      curentFormState?.docFiles && delete curentFormState?.docFiles;
      for (const field of Object.keys(curentFormState)) {
        if (
          curentFormState[field] !== undefined &&
          curentFormState[field] !== '' &&
          curentFormState[field] !== null
        ) {
          bodyFormData.append(field, curentFormState[field]);
        }
      }
      const response =
        activeStep === 0
          ? await addProject(bodyFormData)
          : await editProject(id ?? '', bodyFormData);
      const { success } = response;
      setLoading(false);
      if (success) {
        toast.success(response.message);
        if (activeStep >= 2) navigate(-1);
        if (activeStep >= 2) getProjects(spvId);
        response?.responseData &&
          typeof response?.responseData === 'number' &&
          setId(response?.responseData);
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      setLoading(false);
    }
  };
  const onPrevious = () => {
    setActiveStep(step => step - 1);
  };

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          gap: 2,
          marginTop: 1,
        }}>
        <Box
          style={{
            width: '32%',
            height: '5px',
            borderRadius: 5,
            background: activeStep >= 0 ? '#104960' : '#C2C2C2',
          }}></Box>
        <Box
          style={{
            width: '32%',
            height: '5px',
            borderRadius: 5,
            background: activeStep >= 1 ? '#104960' : '#C2C2C2',
          }}></Box>
        <Box
          style={{
            width: '32%',
            height: '5px',
            borderRadius: 5,
            background: activeStep == 2 ? '#104960' : '#C2C2C2',
          }}></Box>
      </Box>

      <Box>
        <Box
          style={{
            display: activeStep === 0 ? 'block' : 'none',
          }}>
          <AddProject1 extra={{ onNext, onPrevious }} />
        </Box>
        <Box
          style={{
            display: activeStep === 1 ? 'block' : 'none',
          }}>
          <AddProject2 extra={{ onNext, onPrevious }} />
        </Box>
        <Box
          style={{
            display: activeStep === 2 ? 'block' : 'none',
          }}>
          <AddProject3
            extra={{ onNext, onPrevious, btnDisableLoader: loading }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default AddProjectStepper;
