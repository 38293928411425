import User from './User';

class Profile {
  user: User;
  totalInvestor: number | null;
  totalTransaction: number | null;
  totalSalesExecutive: number | null;

  constructor(data: Record<string, unknown>) {
    this.user = new User(data.user as any);
    this.totalInvestor = parseInt((data.totalInvestor ?? 'N/A').toString());
    this.totalTransaction = parseInt(
      (data.totalTransaction ?? 'N/A').toString()
    );
    this.totalSalesExecutive = parseInt(
      (data.totalSalesExecutive ?? 'N/A').toString()
    );
  }

  static fromJson(json: Record<string, unknown>): Profile {
    return new Profile(json);
  }

  toJson(stringify: boolean = false): Record<string, unknown> | string {
    const jsonData = {
      user: this.user.toJson(false),
      totalInvestor: this.totalInvestor,
      totalTransaction: this.totalTransaction,
      totalSalesExecutive: this.totalSalesExecutive,
    };
    return stringify ? JSON.stringify(jsonData) : jsonData;
  }
}

export default Profile;
