import React, { useContext, useMemo, useState } from 'react';
import Props from '../../types/props';
import Box from '../single_component/Box';
import SubjectInput from '../single_component/SubjectInput';
import Dpicker from '../single_component/Dpicker';
import TextInput from '../common_component/TextInput';
import UploadInput from '../single_component/UploadInput';
import TwoButton from '../common_component/TwoButton';
import DeletableChip from '../single_component/DeletableChip';
import Image from '../single_component/Image';
import deleteIcon from '../../assets/close.png';
import TransactionsContex from '../../context/TransactionsContex';
import { toast } from 'react-toastify';
import Autocomplete from '../single_component/Autocomplete';
import AuthContext from '../../context/AuthContext';
import { useFormik } from 'formik';
import ErrorText from '../common_component/ErrorText';
import {
  addTransactionValidation,
  digitRexAcceptDecimal,
} from '../Validation/AddTask';

function InitiateTransactions({ ...props }: Props) {
  const { projectId } = props.extra;
  const [potentialInvestors, setPotentialInvestors] = useState<number[]>([]);
  const [Url, setUrl] = useState('');
  const [Date, setDate] = useState('');
  const { masterData } = useContext(AuthContext);
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);
  const { addTransaction, getTransaction } = useContext(TransactionsContex);

  const onClick = () => {
    props.extra.setToggle(false);
  };

  const getSelectedPotentialInvestors = useMemo(() => {
    const i = potentialInvestors.map(
      e => masterData?.value().investors.at(e)?.name ?? ''
    );
    console.error(i);
    return i;
  }, [potentialInvestors]);

  const handleAddChip = (value: string) => {
    const temp = potentialInvestors ?? [];
    const index = temp.indexOf(parseInt(value)); //-1 index
    if (index == -1 && value !== '') {
      temp.push(parseInt(value));
    }
    setPotentialInvestors([...temp]);
  };

  const handleDeleteChip = (index: number) => {
    const investors =
      potentialInvestors.length > 1 ? potentialInvestors.splice(index, 1) : [];
    setPotentialInvestors(investors);
  };

  const {
    values,
    setFieldValue,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    handleChange,
  } = useFormik({
    initialValues: {
      document: '',
      transcBrief: '',
      date: '',
      'Deal size': '',
      remark: '',
      investors: '',
    },
    validationSchema: addTransactionValidation,
    onSubmit: async values => {
      setbtnDisableLoader(true);
      const investors = potentialInvestors.map(e => {
        return masterData?.value().investors.at(e)?.investorId ?? '';
      });
      const bodyFormData = new FormData();
      bodyFormData.append('document', Url);
      bodyFormData.append('transcBrief', values.transcBrief);
      bodyFormData.append('projectId', projectId);
      bodyFormData.append('date', Date);
      bodyFormData.append('transcStatus', 'Initiated');
      bodyFormData.append('dealSize', values['Deal size']);
      bodyFormData.append('remark', values.remark);
      bodyFormData.append('investors', JSON.stringify(investors));
      const response = await addTransaction(bodyFormData);

      if (response.success) {
        toast.success(response.message);
        props.extra.setToggle(false);
        getTransaction(1, 10, {
          filter: {
            projectId: projectId,
          },
        });
      } else {
        toast.error(response.message);
      }
      setbtnDisableLoader(false);
    },
  });

  return (
    <Box
      style={{ width: '90%', margin: 'auto', marginTop: 2, paddingBottom: 9 }}>
      <Box>
        <SubjectInput
          value={values.transcBrief}
          onChange={handleChange}
          label="Brief of Transaction"
          placeholder="Enter Brief of Transaction"
          name="transcBrief"
          id="transcBrief"
          onBlur={handleBlur}
        />
        {touched.transcBrief && errors.transcBrief ? (
          <ErrorText>{errors.transcBrief}</ErrorText>
        ) : null}

        <Dpicker
          label="Date"
          value={Date}
          extra={{
            placeholder: 'Select date',
            setDate: (e: any) => {
              setDate(e);
              setFieldValue('date', e);
            },
          }}
          name="date"
          id="date"
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {touched.date && errors.date ? (
          <ErrorText>{errors.date}</ErrorText>
        ) : null}

        <TextInput
          onInput={(e: any) => {
            e.target.value = e.target.value
              .trim()
              .replace(digitRexAcceptDecimal, '');
          }}
          label="Deal size"
          placeholder="Enter Amount here"
          name="Deal size"
          id="Deal size"
          onBlur={handleBlur}
          value={values['Deal size']}
          onChange={handleChange}
        />
        {touched['Deal size'] && errors['Deal size'] ? (
          <ErrorText>{errors['Deal size']}</ErrorText>
        ) : null}

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
          }}>
          <Autocomplete
            label="Potential investors"
            placeholder="Enter name of investor"
            options={
              masterData?.value().investors.map((e, i) => ({
                name: e.name,
                value: i.toString(),
              })) ?? []
            }
            onSelect={(e: string) => {
              handleAddChip(e);
              setFieldValue('investors', e);
            }}
          />
        </Box>
        <Box>
          {getSelectedPotentialInvestors.map((chip: any, index: number) => (
            <DeletableChip
              key={index}
              label={chip}
              name="investors"
              id="investors"
              // onChange={(e: any) => {
              //   // setFieldValue('activeMarkets', e);
              // }}
              // onBlur={handleBlur}
              onClick={() => handleDeleteChip(index)}
              style={{ margin: '4px', background: '#E5F7FF' }}
              extra={{
                variant: 'outlined',
                icons: (
                  <Image
                    src={deleteIcon}
                    style={{ cursor: 'pointer', width: '15px' }}
                  />
                ),
              }}
            />
          ))}
          {/* {touched.activeMarkets &&
              errors.activeMarkets &&
              chips.length === 0 ? (
                <ErrorText>{errors.activeMarkets}</ErrorText>
              ) : null} */}
        </Box>
        {touched.investors && errors.investors ? (
          <ErrorText>{errors.investors}</ErrorText>
        ) : null}
        <UploadInput
          label="Mandate"
          placeholder="Upload Documents"
          extra={{
            onchange: (e: any) => {
              setFieldValue('document', e[0]);
              setUrl(e[0]);
            },
          }}
        />

        {touched.document && errors.document ? (
          <ErrorText>{errors.document}</ErrorText>
        ) : null}

        <SubjectInput
          label="Any Remark"
          placeholder="Enter the remarks here"
          value={values.remark}
          name="remark"
          id="remark"
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {touched.remark && errors.remark ? (
          <ErrorText>{errors.remark}</ErrorText>
        ) : null}

        <TwoButton
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bottom: 10,
            position: 'absolute',
            width: '90%',
          }}
          extra={{
            onclickFirst: onClick,
            OnClickSecond: handleSubmit,
            firstVariant: 'outlined',
            secondVariant: 'contained',
            firstButtonText: 'Cancel',
            secondButtonText: 'Save',
            btnDisableLoader: btnDisableLoader,
          }}
        />
      </Box>
    </Box>
  );
}

export default InitiateTransactions;
