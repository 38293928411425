import React from 'react';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import Props from '../../types/props';

function DevContacatDetails({ ...props }: Props) {
  const { phone, email } = props.extra;
  return (
    <Box onClick={props.onClick}>
      {email == undefined || email == null || email == '' ? (
        <Text>N/A</Text>
      ) : (
        <Text style={{ fontWeight: 400, fontSize: 14, color: '#101828' }}>
          {email}
        </Text>
      )}

      {phone == undefined || phone == null || phone == '' ? (
        <Text>N/A</Text>
      ) : (
        <Text style={{ fontWeight: 400, fontSize: 14, color: '#101828' }}>
          +91 {phone}
        </Text>
      )}
    </Box>
  );
}

export default DevContacatDetails;
