import React from 'react';
import Props from '../../types/props';
import Box from '../single_component/Box';
import Text from '../single_component/Text';

function TextLabelWithDesc({ ...props }: Props) {
  const { label, desc } = props.extra;
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        gap: 1,
        alignItems: 'center',
      }}>
      <Text style={{ fontSize: 13, color: 'gray' }}>{label}</Text>
      {desc == '' ? (
        <Text style={{ fontSize: 13, fontWeight: 'bold' }}>N/A</Text>
      ) : (
        <Text style={{ fontSize: 13, fontWeight: 'bold' }}>{desc}</Text>
      )}
    </Box>
  );
}

export default TextLabelWithDesc;
