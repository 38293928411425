import React, { useContext, useState } from 'react';
import Box from '../single_component/Box';
import TwoButton from '../common_component/TwoButton';
import TextInput from '../common_component/TextInput';
import Props from '../../types/props';
import { useFormik } from 'formik';
import { AddExecutive } from '../Validation/AddTask';
import ErrorText from '../common_component/ErrorText';
import { toast } from 'react-toastify';
import SalesExecutiveContext from '../../context/SalesExecutiveContext';

function AddSalesExecutive({ ...props }: Props) {
  const { setToggle } = props.extra;
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);
  const { addSalesExecutive } = useContext(SalesExecutiveContext);

  const onCancel = () => {
    setToggle(false);
  };

  const initialValues = {
    name: '',
    phone: '',
    email: '',
    location: '',
  };

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: AddExecutive,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: async (values, action) => {
        setbtnDisableLoader(true);
        try {
          const response = await addSalesExecutive({
            name: values.name,
            phoneNumber: values.phone,
            email: values.email,
            cityId: values.location ?? '',
          });
          setbtnDisableLoader(false);
          if (response.success) {
            toast.success(response.message);
            action.resetForm();
            setToggle(false);
          } else {
            toast.error(response.message);
          }
        } catch (e) {
          setbtnDisableLoader(false);
          console.error(e);
          setToggle(false);
        }
      },
    });

  return (
    <Box style={{ width: '90%', margin: 'auto', marginTop: 2 }}>
      <Box style={{ overflowY: 'hidden' }}>
        <TextInput
          {...props}
          value={values.name}
          name="name"
          id="name"
          label="Name"
          type="text"
          onBlur={handleBlur}
          onChange={handleChange}
          onInput={(e: any) => {
            e.target.value = e.target.value.replace(/[0-9]/g, '');
          }}
          placeholder="Enter name of the sales executive">
          {touched.name && errors.name ? (
            <ErrorText>{errors.name}</ErrorText>
          ) : null}
        </TextInput>
        <TextInput
          {...props}
          label="Phone Number"
          name="phone"
          type="tel"
          id="phone"
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={10}
          onInput={(e: any) => {
            e.target.value = e.target.value.trim().replace(/[a-zA-Z\s]/g, '');
          }}
          minLength={10}
          placeholder="Enter Phone Number">
          {touched.phone && errors.phone ? (
            <ErrorText>{errors.phone}</ErrorText>
          ) : null}
        </TextInput>
        <TextInput
          {...props}
          label="Email ID"
          type="text"
          name="email"
          id="email"
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Enter Email Id">
          {touched.email && errors.email ? (
            <ErrorText>{errors.email}</ErrorText>
          ) : null}
        </TextInput>

        <TextInput
          label="Location"
          name="location"
          id="location"
          placeholder="Enter location name"
          value={values.location}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {touched.location && errors.location ? (
          <ErrorText>{errors.location}</ErrorText>
        ) : null}
      </Box>
      <TwoButton
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bottom: 10,
          position: 'absolute',
          width: '90%',
          backgroundColor: 'white',
          paddingTop: 1,
        }}
        extra={{
          onclickFirst: onCancel,
          OnClickSecond: handleSubmit,
          firstVariant: 'outlined',
          secondVariant: 'contained',
          firstButtonText: 'Cancel',
          secondButtonText: 'Add',
          btnDisableLoader: btnDisableLoader,
        }}
      />
    </Box>
  );
}

export default AddSalesExecutive;
