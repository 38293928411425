import React from 'react';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import Props from '../../types/props';

function TableTop({ ...props }: Props) {
  const { label, description, Components } = props.extra;

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: 3,
      }}>
      <Box>
        <Text style={{ fontSize: 15, fontWeight: 'bold' }}>{label}</Text>
        <Text style={{ fontSize: 14, color: '#667085' }}>{description}</Text>
      </Box>

      <Components />
    </Box>
  );
}

export default TableTop;
