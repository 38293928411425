import React from 'react';
import { ROLE, RouteObject } from '../types/routeObject';
import { NotFound } from '../pages/NotFound';

export default function ExecutiveRoute(props: RouteObject) {
  return {
    path: props.path,
    handle: props.handle,
    index: props.index,
    children: props.children,
    element: props.role == ROLE.EXECUTIVE ? props.component : <NotFound />,
  };
}
