import React from 'react';
import Props from '../../types/props';
import Chip from '../single_component/Chip';
import CircleIcon from '@mui/icons-material/Circle';

export default function StatusChip({ children, ...props }: Props) {
  return (
    <Chip
      style={{
        color: 'white',
        fontSize: 10,
        height: 18,
        gap: 1,
        padding: 1,
        display: 'inline-flex',
        alignItems: 'center',
        background:
          props.label == 'Initiated'
            ? '#25A9E0'
            : props.label == 'Raised'
            ? '#D4BF00'
            : props.label == 'Hold'
            ? '#D36829'
            : props.label == 'Approved'
            ? '#027A48'
            : props.label == 'Cancelled'
            ? '#B42318'
            : 'gray',
      }}
      label={props.label}
      extra={{
        variant: 'filled',
        icons: <CircleIcon style={{ fontSize: 6, color: 'white' }} />,
      }}>
      {children}
    </Chip>
  );
}
