import DeveloperGroup from './DeveloperGoup';

class DeveloperGroupTable {
  data: DeveloperGroup[]; // Adjust the type here
  total_pages: number | null;
  next: number | null;
  prev: number | null;
  currentPage: number | null;

  constructor(data: Record<string, unknown>) {
    console.error('log data', data);
    this.data = (Array.isArray(data.records) ? data.records : []).map(
      (todo: Record<string, unknown>) => new DeveloperGroup(todo)
    );
    this.total_pages = parseInt((data.totalPages ?? 0).toString());
    this.next =
      data?.nextPage == undefined || data?.nextPage == null
        ? null
        : parseInt((data?.nextPage ?? '0').toString());
    this.prev =
      data?.prevPage == undefined || data?.prevPage == null
        ? null
        : parseInt((data?.prevPage ?? '0').toString());
    this.currentPage = parseInt((data.currentPage ?? 0).toString());
  }

  static fromJson(json: Record<string, unknown>): DeveloperGroupTable {
    return new DeveloperGroupTable(json);
  }

  toJson(stringify: boolean = false): Record<string, unknown> | string {
    const jsonData = {
      data: this.data,
      total_pages: this.total_pages,
      next: this.next,
      prev: this.prev,
    };

    return stringify ? JSON.stringify(jsonData) : jsonData;
  }
}

export default DeveloperGroupTable;
