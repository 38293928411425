import { Moment } from 'moment';
import DateUtils from '../utils/dateUtils';

class DesignatedAccount {
  partnerId: number;
  accountHolder: string;
  accountNumber: string;
  bankName: string;
  ifscCode: string;
  branchAddress: string;
  createdBy: number;
  updatedBy: number;
  createdAt: Moment | null;
  updatedAt: Moment | null;
  selected: boolean;
  accountId: number;

  constructor(data: Record<string, unknown>) {
    this.partnerId = parseInt((data['partnerId'] ?? '0').toString());
    this.accountHolder = (data['accountHolder'] ?? 'N/A').toString();
    this.accountNumber = (data['accountNumber'] ?? 'N/A').toString();
    this.bankName = (data['bankName'] ?? 'N/A').toString();
    this.ifscCode = (data['ifscCode'] ?? 'N/A').toString();
    this.branchAddress = (data['branchAddress'] ?? 'N/A').toString();
    this.createdBy = parseInt((data['createdBy'] ?? '0').toString());
    this.updatedBy = parseInt((data['updatedBy'] ?? '0').toString());
    this.createdAt = DateUtils.parseDate((data.createdAt ?? 'N/A').toString());
    this.updatedAt = DateUtils.parseDate((data.updatedAt ?? 'N/A').toString());
    this.accountId = parseInt((data['id'] ?? '0').toString());
    this.selected = false;
  }

  static fromJson(json: Record<string, unknown>): DesignatedAccount {
    return new DesignatedAccount({
      partnerId: parseInt((json.partnerId ?? 'N/A').toString()),
      accountHolder: (json.accountHolder ?? 'N/A').toString(),
      accountNumber: (json.accountNumber ?? 'N/A').toString(),
      bankName: (json.bankName ?? 'N/A').toString(),
      ifscCode: (json.ifscCode ?? 'N/A').toString(),
      branchAddress: (json.branchAddress ?? 'N/A').toString(),
      createdBy: parseInt((json.createdBy ?? 'N/A').toString()),
      updatedBy: parseInt((json.updatedBy ?? 'N/A').toString()),
      createdAt: DateUtils.parseDate((json.createdAt ?? 'N/A').toString()),
      updatedAt: DateUtils.parseDate((json.updatedAt ?? 'N/A').toString()),
    });
  }

  toJson(stringify: boolean = false): Record<string, unknown> | string {
    const jsonData = {
      partnerId: this.partnerId,
      accountHolder: this.accountHolder,
      accountNumber: this.accountNumber,
      bankName: this.bankName,
      ifscCode: this.ifscCode,
      branchAddress: this.branchAddress,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };

    return stringify ? JSON.stringify(jsonData) : jsonData;
  }
}

export default DesignatedAccount;
