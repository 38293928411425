import React from 'react';
import Props from '../../types/props';
import Box from '../../components/single_component/Box';
import Text from '../../components/single_component/Text';
import Button from '../../components/single_component/Button';
import Sidebar from '../../components/common_component/Sidebar';
import AddGroupForm from '../../components/Forms/AddGroup';
import AddOutlined from '@mui/icons-material/AddOutlined';

function DeveloperTableTop({ ...props }: Props) {
  const {
    label,
    description,
    AddGroup,
    setAddGroup,
    currPage,
    rowsPerPage,
    SidebarData,
    isEditGroup,
    setIsEditGroup,
    setSidebarData,
    setSelectedRowIndex,
  } = props.extra;
  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '12px 24px 0px 24px ',
          background: 'white',
        }}>
        <Box>
          <Text style={{ fontSize: 15, fontWeight: 'bold' }}>{label}</Text>
          <Text style={{ fontSize: 14, color: '#667085' }}>{description}</Text>
        </Box>
        {label == 'Developer Group' ? (
          <Box style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Button
              onClick={() => {
                setAddGroup(!AddGroup);
                setIsEditGroup(false);
                setSidebarData({});
                setSelectedRowIndex(-1);
              }}
              style={{ padding: '8px 24px' }}
              extra={{
                variant: 'contained',
                color: 'primary',
              }}
              icons={<AddOutlined />}>
              <Text style={{ color: 'white', fontSize: 13 }}>Add group</Text>
            </Button>
          </Box>
        ) : null}

        <Sidebar
          label="Add Group"
          extra={{
            anchor: 'right',
            Toggle: AddGroup,
            setToggle: setAddGroup,
            description: 'Add group details here to view in the dashboard',
            width: 400,
          }}>
          <AddGroupForm
            extra={{
              setToggle: setAddGroup,
              SidebarData,
              isEditGroup,
              currPage,
              rowsPerPage,
            }}
          />
        </Sidebar>
      </Box>
    </Box>
  );
}

export default DeveloperTableTop;
