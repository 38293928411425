import React, { useContext, useState, useEffect } from 'react';
import Box from '../../components/single_component/Box';
import DeveloperGroupContext from '../../context/DeveloperGroupContext';
import { useNavigate } from 'react-router-dom';
import { TableCell, TableRow } from '@mui/material';
import DeveloperCard from '../../components/Sales Executive/DeveloperCard';
import DevContacatDetails from '../../components/Sales Executive/DevGroupContactDetails';
import FileChip from '../../components/single_component/FileChip';
import RemoveRedEyeOutlined from '@mui/icons-material/RemoveRedEyeOutlined';
import Tables from '../../components/single_component/Tables';
import Sidebar from '../../components/common_component/Sidebar';
import DeveloperGroup from '../../components/Action/DeveloperGroup';
import Text from '../../components/single_component/Text';
import DeveloperTableTop from '../componets/DeveloperTableTop';
import StringUtils from '../../utils/stringUtil';
import { ROUTES } from '../../types/routeObject';
import NotfoundPage from '../../components/single_component/NotfoundPage';
import Loader from '../../components/single_component/Loader';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
function SalesDevGroupContainer() {
  const { DeveloperGroupData, getDeveloperGroup, loadGroupData } = useContext(
    DeveloperGroupContext
  );
  const [Toggle, setToggle] = useState(false);
  const [AddGroup, setAddGroup] = useState(false);

  const [isEditGroup, setIsEditGroup] = useState(false);
  const [SidebarData, setSidebarData] = useState();
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const navigate = useNavigate();

  const [Page, setPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [Next, setNext] = useState();
  const [Previous, setPrevious] = useState();
  const [currPage, setcurrPage] = useState(1);
  const { loading } = useContext(DeveloperGroupContext);

  useEffect(() => {
    setPage(DeveloperGroupData?.total_pages);
    setNext(DeveloperGroupData?.next);
    setPrevious(DeveloperGroupData?.prev);
  }, [DeveloperGroupData]);

  useEffect(() => {
    getDeveloperGroup(`/developer/getAll`, {
      page: currPage,
      size: rowsPerPage,
    });
  }, [currPage, rowsPerPage, loadGroupData]);
  useEffect(() => {
    DeveloperGroupData?.data?.length > 0 &&
      setSidebarData(DeveloperGroupData?.data[selectedRowIndex] ?? {});
  }, [selectedRowIndex]);
  useEffect(() => {
    DeveloperGroupData?.data?.length > 0 &&
      setSidebarData(DeveloperGroupData?.data[selectedRowIndex] ?? {});
  }, [DeveloperGroupData]);
  const TableRowComponet = (props: any) => {
    const { row, index } = props;
    return (
      <TableRow>
        <TableCell>
          <DeveloperCard
            label={StringUtils.capitalizeFirstLetter(row.name)}
            extra={{
              groupLogo: row.groupLogo,
            }}
            onClick={() =>
              navigate(
                `${ROUTES.VIEW_DEVELOPER_GROUP.replace(
                  ':id',
                  row.developerId
                )}`,
                {
                  state: {
                    groupName: row.name,
                    promoterName: row.promoter_name,
                    promoterEmail: row.email,
                  },
                }
              )
            }
          />
        </TableCell>
        <TableCell>
          {row.city.name == undefined ||
          row.city.name == null ||
          row.city.name == '' ? (
            <Text>N/A</Text>
          ) : (
            <Text>{StringUtils.capitalizeFirstLetter(row.city.name)}</Text>
          )}
        </TableCell>
        <TableCell>
          {row?.promoters[0]?.promoterName == undefined ||
          row?.promoters[0]?.promoterName == null ||
          row?.promoters[0]?.promoterName == '' ? (
            <Text>N/A</Text>
          ) : (
            <Text>
              {StringUtils.capitalizeFirstLetter(
                row?.promoters[0]?.promoterName
              )}
            </Text>
          )}
        </TableCell>

        <TableCell>
          <DevContacatDetails
            extra={{
              phone: row?.promoters[0]?.promoterPhone,
              email: row?.promoters[0]?.promoterEmail,
            }}
          />
        </TableCell>
        <TableCell>
          {row?.groupProfile ? (
            <FileChip
              label="GroupProfile"
              extra={{ url: row?.groupProfile ?? '' }}
            />
          ) : (
            <Text>N/A</Text>
          )}
        </TableCell>
        <TableCell>
          <RemoveRedEyeOutlined
            onClick={() => {
              // setSidebarData(row);
              setToggle(true);
              setSelectedRowIndex(index);
            }}
          />
        </TableCell>
        <TableCell>
          <ModeEditOutlineIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              // setSidebarData(row);
              setAddGroup(true);
              setIsEditGroup(true);
              setSelectedRowIndex(index);
            }}
          />
        </TableCell>
      </TableRow>
    );
  };

  const tableHeaders = [
    { id: 'Group', name: 'Group' },
    {
      id: 'Location',
      name: 'Location',
    },
    {
      id: 'ContactPerson',
      name: 'Contact Person',
    },
    {
      id: 'ContactDetails',
      name: 'Contact Details',
    },
    {
      id: 'GroupProfile',
      name: 'Group Profile',
    },
    {
      id: 'Action',
      name: 'Action',
    },
    {
      id: 'Edit',
      name: 'Edit',
    },
  ];
  return (
    <Box style={{ paddingTop: '56px', paddingBottom: '56px' }}>
      <Box
        style={{
          width: '85%',
          margin: 'auto',
          boxShadow: '0px 2px 10px 0px #1049601A',
        }}>
        <DeveloperTableTop
          extra={{
            label: 'Developer Group',
            description:
              'Here you can create developer data format by uploading files',
            AddGroup,
            setAddGroup,
            SidebarData,
            isEditGroup,
            setIsEditGroup,
            rowsPerPage,
            currPage,
            setSidebarData,
            setSelectedRowIndex,
          }}
        />
        {loading ? (
          <Loader />
        ) : (
          <>
            {DeveloperGroupData?.data &&
            DeveloperGroupData?.data?.length > 0 ? (
              <Tables
                extra={{
                  rows: DeveloperGroupData?.data ?? [],
                  columns: tableHeaders,
                  RowComponent: TableRowComponet,
                  page: Page,
                  setPage: setPage,
                  rowsPerPage: rowsPerPage,
                  setrowsPerPage: setrowsPerPage,
                  Next: Next,
                  Previous: Previous,
                  currPage: currPage,
                  setcurrPage: setcurrPage,
                  setNext: setNext,
                  setPrevious: setPrevious,
                }}
              />
            ) : (
              <NotfoundPage />
            )}
          </>
        )}

        <Sidebar
          label="Group details"
          extra={{
            anchor: 'right',
            Toggle,
            setToggle,
            description: 'Add group details here to view in the dashboard',
            width: 420,
          }}>
          <DeveloperGroup extra={{ setToggle, data: SidebarData }} />
        </Sidebar>
      </Box>
    </Box>
  );
}

export default SalesDevGroupContainer;
