import React, { useContext, useEffect, useState } from 'react';
import Props from '../../types/props';
import Text from '../single_component/Text';
import Dpicker from '../single_component/Dpicker';
import TextInput from '../common_component/TextInput';
import { Alert, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SubjectInput from '../single_component/SubjectInput';
import RadioWithLabel from '../common_component/RadioWithLabel';
import RadioWithBox from '../common_component/RadioWithBox';
import Button from '../single_component/Button';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import TransactionService from '../../services/TransactionService';
import AuthContext from '../../context/AuthContext';
import { ROLE } from '../../types/routeObject';
import { useFormik } from 'formik';
import TransactionsContex from '../../context/TransactionsContex';
import { invoiceValidationSchema } from '../Validation/AddTask';
import DesignatedAccount from '../../models/designatedAccount';
import { toast } from 'react-toastify';
import DashboardContext from '../../context/Dashboard';
import ErrorText from '../common_component/ErrorText';
import DateUtils from '../../utils/dateUtils';
import jsPDF from 'jspdf';
import PDFimage from '../../assets/images/logo.png';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import MailContext from '../../context/MailContext';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    width: '50%',
    color: theme.palette.primary.main,
  },
}));

function KlaxonInvoiceAdmin(props: Props = {}) {
  const { sendMail } = useContext(MailContext);
  const [pdfData, setPdfData] = useState<any>([]);
  const [disabledEdit, setReadOnly] = useState<boolean>(true);

  const { setToggle, label, data, page, userId, ApprovePage } =
    props.extra ?? {};
  const [gst, setGst] = useState<Record<string, unknown>[]>([]);
  // const [metaData, setMetaData] = useState<Record<string, unknown>>({});
  const { getInvoice, getTransaction } = useContext(TransactionsContex);
  const [forminitialValues, setforminitialValues] = useState<any>();
  const { role, masterData } = useContext(AuthContext);
  const { designatedPartners, designatedAccount } = masterData?.value() ?? {};
  const [Accounts, setAccounts] = useState<any>([]);
  const [selected, setSelected] = useState('');
  const { getDashboardData: adminDashBoardData } = useContext(DashboardContext);
  useEffect(() => {
    new TransactionService()
      .getInvoiceMetaData(data['transcId'])
      .then(metaDataResponse => {
        // setMetaData(metaDataResponse);
        setGst(
          Array.isArray(metaDataResponse['gsts'])
            ? metaDataResponse['gsts']
            : []
        );
        getData(metaDataResponse);
      });
    setAccounts(designatedAccount);
  }, []);
  const partnerOptions =
    designatedPartners?.map((item: any, index: number) => {
      return {
        id: index,
        name: item.accountHolder,
      };
    }) ?? [];
  const [approveReadOnly, setApproveReadOnly] = useState(false);
  const getData = async (metaDataResponse: Record<string, unknown>) => {
    if (label == 'Approved') {
      setReadOnly(!true);
      setApproveReadOnly(true);
    }
    if (label !== 'Initiated') {
      getInvoice(data['transcId'] ?? '').then((res: any) => {
        setPdfData(res?.dataResponse?.invoice);
        if (res.success) {
          const data = res.dataResponse;
          const invoicemetagst: Record<string, unknown>[] = Array.isArray(
            metaDataResponse['gsts']
          )
            ? metaDataResponse['gsts']
            : [];
          for (let i = 0; i < invoicemetagst.length; i++) {
            if (invoicemetagst[i]['gstId'] == data?.invoice?.partnerGstId) {
              console.error(
                'checking gst id entry',
                i,
                invoicemetagst[i]['panNumber'],
                invoicemetagst[i]['gstId']
              );
              break;
            }
          }
          const findPanbyIndex = invoicemetagst.findIndex(
            item => item?.partnerGstId == data?.invoice?.gstId
          );
          console.error('findPanbyIndex', findPanbyIndex, invoicemetagst);
          setFieldValue(
            'pan',
            `${invoicemetagst[findPanbyIndex]?.panNumber ?? ''}`
          );

          const partners = designatedPartners ?? [];
          let partnerAccounts: DesignatedAccount[] = [];
          for (let i = 0; i < partners.length; i++) {
            if (partners[i]['partnerId'] == data?.invoice?.partnerId) {
              partnerAccounts =
                masterData?.value().designatedPartners[i].designatedAccounts ??
                [];
              break;
            }
          }

          for (let i = 0; i < partnerAccounts.length; i++) {
            console.error(
              'checking accoutn id',
              partnerAccounts[i],
              partnerAccounts[i]?.accountId,
              data?.invoice?.accountId
            );
          }
          console.error(
            'Invoicedata',
            new Date(data?.invoice?.invoiceDate),
            new Date(data?.invoice?.paymentDate)
          );
          setFieldValue('invoiceNumber', data?.invoice?.invoiceNo ?? '');
          setFieldValue('partnerId', data?.invoice?.partnerId - 1);
          setFieldValue('accountId', data?.invoice?.accountId);
          setFieldValue('invoiceDate', new Date(data?.invoice?.invoiceDate));
          setFieldValue('paymentDate', new Date(data?.invoice?.paymentDate));
          setFieldValue('invoice', data?.invoice?.invoiceNo);
          setFieldValue('contact', data?.invoice?.contactPersonName);
          setFieldValue('company', data?.invoice?.companyName);
          setFieldValue('spv', data?.transaction?.project?.spv?.spvName);
          setFieldValue('contactPersonName', data?.invoice?.contactPersonName);
          setFieldValue('companygst', data?.invoice?.companyGst);
          setFieldValue('companyAddress', data?.invoice?.companyAddress);
          setFieldValue('subject', data?.invoice?.subject);
          setFieldValue('description', data?.invoice?.paymentDesc);
          setFieldValue('amount', data?.invoice?.paymentAmount);
          setFieldValue('sgst', data?.invoice?.paymentGst);
          setFieldValue('cgst', data?.invoice?.paymentCgst);
          setFieldValue('igst', data?.invoice?.paymentIgst);
          setFieldValue('gstId', data?.invoice?.gstId - 1);
          setFieldValue('invoiceId', data?.invoice?.invoiceId);
        }
      });
    }
  };
  console.error(pdfData, 'pdfData');

  const {
    handleSubmit,
    handleBlur,
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: {
      invoiceDate: '',
      invoiceNumber: '',
      paymentDate: '',
      invoice: '',
      company: '',
      companygst: '',
      companyAddress: '',
      subject: '',
      contactPersonName: '',
      description: '',
      amount: '',
      cgst: '',
      sgst: '',
      igst: '',
      holderName: 'Klaxon Advisory Partners LLP',
      pan: '',
      accountId: '',
      partnerId: '',
      gstId: '',
      spv: '',
      invoiceId: '',
    },
    validationSchema: invoiceValidationSchema,
    onSubmit: async values => {
      console.error('add invoice ', values);
      const formData = new FormData();
      for (const pair in forminitialValues) {
        formData.append(pair, forminitialValues[pair]);
      }

      const { success, message } =
        await new TransactionService().approveTransaction(
          data['transcId'],
          formData
        );
      if (success) {
        toast.success(message);
        if (page == 'Dashboard') {
          adminDashBoardData(`admin/dashboard`, {
            page: 1,
            size: 10,
          });
        }

        if (page == 'Sales-Executive') {
          getTransaction(1, 10, {
            filter: {
              createdBy: userId,
            },
          });
        }
      } else {
        toast.error(message);
      }
      if (page == 'Dashboard') {
        adminDashBoardData(`admin/dashboard`, {
          page: 1,
          size: 10,
        });
      }
      // };
    },
  });
  useEffect(() => {
    setsetIndexOfDesignatedPart(values.partnerId);
  });
  const [setIndexOfDesignatedPart, setsetIndexOfDesignatedPart] = useState<any>(
    values.partnerId
  );
  const desName = partnerOptions[setIndexOfDesignatedPart]?.name;
  console.error('errors', errors);
  let totalPaybleAmount: any =
    (isNaN(parseFloat(values.amount)) ? 0 : parseFloat(values.amount)) +
    (isNaN(parseFloat(values.igst))
      ? 0
      : (parseFloat(values.igst) *
          (isNaN(parseFloat(values.amount)) ? 0 : parseFloat(values.amount))) /
        100) +
    (isNaN(parseFloat(values.cgst))
      ? 0
      : (parseFloat(values.cgst) *
          (isNaN(parseFloat(values.amount)) ? 0 : parseFloat(values.amount))) /
        100) +
    ((isNaN(parseFloat(values.sgst)) ? 0 : parseFloat(values.sgst)) *
      (isNaN(parseFloat(values.amount)) ? 0 : parseFloat(values.amount))) /
      100;
  totalPaybleAmount = totalPaybleAmount.toFixed(2);

  const handlePdfDownload = async () => {
    const {
      companyAddress,
      companyGst,
      companyName,
      contactPersonName,
      gstId,
      invoiceDate,
      invoiceNo,
      partnerId,
      paymentAmount,
      paymentCgst,
      paymentDate,
      paymentDesc,
      paymentGst,
      paymentIgst,
      subject,
    } = pdfData;
    console.error(partnerId, 'gstId', gstId);

    const gstNumber = gst.filter(e => {
      return e?.partnerGstId == parseInt(values.gstId) + 1;
    });
    const panNumber = gst.filter(e => {
      return e?.partnerGstId == parseInt(values.gstId) + 1;
    });
    const gstNumberString = (gstNumber[0]?.gstNumber || '').toString();
    const match = /\(([^)]+)\)/.exec(gstNumberString as string);
    const value = match ? match[1] : null;

    const dataforPdf = [
      companyName,
      invoiceNo,
      new Date(invoiceDate).toLocaleDateString(),
      paymentAmount,
      new Date(paymentDate).toLocaleDateString(),
      contactPersonName,
      companyAddress,
      partnerId?.toString(),
      companyGst,
      paymentCgst,
      paymentGst,
      paymentIgst,
      paymentDesc,
      subject,
      value,
      panNumber[0]?.panNumber,
      Accounts[0]?.accountNumber,
      Accounts[0]?.bankName,
      Accounts[0]?.ifscCode,
      Accounts[0]?.branchAddress,
      Accounts[0]?.accountHolder,
      invoiceDate,
      invoiceDate,
    ];
    const doc = new jsPDF();
    doc.addImage(PDFimage, 'JPEG', 25, 7, 50, 15);
    doc.setDrawColor(187, 31, 45);
    doc.setLineWidth(1.2);

    doc.line(10, 7, 10, 245);
    doc.setDrawColor(0, 31, 95);
    doc.line(10, 247, 10, 255);
    doc.setDrawColor(0, 108, 191);
    doc.line(10, 257, 10, 265);
    doc.setDrawColor(108, 122, 131);
    doc.line(10, 267, 10, 275);
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    const text = values.subject;
    const checkmaxWidth = 150;
    const lineHeight = 5;
    const startY = 88;
    const pageWidth = doc.internal.pageSize.getWidth();
    const splitText = doc.splitTextToSize(text, checkmaxWidth);

    // Calculate total height of wrapped text
    // const totalHeight = splitText.length * lineHeight;
    splitText.forEach((line: any, index: any) => {
      const textWidth = doc.getTextWidth(line);
      const xPosition = (pageWidth - textWidth) / 2;
      const yPosition = startY + index * lineHeight;
      doc.text(line, xPosition, yPosition);
    });

    doc.setFont('times', 'normal');
    doc.setFontSize(12);
    const formatedInvoiceDate = moment(values.invoiceDate, 'MM-D-YYYY').format(
      'MMM DD, YYYY'
    );
    doc.text(formatedInvoiceDate, 25, 38);
    doc.setFontSize(12);

    doc.setFontSize(12);
    doc.text(`Invoice no : ${dataforPdf[1]}`, 123, 38);
    doc.text(`${dataforPdf[5]}`, 25, 52);
    doc.text(`${dataforPdf[0]}`, 25, 58);
    const firstTextContent = `${dataforPdf[6]}`;
    const maxWidth = 70;
    doc.text(firstTextContent, 25, 63, { maxWidth: maxWidth });
    const textDimensions = doc.getTextDimensions(firstTextContent, {
      maxWidth: maxWidth,
    });
    const nextY = 63 + textDimensions.h;
    doc.text(`GST No. : ${dataforPdf[8]}`, 25, nextY + 2);
    let finalY = 0;
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(150);
    doc.text(`${desName}`, 25, 255);
    doc.setTextColor(150);
    doc.setFont('times', 'normal');
    doc.text(`Designated Partner`, 25, 260);
    const paymentdate = moment(values.paymentDate, 'MM-D-YYYY').format(
      'MMM DD, YYYY'
    );
    autoTable(doc, { html: '#my-table' });

    const tabl = [
      [`${paymentdate}`, `${values.description}`, `${values.amount}`],
    ];

    const rowsToMerge: any = [];

    if (values.cgst !== '' && values.cgst !== '0') {
      const percentValue = parseFloat(values.cgst);
      const cgstAmount = (parseFloat(values.amount) * percentValue) / 100;
      const cgstAmountString = cgstAmount.toFixed(2);
      tabl.push([`CGST (${values.cgst}%)`, '', `${cgstAmountString}`]);
      rowsToMerge.push(tabl.length - 1);
    }
    if (values.sgst !== '' && values.sgst !== '0') {
      const percentValue = parseFloat(values.sgst);
      const sgstAmount = (parseFloat(values.amount) * percentValue) / 100;
      const sgstAmountString = sgstAmount.toFixed(2);
      tabl.push([`SGST (${values.sgst}%)`, '', `${sgstAmountString}`]);
      rowsToMerge.push(tabl.length - 1);
    }
    if (values.igst !== '' && values.igst !== '0') {
      const percentValue = parseFloat(values.igst);
      const igstAmount = (parseFloat(values.amount) * percentValue) / 100;
      const igstAmountString = igstAmount.toFixed(2);
      tabl.push([`IGST (${values.igst}%)`, '', `${igstAmountString}`]);
      rowsToMerge.push(tabl.length - 1);
    }
    tabl.push(['', 'Total Amount Payable', `${totalPaybleAmount}`]);

    const headers = ['Date', 'Description', 'Amount (in Rs.)'];

    autoTable(doc, {
      head: [headers],
      body: tabl,
      columnStyles: {
        0: {
          halign: 'left',
          cellPadding: { top: 4, right: 4, bottom: 4, left: 4 },
        },
        1: {
          halign: 'left',
          cellPadding: { top: 4, right: 4, bottom: 4, left: 4 },
          minCellWidth: 85,
          cellWidth: 85,
        },
        2: {
          halign: 'left',
          cellPadding: { top: 4, right: 4, bottom: 4, left: 4 },
        },
      },
      headStyles: {
        fillColor: false,
        textColor: '#000000',
        fontStyle: 'normal',
      },
      startY: 97,
      margin: 23,
      theme: 'grid',
      styles: {
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0],
      },
      didDrawPage: data => {
        if (data.cursor) {
          finalY = data.cursor.y;
        }
      },
      didParseCell: function (data) {
        if (data.section === 'body' && data.row.index === tabl.length - 1) {
          data.cell.styles.fontStyle = 'bold';
        }
        if (
          data.section === 'body' &&
          (rowsToMerge.includes(data.row.index) || data.row.index === 1)
        ) {
          if (data.column.index === 0) {
            data.cell.colSpan = 2;
          }
          if (data.column.index === 1) {
            data.cell.styles.cellPadding = 0;
          }
        }
      },
    });

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFont('helvetica', 'bold');
    doc.text(`GST No. :`, 25, finalY + 18);
    doc.text(`PAN No. :`, 25, finalY + 24);
    doc.text(`Bank Detail for Electronic Transfer`, 25, finalY + 30);
    doc.text(`Name of Account Holder :`, 25, finalY + 36);
    doc.text(`Account No. :`, 25, finalY + 42);
    doc.text(`Name of bank :`, 25, finalY + 48);
    doc.text(`IFSC code :`, 25, finalY + 54);
    doc.text(`Branch address :`, 25, finalY + 60);
    doc.setFont('times', 'normal');
    doc.text(` ${dataforPdf[14]}`, 45, finalY + 18);
    doc.text(` ${dataforPdf[15]}`, 45, finalY + 24);
    doc.text(` ${dataforPdf[20]}`, 77, finalY + 36);
    doc.text(` ${dataforPdf[16]}`, 52, finalY + 42);
    doc.text(` ${dataforPdf[17]}`, 56, finalY + 48);
    doc.text(` ${dataforPdf[18]}`, 48, finalY + 54);
    doc.text(` ${dataforPdf[19]}`, 60, finalY + 60);
    const footerText =
      'Registered office: MU-9, Pitam Pura, New Delhi – 110088 | LLP IN: AAJ-6525 | Contact: +91 88269 90233';
    const footerX = 0;
    const footerY = doc.internal.pageSize.height - 10;
    const footerWidth = doc.internal.pageSize.width;
    const footerHeight = 10;
    doc.setFillColor(0, 108, 191);
    doc.rect(
      footerX,
      doc.internal.pageSize.height - 13,
      footerWidth,
      footerHeight,
      'F'
    );
    doc.setFillColor(197, 22, 29);
    doc.rect(footerX, footerY, footerWidth, footerHeight, 'F');
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(10);
    doc.text(footerText, footerX + 30, footerY + 7);

    setToggle(false);
    doc.save('Klaxon_Invoice.pdf');
    if (page === 'Dashboard') {
      if (ApprovePage !== 'ApprovePage') {
        try {
          const response = await sendMail({
            transactionId: data['transcId'],
            isEmail: false,
            ...forminitialValues,
          });
          if (response.success) {
            console.error('Mail sent successfully');
          }
        } catch (error) {
          console.error('An error occurred while sending the mail:', error);
        }
      }
    }
  };

  const classes = useStyles();
  console.error(classes);

  const edithandleChange = (e: any, formData?: string) => {
    const { name, value } = e.target;
    setforminitialValues({ ...forminitialValues, [name]: formData ?? value });
    console.error('editable value', forminitialValues);
  };

  const handleInvoicePdfDownload = () => {
    const {
      companyAddress,
      companyGst,
      companyName,
      contactPersonName,
      invoiceDate,
      invoiceNo,
      partnerId,
      paymentAmount,
      paymentCgst,
      paymentDate,
      paymentDesc,
      paymentGst,
      paymentIgst,
      subject,
    } = pdfData;

    // Filter GST and PAN details based on the gstId value
    const gstNumber = gst.filter(
      e => e?.partnerGstId == parseInt(values.gstId) + 1
    );
    const panNumber = gst.filter(
      e => e?.partnerGstId == parseInt(values.gstId) + 1
    );
    const gstNumberString = (gstNumber[0]?.gstNumber || '').toString();
    const match = /\(([^)]+)\)/.exec(gstNumberString);
    const value = match ? match[1] : null;

    // Data to be included in the PDF
    const dataforPdf = [
      companyName,
      invoiceNo,
      new Date(invoiceDate).toLocaleDateString(),
      paymentAmount,
      new Date(paymentDate).toLocaleDateString(),
      contactPersonName,
      companyAddress,
      partnerId?.toString(),
      companyGst,
      paymentCgst,
      paymentGst,
      paymentIgst,
      paymentDesc,
      subject,
      value,
      panNumber[0]?.panNumber,
      Accounts[0]?.accountNumber,
      Accounts[0]?.bankName,
      Accounts[0]?.ifscCode,
      Accounts[0]?.branchAddress,
      Accounts[0]?.accountHolder,
      invoiceDate,
      invoiceDate,
    ];

    const doc = new jsPDF();
    doc.addImage(PDFimage, 'JPEG', 25, 7, 50, 15);
    doc.setDrawColor(187, 31, 45);
    doc.setLineWidth(1.2);

    doc.line(10, 7, 10, 245);
    doc.setDrawColor(0, 31, 95);
    doc.line(10, 247, 10, 255);
    doc.setDrawColor(0, 108, 191);
    doc.line(10, 257, 10, 265);
    doc.setDrawColor(108, 122, 131);
    doc.line(10, 267, 10, 275);
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    const text = values.subject;
    const checkmaxWidth = 150;
    const lineHeight = 5;
    const startY = 88;
    const pageWidth = doc.internal.pageSize.getWidth();
    const splitText = doc.splitTextToSize(text, checkmaxWidth);

    // Calculate total height of wrapped text
    // const totalHeight = splitText.length * lineHeight;
    splitText.forEach((line: any, index: any) => {
      const textWidth = doc.getTextWidth(line);
      const xPosition = (pageWidth - textWidth) / 2;
      const yPosition = startY + index * lineHeight;
      doc.text(line, xPosition, yPosition);
    });

    doc.setFont('times', 'normal');
    doc.setFontSize(12);
    const formatedInvoiceDate = moment(values.invoiceDate, 'MM-D-YYYY').format(
      'MMM DD, YYYY'
    );
    doc.text(formatedInvoiceDate, 25, 38);
    doc.setFontSize(12);

    doc.setFontSize(12);
    doc.text(`Invoice no : ${dataforPdf[1]}`, 123, 38);
    doc.text(`${dataforPdf[5]}`, 25, 52);
    doc.text(`${dataforPdf[0]}`, 25, 58);
    const firstTextContent = `${dataforPdf[6]}`;
    const maxWidth = 70;
    doc.text(firstTextContent, 25, 63, { maxWidth: maxWidth });
    const textDimensions = doc.getTextDimensions(firstTextContent, {
      maxWidth: maxWidth,
    });
    const nextY = 63 + textDimensions.h;
    doc.text(`GST No. : ${dataforPdf[8]}`, 25, nextY + 2);
    let finalY = 0;
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(150);
    doc.text(`${desName}`, 25, 255);
    doc.setTextColor(150);
    doc.setFont('times', 'normal');
    doc.text(`Designated Partner`, 25, 260);
    const paymentdate = moment(values.paymentDate, 'MM-D-YYYY').format(
      'MMM DD, YYYY'
    );
    autoTable(doc, { html: '#my-table' });
    const tabl = [
      [`${paymentdate}`, `${values.description}`, `${values.amount}`],
    ];

    const rowsToMerge: any = [];
    if (values.cgst !== '' && values.cgst !== '0') {
      const percentValue = parseFloat(values.cgst);
      const cgstAmount = (parseFloat(values.amount) * percentValue) / 100;
      const cgstAmountString = cgstAmount.toFixed(2);
      tabl.push([`CGST (${values.cgst}%)`, '', `${cgstAmountString}`]);
      rowsToMerge.push(tabl.length - 1);
    }
    if (values.sgst !== '' && values.sgst !== '0') {
      const percentValue = parseFloat(values.sgst);
      const sgstAmount = (parseFloat(values.amount) * percentValue) / 100;
      const sgstAmountString = sgstAmount.toFixed(2);
      tabl.push([`SGST (${values.sgst}%)`, '', `${sgstAmountString}`]);
      rowsToMerge.push(tabl.length - 1);
    }
    if (values.igst !== '' && values.igst !== '0') {
      const percentValue = parseFloat(values.igst);
      const igstAmount = (parseFloat(values.amount) * percentValue) / 100;
      const igstAmountString = igstAmount.toFixed(2);
      tabl.push([`IGST (${values.igst}%)`, '', `${igstAmountString}`]);
      rowsToMerge.push(tabl.length - 1);
    }
    tabl.push(['', 'Total Amount Payable', `${totalPaybleAmount}`]);

    const headers = ['Date', 'Description', 'Amount (in Rs.)'];
    autoTable(doc, {
      head: [headers],
      body: tabl,
      columnStyles: {
        0: {
          halign: 'left',
          cellPadding: { top: 4, right: 4, bottom: 4, left: 4 },
        },
        1: {
          halign: 'left',
          cellPadding: { top: 4, right: 4, bottom: 4, left: 4 },
          minCellWidth: 85,
          cellWidth: 85,
        },
        2: {
          halign: 'left',
          cellPadding: { top: 4, right: 4, bottom: 4, left: 4 },
        },
      },
      headStyles: {
        fillColor: false,
        textColor: '#000000',
        fontStyle: 'normal',
      },
      startY: 97,
      margin: 23,
      theme: 'grid',
      styles: {
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0],
      },
      didDrawPage: data => {
        if (data.cursor) {
          finalY = data.cursor.y;
        }
      },
      didParseCell: function (data) {
        if (data.section === 'body' && data.row.index === tabl.length - 1) {
          data.cell.styles.fontStyle = 'bold';
        }
        if (
          data.section === 'body' &&
          (rowsToMerge.includes(data.row.index) || data.row.index === 1)
        ) {
          if (data.column.index === 0) {
            data.cell.colSpan = 2;
          }
          if (data.column.index === 1) {
            data.cell.styles.cellPadding = 0;
          }
        }
      },
    });
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.setFont('helvetica', 'bold');
    doc.text(`GST No. :`, 25, finalY + 18);
    doc.text(`PAN No. :`, 25, finalY + 24);
    doc.text(`Bank Detail for Electronic Transfer`, 25, finalY + 30);
    doc.text(`Name of Account Holder :`, 25, finalY + 36);
    doc.text(`Account No. :`, 25, finalY + 42);
    doc.text(`Name of bank :`, 25, finalY + 48);
    doc.text(`IFSC code :`, 25, finalY + 54);
    doc.text(`Branch address :`, 25, finalY + 60);
    doc.setFont('times', 'normal');
    doc.text(` ${dataforPdf[14]}`, 45, finalY + 18);
    doc.text(` ${dataforPdf[15]}`, 45, finalY + 24);
    doc.text(` ${dataforPdf[20]}`, 77, finalY + 36);
    doc.text(` ${dataforPdf[16]}`, 52, finalY + 42);
    doc.text(` ${dataforPdf[17]}`, 56, finalY + 48);
    doc.text(` ${dataforPdf[18]}`, 48, finalY + 54);
    doc.text(` ${dataforPdf[19]}`, 60, finalY + 60);
    const footerText =
      'Registered office: MU-9, Pitam Pura, New Delhi – 110088 | LLP IN: AAJ-6525 | Contact: +91 88269 90233';
    const footerX = 0;
    const footerY = doc.internal.pageSize.height - 10;
    const footerWidth = doc.internal.pageSize.width;
    const footerHeight = 10;
    doc.setFillColor(0, 108, 191);
    doc.rect(
      footerX,
      doc.internal.pageSize.height - 13,
      footerWidth,
      footerHeight,
      'F'
    );
    doc.setFillColor(197, 22, 29);
    doc.rect(footerX, footerY, footerWidth, footerHeight, 'F');
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(10);
    doc.text(footerText, footerX + 30, footerY + 7);

    console.error('datapdf', dataforPdf);
    // Convert the PDF to a base64 string
    const pdfBase64 = doc.output('blob');
    return pdfBase64;
  };

  const handleInvoiceMail = async () => {
    const pdfBlob = handleInvoicePdfDownload();
    const body = new FormData();
    body.append('document', pdfBlob);
    body.append('invoiceId', values?.invoiceId);
    const response = await sendMail(body);
    console.error('url', response);
    if (response.success) {
      console.error('Mail sent successfully');
      const url = response?.urlData?.url;
      const name = 'Invoice';
      const email = '';
      const subject = encodeURIComponent('Invoice');
      const body = encodeURIComponent(
        `\nPlease find your invoice at the following link:\n\n${name}:${url}\n\nThank you`
      );
      const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`;
      window.open(gmailUrl, '_blank');
    } else {
      console.error('Failed to send mail:', response.error);
    }
  };

  useEffect(() => {
    setSelected(values?.company || '');
  }, [values]);

  const handleChangeSelect = (e: any) => {
    setSelected(e.target.value);
    edithandleChange(e);
  };

  return (
    <Box style={{ width: '96%', margin: 'auto', paddingTop: 0.2 }}>
      {label == 'Raised' && role?.value() == ROLE.EXECUTIVE ? (
        <Alert
          severity="warning"
          style={{
            marginTop: 15,
            border: '1px solid #F2994A',
            borderRadius: 8,
            paddingBlock: 1,
          }}>
          <Text style={{ color: '#DD781E', fontSize: 14 }}>
            Awaiting approval from the admin for the transaction.
          </Text>
        </Alert>
      ) : null}
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          columns: 4,
        }}>
        <Box style={{ width: '31%', marginRight: '2%' }}>
          <Dpicker
            value={values.invoiceDate}
            label="Date"
            name="invoiceDate"
            id="invoiceDate"
            readOnly={approveReadOnly}
            disabled={disabledEdit}
            extra={{
              setDate: (e: any) => {
                setFieldValue('invoiceDate', e);
                setforminitialValues({
                  ...forminitialValues,
                  invoiceDate:
                    DateUtils.formatDate(
                      e,
                      'YYYY-MM-DD HH:MM:SS'
                    )?.toString() ?? '',
                });
              },
              placeholder: 'Choose date',
            }}
          />
        </Box>
        <Box style={{ width: '31%', marginRight: '2%' }}>
          <TextInput
            label="Invoice no."
            placeholder="Enter Invoice no."
            value={values.invoiceNumber}
            disabled={disabledEdit}
            name="invoiceNumber"
            id="invoiceNumber"
            readOnly={approveReadOnly}
            onChange={(e: any) => {
              handleChange(e);
              edithandleChange(e);
            }}
            onBlur={handleBlur}
          />
          {touched.invoiceNumber && errors.invoiceNumber ? (
            <ErrorText>{errors.invoiceNumber}</ErrorText>
          ) : null}
        </Box>
        <Box style={{ width: '31%', marginRight: '2%' }}>
          <TextInput
            label="Contact person name"
            placeholder="Enter Contact person name"
            value={values.contactPersonName}
            disabled={disabledEdit}
            name="contactPersonName"
            id="contactPersonName"
            readOnly={approveReadOnly}
            onChange={(e: any) => {
              handleChange(e);
              edithandleChange(e);
            }}
            onBlur={handleBlur}
          />
          {touched.contactPersonName && errors.contactPersonName ? (
            <ErrorText>{errors.contactPersonName}</ErrorText>
          ) : null}
        </Box>
        <Box style={{ width: '31%', marginRight: '2%' }}>
          <Text style={{ fontSize: 13 }}>Comapny Name</Text>
          <FormControl sx={{ width: '100%', height: 45 }}>
            <Select
              name="companyName"
              readOnly={approveReadOnly}
              disabled={disabledEdit}
              defaultValue={values?.company}
              displayEmpty
              value={selected}
              onChange={handleChangeSelect}
              sx={{ height: 40, fontSize: 13 }}>
              <MenuItem sx={{ fontSize: 13 }} value={values.company}>
                {values.company}
              </MenuItem>
              <MenuItem sx={{ fontSize: 13 }} value={values.spv}>
                {values.spv}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box style={{ width: '31%', marginRight: '2%' }}>
          <TextInput
            label="Company GST no."
            placeholder="Enter Company GST no."
            value={values.companygst}
            disabled={disabledEdit}
            name="companygst"
            id="companygst"
            readOnly={approveReadOnly}
            onChange={(e: any) => {
              handleChange(e);
              edithandleChange(e);
            }}
            onBlur={handleBlur}
          />
          {touched.companygst && errors.companygst ? (
            <ErrorText>{errors.companygst}</ErrorText>
          ) : null}
        </Box>
      </Box>

      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={6} sm={6} lg={6}>
          <SubjectInput
            label="Address of the Company"
            style={{ width: '100%' }}
            placeholder="Enter companyAddress here"
            name="companyAddress"
            id="companyAddress"
            readOnly={approveReadOnly}
            disabled={disabledEdit}
            value={values.companyAddress}
            onChange={(e: any) => {
              handleChange(e);
              edithandleChange(e);
            }}
            onBlur={handleBlur}
          />
          {touched.companyAddress && errors.companyAddress ? (
            <ErrorText>{errors.companyAddress}</ErrorText>
          ) : null}
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <SubjectInput
            label="Subject"
            style={{ width: '100%' }}
            readOnly={approveReadOnly}
            disabled={disabledEdit}
            placeholder="Enter subject here"
            name="subject"
            id="subject"
            value={values.subject}
            onChange={(e: any) => {
              handleChange(e);
              edithandleChange(e);
            }}
            onBlur={handleBlur}
          />
          {touched.subject && errors.subject ? (
            <ErrorText>{errors.subject}</ErrorText>
          ) : null}
        </Grid>
      </Grid>
      <Box
        style={{
          border: '1px solid #E0E0E0',
          borderRadius: 1,
          // marginTop: 2,
          marginBottom: 2,
        }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #E0E0E0',
            background: '#FCFCFD',
          }}>
          <Box
            style={{
              width: '20%',
              borderRight: '1px solid #E0E0E0',
              padding: '5px 20px',
            }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: 'black',
                fontWeight: 500,
              }}>
              Date
            </Text>
          </Box>
          <Box
            style={{
              width: '50%',
              borderRight: '1px solid #E0E0E0',
              padding: '5px 20px',
            }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: 'black',
                fontWeight: 500,
              }}>
              Description
            </Text>
          </Box>
          <Box style={{ width: '20%', padding: '5px 20px' }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: 'black',
                fontWeight: 500,
              }}>
              Amount in ₹
            </Text>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '5px 20px 14px',
            borderBottom: '1px solid #E0E0E0',
          }}>
          <Box style={{ width: '20%' }}>
            <Dpicker
              value={values.paymentDate}
              name="paymentDate"
              id="paymentDate"
              readOnly={approveReadOnly}
              disabled={disabledEdit}
              extra={{
                setDate: (e: any) => {
                  setFieldValue('paymentDate', e);
                  setforminitialValues({
                    ...forminitialValues,
                    paymentDate:
                      DateUtils.formatDate(
                        e,
                        'YYYY-MM-DD HH:MM:SS'
                      )?.toString() ?? '',
                  });
                },
                placeholder: 'Choose date',
              }}
            />
            {touched.paymentDate && errors.paymentDate ? (
              <ErrorText>{errors.paymentDate}</ErrorText>
            ) : null}
          </Box>
          <Box style={{ width: '50%' }}>
            <SubjectInput
              placeholder="Enter description"
              value={values.description}
              name="description"
              id="description"
              readOnly={approveReadOnly}
              disabled={disabledEdit}
              onChange={(e: any) => {
                handleChange(e);
                edithandleChange(e);
              }}
              onBlur={handleBlur}
              //
              style={{ width: '100%' }}
            />
            {touched.description && errors.description ? (
              <ErrorText>{errors.description}</ErrorText>
            ) : null}
          </Box>
          <Box style={{ width: '20%' }}>
            <TextInput
              placeholder="Enter Amount"
              value={values.amount}
              name="amount"
              id="amount"
              onInput={(e: any) => {
                e.target.value = e.target.value
                  .trim()
                  .replace(/^[/d-]|[-+]-|[^0-9-]+|^[\d]+[-][\d]+[-+]/, '');
              }}
              readOnly={approveReadOnly}
              disabled={disabledEdit}
              onChange={(e: any) => {
                handleChange(e);
                setforminitialValues({
                  ...forminitialValues,
                  paymentAmount: e.target.value,
                });
              }}
              onBlur={handleBlur}
            />
            {touched.amount && errors.amount ? (
              <ErrorText>{errors.amount}</ErrorText>
            ) : null}
          </Box>
        </Box>

        <Grid
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 20px',
            borderRadius: 1,
            gap: 20,
          }}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '2px',
            }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: 'black',
                fontWeight: 500,
              }}>
              CGST
            </Text>
            <Box>
              <TextInput
                placeholder="Enter %"
                value={values.cgst}
                readOnly={approveReadOnly}
                disabled={disabledEdit}
                name="cgst"
                onBlur={handleBlur}
                onInput={(e: any) => {
                  e.target.value = e.target.value
                    .trim()
                    .replace(/^[/d-]|[-+]-|[^0-9-]+|^[\d]+[-][\d]+[-+]/, '');
                }}
                onChange={(e: any) => {
                  handleChange(e);
                  edithandleChange(e);
                  setFieldValue('cgst', e.target.value);
                }}
              />

              {touched.cgst && errors.cgst ? (
                <ErrorText>{errors.cgst}</ErrorText>
              ) : null}
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '2px',
            }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: 'black',
                fontWeight: 500,
              }}>
              SGST
            </Text>
            <Box>
              <TextInput
                placeholder="Enter %"
                value={values.sgst}
                name="sgst"
                onBlur={handleBlur}
                readOnly={approveReadOnly}
                disabled={disabledEdit}
                onInput={(e: any) => {
                  e.target.value = e.target.value
                    .trim()
                    .replace(/^[/d-]|[-+]-|[^0-9-]+|^[\d]+[-][\d]+[-+]/, '');
                }}
                onChange={(e: any) => {
                  handleChange(e);
                  edithandleChange(e);
                  setFieldValue('sgst', e.target.value);
                }}
              />
              {touched.sgst && errors.sgst ? (
                <ErrorText>{errors.sgst}</ErrorText>
              ) : null}
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '2px',
            }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: 'black',
                fontWeight: 500,
              }}>
              IGST
            </Text>
            <Box>
              <TextInput
                placeholder="Enter %"
                value={values.igst}
                onInput={(e: any) => {
                  e.target.value = e.target.value
                    .trim()
                    .replace(/^[/d-]|[-+]-|[^0-9-]+|^[\d]+[-][\d]+[-+]/, '');
                }}
                readOnly={approveReadOnly}
                disabled={disabledEdit}
                onChange={(e: any) => {
                  handleChange(e);
                  edithandleChange(e);
                  setFieldValue('igst', e.target.value);
                  setforminitialValues({
                    ...forminitialValues,
                    paymentCgst: parseInt(values.cgst),
                    paymetGst: parseInt(values.sgst),
                    paymentIgst: values.igst,
                  });
                }}
                onBlur={handleBlur}
                name="igst"
                id="igst"
              />
              {touched.igst && errors.igst ? (
                <ErrorText>{errors.igst}</ErrorText>
              ) : null}
            </Box>
          </Box>
        </Grid>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#FCFCFD',
            padding: '5px 20px',
            background: '#FCFCFD',
          }}>
          <Box>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: 'black',
                fontWeight: 500,
              }}>
              Total Payable Amount
            </Text>
          </Box>

          <Box style={{ width: '20%' }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 13,
                color: 'black',
                fontWeight: 500,
              }}>
              ₹ {totalPaybleAmount}
            </Text>
          </Box>
        </Box>
      </Box>
      <RadioWithLabel
        label="Select GST no."
        disabled={disabledEdit || approveReadOnly}
        extra={{
          Selected: values.gstId,
          setSelected: (e: any) => {
            setFieldValue('gstId', e);
            setFieldValue('pan', gst[e]['panNumber'] ?? '');
            setforminitialValues({
              ...forminitialValues,
              gstId: parseInt(e) + 1,
            });
          },
          options: gst.map((e, i) => ({
            id: i,
            name: e['gstNumber'] ?? '',
          })),
        }}
      />
      {touched.pan && errors.pan ? <ErrorText>{errors.pan}</ErrorText> : null}
      <TextInput
        label="PAN Number"
        placeholder=""
        style={{ width: '50%' }}
        value={values.pan}
        name="pan"
        id="pan"
        readOnly={approveReadOnly}
      />
      <Text
        style={{
          fontSize: 13,
          fontWeight: 600,
          marginBlock: 20,
          color: 'black',
        }}>
        Bank Details for electronic Transfer
      </Text>
      <RadioWithLabel
        label="Designated partner"
        disabled={disabledEdit || approveReadOnly}
        value={values.partnerId}
        name="partnerId"
        extra={{
          Selected: values.partnerId,
          setSelected: (e: any) => {
            setFieldValue('partnerId', e);
            setforminitialValues({
              ...forminitialValues,
              partnerId: parseInt(e) + 1,
            });
            setsetIndexOfDesignatedPart(e);
          },
          options: partnerOptions,
        }}
      />
      {touched.partnerId && errors.partnerId ? (
        <ErrorText>{errors.partnerId}</ErrorText>
      ) : null}

      <TextInput
        label="Name of Account Holder"
        placeholder="Klaxon Advisory Partners LLP"
        name="holderName"
        id="holderName"
        readOnly={true}
        // disabled={disabledEdit}
        value={values.holderName}
      />
      {touched.accountId && errors.accountId ? (
        <ErrorText>{errors.accountId}</ErrorText>
      ) : null}
      <RadioWithBox
        style={{ marginBottom: 5 }}
        label="Select Bank Account"
        disabled={disabledEdit}
        value={values.accountId}
        name="accountId"
        extra={{
          disabledEdit: disabledEdit || approveReadOnly,
          Selected: 0,
          setSelected: (e: any) => {
            setFieldValue('accountId', Accounts[e]?.accountId);
            setforminitialValues({
              ...forminitialValues,
              accountId: Accounts[e]?.accountId,
            });
          },
          options: Accounts,
        }}
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bottom: 10,
          position: 'absolute',
          width: '50%',
          backgroundColor: 'white',
          paddingTop: 1,
        }}>
        {label == 'Approved' ? (
          <>
            <Button
              extra={{ variant: 'contained' }}
              onClick={handlePdfDownload}
              icons={<DownloadForOfflineIcon />}
              style={{ width: '48%', marginLeft: '2%' }}>
              Download
            </Button>
            <Button
              extra={{
                variant: 'contained',
                //  disablebutton: disabledEdit ? false : true,
              }}
              onClick={handleInvoiceMail}
              style={{ width: '48%', marginLeft: '2%' }}>
              Send via mail
            </Button>
          </>
        ) : null}
      </Box>
      {label == 'Initiated' || label == 'Hold' || label == 'Raised' ? (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bottom: 10,
            position: 'absolute',
            width: '80%',
            backgroundColor: 'white',
            paddingTop: 1,
          }}>
          {role?.value() == ROLE.ADMIN && (
            <>
              <Button
                extra={{ variant: 'contained' }}
                onClick={() => {
                  handleSubmit();
                }}
                style={{ width: '48%', marginLeft: '2%' }}>
                {disabledEdit ? 'Approve' : 'Approve & save'}
              </Button>

              <Button
                extra={{
                  variant: 'contained',
                  disablebutton: disabledEdit ? false : true,
                }}
                onClick={() => setReadOnly(!disabledEdit)}
                style={{ width: '48%', marginLeft: '2%' }}>
                Edit
              </Button>
              <Button
                extra={{
                  variant: 'contained',
                  disablebutton: disabledEdit ? false : true,
                }}
                onClick={handleInvoiceMail}
                style={{ width: '48%', marginLeft: '2%' }}>
                Send via mail
              </Button>
            </>
          )}
          <Button
            extra={{
              variant: 'outlined',
              disablebutton: disabledEdit ? false : true,
            }}
            onClick={handlePdfDownload}
            icons={<DownloadForOfflineIcon />}
            style={{ width: '48%', marginLeft: '2%' }}>
            Download
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}

export default KlaxonInvoiceAdmin;
