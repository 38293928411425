import React, { useContext, useState, useEffect } from 'react';
import Box from '../components/single_component/Box';
import Button from '../components/single_component/Button';
import Text from '../components/single_component/Text';
import TextInput from '../components/common_component/TextInput';
import { Grid } from '@mui/material';
import ProfileContext from '../context/ProfileContext';
import AuthContext from '../context/AuthContext';
import moment from 'moment';
import { toast } from 'react-toastify';
import StringUtils from '../utils/stringUtil';
import UploadInput from '../components/single_component/UploadInput';
import DeleteIcon from '@mui/icons-material/Delete';
import Image from '../components/single_component/Image';
import { useNavigate } from 'react-router-dom';

function SaveEditDetailContainer() {
  const navigate = useNavigate();
  const [isChange, setIsChange] = useState(true);
  const [numcheck, setnumcheck] = useState(true);
  const { ProfileData, updateProfile, deleteProfileImage, getProfile } =
    useContext(ProfileContext);

  const [loading, setLoading] = useState(false);

  const { user } = ProfileData || {};

  const { masterData } = useContext(AuthContext);

  const locations = masterData?.value()?.locations;

  const [initialValues, setinitialValues] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    cityId: '',
  });
  const handleAddProfile = async (file: any) => {
    setIsChange(false);
    const body = new FormData();
    body.append('profileImg', file);
    if (file) {
      try {
        const response = await updateProfile(`/profile/update`, body);
        if (response.success) {
          getProfile(`/profile/get`);
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
        setIsChange(true);
      } catch (error) {
        console.error('Error on Adding profile', error);
      }
    }
  };
  const handleDeleteProfile = async () => {
    if (user?.profileImg) {
      try {
        const response = await deleteProfileImage();
        if (response.success) {
          toast.success(response.message);
          getProfile(`/profile/get`);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error('Error on Adding profile', error);
      }
    }
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber' && value.length > 10) setnumcheck(false);
    else setnumcheck(true);
    setinitialValues({
      ...initialValues,
      [name]: value,
    });
  };
  const handleSubmit = async () => {
    if (numcheck) {
      setLoading(true);
      const response = await updateProfile(`/profile/update`, {
        name: initialValues?.name,
        phoneNumber: initialValues?.phoneNumber,
        email: initialValues?.email,
        cityId: locations?.find(
          (location: any) => location.name === initialValues?.cityId
        )?.id,
      });
      setLoading(false);
      if (response.success) {
        toast.success(response.message);
        getProfile(`/profile/get`);
        navigate('/profile');
      } else {
        toast.error(response.message);
      }
    }
  };

  useEffect(() => {
    setinitialValues({
      name: StringUtils.capitalizeFirstLetter(user?.name ?? ''),
      phoneNumber: user?.phoneNumber,
      email: user?.email,
      cityId: user?.city?.name,
    });
  }, [user]);
  return (
    <Box
      style={{
        backgroundColor: '#F2FAFE',
        paddingTop: 6,
        paddingBottom: 5,
        background: '#F2FAFE',
        minHeight: '90vh',
      }}>
      <Box style={{ width: '85%', margin: 'auto' }}>
        <Box
          style={{
            boxShadow: ' 0px 1px 3px 0px #1018281A',
            border: 1,
            borderRadius: 1,
            borderColor: '#E7E7E7',
            background: '#FFFFFF',
          }}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingInline: 6,
              paddingBlock: 2,
              borderBottom: 1,
              borderColor: '#E7E7E7',
            }}>
            <Text style={{ fontSize: 16, fontWeight: 600 }}>
              {StringUtils.capitalizeFirstLetter(user?.name)}
            </Text>
          </Box>
          <Box
            style={{
              display: 'flex',
              gap: '60px',
              padding: '32px 40px',
            }}>
            <Box>
              {/* <Image
            alt="DK"
            style={{
              height: 236,
              minWidth: 236,
              background: '#E5F7FF',
              boxShadow: '0px 2px 2px 0px #1049601A',
              borderRadius: '8px',
              marginTop: 12,
            }}
          /> */}
              <Box
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 2,
                  backgroundColor: '#E5F7FF',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}>
                {isChange && (
                  <div style={{ position: 'absolute', right: 5, top: 5 }}>
                    <UploadInput
                      name={'documentFile'}
                      id={'documentFile'}
                      extra={{
                        padding: '1px',
                        isModify: true,
                        onchange: (e: any) => {
                          handleAddProfile(e[0]);
                        },
                        style: { marginTop: '-6px' },
                      }}
                    />
                  </div>
                )}
                <DeleteIcon
                  onClick={() => handleDeleteProfile()}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 40,
                    color: '#104960',
                  }}
                />
                {user?.profileImg ? (
                  <Image
                    alt={user?.name}
                    src={user?.profileImg}
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  />
                ) : (
                  <Text
                    style={{
                      fontSize: 64,
                      color: '#104960',
                      fontWeight: 600,
                    }}>
                    {StringUtils.getInitials(user?.name ?? '')}
                  </Text>
                )}
              </Box>
            </Box>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Role"
                  placeholder="Admin"
                  value={user?.roleId == 1 ? 'Admin' : 'Sales-Executive'}
                  disabled={true}
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Joining Date"
                  placeholder="Admin"
                  readOnly={true}
                  disabled={true}
                  value={moment(user?.joiningDate).format('DD-MM-YYYY')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Name"
                  name="name"
                  id="name"
                  onChange={handleChange}
                  placeholder="Enter Name"
                  value={initialValues?.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Location"
                  name="location"
                  id="location"
                  readOnly={true}
                  disabled={true}
                  onChange={handleChange}
                  placeholder="Enter Name"
                  value={user?.city?.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Email ID"
                  placeholder="Enter Email ID"
                  value={initialValues?.email}
                  readOnly={true}
                  disabled={true}
                  name="email"
                  id="email"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Phone Number"
                  placeholder="Enter Phone Number"
                  value={initialValues?.phoneNumber}
                  name="phoneNumber"
                  id="phoneNumber"
                  onChange={handleChange}
                />
                {!numcheck && (
                  <Text style={{ color: 'red', fontSize: '10px' }}>
                    Phone No. should be of 10 digits
                  </Text>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  extra={{
                    variant: 'contained',
                    color: 'primary',
                    btnDisableLoader: loading,
                  }}
                  style={{
                    borderRadius: 1,
                    width: '100%',
                    padding: 1.5,
                    marginTop: 2,
                  }}
                  onClick={handleSubmit}>
                  <Text
                    style={{ color: 'white', fontWeight: 500, fontSize: 13 }}>
                    Save
                  </Text>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SaveEditDetailContainer;
