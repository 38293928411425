import React from 'react';
import { Navigate } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import { ROLE, ROUTES, RouteObject } from '../types/routeObject';
import Dashboard from '../pages/Dashboard';
// import MyTeam from '../pages/MyTeam';
import Investors from '../pages/Investors';
import AdminRoute from './admin';
import ToDo from '../pages/ToDo';
import { NotFound } from '../pages/NotFound';
import MyTeam from '../pages/MyTeam';
import ActiveTransaction from '../pages/ActiveTransaction';
import CompletedTransactions from '../pages/CompletedTransactions';
import EditProfile from '../pages/EditProfile';
import SaveEditDetailPage from '../pages/SaveEditDetail';
import SalesExecutive from '../pages/SalesExecutive';
import InvestorDetails from '../pages/InvestorDetails';
import SalesDashboard from '../SalesExecutive/Pages/SalesDashboard';
import SalesDeveloperGroup from '../SalesExecutive/Pages/SalesDeveloperGroup';
import SalesTodo from '../SalesExecutive/Pages/SalesTodo';
import ViewDeveloperGroup from '../SalesExecutive/Pages/ViewDeveloperGroup';
import ViewSpv from '../SalesExecutive/Pages/ViewSpv';
import ViewProject from '../SalesExecutive/Pages/ViewProject';
import AddProject from '../SalesExecutive/Pages/AddProject';
import ExecutiveRoute from './executive';
import EditProject from '../SalesExecutive/Pages/EditProject';
import SalesDeveloperLayout from '../SalesExecutive/Pages/SalesDeveloperLayout';

export default function AuthRoutes(isAuthenticated: boolean, role: number) {
  const routeObject: RouteObject = {
    authenticated: isAuthenticated,
    component: role == ROLE.ADMIN ? <Dashboard /> : <SalesDashboard />,
    path: role == ROLE.ADMIN ? ROUTES.HOME : ROUTES.HOME,
    role: role,
    redirectPath: ROUTES.LOGIN,
  };
  return [
    {
      path: '/',
      element: isAuthenticated ? <Layout /> : <Navigate to={ROUTES.LOGIN} />,
      children:
        role == ROLE.PUBLIC
          ? [{ path: ROUTES.HOME, element: <NotFound /> }]
          : role == ROLE.ADMIN
            ? [
                { path: ROUTES.HOME, element: <Dashboard /> },
                { path: ROUTES.TODO, element: <ToDo /> },
                AdminRoute({
                  ...routeObject,
                  path: ROUTES.ACTIVE,
                  component: <ActiveTransaction />,
                }),
                AdminRoute({
                  ...routeObject,
                  path: ROUTES.COMPLETED,
                  component: <CompletedTransactions />,
                }),
                AdminRoute({
                  ...routeObject,
                  path: ROUTES.SALESEXECUTIVE,
                  handle: {
                    crumb: (data: Record<string, string>) => (
                      <span>
                        {(data['executiveName'] ?? 'Sales Executive Details') +
                          ' (Sales Executive)'}
                      </span>
                    ),
                  },
                  component: <SalesExecutive />,
                }),
                AdminRoute({
                  ...routeObject,
                  path: `${ROUTES.DEVELOPER_GROUP}/${ROUTES.VIEW_DEVELOPER_GROUP}`,
                  handle: {
                    crumb: (data: Record<string, string>) => (
                      <span>{data['groupName'] ?? 'Developer Group'}</span>
                    ),
                  },
                  component: <ViewDeveloperGroup />,
                }),
                AdminRoute({
                  ...routeObject,
                  path: `${ROUTES.DEVELOPER_GROUP}/${ROUTES.VIEW_DEVELOPER_GROUP}/${ROUTES.VIEW_SPV}`,
                  handle: {
                    crumb: (data: Record<string, string>) => (
                      <span>{data['spvName'] ?? 'SPVs'}</span>
                    ),
                  },
                  component: <ViewSpv />,
                }),
                AdminRoute({
                  ...routeObject,
                  path: `${ROUTES.DEVELOPER_GROUP}/${ROUTES.VIEW_DEVELOPER_GROUP}/${ROUTES.VIEW_SPV}/${ROUTES.VIEW_PROJECT}`,
                  handle: {
                    crumb: (data: Record<string, string>) => (
                      <span>{data['projectName'] ?? 'Project Details'}</span>
                    ),
                  },
                  component: <ViewProject />,
                }),
                AdminRoute({
                  ...routeObject,
                  path: ROUTES.ADD_PROJECT,
                  component: <AddProject />,
                }),
                AdminRoute({
                  ...routeObject,
                  path: ROUTES.EDIT_PROJECT,
                  component: <EditProject />,
                }),
                AdminRoute({
                  ...routeObject,
                  path: ROUTES.INVESTOR_DETAILS,
                  handle: {
                    crumb: (data: Record<string, string>) => (
                      <span>
                        {(data['investorName'] ?? 'Investor Details') +
                          ' (Investor)'}
                      </span>
                    ),
                  },
                  component: <InvestorDetails />,
                }),
                AdminRoute({
                  ...routeObject,
                  path: ROUTES.INVERSTORS,
                  component: <Investors />,
                }),
                AdminRoute({
                  ...routeObject,
                  path: ROUTES.MYTEAM,
                  component: <MyTeam />,
                }),
                { path: ROUTES.PROFILE, element: <EditProfile /> },
                { path: ROUTES.EDITPROFILE, element: <SaveEditDetailPage /> },
              ]
            : [
                { path: ROUTES.HOME, element: <SalesDashboard /> },
                ExecutiveRoute({
                  ...routeObject,
                  path: ROUTES.DEVELOPER_GROUP,
                  component: <SalesDeveloperGroup />,
                }),
                ExecutiveRoute({
                  ...routeObject,
                  path: ROUTES.DEVELOPER_GROUP,
                  component: <SalesDeveloperLayout />,
                  children: [
                    ExecutiveRoute({
                      ...routeObject,
                      path: `${ROUTES.DEVELOPER_GROUP}/${ROUTES.VIEW_DEVELOPER_GROUP}`,
                      handle: {
                        crumb: (data: Record<string, string>) => (
                          <span>{data['groupName'] ?? 'Developer Group'}</span>
                        ),
                      },
                      component: <ViewDeveloperGroup />,
                    }),
                    ExecutiveRoute({
                      ...routeObject,
                      path: `${ROUTES.DEVELOPER_GROUP}/${ROUTES.VIEW_DEVELOPER_GROUP}/${ROUTES.VIEW_SPV}`,
                      handle: {
                        crumb: (data: Record<string, string>) => (
                          <span>{data['spvName'] ?? 'SPVs'}</span>
                        ),
                      },
                      component: <ViewSpv />,
                    }),
                    ExecutiveRoute({
                      ...routeObject,
                      path: `${ROUTES.DEVELOPER_GROUP}/${ROUTES.VIEW_DEVELOPER_GROUP}/${ROUTES.VIEW_SPV}/${ROUTES.VIEW_PROJECT}`,
                      handle: {
                        crumb: (data: Record<string, string>) => (
                          <span>
                            {data['projectName'] ?? 'Project Details'}
                          </span>
                        ),
                      },
                      component: <ViewProject />,
                    }),
                  ],
                }),
                ExecutiveRoute({
                  ...routeObject,
                  path: ROUTES.ADD_PROJECT,
                  component: <AddProject />,
                }),
                ExecutiveRoute({
                  ...routeObject,
                  path: ROUTES.EDIT_PROJECT,
                  component: <EditProject />,
                }),
                { path: ROUTES.TODO, element: <SalesTodo /> },
                { path: ROUTES.PROFILE, element: <EditProfile /> },
                { path: ROUTES.EDITPROFILE, element: <SaveEditDetailPage /> },
              ],
    },
  ];
}
