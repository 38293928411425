import React, { useContext, useEffect, useState } from 'react';
import Box from '../../components/single_component/Box';
import SalesToDolist from '../../components/Sales Executive/SalesToDoList';
import TodoContext from '../../context/TodoContext';
import FilterList from '@mui/icons-material/FilterList';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SalesTodoAddTask from '../../components/Forms/SalesTodoAddTask';
import Text from '../../components/single_component/Text';
import Sidebar from '../../components/common_component/Sidebar';
import Button from '../../components/single_component/Button';
import TodoFilter from '../../components/Filter/SalesTodoFilter';
import Loader from '../../components/single_component/Loader';
import NotfoundPage from '../../components/single_component/NotfoundPage';
import { MenuItem, Pagination, Select } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import ProfileContext from '../../context/ProfileContext';
import AuthContext from '../../context/AuthContext';
import DateUtils from '../../utils/dateUtils';

function SaleToDoCon() {
  const { TodoData, getTodo, loading, todoPagModule } = useContext(TodoContext);
  const [Toggle, setToggle] = useState(false);
  const [FilterSidebar, setFilterSidebar] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [next, setNext] = useState();
  const [previous, setPrevious] = useState();
  const [currPage, setcurrPage] = useState(1);
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const { masterData } = useContext(AuthContext);
  const data = masterData?.value();
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);
  const [selectedChip, setSelectedChip] = React.useState(-1);
  const { ProfileData } = useContext(ProfileContext);
  const { user } = ProfileData || {};
  const userId = user?.userId;
  const priority = data?.priorities[selectedChip];
  const startDate = DateUtils.formatDate(StartDate, 'YYYY-MM-DD');
  const endDate = DateUtils.formatDate(EndDate, 'YYYY-MM-DD');
  const assignedTo = userId;

  useEffect(() => {
    getTodo(currPage, rowsPerPage, {
      ...(assignedTo && { assignedTo }),
      ...(startDate && { startDate: startDate }),
      ...(endDate && { endDate: endDate }),
      ...(priority && { priority: priority }),
    });
  }, [currPage, rowsPerPage]);

  useEffect(() => {
    setPage(todoPagModule?.data?.total_pages);
    setNext(todoPagModule?.data?.next);
    setPrevious(todoPagModule?.data?.prev);
  }, [todoPagModule]);

  const options = [
    {
      id: 2,
      name: 10,
    },
    {
      id: 3,
      name: 20,
    },
    {
      id: 4,
      name: 30,
    },
  ];

  const onCancel = () => {
    setFilterSidebar(false);
  };
  const handleChipClick = (index: number) => {
    setSelectedChip(index);
  };
  const handleFilter = async () => {
    setbtnDisableLoader(true);
    const assignedTo = userId;
    try {
      const responce = await getTodo(currPage, rowsPerPage, {
        ...(assignedTo && { assignedTo }),
        ...(startDate?.charAt(0) == '2' && { startDate }),
        ...(endDate?.charAt(0) == '2' && { endDate }),
        ...(priority && { priority: priority }),
      });
      if (responce.success) {
        setbtnDisableLoader(false);
        setFilterSidebar(false);
        console.error('happay');
      } else {
        setbtnDisableLoader(false);
      }
    } catch (e) {
      setbtnDisableLoader(false);
      console.error(e);
    }
  };
  return (
    <Box
      style={{
        padding: '56px 100px',
        background: '#F2FAFE',
      }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          padding: 3,
          border: '1px solid #E0E0E0',
          boxShadow: ' 0px 2px 10px 0px #1049601A',
          borderRadius: 1,
          backgroundColor: 'white',
        }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 1.5,
              marginBottom: 1.5,
              justifyContent: 'center',
            }}>
            <Text style={{ fontWeight: 'bold' }}>To-Do’s</Text>
            <Text style={{ fontSize: 14, fontWeight: 400, color: '#667085' }}>
              Here you can add and view all your tasks.
            </Text>
          </Box>

          <Sidebar
            label="Add Task"
            extra={{
              anchor: 'right',
              Toggle,
              setToggle,
              description: 'Add your task here for any work',
              width: 400,
            }}>
            <SalesTodoAddTask
              extra={{
                setToggle,
              }}
            />
          </Sidebar>

          <Sidebar
            label="Filter"
            extra={{
              anchor: 'right',
              Toggle: FilterSidebar,
              setToggle: setFilterSidebar,
              description: 'Filter the data as per your need',
              width: 400,
            }}>
            <TodoFilter
              extra={{
                setSelectedChip,
                selectedChip,
                handleFilter,
                handleChipClick,
                onCancel,
                btnDisableLoader,
                EndDate,
                setEndDate,
                StartDate,
                setStartDate,
                setToggle: setFilterSidebar,
              }}
            />
          </Sidebar>

          <Box style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Button
              onClick={() => setFilterSidebar(!FilterSidebar)}
              extra={{
                variant: 'outlined',
                color: 'tertiary',
              }}
              style={{ padding: '8px 24px', borderRadius: 1.5 }}
              icons={<FilterList />}>
              <Text style={{ color: 'black', fontSize: 13 }}>Add Filters</Text>
            </Button>

            <Button
              onClick={() => setToggle(!Toggle)}
              style={{ padding: '8px 24px' }}
              extra={{
                variant: 'contained',
              }}
              icons={<AddOutlinedIcon />}>
              <Text style={{ color: 'white', fontSize: 13 }}>Add Task</Text>
            </Button>
          </Box>
        </Box>

        {/* <Box style={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {TodoData &&
            TodoData.map((value: any, index: number) => {
              return <SalesToDolist extra={{ value }} key={index} />;
            })}
        </Box> */}
        <Box style={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {loading ? (
            <Loader />
          ) : TodoData && TodoData?.length > 0 ? (
            TodoData?.map((value: any, index: number) => (
              <SalesToDolist extra={{ value }} key={index} />
            ))
          ) : (
            <NotfoundPage /> // Show Not Found page if TodoData is empty
          )}
          <Box
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
              paddingInline: 3,
            }}>
            {previous != null ? (
              <Button
                icons={<WestIcon style={{ fontSize: 14, color: 'black' }} />}
                onClick={() =>
                  setcurrPage(currPage == 0 ? currPage : currPage - 1)
                }
                extra={{
                  variant: 'outlined',
                  color: 'tertiary',
                }}>
                <Text style={{ fontSize: 12, fontWeight: 500, color: 'black' }}>
                  Previous
                </Text>
              </Button>
            ) : (
              <Box></Box>
            )}

            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <Pagination
                count={page}
                page={currPage}
                shape="rounded"
                color="secondary"
                variant="text"
                sx={{
                  '& .MuiPaginationItem-page.Mui-selected': {
                    color: '#FFFFFF',
                    backgroundColor: '#104960 !important',
                  },
                }}
                hidePrevButton
                hideNextButton
                onChange={(e, page) => {
                  setcurrPage(page);
                }}
              />
              <Select
                value={rowsPerPage}
                sx={{ width: 100, height: 35, marginLeft: 5 }}
                defaultValue={options[0].name}
                onChange={(e: any) => {
                  setrowsPerPage(e.target.value);
                }}>
                {options.map(
                  (value: any, index: React.Key | null | undefined) => {
                    return (
                      <MenuItem value={value.name} key={index}>
                        <Text style={{ fontSize: 13, fontWeight: 'normal' }}>
                          {value.name} / Page
                        </Text>
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </Box>

            {next != null ? (
              <Button
                icons2={<EastIcon style={{ fontSize: 14, color: 'black' }} />}
                onClick={() => {
                  setcurrPage(currPage + 1);
                }}
                extra={{
                  variant: 'outlined',
                  color: 'tertiary',
                }}>
                <Text style={{ fontSize: 12, fontWeight: 500, color: 'black' }}>
                  Next
                </Text>
              </Button>
            ) : (
              <Box></Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SaleToDoCon;
