import React, { useContext, useEffect, useState } from 'react';
import Box from './Box';
import Text from './Text';
import Chip from './Chip';
import Button from './Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Sidebar from '../common_component/Sidebar';
import ActiveMarketForm from '../Forms/ActiveMarketForm';
import EditIcon from '@mui/icons-material/BorderColor';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Props from '../../types/props';
import StringUtils from '../../utils/stringUtil';
import UploadInput from '../single_component/UploadInput';
import DeleteIcon from '@mui/icons-material/Delete';
import DateUtils from '../../utils/dateUtils';
import NotificationUtil from '../../utils/notificationUtils';
import Loader from './Loader';
import { toast } from 'react-toastify';
import { Grid } from '@mui/material';
import TextInput from '../common_component/TextInput';
import InvestorContext from '../../context/InvestorContext';
import moment from 'moment';
import Image from './Image';
import Radio from '../common_component/Radio';
import CheckboxLabels from '../common_component/AddCheckbox';

function InvestorDowloadCard({ ...props }: Props) {
  const [Toggle, setToggle] = React.useState(false);
  const {
    data,
    masterDataValue,
    userId,
    oneInvestorDownload,
    updateMasterData,
  } = props.extra;
  const [edit, setEdit] = useState(false);
  const [Selected, setSelected] = useState(-1);
  const [isChange, setIsChange] = useState(true);
  const [selectedInvestors, setSelectedInvestors] = useState<number[]>([]);
  const location = masterDataValue?.locations ?? [];
  const { updateDealDetails, deleteInvestorProfileImage } =
    useContext(InvestorContext);
  const { InvestmentTypes, iContactPeople, activeMarkets } = data ?? {};
  const [actionLoading, setActionLoading] = React.useState(false);
  const [HideShow, setHideShow] = useState(true);
  const [newInvestmentType, setNewInvestmentType] = useState<any[]>([]);
  const [addRemoveNewInvestmentType, setAdRemoveNewInvestmentType] = useState<
    any[]
  >([]);
  const [investorType, setinvestorType] = useState('');
  const HideShowHandle = () => {
    setHideShow(false);
  };
  const HideShowHandleSave = () => {
    setHideShow(true);
    setinvestorType('');
  };
  const locations = activeMarkets?.map((value: any) => {
    return location?.find((item: any) => item?.id === value);
  });
  const options =
    location?.map((item: any, i: number) => {
      return {
        name: item.name,
        value: i.toString(),
      };
    }) ?? [];

  const [investmentTypes, setinvestmentTypes] = useState(
    masterDataValue?.investmentTypes?.map((item: any) => {
      return {
        id: item.id,
        name: item.name,
        selected: data?.InvestmentTypes?.some(
          (val: any) => val?.id == item.invstmntTypeId
        ),
      };
    }) ?? []
  );
  useEffect(() => {
    setinvestmentTypes(
      masterDataValue?.investmentTypes?.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          selected: data?.InvestmentTypes?.some(
            (val: any) => val?.id == item.invstmntTypeId
          ),
        };
      }) ?? []
    );
  }, [masterDataValue]);
  const handleInvestmentType = (value: boolean, index: number) => {
    const temp = [...investmentTypes];
    temp[index]['selected'] = !temp[index]['selected'];
    setinvestmentTypes([...temp]);
    // const anySelected = investmentTypes.some(
    //   (item: any) => item.selected === true
    // );
    // setFieldValue('investmentType', anySelected == true ? value : '');
  };
  const addInvestmentTypes = (value: string) => {
    if (value.length > 0) {
      const temp = [...investmentTypes];
      temp.push({
        id: -1,
        name: value,
        selected: true,
      });
      setinvestmentTypes([...temp]);
      // onInvestorSelect(temp.length - 1);
      if (newInvestmentType.indexOf(value) > -1 === false)
        setNewInvestmentType([...newInvestmentType, value]);
      setAdRemoveNewInvestmentType([...newInvestmentType, value]);
    }
  };
  const onInvestorSelect = (index: number, value: boolean) => {
    if (index < masterDataValue?.investmentTypes?.length) {
      const checkedInvestors = selectedInvestors;
      const passedIndex: number = parseInt(index.toString());
      const checkedIndex = selectedInvestors?.indexOf(passedIndex);
      checkedIndex == -1
        ? checkedInvestors?.push(passedIndex)
        : checkedInvestors?.splice(checkedIndex, 1);
      checkedInvestors?.length > 0 &&
        setSelectedInvestors([...checkedInvestors]);
    } else {
      const removeNewInvestor = addRemoveNewInvestmentType;
      const addNewInvestor = addRemoveNewInvestmentType;
      if (value === false) {
        const val =
          newInvestmentType[index - masterDataValue?.investmentTypes?.length];
        const checkindex = addRemoveNewInvestmentType.indexOf(val);
        removeNewInvestor?.splice(checkindex, 1);
        setAdRemoveNewInvestmentType([...removeNewInvestor]);
      } else {
        addNewInvestor?.push(
          newInvestmentType[index - masterDataValue?.investmentTypes?.length]
        );
        setAdRemoveNewInvestmentType([...removeNewInvestor]);
      }
    }
  };
  const investorTypes =
    masterDataValue?.investorTypes?.map((item: any, index: number) => {
      return {
        id: index + 1,
        name: item,
      };
    }) ?? [];
  const onClick = () => {
    setToggle(!Toggle);
  };
  const [initialValues, setinitialValues] = useState({
    name: '',
    investorType: '',
    investmentType: '',
  });
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setinitialValues({
      ...initialValues,
      [name]: value,
    });
  };
  const handleSubmit = async () => {
    const val = masterDataValue?.investorTypes.indexOf(Selected) + 1;
    const investmenttypearr: any = [];
    selectedInvestors?.map(val =>
      investmenttypearr.push(masterDataValue?.investmentTypes[val].id)
    );
    const response = await updateDealDetails(data?.investorId, {
      name: initialValues.name,
      investorTypes: val,
      investmentTypes: investmenttypearr,
      investmentTypesNames: addRemoveNewInvestmentType,
    });
    if (response.success) {
      updateMasterData();
      toast.success(response.message);
      setEdit(false);
      setNewInvestmentType([]);
      setAdRemoveNewInvestmentType([]);
    } else {
      toast.error(response.message);
    }
  };
  const handleAddInvestorProfile = async (file: any) => {
    setIsChange(false);
    const bodyformdata = new FormData();
    bodyformdata.append('profileImg', file);
    const response = await updateDealDetails(data?.investorId, bodyformdata);
    if (response.success) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    setIsChange(true);
  };
  const handleDeleteInvestorProfile = async () => {
    if (data?.profileImg) {
      try {
        const response = await deleteInvestorProfileImage(data?.investorId);
        if (response.success) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error('Error on Adding profile', error);
      }
    }
  };

  useEffect(() => {
    setinvestmentTypes(
      masterDataValue?.investmentTypes?.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          selected: data?.InvestmentTypes?.some(
            (val: any) => val?.invstmntTypeId == item.id
          ),
        };
      })
    );
    setinitialValues({
      name: data?.name ?? '',
      investorType: data?.investorType ?? '',
      investmentType: data.InvestmentTypes ?? '',
    });
    setSelected(data?.investorType);
    const indices: any = [];
    let j: number = 0;
    if (data?.InvestmentTypes?.length > 0 && investmentTypes.length > 0) {
      for (let i = 0; i < masterDataValue?.investmentTypes?.length; i++) {
        if (
          masterDataValue?.investmentTypes[i].id ===
          data?.InvestmentTypes[j].invstmntTypeId
        ) {
          indices.push(i);
          j++;
        }
        if (j >= data?.InvestmentTypes?.length) break;
      }
    }
    setSelectedInvestors(indices);
  }, [data]);
  const handleDownload = async () => {
    setActionLoading(true);
    try {
      const response = await oneInvestorDownload(userId, data?.name);
      if (!response.success) NotificationUtil.showToast(response.message);
    } catch (e) {
      return false;
    }
    setActionLoading(false);
  };
  return (
    <Box
      style={{
        boxShadow: ' 0px 1px 3px 0px #1018281A',
        border: 1,
        borderRadius: 2,
        borderColor: '#E7E7E7',
        background: '#FFFFFF',
      }}>
      {!edit ? (
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '12px 18px',
              borderBottom: 1,
              borderColor: '#E7E7E7',
            }}>
            <Text style={{ fontSize: 20, fontWeight: 500 }}>
              {StringUtils.capitalizeFirstLetter(data?.name)}
            </Text>
            <Box style={{ display: 'flex', gap: 2 }}>
              <Button
                extra={{ variant: 'outlined', color: 'primary' }}
                style={{ paddingBlock: 1, paddingInline: 2, borderRadius: 1 }}
                icons={<EditIcon style={{ fontSize: 16 }} />}
                onClick={() => setEdit(true)}>
                <Text
                  style={{ color: '#104960', fontWeight: 500, fontSize: 13 }}>
                  Edit Details
                </Text>
              </Button>
              {!actionLoading ? (
                <Button
                  onClick={handleDownload}
                  extra={{ variant: 'outlined', color: 'primary' }}
                  style={{ padding: '8px 24px', borderRadius: 1 }}
                  icons={<FileDownloadIcon />}>
                  <Text
                    style={{ color: '#104960', fontSize: 13, fontWeight: 500 }}>
                    Download
                  </Text>
                </Button>
              ) : (
                <Loader></Loader>
              )}
            </Box>
          </Box>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '60px',
              padding: '32px 40px',
            }}>
            <Box
              style={{
                width: 236,
                height: 236,
                borderRadius: 2,
                backgroundColor: '#E5F7FF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {data?.profileImg ? (
                <Image
                  alt={data?.name}
                  src={data?.profileImg}
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                />
              ) : (
                <Text
                  style={{
                    fontSize: 64,
                    color: '#104960',
                    fontWeight: 600,
                  }}>
                  {StringUtils.getInitials(data?.name ?? '')}
                </Text>
              )}
            </Box>
            <Box
              style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
              <Box style={{ display: 'flex', gap: '20px' }}>
                <Text
                  style={{
                    marginRight: 0,
                    fontFamily: 'Inter',
                    fontSize: 14,
                    color: '#667085',
                    width: 130,
                  }}>
                  EMAIL
                </Text>
                <Text>:</Text>
                <Text>
                  {iContactPeople?.length > 0
                    ? iContactPeople[0]?.contPerEmail
                    : ''}
                </Text>
              </Box>
              <Box style={{ display: 'flex', gap: '20px' }}>
                <Text
                  style={{
                    marginRight: 0,
                    fontFamily: 'Inter',
                    fontSize: 14,
                    color: '#667085',
                    width: 130,
                  }}>
                  PHONE
                </Text>
                <Text>:</Text>
                <Text>
                  +91{' '}
                  {iContactPeople?.length > 0
                    ? iContactPeople[0]?.contPerPhone
                    : ''}
                </Text>
              </Box>
              <Box style={{ display: 'flex', gap: '20px' }}>
                <Text
                  style={{
                    marginRight: 0,
                    fontFamily: 'Inter',
                    fontSize: 14,
                    color: '#667085',
                    width: 130,
                  }}>
                  DATE
                </Text>
                <Text>:</Text>
                <Text>
                  {' '}
                  {DateUtils.formatDate(data?.createdAt, 'MMM DD, YYYY')}
                </Text>
              </Box>
              <Box style={{ display: 'flex', gap: '20px' }}>
                <Text
                  style={{
                    marginRight: 0,
                    fontFamily: 'Inter',
                    fontSize: 14,
                    color: '#667085',
                    width: 130,
                  }}>
                  INVESTOR TYPE
                </Text>
                <Text>:</Text>
                <Text>
                  {StringUtils.capitalizeFirstLetter(data?.investorType)}
                </Text>
              </Box>
              <Box style={{ display: 'flex', gap: '20px' }}>
                <Text
                  style={{
                    marginRight: 0,
                    fontFamily: 'Inter',
                    fontSize: 14,
                    color: '#667085',
                    width: 130,
                  }}>
                  INVESTMENT TYPE
                </Text>
                <Text>:</Text>

                <Box
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1,
                  }}>
                  {InvestmentTypes?.map((value: any, index: number) => {
                    return (
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                        key={index}>
                        {index !== 0 && (
                          <Box
                            style={{
                              width: 6,
                              height: 6,
                              background: '#979797',
                              borderRadius: '50%',
                            }}></Box>
                        )}

                        <Text>
                          {StringUtils.capitalizeFirstLetter(value?.name)}
                        </Text>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box style={{ display: 'flex', gap: '20px' }}>
                <Text
                  style={{
                    marginRight: 0,
                    fontFamily: 'Inter',
                    fontSize: 14,
                    color: '#667085',
                    width: 130,
                  }}>
                  ACTIVE MARKETS
                </Text>
                <Text>:</Text>
                <Box
                  style={{
                    display: 'flex',
                    gap: '16px',
                    alignItems: 'flex-start',
                  }}>
                  <Box
                    style={{
                      display: 'flex',
                      gap: '16px',
                      maxWidth: 430,
                      flexWrap: 'wrap',
                    }}>
                    {locations?.map((value: any, index: number) => {
                      return (
                        <Chip
                          style={{
                            background: '#E5F7FF',
                          }}
                          extra={{
                            variant: 'outlined',
                          }}
                          label={StringUtils.capitalizeFirstLetter(value?.name)}
                          key={index}
                        />
                      );
                    })}
                  </Box>

                  <Box>
                    <Button
                      extra={{
                        variant: 'outlined',
                        color: 'primary',
                        borderRadius: '8px',
                        border: 1,
                      }}
                      style={{ padding: '6px 24px', borderRadius: 2 }}
                      icons={<AddOutlinedIcon />}
                      onClick={onClick}>
                      <Text
                        style={{
                          color: '#104960',
                          fontSize: 13,
                          fontWeight: 500,
                        }}>
                        Add
                      </Text>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Sidebar
              label="Active markets"
              extra={{
                Toggle,
                setToggle,
                anchor: 'right',
                description: 'Here you can add or edit active markets.',
                width: 400,
              }}>
              <ActiveMarketForm
                extra={{
                  setToggle,
                  Toggle,
                  options,
                  locations,
                  userId,
                  masterLocation: location,
                  updateMasterData: updateMasterData,
                }}
              />
            </Sidebar>
          </Box>
        </>
      ) : (
        <>
          <Box
            style={{
              display: 'flex',
              gap: '60px',
              padding: '32px 40px',
            }}>
            <Box>
              {/* <Image
            alt="DK"
            style={{
              height: 236,
              minWidth: 236,
              background: '#E5F7FF',
              boxShadow: '0px 2px 2px 0px #1049601A',
              borderRadius: '8px',
              marginTop: 12,
            }}
          /> */}
              <Box
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 2,
                  backgroundColor: '#E5F7FF',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}>
                {isChange && (
                  <div style={{ position: 'absolute', right: 5, top: 5 }}>
                    <UploadInput
                      name={'documentFile'}
                      id={'documentFile'}
                      extra={{
                        padding: '1px',
                        isModify: true,
                        onchange: (e: any) => {
                          handleAddInvestorProfile(e[0]);
                        },
                        style: { marginTop: '-6px' },
                      }}
                    />
                  </div>
                )}
                <DeleteIcon
                  onClick={() => handleDeleteInvestorProfile()}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 40,
                    color: '#104960',
                  }}
                />
                {data?.profileImg ? (
                  <Image
                    alt={data?.name}
                    src={data?.profileImg}
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  />
                ) : (
                  <Text
                    style={{
                      fontSize: 64,
                      color: '#104960',
                      fontWeight: 600,
                    }}>
                    {StringUtils.getInitials(data?.name ?? '')}
                  </Text>
                )}
              </Box>
            </Box>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Date"
                  placeholder="Date"
                  readOnly={true}
                  disabled={true}
                  value={moment(data?.joiningDate).format('DD-MM-YYYY')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Name"
                  name="name"
                  id="name"
                  onChange={handleChange}
                  placeholder="Enter Name"
                  value={initialValues?.name}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Radio
                  label="Investor type"
                  name="investorType"
                  id="investorType"
                  value={initialValues.investorType}
                  extra={{
                    setSelected: (e: any) => {
                      // setFieldValue('investorType', e);
                      setSelected(e);
                    },
                    Selected: Selected,
                    options: investorTypes,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <CheckboxLabels
                  label="investmentType"
                  id="investmentType"
                  name="investmentType"
                  extra={{
                    onSelect: (value: boolean, index: number) => {
                      onInvestorSelect(index, value);
                      handleInvestmentType(value, index);
                    },
                    headingLabel: 'Investment type',
                    options: investmentTypes,
                    selectedInvestors,
                    // check: check
                  }}
                />
                <Box>
                  {HideShow ? (
                    <Button
                      extra={{ variant: 'outlined', color: 'primary' }}
                      style={{ padding: '6px 60px' }}
                      icons={<AddOutlinedIcon />}
                      onClick={HideShowHandle}>
                      <Text
                        style={{
                          color: '#104960',
                          fontSize: 13,
                          fontWeight: 500,
                        }}>
                        Add new type
                      </Text>
                    </Button>
                  ) : (
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 2,
                      }}>
                      <TextInput
                        placeholder="Enter type here"
                        value={investorType}
                        onChange={(e: any) => {
                          setinvestorType(e.target.value);
                        }}
                      />
                      <Button
                        extra={{ variant: 'outlined' }}
                        style={{ padding: '7px 40px' }}
                        onClick={() => {
                          addInvestmentTypes(investorType);
                          HideShowHandleSave();
                        }}>
                        Save
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  extra={{
                    variant: 'outlined',
                    color: 'primary',
                  }}
                  style={{
                    borderRadius: 1,
                    width: '100%',
                    padding: 1.5,
                    marginTop: 2,
                  }}
                  onClick={() => {
                    setinitialValues({
                      name: data?.name ?? '',
                      investmentType: '',
                      investorType: '',
                    });
                    setEdit(false);
                  }}>
                  <Text
                    style={{ color: '#104960', fontWeight: 500, fontSize: 13 }}>
                    Cancel
                  </Text>
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  extra={{
                    variant: 'contained',
                    color: 'primary',
                  }}
                  style={{
                    borderRadius: 1,
                    width: '100%',
                    padding: 1.5,
                    marginTop: 2,
                  }}
                  onClick={handleSubmit}>
                  <Text
                    style={{ color: 'white', fontWeight: 500, fontSize: 13 }}>
                    Save
                  </Text>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
}

export default InvestorDowloadCard;
