import * as Yup from 'yup';
// const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
const loginSchema = Yup.object({
  email: Yup.string().required('Please Select  Priority !'),
});
const loginValidation = Yup.object({
  email: Yup.string()
    .required('Please Enter Email !')
    .transform((value, originalValue) =>
      originalValue ? originalValue.trim() : originalValue
    )
    .matches(emailRegex, 'Invalid Email Format')
    .min(5),
});

export { loginSchema, loginValidation };
