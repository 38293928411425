import React from 'react';
import Box from '../single_component/Box';
import theme from '../../utils/Theme';
import Props from '../../types/props';
import { Grid, Tab, Tabs } from '@mui/material';
import DocumentBox from '../common_component/DocumentBox';
import Text from '../single_component/Text';

function ViewSpvTabDocuments({ ...props }: Props) {
  //   const [Document, setDocument] = useState<Record<string, File>>({});
  // const { btnDisableLoader } = props.extra;
  const projectData = props?.extra?.document ?? [];
  // const { addProjectBanner } = useContext(ProjectContext);

  const constitutionalDocuments = projectData?.documents?.filter(
    (doc: any) => doc.category === 'constitutional'
  );
  const kycDocuments = projectData?.documents?.filter(
    (doc: any) => doc.category === 'kyc'
  );
  const financialDocuments = projectData?.documents?.filter(
    (doc: any) => doc.category === 'financial'
  );
  const otherDocuments = projectData?.documents?.filter(
    (doc: any) =>
      doc.category !== 'kyc' &&
      doc.category !== 'constitutional' &&
      doc.category !== 'financial'
  );
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event: any, newValue: number) => {
    setSelectedTab(newValue);
  };
  return (
    <Box>
      <Box
        {...props}
        style={{
          backgroundColor: 'white',
          boxShadow: ' 0px 2px 10px 0px #10496030',
          borderRadius: 2,
          marginTop: 5,
        }}>
        <Box>
          <Box
            style={{
              backgroundColor: '#E5F7FF',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
            }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              centered
              sx={{
                paddingInline: 5,

                '& .MuiTabs-flexContainer': { justifyContent: 'space-between' },
                '& .MuiTabs-root': {
                  borderTopLeftRadius: '12px !important',
                  borderTopRightRadius: '12px !important',
                },
                '& .MuiTabs-indicator': { background: '#25A9E0', height: 3 },
              }}>
              {props.extra.tabName === undefined && (
                <Tab
                  label="Constitutional Documents"
                  sx={{
                    fontSize: 13,
                    fontWeight: 600,
                    color: theme.palette.primary.main,
                  }}
                />
              )}
              <Tab
                label="Kyc Documents"
                sx={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              />
              <Tab
                label="Financial Documents"
                sx={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              />
              <Tab
                label="Other Documents"
                sx={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              />
            </Tabs>
          </Box>
          {selectedTab === 0 && props.extra.tabName === undefined && (
            <Box
              style={{
                overflowX: 'scroll',
                height: '290px',
                padding: '0 10px',
              }}>
              <Grid container spacing={2}>
                {constitutionalDocuments?.length > 0 ? (
                  constitutionalDocuments?.map((val: any) => (
                    <Grid item xs={12} sm={6} key={val}>
                      <DocumentBox
                        key={val.documentFile}
                        extra={{
                          textlabel: val.documentName,
                          labelWidth: '370px',
                          chiplabel: val.fileName ?? val.documentName,
                          url: val.documentFile,
                          GrayColor: 'Grayforform',
                        }}
                      />
                    </Grid>
                  ))
                ) : (
                  <Box
                    style={{
                      width: '100%',
                      height: '300px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Text>No Documents founds</Text>
                  </Box>
                )}
              </Grid>
            </Box>
          )}
          {((selectedTab === 1 && props.extra.tabName === undefined) ||
            (props.extra.tabName !== undefined && selectedTab === 0)) && (
            <Box
              style={{
                overflowX: 'scroll',
                height: '290px',
                padding: '0 10px',
              }}>
              <Grid container spacing={2}>
                {kycDocuments?.length > 0 ? (
                  kycDocuments?.map((val: any) => (
                    <Grid item xs={12} sm={6} key={val}>
                      <DocumentBox
                        key={val.documentFile}
                        extra={{
                          textlabel: val.documentName,
                          labelWidth: '370px',
                          chiplabel: val.fileName ?? val.documentName,
                          url: val.documentFile,
                          GrayColor: 'Grayforform',
                        }}
                      />
                    </Grid>
                  ))
                ) : (
                  <Box
                    style={{
                      width: '100%',
                      height: '300px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Text>No Documents founds</Text>
                  </Box>
                )}
              </Grid>
            </Box>
          )}

          {((selectedTab === 2 && props.extra.tabName === undefined) ||
            (props.extra.tabName !== undefined && selectedTab === 1)) && (
            <Box
              style={{
                overflowX: 'scroll',
                height: '290px',
                padding: '0 10px',
              }}>
              <Grid container spacing={2}>
                {financialDocuments?.length > 0 ? (
                  financialDocuments?.map((val: any) => (
                    <Grid item xs={12} sm={6} key={val}>
                      <DocumentBox
                        key={val.documentFile}
                        extra={{
                          textlabel: val.documentName,
                          labelWidth: '370px',
                          chiplabel: val.fileName ?? val.documentName,
                          url: val.documentFile,
                          GrayColor: 'Grayforform',
                        }}
                      />
                    </Grid>
                  ))
                ) : (
                  <Box
                    style={{
                      width: '100%',
                      height: '300px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Text>No Documents founds</Text>
                  </Box>
                )}
              </Grid>
            </Box>
          )}
          {((selectedTab === 3 && props.extra.tabName === undefined) ||
            (props.extra.tabName !== undefined && selectedTab === 2)) && (
            <Box
              style={{
                overflowX: 'scroll',
                height: '290px',
                padding: '0 10px',
              }}>
              <Grid container spacing={2}>
                {otherDocuments?.length > 0 ? (
                  otherDocuments?.map((val: any) => (
                    <Grid item xs={12} sm={6} key={val}>
                      <DocumentBox
                        key={val.documentFile}
                        extra={{
                          textlabel: val.documentName,
                          labelWidth: '370px',
                          chiplabel: val.fileName ?? val.documentName,
                          url: val.documentFile,
                          GrayColor: 'Grayforform',
                        }}
                      />
                    </Grid>
                  ))
                ) : (
                  <Box
                    style={{
                      width: '100%',
                      height: '300px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Text>No Documents founds</Text>
                  </Box>
                )}
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ViewSpvTabDocuments;
