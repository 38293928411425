import React from 'react';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import Button from '../single_component/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import Sidebar from '../common_component/Sidebar';
import SaleDashFilter from '../Filter/SaleDashFilter';
import Props from '../../types/props';

function DashFilterTop(props: Props) {
  const {
    Date,
    setDate,
    filterToggle,
    setFilterToggle,
    selectedChip,
    setSelectedChip,
    fetchData,
  } = props?.extra ?? {};

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '12px 24px 0px 24px ',
        background: 'white',
        borderRadius: '5px 10px 0  0',
      }}>
      <Box>
        <Text style={{ fontWeight: 'bold' }}>Transactions</Text>
        <Text style={{ fontSize: 14, color: '#667085' }}>
          Here you can view all transactions that have been done till date.
        </Text>
      </Box>

      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          extra={{
            variant: 'outlined',
            color: 'tertiary',
          }}
          style={{ padding: '8px 24px', borderRadius: 1.5 }}
          icons={<FilterListIcon />}
          onClick={() => setFilterToggle(true)}>
          <Text style={{ color: 'black', fontSize: 13, fontWeight: 'bolder' }}>
            Add Filters
          </Text>
        </Button>
      </Box>

      <Sidebar
        label="Filter"
        extra={{
          anchor: 'right',
          Toggle: filterToggle,
          setToggle: setFilterToggle,
          description: 'Filter the data as per your need',
          width: 400,
        }}>
        <SaleDashFilter
          extra={{
            Date,
            setDate,
            setFilterToggle,
            selectedChip,
            setSelectedChip,
            fetchData,
          }}
        />
      </Sidebar>
    </Box>
  );
}

export default DashFilterTop;
