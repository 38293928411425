import React, { useContext, useEffect, useState } from 'react';
import Box from '../../components/single_component/Box';
import SalesWelcomeBox from '../../components/common_component/SalesWelcomeBox';
import SalesDeveloperDataTable from '../componets/SalesDeveloperDataTable';
import SalesDashboardContext from '../../context/SalesDashBoardContext';
import TransactionDataTable from '../componets/SalesTransactionTable';
import DateUtils from '../../utils/dateUtils';
import AuthContext from '../../context/AuthContext';

function SalesDashContainer() {
  const { DashBoardData, getDashboardData } = useContext(SalesDashboardContext);
  const { dataFormat, transactions } = DashBoardData;
  const [duration, setDuration] = useState(2);
  const { masterData } = useContext(AuthContext);
  const data = masterData?.value();
  const [Page, setPage] = React.useState(1);
  const [rowsPerPage, setrowsPerPage] = React.useState(10);
  const [Next, setNext] = React.useState();
  const [Previous, setPrevious] = React.useState();
  const [currPage, setcurrPage] = React.useState(1);
  const [Date, setDate] = useState('');
  const [filterToggle, setFilterToggle] = useState(false);
  const [selectedChip, setSelectedChip] = React.useState(-1);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const date = DateUtils.formatDate(Date, 'YYYY-MM-DD');
    const transcStatus = data?.transactionStatus[selectedChip] ?? '';
    setLoading(true);
    await getDashboardData(
      `sales/dashboard?page=${currPage}&size=${rowsPerPage}`,
      {
        filter: {
          duration,
          ...(transcStatus && { transcStatus: [transcStatus] }),
          ...(date?.charAt(0) == '2' && { date }),
        },
      }
    );
    setLoading(false);
    setFilterToggle(false);
  };
  useEffect(() => {
    setPage(transactions?.totalPages);
    setNext(transactions?.nextPage);
    setPrevious(transactions?.prevPage);
  }, [transactions]);

  useEffect(() => {}, [DashBoardData]);

  useEffect(() => {
    fetchData();
  }, [currPage, rowsPerPage, duration]);

  return (
    <Box style={{ paddingBottom: 6, paddingTop: 3 }}>
      <Box style={{ width: '85%', margin: 'auto' }}>
        <Box style={{ marginBottom: 4, marginTop: 4 }}>
          <SalesWelcomeBox
            extra={{
              duration,
              setDuration,
            }}
          />
        </Box>
        <TransactionDataTable
          extra={{
            data: transactions ?? [],
            Page,
            setPage,
            rowsPerPage,
            setrowsPerPage,
            Next,
            setNext,
            Previous,
            setPrevious,
            currPage,
            setcurrPage,
            Date,
            setDate,
            filterToggle,
            setFilterToggle,
            selectedChip,
            setSelectedChip,
            fetchData,
            loading,
          }}
        />
        <Box
          style={{
            marginTop: 8,
            boxShadow: '0px 2px 10px 0px #10496030',
            borderRadius: 10,
          }}>
          <SalesDeveloperDataTable
            extra={{ data: dataFormat ?? [], loading }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default SalesDashContainer;
