import Axios from '../config/axios';
import ApiResponse from '../utils/ApiResponse';

class SpvServices extends Axios {
  constructor() {
    super();
  }

  async addSpv(data: any) {
    const response = await this.post<JSON>(`/spv/add`, data);
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
  async editSpv(data: any) {
    const response = await this.patch<JSON>(`/spv/edit`, data);
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
  async deleteSpv(docId: number) {
    const response = await this.delete<JSON>(`/spv/${docId}`);
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
  async getSpv(query: string) {
    const response = await this.get<JSON>(`/${query}`);
    const apiResponse = new ApiResponse(response);
    const data = apiResponse.getData();
    return data;
  }
}

export default SpvServices;
