import React, { useState } from 'react';
import Props from '../types/props';
import TransactionsContex from '../context/TransactionsContex';
import TransactionService from '../services/TransactionService';

export default function TransactionProvider(props: Props) {
  const [Transaction, setTransaction] = useState({});
  const [loading, setLoading] = useState(true);

  const getTransaction = async (
    page: number,
    size: number,
    filters: Record<string, unknown>
  ) => {
    setLoading(true);
    const response = await new TransactionService().getAllTransaction(
      page,
      size,
      filters
    );
    setLoading(false);
    setTransaction(response.data);
    return response;
  };
  const addTransaction = async (data: any) => {
    setLoading(true);
    const response = await new TransactionService().addTransaction(data);
    setLoading(false);
    return response;
  };
  const cancelTransaction = async (transactionId: string) => {
    const response = await new TransactionService().cancelTransaction(
      transactionId
    );

    return response;
  };

  const addInvoice = async (data: any) => {
    const response = await new TransactionService().addInvoice(data);
    return response;
  };

  const getInvoice = async (data: any) => {
    const response = await new TransactionService().getInvoice(data);
    return response;
  };

  const holdTransaction = async (transcId: string, reason: string) => {
    const result = await new TransactionService().holdTransaction(
      transcId,
      reason
    );

    return result;
  };

  const deleteTransactionDocument = async (
    transactionId: number,
    deleteMandate: boolean,
    data: any
  ) => {
    console.error(data);
    setLoading(true);
    const response = await new TransactionService().deleteTransactionDocument(
      transactionId,
      deleteMandate
    );
    setLoading(false);
    if (response) {
      // getSpv(data);
    }
    return response;
  };
  const addTransactionMandate = async (
    transactionId: number,
    mandate: any,
    data: any
  ) => {
    console.error(data);
    setLoading(true);
    const response = await new TransactionService().addTransactionMandate(
      transactionId,
      mandate
    );
    setLoading(false);
    if (response) {
      // getSpv(data);
    }
    return response;
  };
  const TransactionStatus = async (
    transactionId: number,
    transcStatus: string,
    data: any
  ) => {
    console.error(data);
    setLoading(true);
    const response = await new TransactionService().TransactionStatus(
      transactionId,
      transcStatus
    );
    setLoading(false);
    if (response) {
      // getSpv(data);
    }
    return response;
  };

  return (
    <TransactionsContex.Provider
      value={{
        getTransaction,
        addTransaction,
        Transaction,
        cancelTransaction,
        setLoading,
        loading,
        addInvoice,
        getInvoice,
        holdTransaction,
        TransactionStatus,
        deleteTransactionDocument,
        addTransactionMandate,
      }}>
      {props.children}
    </TransactionsContex.Provider>
  );
}
