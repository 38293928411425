import Axios from '../config/axios';
import ApiResponse from '../utils/ApiResponse';

class SalesExecutiveService extends Axios {
  constructor() {
    super();
  }

  async addSalesExecutive(data: any) {
    const response = await this.post<JSON>(`/admin/addSales`, data);
    const apiResponse = new ApiResponse(response);
    const success = apiResponse.getSuccess();
    const message = apiResponse.getMessage();
    return { success, message };
  }

  async getAllSalesExecutive(
    page: number,
    size: number,
    filter: Record<string, unknown>
  ) {
    const response = await this.post<JSON>(
      `/admin/getAllSales?page=${page}&size=${size}`,
      filter
    );
    const apiResponse = new ApiResponse(response);
    const responseData = apiResponse.getData();
    return responseData;
  }
  async getById(query: string) {
    const response = await this.get<JSON>(`/${query}`);
    const apiResponse = new ApiResponse(response);
    const data = apiResponse.getData();
    return data;
  }
}

export default SalesExecutiveService;
