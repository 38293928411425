class Unit {
  unitId: string;
  name: string;

  constructor(data: Record<string, unknown>) {
    this.unitId = (data.unitId ?? 'N/A').toString();
    this.name = (data.name ?? 'N/A').toString();
  }

  static fromJson(json: Record<string, unknown>): Unit {
    return new Unit(json);
  }

  toJson(stringify: boolean = false): Record<string, unknown> | string {
    const jsonData = {
      unitId: this.unitId,
      name: this.name,
    };

    return stringify ? JSON.stringify(jsonData) : jsonData;
  }
}

export default Unit;
