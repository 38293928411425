import React, { useState, useContext, useEffect } from 'react';
import Box from '../components/single_component/Box';
import InvestTableTop from '../components/common_component/InvestTableTop';
import DownloadIcon from '../assets/images/download.png';
import Check from '../components/single_component/Check';
import MyTeamAvtaar from '../components/My-Team/MyTeamAvtaar';
import ContactDetails from '../components/common_component/ContactDetails';
import Image from '../components/single_component/Image';
import InvestorContext from '../context/InvestorContext';
import { TableRow, TableCell } from '@mui/material';
import Tables from '../components/single_component/Tables';
import Text from '../components/single_component/Text';
import StringUtils from '../utils/stringUtil';
import Loader from '../components/single_component/Loader';
import NotfoundPage from '../components/single_component/NotfoundPage';
import _ from 'lodash';
import Button from '../components/single_component/Button';
import NotificationUtil from '../utils/notificationUtils';
import { ROUTES } from '../types/routeObject';
import AuthContext from '../context/AuthContext';

function InvestorsContainer() {
  const { masterData } = useContext(AuthContext);
  const master = masterData?.value();
  const {
    Data,
    loading,
    getInvestors,
    oneInvestorDownload,
    onInvestorsDownload,
  } = useContext(InvestorContext);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [Page, setPage] = useState(1);
  const [Next, setNext] = useState();
  const [Previous, setPrevious] = useState();
  const [currPage, setcurrPage] = useState(1);

  const [selectedInvestors, setSelectedInvestors] = useState<number[]>([]);
  const [actionLoading, setActionLoading] = React.useState(false);

  const [filter, setFilter] = useState({
    search_key: '',
  });

  const setSearchKey = (key: string) => {
    setFilter({ ...filter, search_key: key });
  };

  const [filteredData, setfilteredData] = useState({});
  const filterData = async (data: Record<string, unknown>) => {
    setfilteredData(data);
    console.error('errors', currPage, rowsPerPage, { ...data, ...filter });

    return await getInvestors(currPage, rowsPerPage, { ...data, ...filter });
  };
  const handleSearch = _.debounce(() => {
    getInvestors(currPage, rowsPerPage, { ...filter, ...filteredData });
  }, 300);

  useEffect(() => {
    setPage(Data?.totalPages);
    setNext(Data?.nextPage);
    setPrevious(Data?.prevPage);
    setSelectedInvestors([]);
  }, [Data]);

  useEffect(() => {
    handleSearch();
    return () => {
      handleSearch.cancel();
    };
  }, [currPage, rowsPerPage, filter]);

  const downloadDataFormats = async () => {
    setActionLoading(true);
    try {
      const records = Data?.records ?? [];
      const ids =
        records.length >= selectedInvestors.length
          ? selectedInvestors.map(e => Data?.records[e]['investorId'])
          : [];
      const response = await onInvestorsDownload(ids);
      if (!response.success) NotificationUtil.showToast(response.message);
      else {
        setSelectedInvestors([]);
      }
    } catch (e) {
      NotificationUtil.showToast('Unable to download data formats.');
      return false;
    }
    setActionLoading(false);
  };

  const onInvestorSelect = (index: number) => {
    const checkedInvestors = selectedInvestors;
    const passedIndex: number = parseInt(index.toString());
    const checkedIndex = selectedInvestors.indexOf(passedIndex);
    checkedIndex == -1
      ? checkedInvestors.push(passedIndex)
      : checkedInvestors.splice(checkedIndex, 1);
    setSelectedInvestors([...checkedInvestors]);
  };

  const onSelectAll = () => {
    if (selectedInvestors.length == Data?.records?.length) {
      setSelectedInvestors([]);
      return;
    }
    const checkedInvestors: number[] = Array.from(
      { length: Data?.records?.length },
      (_, index) => index
    );

    console.error('on select all', checkedInvestors);
    setSelectedInvestors([...checkedInvestors]);
  };

  const TableRowComponet = (props: any) => {
    const { row, index } = props;
    const locationPref = master?.locations.find(
      (value: any) => value.id == row?.locationPref[0]
    );
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: '80%' }}>
        <TableCell>
          <Check
            onChange={() => onInvestorSelect(index)}
            checked={selectedInvestors.indexOf(index) != -1}
            extra={{
              size: 'small',
            }}
          />
        </TableCell>
        <TableCell>
          <MyTeamAvtaar
            extra={{
              location: `${ROUTES.INVESTOR_DETAILS.replace(
                ':id',
                row.investorId
              )}`,
              name: StringUtils.capitalizeFirstLetter(row?.name ?? ''),
              stateData: {
                investorName: StringUtils.capitalizeFirstLetter(
                  row?.name ?? ''
                ),
              },
              locate: StringUtils.capitalizeFirstLetter(
                locationPref?.name ?? ''
              ),
            }}
          />
        </TableCell>
        <TableCell>
          <ContactDetails
            extra={{
              phoneNumber: row?.iContactPeople[0]?.contPerPhone ?? 'N/A',
              email: row?.iContactPeople[0]?.contPerEmail ?? 'N/A',
            }}
          />
        </TableCell>

        <TableCell>
          <Text> {StringUtils.capitalizeFirstLetter(row?.investorType)}</Text>
        </TableCell>

        <TableCell>
          <Text>
            {' '}
            {StringUtils.capitalizeFirstLetter(
              row?.InvestmentTypes[0]?.name ?? 'N/A'
            )}
          </Text>
        </TableCell>

        <TableCell>
          <Text>
            {' '}
            {StringUtils.capitalizeFirstLetter(
              row?.iContactPeople[0]?.contPerName ?? 'N/A'
            )}
          </Text>
        </TableCell>

        <TableCell>
          <Button
            onClick={async () => {
              try {
                const response = await oneInvestorDownload(
                  row?.investorId,
                  row?.name
                );
                if (!response.success)
                  NotificationUtil.showToast(
                    `Unable to download data format of ${row?.name}`
                  );
              } catch (e) {
                NotificationUtil.showToast(
                  `Unable to download data format of ${row?.name}`
                );
                return false;
              }
            }}>
            <Image
              src={DownloadIcon}
              style={{
                width: 32,
                height: 32,
                objectFit: 'cover',
                cursor: 'pointer',
              }}
            />
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  const tableHeaders = [
    {
      id: 'CheckBox',
      name: (
        <Check
          checked={selectedInvestors.length >= Data?.records?.length}
          onChange={onSelectAll}
          extra={{
            size: 'small',
          }}
        />
      ),
      minWidth: 100,
    },
    {
      id: 'Investors',
      name: 'Investors',
      minWidth: 100,
    },
    { id: 'Contact', name: 'Contact', minWidth: 100 },
    {
      id: 'InvestorType',
      name: 'Investor type',
      minWidth: 100,
    },
    {
      id: 'InvestmentType',
      name: 'Investment type',
      minWidth: 100,
    },
    {
      id: 'ContactPerson',
      name: 'Contact Person',
      minWidth: 100,
    },
    { id: 'Action', name: 'Action', minWidth: 100 },
  ];
  const [Toggle, setToggle] = React.useState(false);
  const [selected, setselected] = useState(-1);
  const [selectinvestment, setinvestment] = useState<number[]>([]);
  const [selectinvestor, setinvestor] = useState([]);
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);
  console.error(selected);

  const onCancel = () => {
    setToggle(false);
  };
  const handleFilter = async () => {
    setbtnDisableLoader(true);
    try {
      const filters: Record<string, unknown> = {};
      const investmentId: number[] = selectinvestment.map(
        e => master?.investmentTypes[e].id ?? 0
      );
      if ((selected ?? []) > 0)
        filters['locations'] = master?.locations[selected].id;
      if ((selectinvestor ?? []).length > 0)
        filters['investorType'] = selectinvestor.map(e => e + 1);
      if ((investmentId ?? []).length > 0)
        filters['investmentTypes'] = investmentId;
      const responce = await filterData(filters);
      setbtnDisableLoader(false);
      if (responce.success) {
        setToggle(false);
      } else {
        setbtnDisableLoader(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box
      style={{
        width: '85%',
        margin: 'auto',
        boxShadow: '0px 2px 10px 0px #10496030',
        border: '1px solid #EDEDED',
      }}>
      <InvestTableTop
        extra={{
          checked: selectedInvestors.length > 0,
          selectedLabel: `${selectedInvestors.length} Investor have been selected for download.`,
          onSearchKeyChange: (v: string) => {
            setSearchKey(v);
          },
          loading: actionLoading,
          onDownload: downloadDataFormats,
          Toggle,
          setToggle,
          selectinvestment,
          selectinvestor,
          selected,
          setselected,
          setinvestment,
          setinvestor,
          btnDisableLoader,
          onCancel,
          handleFilter,
        }}
      />
      {loading ? (
        <Loader />
      ) : Data?.records?.length != 0 ? (
        <Tables
          extra={{
            rows: Data?.records ?? [],
            columns: tableHeaders,
            RowComponent: TableRowComponet,
            page: Page,
            setPage: setPage,
            rowsPerPage: rowsPerPage,
            setrowsPerPage: setrowsPerPage,
            Next: Next,
            Previous: Previous,
            currPage: currPage,
            setcurrPage: setcurrPage,
            setNext: setNext,
            setPrevious: setPrevious,
          }}
        />
      ) : (
        <div
          style={{
            marginTop: '10px',
          }}>
          <NotfoundPage />
        </div>
      )}
    </Box>
  );
}

export default InvestorsContainer;
