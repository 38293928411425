import React, { useState, useContext, useEffect } from 'react';
import Box from '../../components/single_component/Box';
import AddProject1 from '../../components/Forms/AddProject1';
import AddProject2 from '../../components/Forms/AddProject2';
import AddProject3 from '../../components/Forms/AddProject3';
import ProjectContext from '../../context/ProjectContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Props from '../../types/props';

function EditProjectStepper({ ...props }: Props) {
  const { groupName } = props.extra;
  const { editProject, getProject, ProjectData } = useContext(ProjectContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [formState, setFormState] = useState<any>();
  const [loading, setLoading] = useState(false);

  const onNext = (values: any) => {
    const curentFormState = { ...formState, ...values };
    console.error('formdatat', curentFormState);
    setFormState(curentFormState);
    if (activeStep >= 2) {
      submitForm(curentFormState);
    } else {
      setActiveStep(step => step + 1);
      submitForm(curentFormState);
    }
  };
  const submitForm = async (curentFormState: any) => {
    console.error('editFormState', curentFormState);
    setLoading(true);
    try {
      const bodyFormData = new FormData();
      curentFormState?.docFiles &&
        Object.keys(curentFormState?.docFiles)?.forEach((doc: any) => {
          bodyFormData.append(doc, curentFormState?.docFiles[doc]);
        });
      curentFormState?.docFiles && delete curentFormState?.docFiles;
      for (const field of Object.keys(curentFormState)) {
        if (curentFormState[field] !== undefined) {
          bodyFormData.append(field, curentFormState[field]);
        }
      }

      // bodyFormData.append('projectName', curentFormState.projectName);
      // bodyFormData.append('projectType', curentFormState.projectType);
      // bodyFormData.append('projectLender', curentFormState.projectLender);
      // bodyFormData.append('projectDesc', curentFormState.projectDesc);
      // bodyFormData.append('longitude', curentFormState.longitude);
      // bodyFormData.append('latitude', curentFormState.latitude);
      // bodyFormData.append('address', curentFormState.address);
      // bodyFormData.append('cityId', curentFormState.cityId ?? 1);
      // bodyFormData.append('landArea', curentFormState.landArea);
      // bodyFormData.append('landAreaUnit', curentFormState.landAreaUnit);
      // bodyFormData.append('fsiArea', curentFormState.fsiArea);
      // bodyFormData.append('builtUpArea', curentFormState.builtUpArea);
      // bodyFormData.append('builtUpAreaUnit', curentFormState.builtUpAreaUnit);
      // bodyFormData.append('fsiAreaUnit', curentFormState.fsiAreaUnit);
      // bodyFormData.append('noOfTowers', curentFormState.noOfTowers);
      // bodyFormData.append('projectReraNo', curentFormState.projectReraNo);
      // bodyFormData.append(
      //   'revisedRera',
      //   curentFormState.revisedRera == 'Yes' ? '1' : '0'
      // );
      // bodyFormData.append('promoterReraNo', curentFormState.promoterReraNo);
      // bodyFormData.append('sanctionLetter', curentFormState.sanctionLetter);
      // bodyFormData.append('projectReraCert', curentFormState.projectReraCert);
      // bodyFormData.append('promotorReraCert', curentFormState.promotorReraCert);

      // if (curentFormState.revisedRera == 'Yes') {
      //   bodyFormData.append('revisedReraCert', curentFormState.revisedReraCert);
      //   bodyFormData.append('revisedReraNo', curentFormState.revisedReraNo);
      //   bodyFormData.append('reraRegDate', curentFormState.reraRegDate);
      //   bodyFormData.append('propPossExtDate', curentFormState.propPossExtDate);
      //   bodyFormData.append('propPossDate', curentFormState.propPossDate);
      // }
      // bodyFormData.append('configuration', curentFormState.configuration);
      // bodyFormData.append('constStartDate', curentFormState.constStartDate);
      // bodyFormData.append('constEndDate', curentFormState.constEndDate);

      // bodyFormData.append('spoc', JSON.stringify(curentFormState.spocs));
      // bodyFormData.append(
      //   'documents',
      //   JSON.stringify(curentFormState.documents)
      // );

      const response = await editProject(
        ProjectData?.projectId ?? '',
        bodyFormData
      );
      console.error('response', response);
      const { success } = response;
      setLoading(false);
      if (success) {
        toast.success(response.message);
        if (activeStep >= 2) navigate(-1);
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };
  const onPrevious = () => {
    if (activeStep == 0) {
      window.history.back();
    } else {
      setActiveStep(step => step - 1);
    }
  };
  console.error(activeStep, 'activeStep');

  useEffect(() => {
    getProject(location?.state?.id ?? '');
  }, []);

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          gap: 2,
          marginTop: 1,
        }}>
        <Box
          style={{
            width: '32%',
            height: '5px',
            borderRadius: 5,
            background: activeStep >= 0 ? '#104960' : '#C2C2C2',
          }}></Box>
        <Box
          style={{
            width: '32%',
            height: '5px',
            borderRadius: 5,
            background: activeStep >= 1 ? '#104960' : '#C2C2C2',
          }}></Box>
        <Box
          style={{
            width: '32%',
            height: '5px',
            borderRadius: 5,
            background: activeStep == 2 ? '#104960' : '#C2C2C2',
          }}></Box>
      </Box>

      <Box>
        <Box
          style={{
            display: activeStep === 0 ? 'block' : 'none',
          }}>
          <AddProject1
            extra={{ onNext, onPrevious, projectData: ProjectData, groupName }}
          />
        </Box>
        <Box
          style={{
            display: activeStep === 1 ? 'block' : 'none',
          }}>
          <AddProject2
            extra={{ onNext, onPrevious, projectData: ProjectData }}
          />
        </Box>
        <Box
          style={{
            display: activeStep === 2 ? 'block' : 'none',
          }}>
          <AddProject3
            extra={{
              onNext,
              onPrevious,
              btnDisableLoader: loading,
              projectData: ProjectData,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default EditProjectStepper;
