import React from 'react';
import InvestorsContainer from '../container/InvestorContainer';
import Box from '../components/single_component/Box';
import InvestorProvider from '../providers/InvestorProvider';

function Investors() {
  return (
    <InvestorProvider>
      <Box
        style={{ backgroundColor: '#F2FAFE', paddingTop: 6, paddingBottom: 5 }}>
        <InvestorsContainer />
      </Box>
    </InvestorProvider>
  );
}

export default Investors;
