import React from 'react';
import { Navigate } from 'react-router-dom';
import Login from '../pages/Login';
import { ROUTES } from '../types/routeObject';
import { NotFound } from '../pages/NotFound';

export default function PublicRoutes(isAuthenticated: boolean) {
  return [
    {
      path: ROUTES.LOGIN,
      element: !isAuthenticated ? <Login /> : <Navigate to={ROUTES.HOME} />,
    },
    {
      path: ROUTES.NOTFOUND,
      element: <NotFound />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ];
}
