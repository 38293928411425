import React, { useState, useContext, useEffect } from 'react';
import FilterList from '@mui/icons-material/FilterList';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ToDosList from '../components/common_component/ToDosList';
import Box from '../components/single_component/Box';
import Text from '../components/single_component/Text';
import Sidebar from '../components/common_component/Sidebar';
import AddTask from '../components/Forms/AddTask';
import Button from '../components/single_component/Button';
import TodoContext from '../context/TodoContext';
import SalesTodoFilter from '../components/Filter/TodoFilter';
import ProfileContext from '../context/ProfileContext';
import NotfoundPage from '../components/single_component/NotfoundPage';
import Loader from '../components/single_component/Loader';
import WestIcon from '@mui/icons-material/West';
import { MenuItem, Pagination, Select } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import AuthContext from '../context/AuthContext';
import DateUtils from '../utils/dateUtils';
import SalesExecutiveContext from '../context/SalesExecutiveContext';

function ToDoContainer() {
  const [Toggle, setToggle] = useState(false);
  const [FilterSidebar, setFilterSidebar] = useState(false);
  const { ProfileData } = useContext(ProfileContext);
  const { user } = ProfileData || {};
  const userId = user?.userId;
  const username = user?.name;
  const { TodoData, getTodo, loading, todoPagModule } = useContext(TodoContext);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [next, setNext] = useState();
  const [previous, setPrevious] = useState();
  const [currPage, setcurrPage] = useState(1);
  const { masterData } = useContext(AuthContext);
  const data = masterData?.value();
  const [selectedChip, setSelectedChip] = React.useState(-1);
  const [Selected, setSelected] = useState('');
  const [startDate, setDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [Self, setSelf] = useState(null);
  const { SalesExecutive } = useContext(SalesExecutiveContext);
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);
  const assignedTo =
    Self !== null
      ? Self !== 'Self'
        ? SalesExecutive?.records[Selected]?.userId
        : user?.userId
      : null;
  const formattedStartDate = DateUtils.formatDate(startDate, 'YYYY-MM-DD');
  const formattedEndDate = DateUtils.formatDate(endDate, 'YYYY-MM-DD');
  const priority = data?.priorities[selectedChip];

  useEffect(() => {
    setPage(todoPagModule?.data?.total_pages);
    setPrevious(todoPagModule?.data?.prev);
    setNext(todoPagModule?.data?.next);
  }, [todoPagModule]);

  useEffect(() => {
    // Fetch data with pagination and filter parameters
    getTodo(currPage, rowsPerPage, {
      ...(assignedTo && { assignedTo }),
      ...(formattedStartDate &&
        formattedStartDate !== 'Invalid date' && {
          startDate: formattedStartDate,
        }),
      ...(formattedEndDate &&
        formattedEndDate !== 'Invalid date' && { endDate: formattedEndDate }),
      ...(data?.priorities[selectedChip] && {
        priority: priority,
      }),
    });
  }, [currPage, rowsPerPage]);

  const options = [
    {
      id: 2,
      name: 10,
    },
    {
      id: 3,
      name: 20,
    },
    {
      id: 4,
      name: 30,
    },
  ];

  const roleOoptions = [
    {
      id: 1,
      name: 'Self',
    },
    {
      id: 2,
      name: 'Sales Executive',
    },
  ];

  const handleChipClick = (index: number) => {
    setSelectedChip(index);
  };

  const handleFormSubmit = async () => {
    setbtnDisableLoader(true);
    const finalData = {
      ...(assignedTo && { assignedTo }),
      ...(formattedStartDate &&
        formattedStartDate !== 'Invalid date' && {
          startDate: formattedStartDate,
        }),
      ...(formattedEndDate &&
        formattedEndDate !== 'Invalid date' && { endDate: formattedEndDate }),
      ...(data?.priorities[selectedChip] && {
        priority: priority,
      }),
    };
    try {
      const response = await getTodo(currPage, rowsPerPage, finalData);
      setbtnDisableLoader(false);
      if (response) {
        setFilterSidebar(false);
      } else {
        setToggle(false);
        setbtnDisableLoader(false);
      }
    } catch (e) {
      setbtnDisableLoader(false);
      console.error(e);
    }
  };
  return (
    <Box
      style={{
        width: '85%',
        gap: 40,
        margin: 'auto',
        backgroundColor: 'white',
      }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          padding: 3,
          border: '1px solid #E0E0E0',
          boxShadow: ' 0px 2px 10px 0px #1049601A',
          borderRadius: 1,
        }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 1.5,
              justifyContent: 'center',
            }}>
            <Text style={{ fontWeight: 'bold' }}>To-Do’s</Text>
            <Text style={{ fontSize: 14, fontWeight: 400, color: '#667085' }}>
              Here you can add and view all your tasks.
            </Text>
          </Box>

          <Sidebar
            label="Add Task"
            extra={{
              anchor: 'right',
              Toggle,
              setToggle,
              description: 'Add your task here for any work',
              width: 400,
            }}>
            <AddTask
              extra={{
                setToggle,
                userId,
                username,
              }}
            />
          </Sidebar>

          <Sidebar
            label="Filter"
            extra={{
              anchor: 'right',
              Toggle: FilterSidebar,
              setToggle: setFilterSidebar,
              description: 'Filter the data as per your need',
              width: 400,
            }}>
            <SalesTodoFilter
              extra={{
                handleFormSubmit,
                btnDisableLoader,
                roleOoptions,
                setToggle: setFilterSidebar,
                currPage: currPage,
                rowsPerPage: rowsPerPage,
                Self,
                setSelf,
                EndDate: endDate,
                setEndDate,
                startDate,
                setDate,
                Selected,
                setSelected,
                setSelectedChip,
                SalesExecutive,
                handleChipClick,
                data,
                selectedChip,
              }}
            />
          </Sidebar>

          <Box style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Button
              onClick={() => setFilterSidebar(!FilterSidebar)}
              extra={{
                variant: 'outlined',
                color: 'tertiary',
              }}
              style={{ padding: '8px 24px', borderRadius: 1.5 }}
              icons={<FilterList />}>
              <Text style={{ color: 'black', fontSize: 13 }}>Add Filters</Text>
            </Button>

            <Button
              onClick={() => setToggle(!Toggle)}
              style={{ padding: '8px 24px', borderRadius: 1.5 }}
              extra={{
                variant: 'contained',
              }}
              icons={<AddOutlinedIcon />}>
              <Text style={{ color: 'white', fontSize: 13 }}>Add Task</Text>
            </Button>
          </Box>
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            border: '1px solid #EDEDED',
            borderRadius: 2,
          }}></Box>
        {loading ? (
          <Loader />
        ) : Array.isArray(TodoData) && TodoData?.length > 0 ? (
          TodoData?.map((todo: any, index: any) => (
            <ToDosList extra={{ value: todo }} key={index} />
          ))
        ) : (
          <NotfoundPage
            extra={{
              message:
                'This message indicates that there is currently no available information or data to display based on the users search or query.',
            }}
          />
        )}
        <Box
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
            paddingInline: 3,
          }}>
          {previous != null ? (
            <Button
              icons={<WestIcon style={{ fontSize: 14, color: 'black' }} />}
              onClick={() =>
                setcurrPage(currPage == 0 ? currPage : currPage - 1)
              }
              extra={{
                variant: 'outlined',
                color: 'tertiary',
              }}>
              <Text style={{ fontSize: 12, fontWeight: 500, color: 'black' }}>
                Previous
              </Text>
            </Button>
          ) : (
            <Box></Box>
          )}

          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Pagination
              count={page}
              page={currPage}
              shape="rounded"
              color="secondary"
              variant="text"
              sx={{
                '& .MuiPaginationItem-page.Mui-selected': {
                  color: '#FFFFFF',
                  backgroundColor: '#104960 !important',
                },
              }}
              hidePrevButton
              hideNextButton
              onChange={(e, page) => {
                setcurrPage(page);
              }}
            />
            <Select
              value={rowsPerPage}
              sx={{ width: 100, height: 35, marginLeft: 5 }}
              defaultValue={options[0].name}
              onChange={(e: any) => {
                setrowsPerPage(e.target.value);
              }}>
              {options.map(
                (value: any, index: React.Key | null | undefined) => {
                  return (
                    <MenuItem value={value.name} key={index}>
                      <Text style={{ fontSize: 13, fontWeight: 'normal' }}>
                        {value.name} / Page
                      </Text>
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </Box>

          {next != null ? (
            <Button
              icons2={<EastIcon style={{ fontSize: 14, color: 'black' }} />}
              onClick={() => {
                setcurrPage(currPage + 1);
              }}
              extra={{
                variant: 'outlined',
                color: 'tertiary',
              }}>
              <Text style={{ fontSize: 12, fontWeight: 500, color: 'black' }}>
                Next
              </Text>
            </Button>
          ) : (
            <Box></Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
export default ToDoContainer;
