import User from './User';

class TodoList {
  subject: string;
  note: string;
  priority: string;
  assignedTo: User;
  isActive: boolean | null;
  startDate: string | null;
  endDate: string | null;

  constructor(data: Record<string, unknown>) {
    this.subject = (data.subject ?? 'N/A').toString();
    this.note = (data.note ?? 'N/A').toString();
    this.isActive = Boolean(data.isActive);
    this.startDate = (data.startDate ?? '0').toString();
    this.endDate = (data.endDate ?? 'N/A').toString();
    this.priority = (data.priority ?? 'N/A').toString();
    this.assignedTo = new User((data.user as any) ?? {});
  }

  static fromJson(json: Record<string, unknown>): TodoList {
    return new TodoList(json);
  }

  toJson(stringify: boolean = false): Record<string, unknown> | string {
    const jsonData = {
      subject: this.subject,
      note: this.note,
      priority: this.priority,
      assignedTo: this.assignedTo,
      isActive: this.isActive,
      startDate: this.startDate,
      endDate: this.startDate,
    };
    return stringify ? JSON.stringify(jsonData) : jsonData;
  }
}

export default TodoList;
