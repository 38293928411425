import React, { useEffect, useContext } from 'react';
import Box from '../../components/single_component/Box';
import Card from '../../components/single_component/Card';
import Image from '../../components/single_component/Image';
import { Grid } from '@mui/material';
import Text from '../../components/single_component/Text';
import theme from '../../utils/Theme';
import Button from '../../components/single_component/Button';
import TableTop from '../../components/common_component/TableTop';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LocationIcon from '../../assets/images/location.png';
import ProjectContext from '../../context/ProjectContext';
import Building from '../../assets/images/building.png';
import StringUtils from '../../utils/stringUtil';
import { ROUTES } from '../../types/routeObject';
import Loader from '../../components/single_component/Loader';

function ViewSpvContainer() {
  const navigate = useNavigate();

  const { getProjects, Projects, loading } = useContext(ProjectContext);

  const { spvId } = useParams();

  const location = useLocation();

  console.error('loading', loading);

  useEffect(() => {
    getProjects(spvId);
  }, []);

  const buttonComponent = () => {
    return (
      <>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            extra={{
              variant: 'contained',
              color: 'primary',
            }}
            style={{ padding: '8px 24px' }}
            icons={<AddOutlinedIcon />}
            onClick={() =>
              navigate('/add-project', {
                state: {
                  spvId: location?.state?.spvId ?? '',
                  spvName: location?.state?.spvName ?? '',
                  groupName: location?.state?.groupName ?? 'N/A',
                },
              })
            }>
            <Text style={{ color: 'white', fontSize: 13 }}>Add Project</Text>
          </Button>
        </Box>
      </>
    );
  };

  return (
    <Box style={{ width: '85%', margin: 'auto' }}>
      <Card>
        <TableTop
          extra={{
            label: StringUtils.capitalizeFirstLetter(
              location?.state?.spvName ?? ''
            ),
            description: `Here you can create and view SPV under ${
              location?.state?.groupName ?? ''
            }`,
            Components: buttonComponent,
          }}
        />

        <Box style={{ width: '96%', margin: 'auto', marginBottom: 3 }}>
          {loading ? (
            <Loader />
          ) : (
            <Grid container spacing={2}>
              {Projects &&
                Projects.map((value: any, index: number) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                      <Card style={{ paddingBottom: 1 }}>
                        <Image
                          src={value?.coverGallery?.imagePath ?? Building}
                          alt="Avatar"
                          style={{ width: '100%', height: 200 }}
                        />

                        <Box style={{ padding: 1 }}>
                          <Text
                            style={{
                              fontSize: 14,
                              color: theme.palette.primary.main,
                              fontWeight: 600,
                            }}>
                            {StringUtils.capitalizeFirstLetter(
                              value.projectName
                            )}
                          </Text>
                          <Box style={{ marginTop: 1 }}>
                            {value.address == undefined ||
                            value.address == null ||
                            value.address == '' ? (
                              <Text
                                style={{
                                  fontSize: 12,
                                  color: theme.palette.primary.main,
                                }}>
                                N/A
                              </Text>
                            ) : (
                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  gap: 0.5,
                                }}>
                                <Image
                                  src={LocationIcon}
                                  style={{
                                    width: 15,
                                    height: 15,
                                    objectFit: 'cover',
                                    alignSelf: 'flex-start',
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: 12,
                                    color: theme.palette.primary.main,
                                  }}>
                                  {StringUtils.capitalizeFirstLetter(
                                    value.address
                                  )}
                                </Text>
                              </Box>
                            )}
                          </Box>

                          <Button
                            extra={{ variant: 'outlined' }}
                            onClick={() =>
                              navigate(
                                `${ROUTES.VIEW_PROJECT.replace(
                                  ':id',
                                  value.projectId
                                )}`,
                                {
                                  state: {
                                    spvId: location?.state?.spvId ?? '',
                                    projectName:
                                      StringUtils.capitalizeFirstLetter(
                                        value.projectName ?? ''
                                      ),
                                    spvName: location?.state?.spvName ?? '',
                                    groupName:
                                      location?.state?.groupName ?? 'N/A',
                                    groupId: location?.state?.groupId ?? '',
                                  },
                                }
                              )
                            }
                            style={{
                              marginTop: 2,
                              width: '100%',
                              padding: 1.5,
                            }}>
                            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                              View Project
                            </Text>
                          </Button>
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </Box>
      </Card>
    </Box>
  );
}

export default ViewSpvContainer;
