import React from 'react';
import Box from '../../components/single_component/Box';
import Text from '../../components/single_component/Text';
import Mask from '../../assets/Mask Group 5.png';
import Image from '../../components/single_component/Image';
import Props from '../../types/props';

const NotfoundPage = (props: Props = {}) => {
  const { message } = props?.extra ?? {};

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        padding: 3,
        // border: '1px solid #E0E0E0',
        height: '100%',
        // boxShadow: ' 0px 2px 10px 0px #1049601A',
        borderRadius: 1,
        border: '1px solid #E0E0E0',
        // boxShadow: ' 0px 2px 10px 0px #1049601A',
        // borderRadius: 1,
        backgroundColor: 'white',
      }}>
      <Image
        src={Mask}
        style={{ width: 100, height: 100, objectFit: 'cover' }}
      />
      <Text style={{ fontWeight: 'bold' }}>Nothing found</Text>
      <Text
        style={{
          fontSize: 14,
          fontWeight: 400,
          color: '#667085',
          textAlign: 'center',
        }}>
        {message ??
          'This message indicates that there is currently no available information or data.'}
      </Text>
    </Box>
  );
};

export default NotfoundPage;
