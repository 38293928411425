import React, { useContext } from 'react';
import Props from '../../types/props';
import Box from '../single_component/Box';
import Dpicker from '../single_component/Dpicker';
import MultipleChip from '../common_component/MultipleChip';
import TwoButton from '../common_component/TwoButton';
import AuthContext from '../../context/AuthContext';

function SaleDashFilter({ ...props }: Props) {
  const { masterData } = useContext(AuthContext);
  const data = masterData?.value();

  const { Date, setDate, selectedChip, setSelectedChip, fetchData } =
    props?.extra ?? {};

  const onCancel = () => {
    setSelectedChip(-1);
    setDate('select date');
  };

  const handleChipClick = (index: number) => {
    setSelectedChip(index);
  };

  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <Box style={{ width: '90%', alignSelf: 'center' }}>
        <Dpicker
          extra={{
            setDate,
            placeholder: 'Select date',
          }}
          value={Date}
          style={{ width: '100%', marginTop: 2, marginBottom: 1 }}
          label="Date"
        />

        <MultipleChip
          style={{ width: '100%', marginTop: 2, marginBottom: 1 }}
          label="Status"
          extra={{
            handleClick: handleChipClick,
            options: data?.transactionStatus,
            variant: 'outlined',
            clickable: true,
            selectedChip: selectedChip,
          }}
        />

        <TwoButton
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bottom: 10,
            position: 'absolute',
            width: '90%',
          }}
          extra={{
            onclickFirst: onCancel,
            OnClickSecond: fetchData,
            firstVariant: 'outlined',
            secondVariant: 'contained',
            firstButtonText: 'Clear filter',
            secondButtonText: 'Apply',
          }}
        />
      </Box>
    </Box>
  );
}

export default SaleDashFilter;
