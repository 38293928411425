import React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import DateAdapter from '../../utils/DateAdapter';
import TextLabel from './TextLabel';
import Box from './Box';
import { makeStyles } from '@mui/styles';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Props from '../../types/props';

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      border: 0,
      borderRadius: 3,
      fontSize: 12,
      height: 25,
    },
  },
});

function Dpicker(props: Props = {}) {
  const { label, disablePast, disabled, style, readOnly } = props ?? '';
  const { placeholder, setDate, minDate, maxDate } = props?.extra ?? '';
  const classes = useStyles();
  return (
    <Box style={style}>
      <DateAdapter>
        <TextLabel> {label}</TextLabel>
        <DatePicker
          value={props?.value || undefined}
          readOnly={readOnly}
          defaultValue={props?.defaultValue}
          onChange={e => {
            setDate(e);
          }}
          minDate={minDate}
          maxDate={maxDate}
          disablePast={disablePast}
          disabled={disabled}
          format="dd-MM-yyyy"
          className={classes.root}
          sx={{ width: '100%', fontFamily: 'Inter' }}
          slots={{
            openPickerIcon: CalendarMonthOutlinedIcon,
          }}
          slotProps={{ textField: { size: 'small', placeholder: placeholder } }}
        />
      </DateAdapter>
    </Box>
  );
}

export default Dpicker;
