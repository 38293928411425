import React from 'react';
import DashboardContainer from '../container/DashboardContainer';
import SalesExecutiveProvider from '../providers/SalesExecutiveProvider';
import DashBoardProvider from '../providers/DashboardProvider';
import TodoProvider from '../providers/TodoProvider';

function Dashboard() {
  return (
    <SalesExecutiveProvider>
      <DashBoardProvider>
        <TodoProvider>
          <DashboardContainer />
        </TodoProvider>
      </DashBoardProvider>
    </SalesExecutiveProvider>
  );
}

export default Dashboard;
