import React, { useState } from 'react';
import Props from '../types/props';
import investorService from '../services/InvestorService';
import InvestorContext from '../context/InvestorContext';

export default function InvestorProvider(props: Props) {
  const [InvestorDetail, setInvestorDetail] = useState({});
  const [Data, setData] = useState({});
  const [investorId, setInvestorId] = useState(-1);
  const [loading, setLoading] = useState(true);

  const getInvestorDetail = async (id: number) => {
    setInvestorId(id);
    setLoading(true);
    const response = await new investorService().getInvestorDetail(
      `investors/get/details/${id}`
    );
    setLoading(false);
    setInvestorDetail(response);
  };

  const getInvestors = async (
    page: number,
    size: number,
    filter: Record<string, unknown>
  ) => {
    setLoading(true);
    const data = await new investorService().getInvestors(page, size, filter);
    setLoading(false);
    setData(data.data);
    return { success: data.success, message: data.message };
  };

  const addInvestor = async (data: Record<string, unknown>) => {
    const response = await new investorService().addInvestor(data);
    if (response.success) {
      getInvestors(1, 10, {});
    }
    return response;
  };

  const addInvestorFormat = async (data: Record<string, unknown>) => {
    const response = await new investorService().addInvestorFormat(
      investorId,
      data
    );
    if (response.success) {
      getInvestorDetail(investorId);
    }
    return response;
  };

  const addInvestorThesis = async (
    investorId: number,
    data: Record<string, unknown>
  ) => {
    const response = await new investorService().addInvestorThesis(
      investorId,
      data
    );
    if (response.success) {
      getInvestorDetail(investorId);
    }
    return response;
  };

  const addContactPerson = async (
    investorId: number,
    data: Record<string, unknown>
  ) => {
    const response = await new investorService().addContactPerson(
      investorId,
      data
    );
    if (response.success) {
      getInvestorDetail(investorId);
    }
    return response;
  };

  const addActiveMarkets = async (
    investorId: number,
    data: Record<string, unknown>
  ) => {
    const response = await new investorService().addActiveMarkets(
      investorId,
      data
    );
    if (response.success) {
      getInvestorDetail(investorId);
    }
    return response;
  };
  const updateDealDetails = async (investorId: number, data: any) => {
    const response = await new investorService().updateDealDetails(
      investorId,
      data
    );
    if (response.success) {
      getInvestorDetail(investorId);
    }
    return response;
  };
  const oneInvestorDownload = async (id: number, name: string) => {
    const response = await new investorService().downloadInvestorDetail(
      id,
      name
    );
    return response;
  };
  const deleteContactPerson = async (
    ContactPerId: number,
    investorId: number
  ) => {
    setLoading(true);
    const response = await new investorService().deleteContactPerson(
      ContactPerId
    );
    if (response.success) {
      getInvestorDetail(investorId);
    }
    setLoading(false);
    return response;
  };
  const deleteInvestorProfileImage = async (investorId: number) => {
    setLoading(true);
    const response = await new investorService().deleteInvestorProfileImage(
      investorId
    );
    if (response.success) {
      getInvestorDetail(investorId);
    }
    setLoading(false);
    return response;
  };
  const deleteInvestorDataFormat = async (
    invDataFormatId: number,
    investorId: number
  ) => {
    setLoading(true);
    const response = await new investorService().deleteInvestorDataFormat(
      invDataFormatId
    );
    if (response.success) {
      getInvestorDetail(investorId);
    }
    setLoading(false);
    return response;
  };
  const onInvestorsDownload = async (ids: number[]) => {
    const response = await new investorService().downloadInvestorsDetail(ids);
    return response;
  };

  // useEffect(() => {
  //   getInvestors(1, 10, {}); // Assuming page=1, size=10, and an empty filter object
  // }, []);

  return (
    <InvestorContext.Provider
      value={{
        getInvestorDetail,
        InvestorDetail,
        Data,
        addInvestor,
        getInvestors,
        addInvestorFormat,
        deleteContactPerson,
        updateDealDetails,
        investorId,
        setLoading,
        loading,
        addInvestorThesis,
        addContactPerson,
        addActiveMarkets,
        deleteInvestorDataFormat,
        oneInvestorDownload,
        deleteInvestorProfileImage,
        onInvestorsDownload,
      }}>
      {props.children}
    </InvestorContext.Provider>
  );
}
