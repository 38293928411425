import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import Button from '../single_component/Button';
import Props from '../../types/props';
import Sidebar from './Sidebar';
// import ActiveApproveAction from '../Action/ActiveApproveAction';
import { makeStyles } from '@mui/styles';
import AuthService from '../../services/AuthService';
import AppNotification from '../../models/AppNotification';
import KlaxonInvoiceAdmin from '../Action/KlaxonInvoiceAdmin';
import TransactionService from '../../services/TransactionService';
import MailProvider from '../../providers/MailProvider';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-container': {
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
    },
  },
});

function Notification({ ...props }: Props) {
  const classes = useStyles();
  const { Toggle, setToggle } = props.extra;
  const [ApprovedSidebar, setApprovedSidebar] = useState(false);
  const [notifications, setNotifications] = useState<AppNotification[]>([]);
  const [transaction, setTransaction] = useState<Record<
    string,
    unknown
  > | null>(null);

  const getNotification = async () => {
    setNotifications(await new AuthService().getNotifications());
  };

  const onViewTransaction = async (transactionId: string) => {
    setTransaction(
      await new TransactionService().getTransactionById(transactionId)
    );
    setToggle(false);
    setApprovedSidebar(true);
  };

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={Toggle}
        onClose={() => setToggle(false)}
        scroll="paper"
        className={classes.root}
        sx={{
          position: 'absolute',
          top: 30,
          right: 70,
        }}>
        <DialogTitle style={{ fontSize: 16, fontWeight: 'bold' }}>
          Notifications
        </DialogTitle>

        <DialogContent>
          <Box>
            <Text style={{ fontSize: 14 }}>Today</Text>
            {notifications.map((value: AppNotification, index: number) => {
              return (
                <Box
                  key={index}
                  style={{
                    backgroundColor: (index + 1) % 2 == 0 ? 'white' : '#F2FAFE',
                    padding: 1.5,
                    paddingInline: 2,
                    borderRadius: 1,
                    display: 'flex',
                    marginTop: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 4,
                  }}>
                  <Box>
                    <Box
                      style={{
                        display: 'flex',
                        gap: 1,
                        alignItems: 'center',
                        marginBottom: 0.4,
                      }}>
                      <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                        {value.text}
                      </Text>
                      {value.seenAt ? (
                        <Text
                          style={{
                            fontSize: 10,
                            backgroundColor: '#25A9E0',
                            padding: '3px 15px',
                            borderRadius: 50,
                            color: 'white',
                          }}>
                          New
                        </Text>
                      ) : null}
                    </Box>
                    <Text style={{ fontSize: 14 }}>{value.description}</Text>
                  </Box>

                  {(value.action ?? {})['action'] == 'click' && (
                    <Button
                      extra={{ variant: 'contained', color: 'primary' }}
                      onClick={() =>
                        onViewTransaction(
                          ((value.action ?? {})['transaction'] ?? '').toString()
                        )
                      }>
                      <Text style={{ fontSize: 13 }}>View</Text>
                    </Button>
                  )}
                </Box>
              );
            })}
          </Box>
          {/* <Box>
            <Text style={{ fontSize: 14 }}>Yesterday</Text>
            {notifications.map((value: any, index: number) => {
              return (
                <Box
                  key={index}
                  style={{
                    backgroundColor: (index + 1) % 2 == 0 ? 'white' : '#F2FAFE',
                    padding: 1.5,
                    paddingInline: 2,
                    borderRadius: 1,
                    display: 'flex',
                    marginTop: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 4,
                  }}>
                  <Box>
                    <Box
                      style={{
                        display: 'flex',
                        gap: 1,
                        alignItems: 'center',
                        marginBottom: 0.4,
                      }}>
                      <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
                        {value.label}
                      </Text>
                    </Box>
                    <Text style={{ fontSize: 14 }}>{value.description}</Text>
                  </Box>
                </Box>
              );
            })}
          </Box> */}
        </DialogContent>
      </Dialog>

      <Sidebar
        label="Klaxon Invoice"
        style={{ zIndex: 1 }}
        extra={{
          anchor: 'right',
          Toggle: ApprovedSidebar,
          setToggle: setApprovedSidebar,
          description: '',
          width: 800,
        }}>
        <MailProvider>
          <KlaxonInvoiceAdmin
            extra={{
              setToggle: setApprovedSidebar,
              label: transaction?.transcStatus ?? '',
              data: transaction,
            }}
          />
        </MailProvider>
      </Sidebar>
    </>
  );
}

export default Notification;
