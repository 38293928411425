import React from 'react';
import Box from '../single_component/Box';
import TextWithLabelGray from '../common_component/TextWithLabelGray';
import LabelWithList from '../common_component/LabelWithList';
import Text from '../single_component/Text';
import Props from '../../types/props';
import DocumentBox from '../common_component/DocumentBox';
import StatusChip from '../common_component/StatusChip';
import TwoButton from '../common_component/TwoButton';
import DateUtils from '../../utils/dateUtils';

function Cancelled({ ...props }: Props) {
  const { setToggle, label, data } = props.extra;
  const onCancel = () => {
    setToggle(false);
  };
  const handleSubmit = () => {
    setToggle(false);
  };

  return (
    <Box style={{ width: '90%', margin: 'auto', paddingTop: 1 }}>
      <Box style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <TextWithLabelGray
          label="Date of transaction"
          extra={{
            description: DateUtils.formatDate(data?.date, ' MMM DD,YYYY'),
          }}
        />
        <TextWithLabelGray
          label="Deal Size"
          extra={{
            description: `₹ ${data?.dealSize} `,
          }}
        />
      </Box>
      <Box style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Text style={{ color: '#8b8b8b', fontSize: 14 }}>
          Transaction status
        </Text>
        <StatusChip label={label} />
      </Box>
      <TextWithLabelGray
        label="Brief"
        extra={{
          description: data?.transcBrief,
        }}
      />
      <LabelWithList
        label="Potential Investors"
        extra={{
          options: data?.investors,
        }}
      />
      <Box>
        <DocumentBox
          download
          extra={{
            url: data.mandate,
            chiplabel: 'Mandate',
            textlabel: 'Documents',
            GrayColor: 'Grayforform',
          }}
        />

        <TextWithLabelGray
          label="Remark"
          extra={{
            description: data?.remark,
          }}
        />
      </Box>
      <TwoButton
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bottom: 10,
          position: 'absolute',
          width: '90%',
        }}
        extra={{
          onclickFirst: onCancel,
          OnClickSecond: handleSubmit,
          firstVariant: 'outlined',
          secondVariant: 'contained',
          firstButtonText: 'Cancel',
          secondButtonText: 'Okay',
        }}
      />
    </Box>
  );
}

export default Cancelled;
