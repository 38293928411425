import React, { useContext } from 'react';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import Image from '../single_component/Image';
import HiHand from '../../assets/HiHand.png';
import DropdownlBold from './DropdownBold';
import ProfileContext from '../../context/ProfileContext';
import StringUtils from '../../utils/stringUtil';
import Props from '../../types/props';
function SalesWelcomeBox(props: Props) {
  const { ProfileData } = useContext(ProfileContext);
  const { user } = ProfileData || {};

  const durationNames = [
    "Today's",
    'Weekly',
    'Monthly',
    'Quarterly',
    'Half-Yearly',
    'Yearly',
  ];

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Text
          style={{
            fontSize: 32,
            fontWeight: '600',
          }}>
          Welcome back, {StringUtils.capitalizeFirstLetter(user?.name)}
        </Text>
        <Image
          src={HiHand}
          style={{
            width: 32,
            height: 32,
            objectFit: 'cover',
            marginLeft: '10px',
          }}
        />
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end',
        }}>
        <Box style={{ width: 150 }}>
          <DropdownlBold
            value={durationNames[props?.extra?.duration ?? 2] ?? {}}
            name="duration"
            id="duration"
            onChange={(e: any) => {
              props?.extra?.setDuration(e.target.value);
            }}
            extra={{
              options: durationNames.map(e => ({
                name: e,
              })),
              placeholder: 'Select location',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default SalesWelcomeBox;
