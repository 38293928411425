import React, { useContext, useEffect, useState } from 'react';
import Box from '../single_component/Box';
import { useFormik } from 'formik';
import Props from '../../types/props';
import TwoButton from '../common_component/TwoButton';
import TextInput from '../common_component/TextInput';
import UploadInput from '../single_component/UploadInput';
import DeveloperGroupContext from '../../context/DeveloperGroupContext';
import ErrorText from '../common_component/ErrorText';
import { AddGroup } from '../Validation/AddTask';
import { toast } from 'react-toastify';
import Button from '../single_component/Button';
import Text from '../single_component/Text';
import AddOutlined from '@mui/icons-material/AddOutlined';
// import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DocumentBox from '../common_component/DocumentBox';
import { Modal } from '@mui/material';
import { Close, Delete, Edit } from '@mui/icons-material';
import StringUtils from '../../utils/stringUtil';
import EditPromoterModal from './EditPromoterModal';

type Promoter = {
  promoterName: string;
  promoterEmail: string;
  promoterPhone: string;
  promoterId: string;
};
function AddGroupForm({ ...props }: Props) {
  const {
    editDeveloperGroup,
    addDeveloperGroup,
    getDeveloperGroup,
    deleteDeveloperGroupDocs,
    deleteDevPromoterById,
    deleteDevPromoterDocId,
  } = useContext(DeveloperGroupContext);

  const handleDeleteDeveloperDocs = async (
    developerId: number,
    name: string
  ) => {
    try {
      const response = await deleteDeveloperGroupDocs(developerId, name);
      if (response?.success) {
        toast.success(response?.message);
        getDeveloperGroup(`/developer/getAll`, {
          page: currPage,
          size: rowsPerPage,
        });
        setToggle(false);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('Error occurred while adding file:', error);
    }
  };
  const handleUpdateDeveloper = async (
    developerId: number,
    file: any,
    name: string
  ) => {
    setToggle(false);
    const bodyFormData = new FormData();
    bodyFormData.append(name, file);
    if (file !== undefined) {
      try {
        const response = await editDeveloperGroup(developerId, bodyFormData);
        if (response?.success) {
          toast.success(response?.message);
          getDeveloperGroup(`/developer/getAll`, {
            page: currPage,
            size: rowsPerPage,
          });
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        console.error('Error occurred while adding file:', error);
      }
    }
  };
  const { setToggle, SidebarData, isEditGroup, currPage, rowsPerPage } =
    props.extra;
  const onCancel = () => {
    setToggle(false);
  };
  const [isEditPromoter, setisEditPromoter] = useState(false);
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);
  const [editPromoterModal, setEditPromoterModal] = useState(false);
  const [promoterDocs, setPromoterDocs] = useState({
    docFiles: [],
    documents: {},
  });
  const [isAddPromoter, setIsAddPromoter] = useState(false);
  const [selectedPromoter, setSelectedPromoter] = useState(0);
  const initialPromotersData = SidebarData?.promoters?.map((promoter: any) => ({
    promoterName: promoter.promoterName || '',
    promoterEmail: promoter.promoterEmail || '',
    promoterPhone: promoter.promoterPhone || '',
    promoterId: promoter?.promoterId,
    documents: promoter.PromoterDocuments,
  }));
  const [promoterModal, setPromoterModal] = useState(false);
  const [promoters, setPromoters] = useState(
    isEditGroup ? initialPromotersData : []
  );
  useEffect(() => {
    const initialPromotersData = SidebarData?.promoters?.map(
      (promoter: any) => ({
        promoterName: promoter.promoterName || '',
        promoterEmail: promoter.promoterEmail || '',
        promoterPhone: promoter.promoterPhone || '',
        promoterId: promoter?.promoterId,
        documents: promoter?.PromoterDocuments,
      })
    );
    setPromoters(isEditGroup ? initialPromotersData : []);
  }, [SidebarData]);
  const handleAddPromoter = () => {
    setPromoters([
      ...promoters,
      {
        promoterName: '',
        promoterEmail: '',
        promoterPhone: '',
        // promoterId: '',
      },
    ]);
  };
  const handleDeletePromoter = async (index: number, promoterId: string) => {
    if (promoterId) {
      try {
        const response = await deleteDevPromoterById(promoterId);
        if (response.success) {
          toast.success(response.message);
          setisEditPromoter(false);
          // if (promoters.length === 1) {
          //   return;
          // }
          const updatedPromoters = [...promoters];
          updatedPromoters.splice(index, 1);
          setPromoters(updatedPromoters);
          getDeveloperGroup(`/developer/getAll`, {
            page: currPage,
            size: rowsPerPage,
          });
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error('An error occurred:', error);
        toast.error('An error occurred while deleting the promoter.');
      }
    } else {
      const updatedPromoters = [...promoters];
      updatedPromoters.splice(index, 1);
      setPromoters(updatedPromoters);
    }
  };
  function findMissingObjects(arr1: Promoter[], arr2: Promoter[]): Promoter[] {
    const missingObjects: Promoter[] = [];
    arr1?.forEach(obj1 => {
      const found = arr2?.some(
        obj2 =>
          obj1?.promoterName === obj2?.promoterName &&
          obj1?.promoterEmail === obj2?.promoterEmail &&
          obj1?.promoterPhone === obj2?.promoterPhone
      );

      if (!found) {
        missingObjects?.push(obj1);
      }
    });

    return missingObjects;
  }
  const missingObjects = findMissingObjects(promoters, SidebarData?.promoters);
  const handlePromoterEdit = () => {
    setEditPromoterModal(false);
    if (isAddPromoter) {
      const promoterArr = promoters;
      promoterArr.pop();
      setPromoters(promoterArr);
      setIsAddPromoter(false);
    }
  };

  const handlePromoterChange = (
    index: number,
    field: string,
    value: string
  ) => {
    if (isEditGroup) {
      setisEditPromoter(true);
    }
    const updatedPromoters = [...promoters];
    updatedPromoters[index][field] = value;
    setPromoters(updatedPromoters);
  };

  const initialValues = isEditGroup
    ? {
        Name: `${SidebarData?.name}`,
        location: `${SidebarData?.city?.name}`,
        promoters: [
          {
            promoterName: '',
            promoterEmail: '',
            promoterPhone: '',
          },
        ],
        GroupProfile: `${SidebarData?.GroupProfile ? SidebarData?.GroupProfile : ''}`,
      }
    : {
        Name: '',
        location: '',
        GroupLogo: '',
        promoters: [
          {
            promoterName: '',
            promoterEmail: '',
            promoterPhone: '',
          },
        ],
        GroupProfile: `${SidebarData?.GroupLogo ? SidebarData?.GroupProfile : ''}`,
      };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: AddGroup,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, action) => {
      setbtnDisableLoader(true);
      const bodyFormData = new FormData();
      bodyFormData.append('name', values.Name);
      values.location && bodyFormData.append('cityId', values.location);
      values.GroupLogo !== undefined
        ? bodyFormData.append('groupLogo', values.GroupLogo)
        : null;
      if (promoters.length > 0) {
        (promoters[0]['promoterName'] ||
          promoters[0]['promoterEmail'] ||
          promoters[0]['promoterPhone']) &&
          bodyFormData.append(
            'promoter',
            isEditPromoter
              ? JSON.stringify(missingObjects)
              : JSON.stringify(promoters)
          );
      }
      values.GroupProfile &&
        bodyFormData.append('groupProfile', values.GroupProfile);
      Object.keys(promoterDocs.docFiles).forEach((doc: any) => {
        bodyFormData.append(doc, promoterDocs.docFiles[doc]);
      });

      try {
        let response;
        if (isEditGroup) {
          response = await editDeveloperGroup(
            SidebarData?.developerId,
            bodyFormData
          );
        } else {
          response = await addDeveloperGroup(bodyFormData);
        }
        setbtnDisableLoader(false);
        if (response.success) {
          setTimeout(() => {
            getDeveloperGroup(`/developer/getAll`, {
              page: currPage,
              size: rowsPerPage,
            });
          }, 100);
          (promoterModal === false || isEditGroup === false) &&
            setToggle(false);
          setIsAddPromoter(false);
          setEditPromoterModal(false);
          setbtnDisableLoader(false);
          toast.success(response.message);
          action.resetForm();
        } else {
          setbtnDisableLoader(false);
          toast.error(response.message);
        }
      } catch (e) {
        setbtnDisableLoader(false);
        console.error(e);
      }
    },
  });
  const handlePromoterSubmit = (obj: any) => {
    const updatedPromoter = [...promoters];
    updatedPromoter[selectedPromoter] = {
      ...updatedPromoter[selectedPromoter],
      documents: obj.documents,
    };
    setPromoters(updatedPromoter);
    setPromoterDocs({
      ...promoterDocs,
      ['docFiles']: { ...obj.docFiles, ...promoterDocs.docFiles },
    });
    if (isEditGroup) handleSubmit();
    else {
      setIsAddPromoter(false);
      setEditPromoterModal(false);
    }
  };
  const handledocdelete = async (id: any) => {
    if (id) {
      try {
        const response = await deleteDevPromoterDocId(id);
        if (response.success) {
          toast.success(response.message);
          getDeveloperGroup(`/developer/getAll`, {
            page: currPage,
            size: rowsPerPage,
          });
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error('An error occurred:', error);
        toast.error('An error occurred while deleting the promoter.');
      }
    }
  };
  useEffect(() => {
    setValues(
      isEditGroup
        ? {
            Name: `${SidebarData?.name}`,
            location: `${SidebarData?.city?.name}`,
            promoters: [
              {
                promoterName: '',
                promoterEmail: '',
                promoterPhone: '',
              },
            ],
            GroupProfile: `${SidebarData?.GroupProfile ? SidebarData?.GroupProfile : ''}`,
          }
        : {
            Name: '',
            location: '',
            GroupLogo: '',
            promoters: [
              {
                promoterName: '',
                promoterEmail: '',
                promoterPhone: '',
              },
            ],
            GroupProfile: `${SidebarData?.GroupLogo ? SidebarData?.GroupProfile : ''}`,
          }
    );
  }, [SidebarData]);

  console.error(SidebarData, 'sideBarData');
  return (
    <Box
      style={{ width: '90%', margin: 'auto', marginTop: 2, paddingBottom: 10 }}>
      <Box>
        <TextInput
          label="Name of the group"
          name="Name"
          id="Name"
          placeholder="Enter name of the group"
          value={values.Name}
          onBlur={handleBlur}
          onChange={handleChange}
          // onInput={(e: any) => {
          //   e.target.value = e.target.value.replace(/[0-9]/g, '');
          // }}
        />
        {touched.Name && errors.Name ? (
          <ErrorText>{errors.Name}</ErrorText>
        ) : null}
        <TextInput
          label="Location"
          name="location"
          id="location"
          placeholder="Enter location name"
          value={values.location}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {touched.location && errors.location ? (
          <ErrorText>{errors.location}</ErrorText>
        ) : null}

        {SidebarData?.groupLogo && isEditGroup ? (
          <DocumentBox
            extra={{
              type: 'editMandate',
              url: SidebarData?.groupLogo,
              chiplabel: 'Group logo',
              textlabel: 'Group logo',
              GrayColor: 'Grayforform',
              handleDeleteDocuments: () =>
                handleDeleteDeveloperDocs(
                  SidebarData?.developerId,
                  'groupLogo'
                ),
              component: (
                <UploadInput
                  name="mandate"
                  extra={{
                    isModify: true,
                    onchange: (e: any) => {
                      handleUpdateDeveloper(
                        SidebarData?.developerId,
                        e[0],
                        'groupLogo'
                      );
                    },
                  }}
                />
              ),
            }}
          />
        ) : (
          <UploadInput
            label="Group logo"
            name="GroupLogo"
            id="GroupLogo"
            onBlur={handleBlur}
            extra={{
              onchange: (e: any) => {
                setFieldValue('GroupLogo', e[0]);
              },
            }}
          />
        )}
        {touched.GroupLogo && errors.GroupLogo ? (
          <ErrorText>{errors.GroupLogo}</ErrorText>
        ) : null}

        {/* {promoters.map((promoter: any, index: any) => (
          <Box key={index}>
            {promoters.length > 1 && (
              <Box
                style={{
                  textAlign: 'right',
                  marginTop: '1rem',
                  marginBottom: '-30px',
                }}
                onClick={() =>
                  handleDeletePromoter(index, promoter?.promoterId)
                }>
                <RemoveCircleOutlineIcon />
              </Box>
            )}
            <TextInput
              type="text"
              label="Promoter's name"
              placeholder="Enter promoter name"
              name={`promoters[${index}].promoterName`}
              value={promoter.promoterName}
              onChange={(e: any) => {
                handlePromoterChange(index, 'promoterName', e.target.value);
                handleChange(e);
              }}
              onBlur={handleBlur}
            />

            {touched.promoters &&
            touched.promoters[index] &&
            touched.promoters[index].promoterName &&
            errors.promoters &&
            errors.promoters[index] &&
            (errors.promoters[index] as any)?.promoterName ? (
              <ErrorText>
                {(errors.promoters[index] as any)?.promoterName}
              </ErrorText>
            ) : null}
            <TextInput
              type="email"
              label="Promoter’s email ID"
              placeholder="Enter Promoter’s Email ID"
              name={`promoters[${index}].promoterEmail`}
              value={promoter.promoterEmail}
              onChange={(e: any) => {
                handlePromoterChange(index, 'promoterEmail', e.target.value);
                handleChange(e);
              }}
              onBlur={handleBlur}
            />
            {touched.promoters &&
            touched.promoters[index] &&
            touched.promoters[index].promoterEmail &&
            errors.promoters &&
            errors.promoters[index] &&
            (errors.promoters[index] as any)?.promoterEmail ? (
              <ErrorText>
                {(errors.promoters[index] as any)?.promoterEmail}
              </ErrorText>
            ) : null}

            <TextInput
              type="tel"
              label="Promoter’s Phone Number"
              placeholder="Enter Promoter’s Phone Number"
              name={`promoters[${index}].promoterPhone`}
              value={promoter.promoterPhone}
              onChange={(e: any) => {
                handlePromoterChange(index, 'promoterPhone', e.target.value);
                handleChange(e);
              }}
              onInput={(e: any) => {
                e.target.value = e.target.value.replace(/[a-zA-Z\s]/g, '');
              }}
              onBlur={handleBlur}
              maxLength={10}
              minLength={10}
            />
            {touched.promoters &&
            touched.promoters[index] &&
            touched.promoters[index].promoterPhone &&
            errors.promoters &&
            errors.promoters[index] &&
            (errors.promoters[index] as any)?.promoterPhone ? (
              <ErrorText>
                {(errors.promoters[index] as any)?.promoterPhone}
              </ErrorText>
            ) : null}
          </Box>
        ))}
        <Button
          extra={{
            variant: 'outlined',
          }}
          style={{ marginBlock: 1, padding: '8px 25px', borderRadius: 2 }}
          onClick={handleAddPromoter}
          icons={<AddOutlined />}>
          <Text style={{ color: '#104960', fontSize: 13 }}>
            Add another promoter
          </Text>
        </Button> */}

        {SidebarData?.groupProfile && isEditGroup ? (
          <DocumentBox
            extra={{
              type: 'editMandate',
              url: SidebarData?.groupProfile,
              chiplabel: 'Group Profile',
              textlabel: 'Group Profile',
              GrayColor: 'Grayforform',

              handleDeleteDocuments: () =>
                handleDeleteDeveloperDocs(
                  SidebarData?.developerId,
                  'groupProfile'
                ),
              component: (
                <UploadInput
                  name="mandate"
                  extra={{
                    isModify: true,
                    onchange: (e: any) => {
                      handleUpdateDeveloper(
                        SidebarData?.developerId,
                        e[0],
                        'groupProfile'
                      );
                    },
                  }}
                />
              ),
            }}
          />
        ) : (
          <UploadInput
            label="Group Profile"
            id="GroupProfile"
            name="GroupProfile"
            extra={{
              onchange: (e: any) => {
                console.error('it my file', e);
                setFieldValue('GroupProfile', e[0]);
              },
            }}
            onBlur={handleBlur}
          />
        )}

        {touched.GroupProfile && errors.GroupProfile ? (
          <ErrorText>{errors.GroupProfile}</ErrorText>
        ) : null}
        <TwoButton
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bottom: 10,
            position: 'absolute',
            width: '90%',
          }}
          extra={{
            onclickFirst: onCancel,
            OnClickSecond: handleSubmit,
            firstVariant: 'outlined',
            secondVariant: 'contained',
            firstButtonText: 'Cancel',
            secondButtonText: isEditGroup ? 'Save' : 'Add',
            btnDisableLoader: btnDisableLoader,
          }}
        />
        <Button
          extra={{
            variant: 'outlined',
          }}
          style={{
            marginBlock: 1,
            padding: '8px 25px',
            borderRadius: 2,
            marginTop: 2,
          }}
          onClick={() => setPromoterModal(true)}
          // icons={<AddOutlined />}
        >
          <Text style={{ color: '#104960', fontSize: 13 }}>
            Manage promoters
          </Text>
        </Button>

        <Modal
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          open={promoterModal}
          onClose={() => setPromoterModal(false)}>
          <Box
            style={{
              width: '80%',
              background: '#fff',
              padding: 4,
              position: 'relative',
            }}>
            <Close
              onClick={() => setPromoterModal(false)}
              sx={{
                position: 'absolute',
                fontSize: 30,
                right: 15,
                top: 15,
                color: 'red',
                cursor: 'pointer',
              }}
            />
            <Box
              style={{
                background: 'rgb(218, 237, 255)',
                marginRight: 2,
                marginTop: 2,
                padding: 1,
                borderRadius: 2,
              }}>
              <Text>Promoters</Text>
            </Box>
            <Box
              style={{
                overflowY: 'scroll',
                overflowX: 'hidden',
                maxHeight: '450px',
              }}>
              {promoters?.length > 0 ? (
                promoters?.map((value: any, index: number) => {
                  return (
                    <Box
                      style={{
                        display: 'flex',
                        gap: '10px',
                        width: '100%',
                        // flexDirection: 'column',
                        alignItems: 'center',
                        borderBottom: '1px solid #E0E0E0',
                        paddingBottom: 1,
                        paddingTop: 1,
                        margin: '10px 10px',
                      }}
                      key={index}>
                      <Box style={{ display: 'flex', gap: '10px', width: 250 }}>
                        <Text
                          style={{
                            marginTop: '2px',
                            fontSize: '14px',
                            color: '#667085',
                          }}>
                          Name
                        </Text>
                        <Text>:</Text>
                        <Text>
                          {value?.promoterName
                            ? StringUtils.capitalizeFirstLetter(
                                value.promoterName
                              )
                            : 'N/A'}
                        </Text>
                      </Box>
                      <Box style={{ display: 'flex', gap: '10px', width: 250 }}>
                        <Text
                          style={{
                            // width: 130,
                            fontSize: '14px',
                            color: '#667085',
                            marginTop: '2px',
                            // textWrap: 'nowrap',
                          }}>
                          Email ID
                        </Text>
                        <Text>:</Text>
                        <Text style={{ wordBreak: 'break-word' }}>
                          {value?.promoterEmail ? value.promoterEmail : 'N/A'}
                        </Text>
                      </Box>
                      <Box style={{ display: 'flex', gap: '10px', width: 280 }}>
                        <Text
                          style={{
                            // width: 130,
                            fontSize: '14px',
                            color: '#667085',
                            marginTop: '2px',
                          }}>
                          Phone Number
                        </Text>
                        <Text>:</Text>
                        <Text>
                          {' '}
                          {value.promoterPhone
                            ? `+91${value.promoterPhone}`
                            : 'N/A'}
                        </Text>
                      </Box>
                      <Box
                        style={{
                          display: 'flex',
                          gap: '1rem',
                          marginLeft: '20px',
                        }}>
                        <Edit
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            setEditPromoterModal(true);
                            setSelectedPromoter(index);
                          }}
                        />
                        <Delete
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleDeletePromoter(index, value?.promoterId);
                          }}
                        />
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <Text style={{ margin: 2, textAlign: 'center' }}>
                  Promoter not found
                </Text>
              )}
            </Box>
            <Button
              extra={{
                variant: 'outlined',
              }}
              style={{ marginBlock: 1, padding: '8px 25px', borderRadius: 2 }}
              onClick={() => {
                setEditPromoterModal(true);
                handleAddPromoter();
                setSelectedPromoter(promoters?.length);
                setIsAddPromoter(true);
              }}
              icons={<AddOutlined />}>
              <Text style={{ color: '#104960', fontSize: 13 }}>
                Add promoters
              </Text>
            </Button>
            {promoters?.length > 0 && !isEditGroup && (
              <Button
                extra={{
                  variant: 'contained',
                }}
                style={{
                  marginBlock: 1,
                  padding: '8px 25px',
                  borderRadius: 2,
                  marginLeft: 2,
                }}
                onClick={() => {
                  handleSubmit();
                }}
                // icons={<AddOutlined />}
              >
                <Text style={{ color: '#fff', fontSize: 13 }}>Add Group</Text>
              </Button>
            )}
          </Box>
        </Modal>
        <Modal
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          open={editPromoterModal}
          onClose={() => handlePromoterEdit()}>
          <Box
            style={{
              width: '80%',
              background: '#fff',
              padding: 4,
              position: 'relative',
            }}>
            <Close
              onClick={() => handlePromoterEdit()}
              sx={{
                position: 'absolute',
                fontSize: 30,
                right: 15,
                top: 15,
                color: 'red',
                cursor: 'pointer',
              }}
            />
            <EditPromoterModal
              extra={{
                handledocdelete: handledocdelete,
                handleChange: handleChange,
                handlePromoterChange: handlePromoterChange,
                promoter:
                  promoters?.length > 0 ? promoters[selectedPromoter] : {},
                promoterIndex: selectedPromoter,
                touched: touched,
                errors: errors,
                onNext: handlePromoterSubmit,
                document: {
                  documents:
                    SidebarData?.promoters?.length > 0
                      ? SidebarData?.promoters[selectedPromoter]
                          ?.PromoterDocuments
                      : [],
                },
              }}
            />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default AddGroupForm;
