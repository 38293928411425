import React from 'react';
import { TextField } from '@mui/material';
import Props from '../../types/props';

function Input({ ...props }: Props) {
  return (
    <TextField
      type={props.type}
      placeholder={props.placeholder}
      style={props.style}
      value={props.value}
      disabled={props.disabled}
      onChange={props.onChange}
      onBlur={props.onBlur}
      name={props.name}
      id={props.id}
      onInput={props.onInput}
      inputProps={{
        maxLength: props.maxLength,
        minLength: props.minLength,
        readOnly: props.readOnly,
        style: {
          height: 10,
          color: 'black',
          fontSize: 13,
          fontWeight: 'normal',
          background: 'white',
        },
      }}
    />
  );
}

export default Input;
