import React from 'react';
import Props from '../../types/props';
import { CircularProgress, Button as MuiButton } from '@mui/material';

function Button(props: Props = {}) {
  const { btnDisableLoader, disablebutton } = props?.extra ?? {};
  const disabledStyle = btnDisableLoader
    ? {
        backgroundColor: '#6f8a95',
        '& .MuiCircularProgress-svg': {
          color: 'white',
        },
      }
    : {};
  return (
    <MuiButton
      startIcon={props?.icons ?? ''}
      endIcon={props?.icons2 ?? ''}
      sx={{
        ...(props?.style ?? ''),
        '&:disabled': disabledStyle,
      }}
      variant={props?.extra?.variant}
      disabled={btnDisableLoader ?? disablebutton}
      onClick={props?.onClick}
      color={props?.extra?.color}>
      {btnDisableLoader ? (
        <CircularProgress size={25} />
      ) : (
        props?.children ?? ''
      )}
    </MuiButton>
  );
}

export default Button;
