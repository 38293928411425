import React, { useState } from 'react';
import Props from '../types/props';
import TodoContext from '../context/TodoContext';
import TodoService from '../services/Todoservices';
import TodoList from '../models/TodoList';
import User from '../models/User';

export default function TodoProvider(props: Props) {
  const [TodoData, setTodoData] = useState<TodoList[]>([]);
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [todoPagModule, setTodoPagModule] = useState({});

  const getTodo = async (
    page: number,
    size: number,
    filter: Record<string, unknown>
  ) => {
    setLoading(true);
    const response = await new TodoService().getTodo(page, size, filter);
    setLoading(false);
    setTodoData(response?.data?.data as any);
    setTodoPagModule(response);
    console.error(response);
    return response;
  };

  const addTodo = async (username: string, data: any) => {
    console.error('data', username);
    setbtnDisableLoader(true);
    const { success, message } = await new TodoService().addTask(data);

    const user = User.fromJson({
      name: username,
    });

    if (success) {
      const todoTempData = TodoData;
      todoTempData.unshift(
        TodoList.fromJson({
          subject: data?.subject ?? '',
          note: data?.note ?? '',
          isActive: data?.isActive,
          startDate: data?.startDate ?? '',
          endDate: data?.endDate ?? '',
          priority: data?.priority ?? '',
          user: user ?? '',
        })
      );
      setTodoData(todoTempData);
    }
    setbtnDisableLoader(false);
    return { success, message };
  };

  return (
    <TodoContext.Provider
      value={{
        TodoData,
        addTodo,
        getTodo,
        btnDisableLoader,
        setLoading,
        loading,
        setbtnDisableLoader,
        todoPagModule,
      }}>
      {props.children}
    </TodoContext.Provider>
  );
}
