import React from 'react';
import Props from '../../types/props';
import Box from '../single_component/Box';
import TextLabel from '../single_component/TextLabel';
import Input from '../single_component/Input';

function TextInput({ children, ...props }: Props) {
  return (
    <Box style={{ width: '100%', marginTop: 0, marginBottom: 1.5 }}>
      <TextLabel> {props.label}</TextLabel>
      <Input
        id={props.id}
        disabled={props.disabled}
        name={props.name}
        className={props.className}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        style={{ ...props.style, width: props.style?.width ?? '100%' }}
        type={props.type}
        maxLength={props.maxLength}
        minLength={props.minLength}
        onInput={props.onInput}
        readOnly={props.readOnly}
      />
      {children}
    </Box>
  );
}

export default TextInput;
