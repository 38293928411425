import React from 'react';
import Box from '../single_component/Box';
import TwoButton from '../common_component/TwoButton';
import MultipleChip from '../common_component/MultipleChip';
import Props from '../../types/props';
import Dpicker from '../single_component/Dpicker';
import Radio from '../common_component/Radio';
import Dropdown from '../common_component/Dropdown';

function SalesTodoFilter({ ...props }: Props) {
  const {
    handleFormSubmit,
    btnDisableLoader,
    roleOoptions,
    Self,
    setSelf,
    EndDate,
    setEndDate,
    startDate,
    setDate,
    Selected,
    setSelected,
    SalesExecutive,
    handleChipClick,
    setSelectedChip,
    data,
    selectedChip,
  } = props?.extra || {};
  const handleClearFilter = () => {
    setSelected('');
    setSelf(null);
    setSelectedChip(-1);
    setDate('select start date');
    setEndDate('select end date');
  };

  return (
    <Box style={{ width: '90%', margin: 'auto', marginTop: 2 }}>
      <Radio
        style={{ marginTop: 2 }}
        label="Assignee"
        extra={{
          Selected: Self,
          setSelected: setSelf,
          options: roleOoptions,
        }}
      />
      {Self == 'Self' ? null : (
        <Dropdown
          label="Sales Executive"
          name="sales"
          id="sales"
          onChange={(e: any) => {
            setSelected(e.target.value);
          }}
          value={(SalesExecutive.records[Selected] ?? {})['name'] ?? ''}
          extra={{
            options: SalesExecutive?.records,
            placeholder: 'Select sales executive',
          }}
        />
      )}
      <MultipleChip
        style={{ width: '100%', marginTop: 2, marginBottom: 1 }}
        label="Priority of the task"
        extra={{
          handleClick: handleChipClick,
          options: data?.priorities,
          variant: 'outlined',
          clickable: true,
          selectedChip: selectedChip,
        }}
      />

      <Box style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Dpicker
          style={{ margin: 0 }}
          label="Task Date"
          value={startDate}
          extra={{
            setDate,
            placeholder: 'Choose start date',
            maxDate: EndDate,
          }}
        />
        <Dpicker
          style={{ margin: 0, alignSelf: 'flex-end' }}
          label=""
          value={EndDate}
          extra={{
            setDate: setEndDate,
            placeholder: 'Choose end date',
            disblePast: true,
            minDate: startDate,
          }}
        />
      </Box>
      <TwoButton
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bottom: 10,
          position: 'absolute',
          width: '90%',
        }}
        extra={{
          onclickFirst: handleClearFilter,
          OnClickSecond: handleFormSubmit,
          firstVariant: 'outlined',
          secondVariant: 'contained',
          firstButtonText: 'Clear Filter',
          secondButtonText: 'Apply',
          btnDisableLoader: btnDisableLoader,
        }}
      />
    </Box>
  );
}

export default SalesTodoFilter;
