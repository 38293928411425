import React, { useEffect, useRef } from 'react';
import TextInput from '../common_component/TextInput';

interface PlacesAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult) => void;
  id: string;
  label?: string;
  isLoaded: boolean;
  name?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
}

const PlacesAutoComplete: React.FC<PlacesAutocompleteProps> = props => {
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    if (props.isLoaded && !autocompleteRef.current) {
      autocompleteRef.current = new google.maps.places.Autocomplete(
        document.getElementById(props.id) as HTMLInputElement
      );

      if (autocompleteRef?.current) {
        autocompleteRef.current.addListener('place_changed', () => {
          const place = autocompleteRef.current!.getPlace();
          props.onPlaceSelect(place);
        });
      }
    }
  }, [props.isLoaded, props.onPlaceSelect]);

  return (
    <TextInput
      id={props.id}
      label={props.label}
      placeholder={props.placeholder ?? 'Search location'}
      name={props.name}
    />
  );
};

export default PlacesAutoComplete;
