import React, { useState } from 'react';
import Box from '../single_component/Box';
import theme from '../../utils/Theme';
import Props from '../../types/props';
import { Tab, Tabs } from '@mui/material';
import DocumentTab from './DocumentTab';
import Button from '../single_component/Button';
import CommonUtils from '../../utils/commonUtils';
import TextInput from '../common_component/TextInput';
import ErrorText from '../common_component/ErrorText';

function EditPromoterModal({ ...props }: Props) {
  const [Document, setDocument] = useState<Record<string, File>>({});
  // const { btnDisableLoader } = props.extra;
  const projectData = props?.extra?.document ?? [];
  // const { addProjectBanner } = useContext(ProjectContext);
  const {
    handlePromoterChange,
    promoterIndex,
    promoter,
    touched,
    errors,
    handleChange,
  } = props?.extra ?? {};
  // const constitutionalDocuments = projectData?.documents?.filter(
  //   (doc: any) => doc.category === 'constitutional'
  // );
  const kycDocuments = projectData?.documents?.filter(
    (doc: any) => doc.category === 'kyc'
  );
  const financialDocuments = projectData?.documents?.filter(
    (doc: any) => doc.category === 'financial'
  );
  const otherDocuments = projectData?.documents?.filter(
    (doc: any) =>
      doc.category !== 'constitutional' &&
      doc.category !== 'kyc' &&
      doc.category !== 'financial'
  );

  const [selectedTab, setSelectedTab] = React.useState(0);
  const [files, setFiles] = useState<Record<string, any>>({
    constitutional: [
      {
        documentName: '',
        fileName: '',
      },
    ],
    kyc: [
      {
        documentName: '',
        fileName: '',
      },
    ],
    financial: [
      {
        documentName: '',
        fileName: '',
      },
    ],
    other: [
      {
        documentName: '',
        fileName: '',
      },
    ],
  });

  const handleTabChange = (event: any, newValue: number) => {
    setSelectedTab(newValue);
  };
  const handleChangeDocument = (
    index: number,
    field: string,
    category: string,
    value: unknown
  ) => {
    setFiles((prevFiles: any) => {
      const filesArray = [...prevFiles[category]];

      filesArray.forEach((values: any, ind: number) => {
        if (ind === index) {
          if (field === 'file') {
            const tempDocument = Document;
            const newFileName =
              `promoter${promoterIndex}${category}-files` +
              Object.keys(tempDocument).length;
            tempDocument[newFileName] = value as File;
            setDocument({ ...tempDocument });
            values.fileName = value;
            values.file = newFileName;
          } else if (field === 'text') {
            values.documentName = value;
          }
        }
      });

      return {
        ...prevFiles,
        [category]: filesArray,
      };
    });
  };
  const addMoreField = (category: string) => {
    const fileArray = files[category];

    fileArray?.push({
      documentName: '',
      fileName: '',
    });

    files[category] = fileArray;

    setFiles((prevFiles: any) => {
      return {
        ...prevFiles,
        fileArray,
      };
    });
  };

  const handleSubmit = () => {
    const filteredDocs = CommonUtils.filterNonEmptyDocuments(files);
    props?.extra?.onNext({
      documents: JSON.stringify(filteredDocs),
      docFiles: Document,
    });
  };
  return (
    <Box>
      <Box style={{ fontWeight: 'bold', marginLeft: 2 }}>Promoter Details</Box>

      <Box>
        <Box style={{ display: 'flex', gap: 2, marginBottom: -4 }}>
          <TextInput
            type="text"
            label="Promoter’s Name"
            placeholder="Enter Promoter’s Name"
            // name={`promoters[${promoterIndex}].promoterEmail`}
            value={promoter?.promoterName}
            onChange={(e: any) => {
              handlePromoterChange(
                promoterIndex,
                'promoterName',
                e.target.value
              );
              handleChange(e);
            }}
            // onBlur={handleBlur}
          />
          <TextInput
            type="email"
            label="Promoter’s email ID"
            placeholder="Enter Promoter’s Email ID"
            // name={`promoters[${promoterIndex}].promoterEmail`}
            value={promoter?.promoterEmail}
            onChange={(e: any) => {
              handlePromoterChange(
                promoterIndex,
                'promoterEmail',
                e.target.value
              );
              handleChange(e);
            }}
            // onBlur={handleBlur}
          />
          {touched.promoters &&
          touched.promoters[promoterIndex] &&
          touched.promoters[promoterIndex].promoterEmail &&
          errors.promoters &&
          errors.promoters[promoterIndex] &&
          (errors.promoters[promoterIndex] as any)?.promoterEmail ? (
            <ErrorText>
              {(errors.promoters[promoterIndex] as any)?.promoterEmail}
            </ErrorText>
          ) : null}
          <TextInput
            type="tel"
            label="Promoter’s Phone Number"
            placeholder="Enter Promoter’s Phone Number"
            // name={`promoters[${promoterIndex}].promoterEmail`}
            value={promoter?.promoterPhone}
            onChange={(e: any) => {
              handlePromoterChange(
                promoterIndex,
                'promoterPhone',
                e.target.value
              );
              handleChange(e);
            }}
            onInput={(e: any) => {
              e.target.value = e.target.value.replace(/[a-zA-Z\s]/g, '');
            }}
            maxLength={10}
            minLength={10}
            // onBlur={handleBlur}
          />
        </Box>
      </Box>
      <Box
        {...props}
        style={{
          backgroundColor: 'white',
          boxShadow: ' 0px 2px 10px 0px #10496030',
          borderRadius: 2,
          marginTop: 5,
        }}>
        <Box>
          <Box
            style={{
              backgroundColor: '#E5F7FF',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
            }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              centered
              sx={{
                paddingInline: 5,

                '& .MuiTabs-flexContainer': { justifyContent: 'space-between' },
                '& .MuiTabs-root': {
                  borderTopLeftRadius: '12px !important',
                  borderTopRightRadius: '12px !important',
                },
                '& .MuiTabs-indicator': { background: '#25A9E0', height: 3 },
              }}>
              <Tab
                label="Kyc Documents"
                sx={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              />
              <Tab
                label="Financial Documents"
                sx={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              />
              <Tab
                label="Other Documents"
                sx={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              />
            </Tabs>
          </Box>
          {/* {selectedTab === 0 && (
            <Box style={{overflowX:'scroll',height:'290px'}}>
              <DocumentTab
                extra={{
                  state: files,
                  handleChangeDocument,
                  DocumentType: 'constitutional',
                  addMoreField,
                  editDocuments: constitutionalDocuments,
                  labelWidth:'380px',
                  handledocdelete:props?.extra?.handledocdelete
                }}
              />
            </Box>
          )} */}
          {selectedTab === 0 && (
            <Box style={{ overflowX: 'scroll', height: '290px' }}>
              <DocumentTab
                extra={{
                  state: files,
                  handleChangeDocument,
                  DocumentType: 'kyc',
                  addMoreField,
                  editDocuments: kycDocuments,
                  labelWidth: '380px',
                  handledocdelete: props?.extra?.handledocdelete,
                }}
              />
            </Box>
          )}
          {selectedTab === 1 && (
            <Box style={{ overflowX: 'scroll', height: '290px' }}>
              <DocumentTab
                extra={{
                  state: files,
                  handleChangeDocument,
                  DocumentType: 'financial',
                  addMoreField,
                  editDocuments: financialDocuments,
                  labelWidth: '380px',
                  handledocdelete: props?.extra?.handledocdelete,
                }}
              />
            </Box>
          )}
          {selectedTab === 2 && (
            <Box style={{ overflowX: 'scroll', height: '290px' }}>
              <DocumentTab
                extra={{
                  state: files,
                  handleChangeDocument,
                  DocumentType: 'other',
                  addMoreField,
                  editDocuments: otherDocuments,
                  labelWidth: '380px',
                  handledocdelete: props?.extra?.handledocdelete,
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box style={{ marginTop: 4 }}>
        <Button
          extra={{
            variant: 'contained',
            // btnDisableLoader: btnDisableLoader,
          }}
          onClick={handleSubmit}
          style={{ width: '43%', marginLeft: '2%', paddingBlock: 1 }}>
          Save
        </Button>
      </Box>
    </Box>
  );
}

export default EditPromoterModal;
