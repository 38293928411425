import React, { useContext } from 'react';
import Box from '../single_component/Box';
import TwoButton from '../common_component/TwoButton';
import MultipleChip from '../common_component/MultipleChip';
import Props from '../../types/props';
import Dpicker from '../single_component/Dpicker';
import AuthContext from '../../context/AuthContext';

function TodoFilter({ ...props }: Props) {
  const { masterData } = useContext(AuthContext);
  const data = masterData?.value();
  const {
    selectedChip,
    handleFilter,
    handleChipClick,
    btnDisableLoader,
    EndDate,
    setEndDate,
    StartDate,
    setStartDate,
    setSelectedChip,
  } = props.extra || {};

  const handleClearFilter = () => {
    setStartDate('select start date');
    setEndDate('select end date');
    setSelectedChip(-1);
  };
  return (
    <Box style={{ width: '90%', margin: 'auto', marginTop: 2 }}>
      <MultipleChip
        style={{ width: '100%', marginTop: 2, marginBottom: 1 }}
        label="Priority of the task"
        extra={{
          handleClick: handleChipClick,
          options: data?.priorities,
          variant: 'outlined',
          clickable: true,
          selectedChip: selectedChip,
        }}
      />
      <Box style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Dpicker
          style={{ margin: 0 }}
          label="Task Date"
          value={StartDate}
          extra={{
            setDate: setStartDate,
            placeholder: 'Choose  date',
            maxDate: EndDate,
          }}
        />
        <Dpicker
          style={{ margin: 0, alignSelf: 'flex-end' }}
          label=""
          value={EndDate}
          extra={{
            setDate: setEndDate,
            placeholder: 'Choose end date',
            minDate: StartDate,
          }}
        />
      </Box>
      <TwoButton
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bottom: 10,
          position: 'absolute',
          width: '90%',
        }}
        extra={{
          onclickFirst: handleClearFilter,
          OnClickSecond: handleFilter,
          firstVariant: 'outlined',
          secondVariant: 'contained',
          firstButtonText: 'Clear Filter',
          secondButtonText: 'Apply',
          btnDisableLoader: btnDisableLoader,
        }}
      />
    </Box>
  );
}

export default TodoFilter;
