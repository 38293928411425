import React, { useContext } from 'react';
import Props from '../../types/props';
import Box from '../single_component/Box';
import Image from '../single_component/Image';
import AlertIcon from '../../assets/images/Alert.png';
import { Dialog } from '@mui/material';
import Text from '../single_component/Text';
import Button from '../single_component/Button';
import { toast } from 'react-toastify';
import TransactionsContex from '../../context/TransactionsContex';
import SalesDashboardContext from '../../context/SalesDashBoardContext';
import DashboardContext from '../../context/Dashboard';

const CancelTransactionDialog = (props: Props = {}) => {
  const { projectId, page, userId } = props.extra;
  const { cancelTransaction, getTransaction } = useContext(TransactionsContex);
  const { getDashboardData } = useContext(SalesDashboardContext);
  const { getDashboardData: adminDashBoardData } = useContext(DashboardContext);
  const onCancelTransaction = async () => {
    if (!props?.extra?.transcId) return;
    const cancel = await cancelTransaction(props?.extra?.transcId, projectId);
    if (cancel.success) {
      if (page == 'Sales-Dashboard') {
        getDashboardData(`sales/dashboard`, {
          page: 1,
          size: 10,
        });
      }

      if (page == 'Project') {
        getTransaction(1, 10, {
          filter: {
            projectId,
          },
        });
      }

      if (page == 'Dashboard') {
        adminDashBoardData(`admin/dashboard`, {
          page: 1,
          size: 10,
        });
      }

      if (page == 'Sales-Executive') {
        getTransaction(1, 10, {
          filter: {
            createdBy: userId,
          },
        });
      }

      toast.success(cancel.message);
      if (props?.extra?.closeDialog) props?.extra?.closeDialog();
    } else {
      toast.error(cancel.message);
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      open={props?.extra?.showDialog}
      onClose={props?.extra?.closeDialog}
      scroll="paper"
      sx={{
        position: 'absolute',
        top: 30,
        right: 10,
      }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 2,
          textAlign: 'center',
          alignItems: 'center',
          gap: 2,
          width: 500,
        }}>
        <Image src={AlertIcon} style={{ width: 30 }} />
        <Box>
          <Text style={{ fontSize: 18, fontWeight: 600 }}>
            Are you sure you want to cancel this transaction ?
          </Text>
          <Text style={{ fontSize: 14, color: '#667085', width: '30em' }}>
            By clicking on the yes button you are making sure that you want to
            cancel this transaction.{' '}
          </Text>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bottom: 10,
            width: '90%',
          }}>
          <Button
            extra={{ variant: 'outlined' }}
            onClick={props?.extra?.closeDialog}
            style={{ width: '48%', paddingBlock: 1 }}>
            Cancel
          </Button>
          <Button
            extra={{ variant: 'contained' }}
            onClick={onCancelTransaction}
            style={{ width: '48%', paddingBlock: 1 }}>
            Yes, Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CancelTransactionDialog;
