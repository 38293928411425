import React from 'react';
import Props from '../../types/props';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import TextLabel from './TextLabel';
import { DropzoneArea } from 'material-ui-dropzone';

const MuiDropzoneArea = makeStyles({
  MuiDropzoneArea: {
    borderRadius: '6px !important',
    width: '500px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '130px !important',
    border: '2px dashed #25A9E0 !important',
    backgroundColor: '#F2FAFE !important',
  },
  text: {
    color: '#98A2B3',
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16,
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 10,
  },
  icon: {
    color: '#9E9E9E !important',
    height: '28px !important',
    order: -1,
  },
});

function UploadDoucuments({ ...props }: Props) {
  const { onChange, dropzoneText } = props?.extra || {};
  const classes = MuiDropzoneArea();
  return (
    <Box>
      <TextLabel>{props.label}</TextLabel>
      <DropzoneArea
        classes={{
          root: classes.MuiDropzoneArea,
          text: classes.text,
          textContainer: classes.textContainer,
          icon: classes.icon,
        }}
        dropzoneText={
          dropzoneText ? dropzoneText : 'Drag & drop to upload or Browse'
        }
        onChange={onChange}
        showPreviews={true}
        showPreviewsInDropzone={false}
        useChipsForPreview
        previewText=""
        previewGridClasses={{
          container: 'Chips',
        }}
      />
    </Box>
  );
}

export default UploadDoucuments;
