import { Moment } from 'moment';
import DateUtils from '../utils/dateUtils';

class Investor {
  investorId: number;
  name: string;
  investorType: string; // Assuming ENUM is represented as a string
  investmentTypes: number[]; // Many to Many with Investment Type
  contactPersons: number[]; // Many to Many with InvestmentContactPerson
  dryPowder: number | null;
  dealSizePref: number | null;
  dealType: string; // Assuming ENUM is represented as a string
  investmentCriteria: number[]; // Many to Many with Investment Criteria
  interestRange: number | null;
  activeMarkets: number[]; // JSONB(Array(cityId))
  locationPref: number[]; // JSONB(Array(cityId))
  isActive: boolean;
  createdBy: number;
  updatedBy: number;
  createdAt: Moment | null;
  updatedAt: Moment | null;

  constructor(data: Record<string, unknown>) {
    this.investorId = parseInt((data.investorId ?? 'N/A').toString());
    this.name = (data.name ?? 'N/A').toString();
    this.investorType = (data.investorType ?? 'N/A').toString();
    this.investmentTypes = Array.isArray(data.investmentTypes)
      ? data.investmentTypes.map(id => parseInt(id.toString()))
      : [];
    this.contactPersons = Array.isArray(data.contactPersons)
      ? data.contactPersons.map(id => parseInt(id.toString()))
      : [];
    this.dryPowder =
      data.dryPowder != null ? parseFloat(data.dryPowder.toString()) : null;
    this.dealSizePref =
      data.dealSizePref != null
        ? parseFloat(data.dealSizePref.toString())
        : null;
    this.dealType = (data.dealType ?? 'N/A').toString();
    this.investmentCriteria = Array.isArray(data.investmentCriteria)
      ? data.investmentCriteria.map(id => parseInt(id.toString()))
      : [];
    this.interestRange =
      data.interestRange != null
        ? parseFloat(data.interestRange.toString())
        : null;
    this.activeMarkets = Array.isArray(data.activeMarkets)
      ? data.activeMarkets.map(id => parseInt(id.toString()))
      : [];
    this.locationPref = Array.isArray(data.locationPref)
      ? data.locationPref.map(id => parseInt(id.toString()))
      : [];
    this.isActive = Boolean(data.isActive);
    this.createdBy = parseInt((data.createdBy ?? 'N/A').toString());
    this.updatedBy = parseInt((data.updatedBy ?? 'N/A').toString());
    this.createdAt = DateUtils.parseDate((data.createdAt ?? 'N/A').toString());
    this.updatedAt = DateUtils.parseDate((data.updatedAt ?? 'N/A').toString());
  }

  static fromJson(json: Record<string, unknown>): Investor {
    return new Investor(json);
  }

  toJson(stringify: boolean = false): Record<string, unknown> | string {
    const jsonData = {
      investorId: this.investorId,
      name: this.name,
      investorType: this.investorType,
      investmentTypes: this.investmentTypes,
      contactPersons: this.contactPersons,
      dryPowder: this.dryPowder,
      dealSizePref: this.dealSizePref,
      dealType: this.dealType,
      investmentCriteria: this.investmentCriteria,
      interestRange: this.interestRange,
      activeMarkets: this.activeMarkets,
      locationPref: this.locationPref,
      isActive: this.isActive,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      createdAt: this.createdAt?.toISOString(),
      updatedAt: this.updatedAt?.toISOString(),
    };

    return stringify ? JSON.stringify(jsonData) : jsonData;
  }
}

export default Investor;
