import React from 'react';
import Box from '../single_component/Box';
import Props from '../../types/props';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Text from '../single_component/Text';

function CircleCheckBox({ ...props }: Props) {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 1.5,
      }}>
      <CheckCircleIcon color="primary" style={{ fontSize: 20 }} />
      <Text style={{ fontSize: 13, color: 'black' }}>{props.label}</Text>
    </Box>
  );
}

export default CircleCheckBox;
