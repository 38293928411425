import React, { useState, useEffect } from 'react';
import Props from '../types/props';
import ProfileContext from '../context/ProfileContext';
import ProfileService from '../services/ProfileService';

export default function ProfileProvider(props: Props) {
  const [ProfileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);

  const getProfile = async (query: string) => {
    setLoading(true);
    const response = await new ProfileService().fetchProfile(query);
    setLoading(false);
    setProfileData(response);
  };
  const updateProfile = async (query: string, body: any) => {
    const response = await new ProfileService().updateProfile(query, body);
    // const tempProfile = ProfileData;
    // tempProfile?.user.setName(body['name']);
    // setProfileData(tempProfile);
    return response;
  };
  const updateSalesExcetiveProfile = async (userId: number, body: any) => {
    const response = await new ProfileService().updateSalesExcetiveProfile(
      userId,
      body
    );
    return response;
  };
  const deleteProfileImage = async () => {
    const response = await new ProfileService().deleteProfileImage();
    return response;
  };
  const deleteSalesExecutiveProfileImg = async (userId: number) => {
    const response = await new ProfileService().deleteSalesExecutiveProfileImg(
      userId
    );
    return response;
  };

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken !== null && (authToken ?? '' !== '')) {
      getProfile(`/profile/get`);
    }
  }, []);

  return (
    <ProfileContext.Provider
      value={{
        ProfileData,
        getProfile,
        deleteProfileImage,
        updateProfile,
        setLoading,
        deleteSalesExecutiveProfileImg,
        loading,
        updateSalesExcetiveProfile,
      }}>
      {props?.children ?? ''}
    </ProfileContext.Provider>
  );
}
