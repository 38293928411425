import React, { useContext, useEffect, useState } from 'react';
import Box from './Box';
import Text from './Text';
import Props from '../../types/props';
import AuthContext from '../../context/AuthContext';
import EditIcon from '@mui/icons-material/BorderColor';
import StringUtils from '../../utils/stringUtil';
import Button from './Button';
import { Grid } from '@mui/material';
import TextInput from '../common_component/TextInput';
import InvestorContext from '../../context/InvestorContext';
import { toast } from 'react-toastify';
import Radio from '../common_component/Radio';

function DealDetails({ ...props }: Props) {
  const { data } = props.extra;
  const { masterData } = useContext(AuthContext);
  const { updateDealDetails } = useContext(InvestorContext);
  const [edit, setEdit] = useState(false);
  const masterDataValue = masterData?.value();
  const { InvestmentCriteria, locationPref } = data ?? {};
  const activeMarketsLocations = masterDataValue?.locations.filter(
    (value: any) => locationPref?.includes(value.id)
  );
  const [initialValues, setinitialValues] = useState({
    dryPowder: '',
    dealSizePrefMax: '',
    dealType: '',
    InvestmentCriteria: '',
    interestRange: '',
    locationPref: '',
    remarks: '',
  });
  const [SelectedDealType, setSelectedDealType] = useState(-1);
  const dealTypes =
    masterDataValue?.dealTypes?.map((item: any, index: number) => {
      return {
        id: index + 1,
        name: item,
      };
    }) ?? [];
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setinitialValues({
      ...initialValues,
      [name]: value,
    });
  };
  const handleSubmit = async () => {
    let dealTypeval = masterDataValue?.dealTypes?.indexOf(
      SelectedDealType.toString()
    );
    dealTypeval = dealTypeval ? dealTypeval + 1 : -1;
    const values = initialValues?.dealSizePrefMax?.split('-');
    const dealSizeMinValue = parseInt(values[1]) ? parseInt(values[0]) : 0;
    const dealSizeMaxValue = parseInt(values[1])
      ? parseInt(values[1])
      : parseInt(values[0]);
    const valuesinterest = initialValues?.interestRange?.split('-');
    const InterestRangeMinValue = parseInt(valuesinterest[1])
      ? parseInt(valuesinterest[0])
      : 0;
    const InterestRangeMaxValue = parseInt(valuesinterest[1])
      ? parseInt(valuesinterest[1])
      : parseInt(valuesinterest[0]);
    const response = await updateDealDetails(data?.investorId, {
      dryPowder: initialValues?.dryPowder ?? '',
      dealSizePrefMin: dealSizeMinValue ?? '',
      dealSizePrefMax: dealSizeMaxValue ?? '',
      dealType: dealTypeval > 0 ? dealTypeval.toString() : '1',
      InvestmentCriteria: initialValues?.InvestmentCriteria ?? '',
      interestRangeMin: InterestRangeMinValue ?? '',
      interestRangeMax: InterestRangeMaxValue ?? '',
      locationPref: [initialValues?.locationPref] ?? [],
      remarks: data.remarks ?? '',
    });
    if (response.success) {
      toast.success(response.message);
      setEdit(false);
    } else {
      toast.error(response.message);
    }
  };

  useEffect(() => {
    const interestRangeMin = data?.interestRangeMin;
    const interestRangeMax = data?.interestRangeMax;
    let interestRange;
    if (parseFloat(interestRangeMin) === 0) {
      interestRange = interestRangeMax.toString();
    } else {
      interestRange = interestRangeMin + '-' + interestRangeMax;
    }
    setSelectedDealType(data?.dealType);
    setinitialValues({
      dryPowder: data?.dryPowder ?? '',
      dealSizePrefMax: data?.dealSizePrefMax ?? '',
      dealType: data?.dealType ?? '',
      InvestmentCriteria:
        data?.InvestmentCriteria?.length > 0
          ? data?.InvestmentCriteria[0]?.name
          : '',
      interestRange: interestRange ?? '',
      locationPref:
        activeMarketsLocations !== undefined
          ? activeMarketsLocations[0]?.name
          : '',
      remarks: data.remarks ?? '',
    });
  }, [data]);
  return (
    <Box
      style={{
        background: 'white',
        paddingInline: 2,
        borderRadius: 2,
        paddingBlock: 3,
        boxShadow: '0px 2px 10px 0px #10496030',
      }}>
      {edit ? (
        <>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <TextInput
                label="Dry Powder"
                name="dryPowder"
                id="Dry Powder"
                onChange={handleChange}
                placeholder="Enter Dry Powder"
                value={initialValues?.dryPowder}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                label="DEAL SIZE PREFERENCE"
                name="dealSizePrefMax"
                id="DEAL SIZE PREFERENCE"
                onChange={handleChange}
                placeholder="Enter deal size preference"
                value={initialValues?.dealSizePrefMax}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Radio
                label="DEAL TYPE"
                name="dealType"
                id="dealType"
                value={initialValues.dealType}
                extra={{
                  setSelected: (e: any) => {
                    setSelectedDealType(e);
                  },
                  Selected: SelectedDealType,
                  options: dealTypes,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                label="INVESTMENT CRITERIA"
                name="InvestmentCriteria"
                id="INVESTMENT CRITERIA"
                onChange={handleChange}
                placeholder="INVESTMENT CRITERIA"
                value={initialValues?.InvestmentCriteria}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                label="INTEREST RANGE"
                name="interestRange"
                id="INTEREST RANGE"
                onChange={handleChange}
                placeholder="Enter Interest start range"
                value={initialValues?.interestRange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                label="LOCATION PREFERENCE"
                name="locationPref"
                id="LOCATION PREFERENCE"
                onChange={handleChange}
                placeholder="Enter location preference"
                value={initialValues?.locationPref}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                label="REMARK"
                name="remarks"
                id="REMARK"
                onChange={handleChange}
                placeholder="Enter Remark"
                value={initialValues?.remarks}
              />
            </Grid>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  extra={{
                    variant: 'outlined',
                    color: 'primary',
                  }}
                  style={{
                    borderRadius: 1,
                    width: '98%',
                    marginLeft: 2,
                    padding: 1.5,
                    marginTop: 2,
                  }}
                  onClick={() => {
                    const interestRangeMin = data?.interestRangeMin;
                    const interestRangeMax = data?.interestRangeMax;
                    let interestRange;
                    if (parseFloat(interestRangeMin) === 0) {
                      interestRange = interestRangeMax.toString();
                    } else {
                      interestRange = interestRangeMin + '-' + interestRangeMax;
                    }
                    setinitialValues({
                      dryPowder: data?.dryPowder ?? '',
                      dealSizePrefMax: data?.dealSizePrefMax ?? '',
                      dealType: data?.dealType ?? '',
                      InvestmentCriteria:
                        data?.InvestmentCriteria?.length > 0
                          ? data?.InvestmentCriteria[0]?.name
                          : '',
                      interestRange: interestRange ?? '',
                      locationPref:
                        activeMarketsLocations !== undefined
                          ? activeMarketsLocations[0]?.name
                          : '',
                      remarks: data.remarks ?? '',
                    });
                    setEdit(false);
                  }}>
                  <Text
                    style={{ color: '#104960', fontWeight: 500, fontSize: 13 }}>
                    Cancel
                  </Text>
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  extra={{
                    variant: 'contained',
                    color: 'primary',
                    // btnDisableLoader: loading,
                  }}
                  style={{
                    borderRadius: 1,
                    width: '100%',
                    padding: 1.5,
                    marginTop: 2,
                  }}
                  onClick={handleSubmit}>
                  <Text
                    style={{ color: 'white', fontWeight: 500, fontSize: 13 }}>
                    Save
                  </Text>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 5,
              borderBottom: 1,
              borderColor: '#E7E7E7',
              paddingBottom: '0.5rem',
            }}>
            <Text style={{ fontSize: 20, fontWeight: 500 }}>Deal details</Text>
            <Button
              extra={{ variant: 'outlined', color: 'primary' }}
              style={{ paddingBlock: 1, paddingInline: 2, borderRadius: 1 }}
              icons={<EditIcon style={{ fontSize: 16 }} />}
              onClick={() => setEdit(true)}>
              <Text style={{ color: '#104960', fontWeight: 500, fontSize: 13 }}>
                Edit Details
              </Text>
            </Button>
          </Box>
          <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
            <Box style={{ minWidth: 240 }}>
              <Text
                style={{
                  fontWeight: 600,
                  color: '#757575',
                  textTransform: 'uppercase',
                  fontSize: 14,
                }}>
                Dry Powder
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  marginTop: 3,
                }}>
                ₹{data?.dryPowder}
              </Text>
            </Box>
            <Box style={{ minWidth: 240 }}>
              <Text
                style={{
                  fontWeight: 600,
                  color: '#757575',
                  textTransform: 'uppercase',
                  fontSize: 14,
                }}>
                Deal size preference
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  marginTop: 3,
                }}>
                ₹{data.dealSizePrefMin > 0 && `${data.dealSizePrefMin} - `}
                {data.dealSizePrefMax}
              </Text>
            </Box>
            <Box style={{ minWidth: 240 }}>
              <Text
                style={{
                  fontWeight: 600,
                  color: '#757575',
                  textTransform: 'uppercase',
                  fontSize: 14,
                }}>
                Deal Type
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  marginTop: 3,
                }}>
                {data?.dealType}
              </Text>
            </Box>
            <Box style={{ minWidth: 240 }}>
              <Text
                style={{
                  fontWeight: 600,
                  color: '#757575',
                  textTransform: 'uppercase',
                  fontSize: 14,
                }}>
                Investment criteria
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  marginTop: 3,
                }}>
                {InvestmentCriteria?.length > 0
                  ? InvestmentCriteria[0]?.name
                  : 'N/A'}
              </Text>
            </Box>
            <Box style={{ minWidth: 240 }}>
              <Text
                style={{
                  fontWeight: 600,
                  color: '#757575',
                  textTransform: 'uppercase',
                  fontSize: 14,
                }}>
                Interest range
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  marginTop: 3,
                }}>
                {data.interestRangeMin > 0 && `${data.interestRangeMin}% - `}
                {data.interestRangeMax} %
              </Text>
            </Box>
            <Box style={{ minWidth: 240 }}>
              <Text
                style={{
                  fontWeight: 600,
                  color: '#757575',
                  textTransform: 'uppercase',
                  fontSize: 14,
                }}>
                Location preference
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  marginTop: 3,
                }}>
                {data?.locationPref?.length > 0
                  ? activeMarketsLocations
                      ?.map(location =>
                        StringUtils.capitalizeFirstLetter(location.name)
                      )
                      .join(', ')
                  : ''}
              </Text>
            </Box>
          </Box>
          <Box style={{ minWidth: 240, marginTop: 2 }}>
            <Text
              style={{
                fontWeight: 600,
                color: '#757575',
                textTransform: 'uppercase',
                fontSize: 14,
              }}>
              REMARK
            </Text>
            <Text
              style={{
                fontWeight: 600,
                fontSize: 14,
                marginTop: 3,
              }}>
              {StringUtils.capitalizeFirstLetter(data?.remarks ?? 'o remark')}
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
}

export default DealDetails;
