import React, { useState, useContext } from 'react';
import TextInput from '../common_component/TextInput';
import Props from '../../types/props';
import Box from '../single_component/Box';
import Dpicker from '../single_component/Dpicker';
import MultipleChip from '../common_component/MultipleChip';
import TwoButton from '../common_component/TwoButton';
import TodoContext from '../../context/TodoContext';
import { toast } from 'react-toastify';
import moment from 'moment';
import AuthContext from '../../context/AuthContext';
import ProfileContext from '../../context/ProfileContext';
import ErrorText from '../common_component/ErrorText';

function SalesTodoAddTask({ ...props }: Props) {
  const { userId, setToggle } = props.extra;
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [Notes, setNotes] = useState('');
  const [Subject, setSubject] = useState('');
  console.error('userId', userId);

  const { masterData } = useContext(AuthContext);

  const { ProfileData } = useContext(ProfileContext);
  const username = ProfileData?.user?.name;
  const data = masterData?.value();

  const [errorSubejct, setErrorSubject] = useState('');
  const [selectedChip, setSelectedChip] = React.useState(-1);

  const onCancel = () => {
    setToggle(false);
  };

  const { addTodo, getTodo, btnDisableLoader, setbtnDisableLoader } =
    useContext(TodoContext);

  const handleChipClick = (index: number) => {
    setSelectedChip(index);
  };
  const handleSubmit = async () => {
    if (Subject.trim().length === 0) {
      setErrorSubject('Please enter a subject.');
    } else {
      setErrorSubject('');
    }

    const finalData = {
      assignedTo: userId,
      subject: Subject,
      note: Notes,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      priority: data?.priorities[selectedChip],
    };
    const response = await addTodo(username, finalData);
    setbtnDisableLoader(false);
    if (response.success) {
      setToggle(false);
      toast.success(response.message);
      await getTodo(1, 10, {
        assignedTo: userId,
      });
    } else {
      toast.error(response?.message);
    }
  };

  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <Box style={{ width: '90%', alignSelf: 'center' }}>
        <TextInput
          {...props}
          label="Subject"
          type="text"
          name="subject"
          id="subject"
          value={Subject}
          onChange={(e: any) => {
            setSubject(e.target.value);
          }}
          placeholder="Enter subject here"></TextInput>
        {errorSubejct ? <ErrorText>{errorSubejct}</ErrorText> : null}
        <TextInput
          label="Note"
          type="text"
          name="notes"
          id="notes"
          value={Notes}
          onChange={(e: any) => {
            setNotes(e.target.value);
          }}
          placeholder="Enter notes here"></TextInput>

        <Dpicker
          name="startDate"
          id="startDate"
          disablePast={true}
          extra={{
            setDate: setStartDate,
            placeholder: 'Choose date',
            maxDate: endDate,
          }}
          value={startDate}
          style={{ width: '100%', marginTop: 2, marginBottom: 1 }}
          label="Start Date"
        />
        <Dpicker
          name="endDate"
          id="endDate"
          value={endDate}
          extra={{
            setDate: setEndDate,
            placeholder: 'Choose date',
            minDate: startDate,
          }}
          style={{ width: '100%', marginTop: 2, marginBottom: 1 }}
          label="End Date"
        />
        <MultipleChip
          style={{ width: '100%', marginTop: 2, marginBottom: 1 }}
          label="Priority of the task"
          extra={{
            handleClick: handleChipClick,
            options: data?.priorities,
            variant: 'outlined',
            clickable: true,
            selectedChip: selectedChip,
          }}
        />

        <TwoButton
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bottom: 10,
            position: 'absolute',
            width: '90%',
          }}
          extra={{
            onclickFirst: onCancel,
            OnClickSecond: handleSubmit,
            firstVariant: 'outlined',
            secondVariant: 'contained',
            firstButtonText: 'Cancel',
            secondButtonText: 'Add',
            btnDisableLoader: btnDisableLoader,
          }}
        />
      </Box>
    </Box>
  );
}

export default SalesTodoAddTask;
