class StringUtils {
  static capitalizeFirstLetter = (string: string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
  };

  static getInitials(name: string, limit: number = 2) {
    let names = name.split(' ');
    names = names.slice(0, names.length > limit ? limit : names.length);
    let initials = '';
    for (let i = 0; i < names.length; i++) {
      const string = names[i];
      initials += string.length > 0 ? string[0].toUpperCase() : '';
    }
    return initials;
  }
}

export default StringUtils;
