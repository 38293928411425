import React, { useContext, useEffect, useState } from 'react';
import Box from '../single_component/Box';
import TextInput from '../common_component/TextInput';
import { Grid } from '@mui/material';
import FormHead from '../common_component/FormHead';
import Button from '../single_component/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Text from '../single_component/Text';
import FormHeadIcon from '../common_component/FormHeadIcon';
import LocationIcon from '../../assets/images/location.png';
import CallIcon from '../../assets/images/call.png';
import LocationMap from '../single_component/LocationMap';
import Props from '../../types/props';
import { useFormik } from 'formik';
import { projecBasicInfoSchema } from '../Validation/AddProjectSchema';
import ErrorText from '../common_component/ErrorText';
import { useLocation } from 'react-router-dom';
import PlacesAutoComplete from '../single_component/PlacesAutoComplete';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ProjectContext from '../../context/ProjectContext';
import { toast } from 'react-toastify';
import { useJsApiLoader } from '@react-google-maps/api';

type SPOC = {
  spocName: string;
  spocEmail: string;
  spocPhone: string;
  spocDesignation: string;
};

function AddProject1({ ...props }: Props) {
  const { deleteSpocs, getProject } = useContext(ProjectContext);
  const location = useLocation();
  const generateProjecBasicInfoSchema = projecBasicInfoSchema(location);

  //!Important:Need to load Google API only once whever we use Components which have google components. isLoaded will change once google is loaded and ready to use.
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBEujiUSLLR_Tfhms6hb7ohSeQkHfjSPlE', // Replace with your API key
    libraries: ['places'],
  });

  const [spocs, setSpoc] = useState<SPOC[]>([
    {
      spocName: '',
      spocEmail: '',
      spocPhone: '',
      spocDesignation: '',
    },
  ]);
  const isEdit = location?.state?.edit;
  const projectData = location?.state?.edit ? props?.extra?.projectData : {};
  const [initialValues, setinitialValues] = useState<Record<string, unknown>>(
    {}
  );
  const onMapChange = (place: google.maps.places.PlaceResult) => {
    const city =
      place.address_components?.find(component =>
        component.types.includes('locality')
      )?.long_name || '';
    const address = place?.formatted_address;
    const lat = place?.geometry?.location?.lat;
    const lng = place?.geometry?.location?.lng;
    setFieldValue('city', city);
    setFieldValue('address', address);
    setFieldValue('latitude', `${lat ?? ''}`);
    setFieldValue('longitude', `${lng ?? ''}`);
    console.error('places', city, lat, lng, address, values);
    // setValues({
    //   // ...values,
    //   city: city,
    //   address: address ?? '',
    //   latitude: `${lat ?? ''}`,
    //   longitude: `${lng ?? ''}`,
    // });
    setinitialValues({
      ...initialValues,
      city: city,
      address: address,
      latitude: lat,
      longitude: lng,
    });
  };

  const onPlaceChange = (place: google.maps.places.PlaceResult) => {
    const city =
      place.address_components?.find(component =>
        component.types.includes('locality')
      )?.long_name || '';
    const lat = place?.geometry?.location?.lat();
    const lng = place?.geometry?.location?.lng();
    const address = place?.formatted_address;
    console.error('places', city, lat, lng, address);
    setFieldValue('city', city);
    setFieldValue('address', address);
    setFieldValue('latitude', `${lat ?? ''}`);
    setFieldValue('longitude', `${lng ?? ''}`);
    // setValues({
    //   ...values,
    //   city: city,
    //   address: address ?? '',
    //   latitude: `${lat ?? ''}`,
    //   longitude: `${lng ?? ''}`,
    // });
    setinitialValues({
      ...initialValues,
      city: city,
      address: address,
      latitude: lat,
      longitude: lng,
    });
  };
  const handleProjectSpocChange = (
    index: number,
    field: keyof SPOC,
    value: string
  ) => {
    const updatedSPOC: SPOC[] = [...spocs];
    console.error(
      'updatedSPOC',
      updatedSPOC,
      updatedSPOC[index],
      updatedSPOC[index][field]
    );
    updatedSPOC[index][field] = value;
    setSpoc(updatedSPOC);
    setFieldValue('spocs', updatedSPOC);
    setinitialValues({ ...initialValues, spocs: JSON.stringify(updatedSPOC) });
  };

  const handleAddspoc = () => {
    const tempSpoc = spocs;
    tempSpoc.push({
      spocName: '',
      spocEmail: '',
      spocPhone: '',
      spocDesignation: '',
    });
    setSpoc([...tempSpoc]);
    setFieldValue('spocs', tempSpoc);
  };

  const projecBasicInfoState = {
    projectName: '',
    projectType: '',
    groupName: location?.state?.groupName,
    spvId: location?.state?.spvId ?? '',
    projectLender: '',
    projectSPVName: projectData?.spvName ?? '',
    projectDesc: '',
    latitude: isEdit ? projectData?.latitude : '19.1991586',
    longitude: isEdit ? projectData?.longitude : '72.93504949999999',
    address: '',
    city: '',
    spocs: [
      {
        spocName: '',
        spocEmail: '',
        spocPhone: '',
        spocDesignation: '',
      },
    ],
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: projecBasicInfoState,
    validationSchema: generateProjecBasicInfoSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async values => {
      console.error(errors, 'add new one');
      props?.extra?.onNext(
        isEdit
          ? initialValues
          : {
              ...values,
              spocs: JSON.stringify(values['spocs']),
            }
      );
    },
  });
  const edithandleChange = (e: any) => {
    const { name, value } = e.target;
    console.error(name, value, e.target);
    setinitialValues({ ...initialValues, [name]: value });
    console.error(initialValues);
  };

  const handleDeleteSpoc = async (spocId: number) => {
    try {
      const response = await deleteSpocs(spocId);
      if (response?.success) {
        toast.success(response?.message);
        getProject(projectData.projectId);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('Error occurred while adding file:', error);
    }
  };
  const handleDeletePromoter = (index: number) => {
    if (spocs?.length === 1) {
      return;
    }
    const updatedPromoters = [...spocs];
    updatedPromoters.splice(index, 1);
    setSpoc(updatedPromoters);
    if (projectData?.Spocs && projectData.Spocs[index] !== undefined)
      handleDeleteSpoc(projectData?.Spocs[index]?.spocId);
  };
  useEffect(() => {
    if (location?.state?.edit || location?.pathname == '/edit-project') {
      const spocData = (projectData?.Spocs ?? []).map(
        (e: Record<string, unknown>) => ({
          spocName: e.spocName,
          spocEmail: e.spocEmail,
          spocPhone: e.spocPhone,
          spocDesignation: e.spocDesignation,
          spocId: e.spocId,
        })
      );
      setSpoc([...spocData]);
      setTimeout(() => {
        setValues({
          projectName: projectData?.projectName ?? '',
          projectType: projectData?.projectType ?? '',
          groupName: location?.state?.groupName,
          spvId: location?.state?.spvId ?? '',
          projectLender: projectData?.projectLender ?? '',
          projectSPVName: projectData?.projectSPVName ?? '',
          projectDesc: projectData?.projectDesc ?? '',
          latitude: projectData?.latitude ?? '',
          longitude: projectData?.longitude ?? '',
          address: projectData?.address ?? '',
          city: projectData?.city ?? '',
          spocs: spocData,
        });
      }, 100);
    }
  }, [projectData, projectData?.latitude, projectData?.longitude]);
  // useEffect(()=>{
  //   setValues({
  //     projectName: projectData?.projectName ?? '',
  //     projectType: projectData?.projectType ?? '',
  //     groupName: location?.state?.groupName,
  //     spvId: location?.state?.spvId ?? '',
  //     projectLender: projectData?.projectLender ?? '',
  //     projectSPVName: projectData?.projectSPVName ?? '',
  //     projectDesc: projectData?.projectDesc ?? '',
  //     latitude: projectData?.latitude ?? '',
  //     longitude: projectData?.longitude ?? '',
  //     address: projectData?.address ?? '',
  //     city: projectData?.city ?? '',
  //     spocs: spocData,
  //   });
  // },[projectData?.latitude])
  return (
    <Box style={{ marginTop: 5 }}>
      <Box
        style={{
          backgroundColor: 'white',
          boxShadow: ' 0px 2px 10px 0px #10496030',
          borderRadius: 2,
        }}>
        <FormHead label="Basic Information" extra={{ page: 'AddProject' }} />
        <Grid
          container
          columnSpacing={2}
          style={{ paddingInline: 22, paddingBlock: 10 }}>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInput
              label="Project Name"
              placeholder="Enter project name"
              name="projectName"
              id="projectName"
              value={values.projectName}
              onChange={e => {
                handleChange(e);
                edithandleChange(e);
              }}
              onBlur={handleBlur}
            />
            {touched.projectName && errors.projectName ? (
              <ErrorText>{errors.projectName}</ErrorText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInput
              label="Project type"
              placeholder="Enter project type"
              id="projectType"
              name="projectType"
              value={values.projectType}
              onChange={e => {
                handleChange(e);
                edithandleChange(e);
              }}
              onBlur={handleBlur}
            />
            {touched.projectType && errors.projectType ? (
              <ErrorText>{errors.projectType}</ErrorText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInput
              label="Group Name / Brand Name"
              name="groupName"
              disabled={true}
              value={values.groupName}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInput
              label="Project lender"
              placeholder="Enter project lender"
              name="projectLender"
              id="projectLender"
              value={values.projectLender}
              onChange={e => {
                handleChange(e);
                edithandleChange(e);
              }}
              onBlur={handleBlur}
            />
            {touched.projectLender && errors.projectLender ? (
              <ErrorText>{errors.projectLender}</ErrorText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInput
              label="Project SPV Name"
              disabled={true}
              name="spvId"
              value={
                (location?.state?.spvName ?? '') ||
                (projectData?.spv?.spvName ?? '')
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        style={{
          backgroundColor: 'white',
          boxShadow: ' 0px 2px 10px 0px #10496030',
          borderRadius: 2,
          marginTop: 5,
        }}>
        <FormHead label="Project Description" extra={{ page: 'AddProject' }} />

        <Grid
          container
          columnSpacing={2}
          style={{ paddingInline: 22, paddingBlock: 10 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextInput
              placeholder="Write description about the project here"
              id="projectDesc"
              name="projectDesc"
              onChange={e => {
                handleChange(e);
                edithandleChange(e);
              }}
              onBlur={handleBlur}
              value={values.projectDesc}
              // maxLength={250}
              minLength={3}
            />
            {touched.projectDesc && errors.projectDesc ? (
              <ErrorText>{errors.projectDesc}</ErrorText>
            ) : null}
          </Grid>
        </Grid>
      </Box>

      <Box
        style={{
          backgroundColor: 'white',
          boxShadow: ' 0px 2px 10px 0px #10496030',
          borderRadius: 2,
          marginTop: 5,
        }}>
        <FormHeadIcon
          label="Location"
          extra={{ LeftIcon: LocationIcon }}></FormHeadIcon>
        <Grid
          container
          columnSpacing={2}
          style={{ paddingInline: 22, paddingBlock: 10 }}>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInput
              label="Longitude (Drag marker on the map)"
              placeholder="Map Longitude"
              name="longitude"
              readOnly={true}
              value={values.longitude}
              onChange={e => {
                handleChange(e);
                edithandleChange(e);
              }}
              onBlur={handleBlur}
            />
            {/* {touched.longitude && errors.longitude ? (
              <ErrorText>{errors.longitude}</ErrorText>
            ) : null} */}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInput
              label="Latitude (Drag marker on the map)"
              placeholder="Map Latitude"
              name="latitude"
              id="latitude"
              value={values.latitude}
              readOnly={true}
              onChange={e => {
                handleChange(e);
                edithandleChange(e);
              }}
              onBlur={handleBlur}
            />
            {/* {touched.latitude && errors.latitude ? (
              <ErrorText>{errors.latitude}</ErrorText>
            ) : null} */}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={12}>
            <div
              style={{
                position: 'absolute',
                zIndex: 1,
                padding: '10px',
              }}>
              <PlacesAutoComplete
                isLoaded={isLoaded}
                id="addressSearch"
                placeholder="search places"
                onPlaceSelect={onPlaceChange}
              />
            </div>
            <LocationMap
              isLoaded={isLoaded}
              initialPosition={{
                lat: parseFloat(values?.latitude),
                lng: parseFloat(values?.longitude),
              }}
              onAddressChange={onMapChange}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInput
              label="Address"
              placeholder="Enter Address"
              value={values.address}
              name="address"
              readOnly={true}
            />
            {touched.address && errors.address ? (
              <ErrorText>{errors.address}</ErrorText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextInput
              label="City"
              placeholder="Enter city"
              value={values.city}
              name="city"
              readOnly={true}
              onChange={e => {
                handleChange(e);
                edithandleChange(e);
              }}
              onBlur={handleBlur}
            />
            {touched.city && errors.city ? (
              <ErrorText>{errors.city}</ErrorText>
            ) : null}
          </Grid>
        </Grid>
      </Box>

      <Box
        style={{
          backgroundColor: 'white',
          boxShadow: ' 0px 2px 10px 0px #10496030',
          borderRadius: 2,
          marginTop: 5,
          paddingBottom: 1.5,
        }}>
        <FormHeadIcon
          label="Project SPOC"
          extra={{ LeftIcon: CallIcon }}></FormHeadIcon>
        <Grid
          container
          columnSpacing={2}
          style={{ paddingInline: 15, paddingBlock: 10 }}>
          {spocs.map((promoter, index) => (
            <Grid container item xs={12} sm={6} md={4} lg={12} key={index}>
              <Grid item xs={12} sm={6} ml={2} md={4} lg={5.5}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {spocs.length > 1 && (
                    <RemoveCircleOutlineIcon style={{ color: 'white' }} />
                  )}
                </Box>
                <TextInput
                  label="Name of SPOC"
                  type="text"
                  id={`spocs[${index}].spocName`}
                  placeholder="Enter Contact person name"
                  name={`spocs[${index}].spocName`}
                  value={promoter.spocName}
                  onChange={(e: any) => {
                    handleProjectSpocChange(index, 'spocName', e.target.value);
                  }}
                  onBlur={handleBlur}
                />
                {touched.spocs &&
                touched.spocs[index] &&
                touched.spocs[index].spocName &&
                errors.spocs &&
                errors.spocs[index] &&
                (errors.spocs[index] as any)?.spocName ? (
                  <ErrorText>
                    {(errors.spocs[index] as any)?.spocName}
                  </ErrorText>
                ) : null}
                <TextInput
                  type="spocEmail"
                  label="Email"
                  id={`spocs[${index}].spocEmail`}
                  placeholder="Enter Contact person spocEmail"
                  name={`spocs[${index}].spocEmail`}
                  value={promoter.spocEmail}
                  onChange={(e: any) => {
                    handleProjectSpocChange(index, 'spocEmail', e.target.value);
                  }}
                  onBlur={handleBlur}
                />
                {touched.spocs &&
                touched.spocs[index] &&
                touched.spocs[index].spocEmail &&
                errors.spocs &&
                errors.spocs[index] &&
                (errors.spocs[index] as any)?.spocEmail ? (
                  <ErrorText>
                    {(errors.spocs[index] as any)?.spocEmail}
                  </ErrorText>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6} ml={2} md={4} lg={6} key={index}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {spocs.length > 1 && (
                    <RemoveCircleOutlineIcon
                      onClick={() => handleDeletePromoter(index)}
                    />
                  )}
                </Box>
                <TextInput
                  type="tel"
                  label="Phone Number"
                  id={`spocs[${index}].spocPhone`}
                  placeholder="Enter Contact person phone number"
                  name={`spocs[${index}].spocPhone`}
                  value={promoter.spocPhone}
                  onChange={(e: any) => {
                    handleProjectSpocChange(index, 'spocPhone', e.target.value);
                  }}
                  onInput={(e: any) => {
                    e.target.value = e.target.value
                      .trim()
                      .replace(/[a-zA-Z\s]/g, '');
                  }}
                  onBlur={handleBlur}
                  maxLength={10}
                  minLength={10}
                />
                {touched.spocs &&
                touched.spocs[index] &&
                touched.spocs[index].spocPhone &&
                errors.spocs &&
                errors.spocs[index] &&
                (errors.spocs[index] as any)?.spocPhone ? (
                  <ErrorText>
                    {(errors.spocs[index] as any)?.spocPhone}
                  </ErrorText>
                ) : null}
                <TextInput
                  type="text"
                  label="Designation of SPOC"
                  id={`spocs[${index}].spocDesignation`}
                  placeholder="Enter Designation of SPOC"
                  name={`spocs[${index}].spocDesignation`}
                  value={promoter.spocDesignation}
                  onChange={(e: any) => {
                    handleProjectSpocChange(
                      index,
                      'spocDesignation',
                      e.target.value
                    );
                  }}
                  onBlur={handleBlur}
                />
                {touched.spocs &&
                touched.spocs[index] &&
                touched.spocs[index].spocDesignation &&
                errors.spocs &&
                errors.spocs[index] &&
                (errors.spocs[index] as any)?.spocDesignation ? (
                  <ErrorText>
                    {(errors.spocs[index] as any)?.spocDesignation}
                  </ErrorText>
                ) : null}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} ml={4} mt={-1} sm={6} md={4} lg={6}>
          <Button
            extra={{ variant: 'outlined' }}
            icons={<AddOutlinedIcon />}
            onClick={handleAddspoc}
            style={{ marginTop: 1 }}>
            <Text style={{ fontSize: 12, fontWeight: 600 }}>
              Add another SPOC
            </Text>
          </Button>
        </Grid>
      </Box>

      <Box style={{ marginTop: 4 }}>
        <Button
          extra={{ variant: 'outlined' }}
          onClick={() => window.history.back()}
          style={{ width: '43%', marginRight: '2%', paddingBlock: 1 }}>
          Cancel
        </Button>
        <Button
          extra={{ variant: 'contained' }}
          onClick={handleSubmit}
          style={{ width: '43%', marginLeft: '2%', paddingBlock: 1 }}>
          Save and Continue
        </Button>
      </Box>
    </Box>
  );
}

export default AddProject1;
