import React from 'react';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import Props from '../../types/props';
import Image from '../single_component/Image';

function FormHeadIcon({ ...props }: Props) {
  const { LeftIcon, sx, page } = props.extra;
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 2,
        borderBottom: '1px solid #f0f0f0',
        padding: '16px 24px',
        background: page == 'ProjectDetails' ? '#E4F7FF' : 'white',
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        ...sx,
      }}>
      <Image
        src={LeftIcon}
        style={{ width: 20, height: 20, objectFit: 'cover' }}
      />
      <Text style={{ fontSize: 14 }}>{props.label}</Text>
    </Box>
  );
}

export default FormHeadIcon;
