import React, { useState } from 'react';
import Props from '../types/props';
import BreadCrumbContext from '../context/BreadCrumbContext';

export default function BreadCrumbProvider(props: Props) {
  const [pathParams, setPathParams] = useState({});

  return (
    <BreadCrumbContext.Provider value={{ pathParams, setPathParams }}>
      {props.children}
    </BreadCrumbContext.Provider>
  );
}
