import React from 'react';
import ToDoContainer from '../container/ToDoContainer';
import Box from '../components/single_component/Box';
import TodoProvider from '../providers/TodoProvider';
import SalesExecutiveProvider from '../providers/SalesExecutiveProvider';

function ToDo() {
  return (
    <SalesExecutiveProvider>
      <TodoProvider>
        <Box
          style={{
            backgroundColor: '#F2FAFE',
            paddingTop: 6,
            paddingBottom: 5,
          }}>
          <ToDoContainer />
        </Box>
      </TodoProvider>
    </SalesExecutiveProvider>
  );
}

export default ToDo;
