import React from 'react';
import Box from '../../components/single_component/Box';
import ProjectProvider from '../../providers/ProjectProvider';
import EditProjectStepper from '../Container/editProjectStepper';
import { useLocation } from 'react-router-dom';

function EditProject() {
  const location = useLocation();
  const groupName = location.state.groupName ?? 'N/A';
  return (
    <ProjectProvider>
      <Box>
        <Box
          style={{
            backgroundColor: '#F2FAFE',
            minHeight: '100vh',
            padding: '56px 100px',
          }}>
          <EditProjectStepper extra={{ groupName }} />
        </Box>
      </Box>
    </ProjectProvider>
  );
}

export default EditProject;
