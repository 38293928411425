import React from 'react';
import Box from '../../components/single_component/Box';
import ProjectProvider from '../../providers/ProjectProvider';
import AddProjectStepper from '../Container/AddProjectStepper';

function AddProject() {
  return (
    <ProjectProvider>
      <Box>
        <Box
          style={{
            backgroundColor: '#F2FAFE',
            minHeight: '100vh',
            padding: '56px 100px',
          }}>
          <AddProjectStepper />
        </Box>
      </Box>
    </ProjectProvider>
  );
}

export default AddProject;
