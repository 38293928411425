import Axios from '../config/axios';
import ApiResponse from '../utils/ApiResponse';

class MailService extends Axios {
  constructor() {
    super();
  }
  async sendMail(data: any) {
    const response = await this.post<JSON>(`/invoice/upload`, data);
    const finalResponse = new ApiResponse(response);
    const urlData = finalResponse?.data;
    const success = finalResponse.getSuccess();
    const message = finalResponse.getMessage();
    return { success, message, urlData };
  }
}

export default MailService;
