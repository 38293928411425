import React, { useContext, useState } from 'react';
import Box from '../single_component/Box';
import Props from '../../types/props';
import TwoButton from '../common_component/TwoButton';
import SubjectInput from '../single_component/SubjectInput';
import { Link } from '@mui/material';
import theme from '../../utils/Theme';
import { useFormik } from 'formik';
import ErrorText from '../common_component/ErrorText';
import { holdTransactionSchema } from '../Validation/AddTask';
import { toast } from 'react-toastify';
import CancelTransactionDialog from '../common_component/cancelTransactionDialog';
import TransactionsContex from '../../context/TransactionsContex';
import SalesDashboardContext from '../../context/SalesDashBoardContext';
import DashboardContext from '../../context/Dashboard';

function HoldTransaction({ ...props }: Props) {
  const { setToggle, setHold, transcId, projectId, page } = props.extra;
  const { holdTransaction, getTransaction } = useContext(TransactionsContex);
  const { getDashboardData } = useContext(SalesDashboardContext);
  const { getDashboardData: adminDashBoardData } = useContext(DashboardContext);
  const [showDialog, setShowDialog] = useState(false);
  const onCancel = () => {
    setHold(false);
  };

  const { handleBlur, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: holdTransactionSchema,
    onSubmit: async values => {
      console.error(values);
    },
  });

  const onHold = async () => {
    const result = await holdTransaction(transcId, values.reason, projectId);
    if (result.success) {
      setHold(false);
      setToggle(false);
      if (page == 'Sales-Dashboard') {
        getDashboardData(`sales/dashboard`, {});
      }
      if (page == 'Project') {
        getTransaction(1, 10, {
          filter: {
            projectId,
          },
        });
      }
      if (page == 'Dashboard') {
        adminDashBoardData(`admin/dashboard`, {
          page: 1,
          size: 10,
        });
      }

      if (page == 'Sales-Executive') {
        getTransaction(1, 10, {
          filter: {
            createdBy: props.extra?.userId,
          },
        });
      }

      toast.success(result?.message ?? '');
    } else {
      toast.error(result?.message ?? '');
    }
  };

  return (
    <Box
      style={{ width: '90%', margin: 'auto', paddingTop: 1, paddingBottom: 2 }}>
      <SubjectInput
        label="Reason to hold "
        placeholder="Enter the reason to hold this transaction"
        name="reason"
        id="reason"
        value={values.reason}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      {errors.reason && touched.reason ? (
        <ErrorText>{errors.reason}</ErrorText>
      ) : null}

      <TwoButton
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bottom: 10,
          position: 'absolute',
          width: '90%',
        }}
        extra={{
          onclickFirst: onCancel,
          OnClickSecond: onHold,
          firstVariant: 'outlined',
          secondVariant: 'contained',
          firstButtonText: 'Back to raise invoice',
          secondButtonText: 'Hold',
        }}
      />
      <Link
        component="button"
        variant="body2"
        onClick={() => {
          setShowDialog(true);
        }}
        style={{
          fontWeight: 500,
          fontSize: 13,
          color: theme.palette.secondary.main,
          cursor: 'pointer',
          position: 'absolute',
          top: '77%',
          left: '64%',
        }}>
        Cancel transaction
      </Link>
      <CancelTransactionDialog
        extra={{
          showDialog,
          transcId,
          projectId: projectId,
          page,
          closeDialog: () => {
            setShowDialog(false);
          },
        }}
      />
    </Box>
  );
}

export default HoldTransaction;
