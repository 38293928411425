import React, { useState, useContext } from 'react';
import Button from '../single_component/Button';
import Sidebar from './Sidebar';
import AddTask from '../Forms/AddTask';
import Text from '../single_component/Text';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Box from '../single_component/Box';
import Image from '../single_component/Image';
import HiHand from '../../assets/HiHand.png';
import DropdownlBold from './DropdownBold';
import ProfileContext from '../../context/ProfileContext';
import StringUtils from '../../utils/stringUtil';
import Props from '../../types/props';

function WelcomeBox(props: Props) {
  const [Toggle, setToggle] = useState(false);
  const { durationNames } = props?.extra || [];
  const { ProfileData } = useContext(ProfileContext);

  const onClick = () => {
    setToggle(!Toggle);
  };

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: 7,
      }}>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Text
          style={{
            fontSize: 32,
            fontWeight: '600',
          }}>
          Welcome{' '}
          {StringUtils.capitalizeFirstLetter(ProfileData?.user?.name || 'User')}
        </Text>
        <Image
          src={HiHand}
          style={{
            width: 32,
            height: 32,
            objectFit: 'cover',
            marginLeft: '10px',
          }}
        />
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end',
        }}>
        <Box style={{ width: 150, marginRight: 2 }}>
          <DropdownlBold
            value={durationNames[props?.extra?.duration] ?? []}
            name="duration"
            id="duration"
            onChange={(e: any) => {
              props?.extra?.setDuration(e.target.value);
            }}
            extra={{
              options: durationNames?.map((e: any) => ({
                name: e,
              })),
              placeholder: 'Select location',
            }}
          />
        </Box>
        <Box>
          <Button
            extra={{ variant: 'contained', color: 'primary' }}
            style={{ padding: '10px 24px', borderRadius: 1.5 }}
            icons={<AddOutlinedIcon />}
            onClick={onClick}>
            <Text style={{ color: 'white', fontSize: 13, fontWeight: 500 }}>
              Add Task
            </Text>
          </Button>
        </Box>
      </Box>

      <Sidebar
        label="Add Task"
        extra={{
          anchor: 'right',
          Toggle,
          setToggle,
          description: 'Add your task here for any work',
          width: 400,
        }}>
        <AddTask
          extra={{
            setToggle,
            userId: ProfileData?.user?.userId,
          }}
        />
      </Sidebar>
    </Box>
  );
}

export default WelcomeBox;
