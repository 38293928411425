import React from 'react';
import DownloadIcon from '../../assets/images/download.png';
import FileChip from '../../components/single_component/FileChip';
import Box from '../../components/single_component/Box';
import Image from '../../components/single_component/Image';
import DashTableTop from './DashTableTop';
import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import Text from '../../components/single_component/Text';
import Props from '../../types/props';
import TablesWithoutPagination from '../../components/common_component/TablesWithoutPagination';
import StringUtils from '../../utils/stringUtil';
import NotfoundPage from '../../components/single_component/NotfoundPage';
import Loader from '../../components/single_component/Loader';
function DeveloperDataTable({ ...props }: Props = {}) {
  const [Page, setPage] = React.useState(1);
  const [rowsPerPage, setrowsPerPage] = React.useState(10);
  const [Next, setNext] = React.useState();
  const [Previous, setPrevious] = React.useState();
  const [currPage, setcurrPage] = React.useState(1);

  const { data, loading } = props.extra ?? {};

  const TableRowComponet = (props: any) => {
    const { row } = props;
    const handleDownload = (url: string, chiplabel: string) => {
      const fileExtension = url.split('.').pop()?.toLowerCase();
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        if (this.status === 200) {
          const blob = new Blob([this.response], {
            type: 'application/octet-stream',
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${chiplabel ?? 'document'}.${fileExtension}`;
          link.click();
          window.URL.revokeObjectURL(link.href);
        }
      };
      xhr.send();
    };
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: '80%' }}>
        <TableCell>
          <Text style={{ fontSize: 14 }}>
            {moment(row?.createdAt ?? '').format('DD MMM YYYY')}
          </Text>
        </TableCell>
        <TableCell>
          <Text style={{ fontSize: 14 }}>
            {StringUtils.capitalizeFirstLetter(row?.subject ?? '')}
          </Text>
        </TableCell>
        <TableCell>
          <FileChip
            label={row?.fileName}
            extra={{ url: row?.documentFile ?? '' }}
          />
        </TableCell>
        <TableCell>
          <button
            style={{ border: 'none', outline: 'none' }}
            onClick={() => handleDownload(row?.documentFile, row?.fileName)}>
            <Image
              src={DownloadIcon}
              style={{
                width: 32,
                height: 32,
                objectFit: 'cover',
                cursor: 'pointer',
              }}
            />
          </button>
        </TableCell>
      </TableRow>
    );
  };

  const tableHeaders = [
    { name: 'Date', id: 'Sales Executive' },
    { name: 'Subject', id: 'Contact' },
    { name: 'Uploaded files', id: 'PhoneNumber' },
    { name: 'Action', id: 'DOJ' },
  ];

  return (
    <Box style={{ width: '100%', margin: 'auto' }}>
      <DashTableTop
        extra={{
          label: 'Data format',
          description:
            'Here you can create developer data format by uploading files',
        }}
      />

      {/* {!loading ? (
          <Loader />
          ): data.length !== 0 ?(
            <NotfoundPage/>
          ):(
      <TablesWithoutPagination
        extra={{
          rows: data,
          columns: tableHeaders,
          RowComponent: TableRowComponet,
          page: Page,
          setPage: setPage,
          rowsPerPage: rowsPerPage,
          setrowsPerPage: setrowsPerPage,
          Next: Next,
          Previous: Previous,
          setPrevious: setPrevious,
          setNext: setNext,
          currPage: currPage,
          setcurrPage: setcurrPage,
        }}
      />     
)} */}

      {loading ? (
        <Loader />
      ) : (
        <>
          {data && data.length > 0 ? (
            <TablesWithoutPagination
              extra={{
                rows: data,
                columns: tableHeaders,
                RowComponent: TableRowComponet,
                page: Page,
                setPage: setPage,
                rowsPerPage: rowsPerPage,
                setrowsPerPage: setrowsPerPage,
                Next: Next,
                Previous: Previous,
                setPrevious: setPrevious,
                setNext: setNext,
                currPage: currPage,
                setcurrPage: setcurrPage,
              }}
            />
          ) : (
            <NotfoundPage />
          )}
        </>
      )}
    </Box>
  );
}

export default DeveloperDataTable;
