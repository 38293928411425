import React, { useContext } from 'react';
import Box from '../components/single_component/Box';
import Text from '../components/single_component/Text';
import EyeIcon from '../assets/images/Eye.png';
// import Sidebar from '../components/common_component/Sidebar';
// import DocumentForm from '../components/Forms/DoumentForm';
import { useNavigate } from 'react-router';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

import Button from '../components/single_component/Button';
import Image from '../components/single_component/Image';
import Props from '../types/props';
import moment from 'moment';
import StringUtils from '../utils/stringUtil';
import { ROUTES } from '../types/routeObject';
import SpvContext from '../context/SpvContext';
import { Modal } from '@mui/material';
import { Close } from '@mui/icons-material';
import ViewSpvTabDocuments from '../components/Forms/ViewSpvTabDocuments';

function SpvContainer({ ...props }: Props) {
  const [Doument, setDoument] = React.useState(false);
  const { value, id, groupName } = props.extra;
  const navigate = useNavigate();
  const { setAddSpv, setisEditSpv, setspvData } = useContext(SpvContext);
  const showDocument = () => {
    setDoument(true);
  };
  const handleNavigate = (spvId: number, spvName: string) => {
    navigate(
      `${ROUTES.VIEW_SPV.replace(':id', id).replace(
        ':spvId',
        spvId.toString()
      )}`,
      {
        state: {
          spvId,
          spvName,
          groupName,
          groupId: id,
        },
      }
    );
  };

  return (
    <Box style={{ width: '49%', border: '1px solid #ebebeb', borderRadius: 1 }}>
      <Box
        style={{
          background: '#E5F7FF',
          padding: '16px 24px',
          borderBottom: '1px solid #E0E0E0',
        }}>
        <Box
          style={{
            fontSize: 24,
            fontWeight: 500,
            marginBottom: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          {StringUtils.capitalizeFirstLetter(value && value.spvName)}
          <ModeEditOutlineIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setAddSpv(true);
              setisEditSpv(true);
              setspvData(value);
            }}
          />
        </Box>
        <Box style={{ fontSize: 14, color: '#424242' }}>
          Created on {moment(value && value.createdAt).format('DD MMMM YYYY')}
        </Box>
      </Box>
      <Box style={{ padding: '28px 24px' }}>
        <Box style={{ marginBottom: 2 }}>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 3,
              }}>
              <Box>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: '#757575',
                    textTransform: 'uppercase',
                  }}>
                  Authorized share capital
                </Text>
                <Text style={{ fontWeight: 600, fontSize: 15, marginTop: 6 }}>
                  ₹{' '}
                  {value && value?.authSharedCap === null
                    ? 'N/A'
                    : value?.authSharedCap}
                </Text>
              </Box>
              <Box>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: '#757575',
                    textTransform: 'uppercase',
                  }}>
                  paid up share capital
                </Text>
                <Text style={{ fontWeight: 600, fontSize: 15, marginTop: 6 }}>
                  ₹{' '}
                  {value && value?.paidSharedCap === null
                    ? 'N/A'
                    : value?.paidSharedCap}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              extra={{ variant: 'outlined' }}
              onClick={showDocument}
              icons={
                <Image
                  src={EyeIcon}
                  style={{ width: 20, height: 20, objectFit: 'cover' }}
                />
              }
              style={{ width: '48%', borderRadius: 2 }}>
              View Documents
            </Button>
            <Button
              extra={{ variant: 'contained' }}
              onClick={() => handleNavigate(value.spvId, value.spvName)}
              style={{ width: '48%', borderRadius: 2 }}>
              View SPV
            </Button>
          </Box>
          {/* <Sidebar
            label="Documents"
            extra={{
              anchor: 'right',
              Toggle: Doument,
              setToggle: setDoument,
              description: 'View all the documents here and download it.',
              width: 400,
            }}>
            <DocumentForm extra={{ setToggle: setDoument, value }} />
          </Sidebar> */}
        </Box>
      </Box>
      <Modal
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        open={Doument}
        onClose={() => setDoument(false)}>
        <Box
          style={{
            width: '80%',
            background: '#fff',
            padding: 4,
            position: 'relative',
          }}>
          <Close
            onClick={() => setDoument(false)}
            sx={{
              position: 'absolute',
              fontSize: 30,
              right: 15,
              top: 15,
              color: 'red',
              cursor: 'pointer',
            }}
          />
          <ViewSpvTabDocuments
            extra={{ document: { documents: value?.SpvDocuments ?? [] } }}
          />
        </Box>
      </Modal>
    </Box>
  );
}

export default SpvContainer;
