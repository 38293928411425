import { Moment } from 'moment';
import DateUtils from '../utils/dateUtils';

class AppNotification {
  id: number;
  text: string;
  description: string;
  seenAt: Moment | null;
  action: Record<string, unknown> | null;
  thumbnail: string | null;
  isActive: boolean;
  createdBy: number;
  updatedBy: number;
  createdAt: Moment | null;
  updatedAt: Moment | null;
  createdFor: number;

  constructor(data: Record<string, unknown>) {
    this.id = parseInt((data.id ?? 'N/A').toString());
    this.text = (data.text ?? 'N/A').toString();
    this.description = (data.description ?? 'N/A').toString();
    this.seenAt = DateUtils.parseDate((data.seenAt ?? 'N/A').toString());
    this.action = JSON.parse((data.action ?? '').toString());
    this.thumbnail = (data.thumbnail ?? 'N/A').toString() || null;
    this.isActive = Boolean(data.isActive);
    this.createdBy = parseInt((data.createdBy ?? 'N/A').toString());
    this.updatedBy = parseInt((data.updatedBy ?? 'N/A').toString());
    this.createdAt = DateUtils.parseDate((data.createdAt ?? 'N/A').toString());
    this.updatedAt = DateUtils.parseDate((data.updatedAt ?? 'N/A').toString());
    this.createdFor = parseInt((data.createdFor ?? 'N/A').toString());
  }

  static fromJson(json: Record<string, unknown>): AppNotification {
    return new AppNotification(json);
  }

  toJson(stringify: boolean = false): Record<string, unknown> | string {
    const jsonData: Record<string, unknown> = {
      id: this.id,
      text: this.text,
      description: this.description,
      seenAt: this.seenAt?.toISOString() || null,
      action: this.action,
      thumbnail: this.thumbnail,
      isActive: this.isActive,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      createdAt: this.createdAt?.toISOString() || null,
      updatedAt: this.updatedAt?.toISOString() || null,
      createdFor: this.createdFor,
    };

    return stringify ? JSON.stringify(jsonData) : jsonData;
  }
}

export default AppNotification;
