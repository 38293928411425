import Axios from '../config/axios';
import AppNotification from '../models/AppNotification';
import MasterData from '../models/masterData';
import ApiResponse from '../utils/ApiResponse';

class AuthService extends Axios {
  constructor() {
    super();
  }

  async sendOtp(data: { email: string }): Promise<ApiResponse> {
    const response = await this.post<JSON>(`/auth/login`, data);
    return new ApiResponse(response);
  }

  async verifyOtp(data: {
    email: string;
    code: string;
    token: string;
  }): Promise<ApiResponse> {
    const response = await this.post<JSON>(`/auth/verify`, data);
    return new ApiResponse(response);
  }

  async getNotifications(): Promise<AppNotification[]> {
    let notification: AppNotification[] = [];
    try {
      const response = await this.post<JSON>(`/notification/get`, {});
      const apiResponse = new ApiResponse(response);
      const data = apiResponse.getData();
      notification = (
        Array.isArray(data['records']) ? data['records'] : []
      ).map(notification => AppNotification.fromJson(notification));

      console.error('from notification response', notification, data);
    } catch (e) {
      console.error('from notification', e);
      notification = [];
    }
    return notification;
  }

  async getMasterData(): Promise<MasterData> {
    const response = await this.post<JSON>(`/master/get`, {});
    const apiResponse = new ApiResponse(response);
    const masterData = MasterData.fromJson(apiResponse.getData());
    return masterData;
  }
}

export default AuthService;
