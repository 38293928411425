import Axios from '../config/axios';
import ApiResponse from '../utils/ApiResponse';
import DownloadUtil from '../utils/downloadUtil';
import { getAuthToken } from './PreferenceService';

class investorService extends Axios {
  async getInvestorDetail(query: string) {
    const response = await this.get<JSON>(`/${query}`);
    const apiResponse = new ApiResponse(response);
    const data = apiResponse.getData();
    return data;
  }

  async getInvestors(page: number, size: number, filter: any) {
    const response = await this.post<JSON>(
      `investors/get?page=${page}&size=${size}`,
      filter
    );
    const apiResponse = new ApiResponse(response);
    return apiResponse;
  }
  async addInvestor(data: Record<string, unknown>) {
    const response = await this.post<JSON>(`/investors/add`, data);
    const apiResponse = new ApiResponse(response);
    const success = apiResponse.getSuccess();
    const message = apiResponse.getMessage();
    return { success, message };
  }
  async downloadInvestorDetail(id: number, name: string) {
    const userToken = getAuthToken();
    let success = false;
    let message = 'Unable to download dataFormat';
    const response = await fetch(`${this.getBaseUrl}/download/zipFile/${id}`, {
      headers: {
        Authorization: userToken ? `Bearer ${userToken}` : '',
      },
    });
    try {
      const blob = await response.blob();
      DownloadUtil.downloadFromBlob(blob, `${name}-dataFormat.zip`);
      success = true;
      message = 'Data downloaded successfully.';
    } catch (e) {
      success = true;
    }
    return { success, message };
  }

  async downloadInvestorsDetail(ids: number[]) {
    const userToken = getAuthToken();
    let success = false;
    let message = 'Unable to download dataFormat';
    try {
      const response = await fetch(
        `${this.getBaseUrl}/download/zipFile/multiple`,
        {
          method: 'POST',
          headers: {
            Authorization: userToken ? `Bearer ${userToken}` : '',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            investorIds: ids,
          }),
        }
      );
      const blob = await response.blob();
      DownloadUtil.downloadFromBlob(blob, 'investors-dataformat.zip');
      success = true;
      message = 'Data downloaded successfully.';
    } catch (e) {
      success = true;
    }
    return { success, message };
  }

  async addInvestorFormat(id: number, data: Record<string, unknown>) {
    const response = await this.post<JSON>(
      `/investors/${id}/data-format`,
      data
    );
    const apiResponse = new ApiResponse(response);
    const success = apiResponse.getSuccess();
    const message = apiResponse.getMessage();
    return { success, message };
  }

  async addInvestorThesis(id: number, data: Record<string, unknown>) {
    const response = await this.post<JSON>(
      `/investors/${id}/investment-thesis`,
      data
    );
    const apiResponse = new ApiResponse(response);
    const success = apiResponse.getSuccess();
    const message = apiResponse.getMessage();
    return { success, message };
  }

  async addActiveMarkets(id: number, data: Record<string, unknown>) {
    const response = await this.patch<JSON>(
      `/investors/${id}/active-markets`,
      data
    );
    const apiResponse = new ApiResponse(response);
    const success = apiResponse.getSuccess();
    const message = apiResponse.getMessage();
    return { success, message };
  }
  async updateDealDetails(id: number, data: any) {
    const response = await this.patch<JSON>(`/investors/edit/${id}`, data);
    const apiResponse = new ApiResponse(response);
    const success = apiResponse.getSuccess();
    const message = apiResponse.getMessage();
    return { success, message };
  }

  async addContactPerson(id: number, data: Record<string, unknown>) {
    const response = await this.post<JSON>(
      `/investors/${id}/contact-persons`,
      data
    );
    const apiResponse = new ApiResponse(response);
    const success = apiResponse.getSuccess();
    const message = apiResponse.getMessage();
    return { success, message };
  }
  async deleteContactPerson(contPerId: number) {
    const response = await this.delete<JSON>(
      `/investors/contact-persons/${contPerId}`
    );
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
  async deleteInvestorProfileImage(investerId: number) {
    const response = await this.delete<JSON>(`/investors/remove/${investerId}`);
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
  async deleteInvestorDataFormat(invDataFormatId: number) {
    const response = await this.delete<JSON>(
      `/investors/data-format/${invDataFormatId}`
    );
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
}

export default investorService;
