import React from 'react';
import { Chip as MuiChip } from '@mui/material';
import Props from '../../types/props';

function Chip({ ...props }: Props) {
  const { variant, color, clickable } = props.extra;
  return (
    <MuiChip
      icon={props.extra.icons}
      label={props.label}
      variant={variant}
      clickable={clickable}
      style={{ ...props.style }}
      sx={{
        '& .MuiChip-label': {
          fontFamily: 'Inter',
        },
      }}
      color={color}
      onClick={props.onClick}
    />
  );
}

export default Chip;
