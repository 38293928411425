import * as Yup from 'yup';
import Message from '../../utils/message';
const emailRegex = /^[\w-/.]+@([\w-]+\.)+[\w-]{2,4}$/g;
const CheckPhoneTen = /^[1-9][0-9]{9}$/;
const Type = {
  String: Yup.string(),
  Mixed: Yup.mixed(),
  Date: Yup.date(),
};
const messageObj = new Message();

const projecBasicInfoSchema = (editMode: any) => {
  console.error(editMode?.state?.edit, 'editMode?.state?.edit');

  return Yup.object().shape({
    projectName: Type.String,
    projectType: Type.String,
    projectLender: Type.String,
    projectDesc: Type.String,
    longitude: Type.String,
    latitude: Type.String,
    address: Type.String,
    city: Type.String,
    spocs: Yup.array().of(
      Yup.object().shape({
        spocName: Type.String,
        spocEmail: Type.String.matches(emailRegex, messageObj.invalid('Email'))
          .email(messageObj.invalid('Email'))
          .min(3, messageObj.length('Email ID', 3, undefined)),
        spocPhone: Type.String.matches(
          CheckPhoneTen,
          messageObj.phone('Phone number')
        ),
        spocDesignation: Type.String,
      })
    ),
  });
};

const constructionDetailSchema = Yup.object().shape({
  landArea: Type.String,
  fsiArea: Type.String,
  builtUpArea: Type.String,
  noOfTowers: Type.String,
  constStartDate: Type.Date,
  constEndDate: Type.Date,
  promotorReraNo: Type.String,
  projectReraNo: Type.String,
  projectReraCert: Type.Mixed,
  configuration: Type.Mixed,
  revisedRera: Type.String,
  revisedReraNo: Yup.string().test(
    'required',
    messageObj.required('Revised RERA No.'),
    function (value) {
      const { revisedRera } = this.parent;
      return !(revisedRera === 'Yes' && !value);
    }
  ),
  revisedReraCert: Type.Mixed,
  reraRegDate: Type.String,
  propPossDate: Type.String,
  propPossExtDate: Type.String,
});

export { projecBasicInfoSchema, constructionDetailSchema };
