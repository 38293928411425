import React, { useContext, useState } from 'react';
import Box from '../single_component/Box';
import TwoButton from '../common_component/TwoButton';
import Props from '../../types/props';
import SubjectInput from '../single_component/SubjectInput';
import { useFormik } from 'formik';
import ErrorText from '../common_component/ErrorText';
import { toast } from 'react-toastify';
import UploadInput from '../single_component/UploadInput';
import InvestorContext from '../../context/InvestorContext';
import { InvestorDataFormateSchema } from '../Validation/AddTask';
function AddInvestorDataFormat(props: Props = {}) {
  const { setToggle } = props?.extra ?? {};
  const { addInvestorFormat } = useContext(InvestorContext);
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);

  const formik = useFormik({
    initialValues: {
      Subject: '',
      GroupLogo: '',
    },
    validationSchema: InvestorDataFormateSchema,
    onSubmit: async values => {
      setbtnDisableLoader(true);
      try {
        const data = new FormData();
        data.append('documentFile', values.GroupLogo);
        data.append('subject', values.Subject);
        const res = await addInvestorFormat(data);
        setbtnDisableLoader(false);
        if (res.success) {
          toast.success(res.message);
          setToggle(false);
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        setbtnDisableLoader(false);
        setToggle(false);
        console.error('Error submitting form:', error);
      }
    },
  });

  const onCancel = () => {
    setToggle(false);
  };

  return (
    <Box style={{ width: '90%', margin: 'auto', marginTop: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        <SubjectInput
          label="Subject"
          placeholder="Enter subject here"
          style={{ width: '100%' }}
          name="Subject"
          id="Subject"
          value={formik.values.Subject}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.Subject && formik.errors.Subject ? (
          <ErrorText>{formik.errors.Subject}</ErrorText>
        ) : null}
        <UploadInput
          label="Upload File"
          name="GroupLogo"
          id="GroupLogo"
          onBlur={formik.handleBlur}
          extra={{
            onchange: (e: any) => {
              formik.setFieldValue('GroupLogo', e[0]);
            },
          }}
        />
        {formik.touched.GroupLogo && formik.errors.GroupLogo ? (
          <ErrorText>{formik.errors.GroupLogo}</ErrorText>
        ) : null}
        <TwoButton
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bottom: 10,
            position: 'absolute',
            width: '90%',
          }}
          extra={{
            onclickFirst: onCancel,
            OnClickSecond: formik.handleSubmit,
            firstVariant: 'outlined',
            secondVariant: 'contained',
            firstButtonText: 'Cancel',
            secondButtonText: 'Add',
            btnDisableLoader: btnDisableLoader,
          }}
        />
      </form>
    </Box>
  );
}

export default AddInvestorDataFormat;
