import React, { useContext, useState } from 'react';
import Box from '../single_component/Box';
import Image from '../single_component/Image';
import logo from '../../assets/images/logo.png';
import Menu from './Menu';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Links from '../common_component/Links';
import Notification from '../common_component/Notification';
import AuthContext from '../../context/AuthContext';
import { ROLE, getHeaderMenu } from '../../types/routeObject';
import { useNavigate } from 'react-router';
import ProfileContext from '../../context/ProfileContext';

function Header() {
  const [Toggle, setToggle] = useState(false);
  const { role } = useContext(AuthContext);
  const { getProfile, ProfileData } = useContext(ProfileContext);
  const navigate = useNavigate();
  const { user } = ProfileData || {};
  return (
    <Box
      style={{
        background: 'white',
        width: '100%',
        boxShadow: '#ada4a4 4px -13px 14px 9px',
        padding: '10px 30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        zIndex: 10,
      }}>
      <Links href="/">
        <Image
          src={logo}
          style={{
            width: '40%',
          }}
        />
      </Links>

      <Menu
        extra={{
          menuData: getHeaderMenu(role?.value() ?? ROLE.PUBLIC),
        }}
      />

      <Notification
        extra={{
          Toggle,
          setToggle,
        }}
      />

      <Box style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
        <Box
          onClick={() => setToggle(true)}
          style={{
            cursor: 'pointer',
          }}>
          <NotificationsOutlinedIcon />
        </Box>
        <Image
          src={
            user?.profileImg
              ? user?.profileImg
              : 'https://cdn-icons-png.flaticon.com/512/2919/2919906.png'
          }
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '20px',
            objectFit: 'cover',
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate('/profile');
            getProfile(`/profile/get`);
          }}
        />
      </Box>
    </Box>
  );
}

export default Header;
