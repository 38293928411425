import React from 'react';
import { Card as MuiCard } from '@mui/material';

import Props from '../../types/props';

function Card({ children, ...props }: Props) {
  const { style, onClick } = props;
  return (
    <MuiCard sx={style} onClick={onClick}>
      {children}
    </MuiCard>
  );
}

export default Card;
