import { Moment } from 'moment';
import DateUtils from '../utils/dateUtils';
import City from './City';

class User {
  userId: number | null;
  name: string | null;
  phoneNumber: string | null;
  email: string | null;
  joiningDate: Moment | null;
  roleId: number | null;
  city: City;
  profileImg: string | null;

  constructor(data: Record<string, unknown>) {
    this.userId = parseInt((data.userId ?? 'N/A').toString());
    this.name = (data.name ?? 'N/A').toString();
    this.phoneNumber = (data.phoneNumber ?? 'N/A').toString();
    this.email = (data.email ?? 'N/A').toString();
    this.joiningDate = DateUtils.parseDate(
      (data.joiningDate ?? 'N/A').toString()
    );
    this.roleId = parseInt((data.roleId ?? 'N/A').toString());
    this.city = new City((data.city as any) ?? {});
    this.profileImg = (data.profileImg ?? '').toString();
  }

  setName = (value: string) => {
    this.name = value;
  };

  static fromJson(json: Record<string, unknown>): User {
    return new User(json);
  }

  toJson(stringify: boolean = false): Record<string, unknown> | string {
    const jsonData = {
      userId: this.userId,
      name: this.name,
      phoneNumber: this.phoneNumber,
      email: this.email,
      joiningDate: this.joiningDate?.toISOString(),
      roleId: this.roleId,
      profileImg: this.profileImg,
    };
    return stringify ? JSON.stringify(jsonData) : jsonData;
  }
}

export default User;
