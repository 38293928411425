import moment, { Moment } from 'moment';

class DateUtils {
  static parseDate(dateString: string | undefined | null): Moment | null {
    return dateString ? moment(dateString) : null;
  }

  static formatDate(
    dateString: string | undefined | null | number,
    format?: string
  ): null | string {
    try {
      return dateString ? moment(dateString).format(format) : null;
    } catch (e) {
      return null;
    }
  }
}

export default DateUtils;
