import React, { useContext, useState } from 'react';
import Box from './Box';
import Button from './Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Text from './Text';
import Sidebar from '../common_component/Sidebar';
import FileChip from './FileChip';
import Image from './Image';
import DownloadIcon from '../../assets/images/download.png';
import Props from '../../types/props';
import Tables from './Tables';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import StringUtils from '../../utils/stringUtil';
import AddInvestorDataFormat from '../Forms/AddInvestorDataFormat';
import { toast } from 'react-toastify';
import InvestorContext from '../../context/InvestorContext';

function InvestorDataFormat({ ...props }: Props) {
  const { deleteInvestorDataFormat } = useContext(InvestorContext);
  const [Toggle, setToggle] = useState(false);
  const [Page, setPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [Next, setNext] = useState();
  const [Previous, setPrevious] = useState();
  const [currPage, setcurrPage] = useState(1);

  const { data } = props.extra;
  const onClick = () => {
    setToggle(!Toggle);
  };
  const handleDataFormatDelete = async (
    dataformatId: number,
    investorId: number
  ) => {
    try {
      const response = await deleteInvestorDataFormat(dataformatId, investorId);
      if (response.success) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const TableRowComponet = (props: any) => {
    const { row } = props;
    const handleDownload = (url: string, chiplabel: string) => {
      const fileExtension = url.split('.').pop()?.toLowerCase();
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        if (this.status === 200) {
          const blob = new Blob([this.response], {
            type: 'application/octet-stream',
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${chiplabel ?? 'document'}.${fileExtension}`;
          link.click();
          window.URL.revokeObjectURL(link.href);
        }
      };
      xhr.send();
    };
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: '80%' }}>
        <TableCell>
          <Text style={{ fontSize: 14 }}>
            {moment(row?.createdAt).format('DD MMM,YYYY')}
          </Text>
        </TableCell>
        <TableCell>
          <Text> {StringUtils.capitalizeFirstLetter(row?.subject)}</Text>
        </TableCell>

        <TableCell>
          <FileChip
            label={row?.fileName ?? 'document file'}
            extra={{ url: row?.documentFile ?? '' }}
          />
        </TableCell>
        <TableCell>
          <Box style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <button
              style={{ border: 'none', outline: 'none' }}
              onClick={() => handleDownload(row?.documentFile, row?.fileName)}>
              <Image
                src={DownloadIcon}
                style={{
                  width: 32,
                  height: 32,
                  objectFit: 'cover',
                  cursor: 'pointer',
                }}
              />
            </button>
            <DeleteIcon
              sx={{ cursor: 'pointer', color: '#104960' }}
              onClick={() =>
                handleDataFormatDelete(row?.invDataFormatId, row?.investorId)
              }
            />
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  const tableHeaders = [
    {
      id: 'Date',
      name: 'Date',
    },
    {
      id: 'Subject',
      name: 'Subject',
    },
    {
      id: 'UploadFiles',
      name: 'Upload Files',
    },
    { id: 'Action', name: 'Action' },
  ];

  return (
    <Box style={{ boxShadow: '0px 2px 10px 0px #10496030', borderRadius: 2 }}>
      <Sidebar
        name="Investor data format"
        label="Add Investor data format"
        extra={{
          Toggle,
          setToggle,
          anchor: 'right',
          description: 'Add Investor data format here',
          width: 400,
        }}>
        <AddInvestorDataFormat extra={{ setToggle }} />
      </Sidebar>

      <Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 2,
            borderBottom: '1px solid #E0E0E0',
            alignItems: 'center',
            background: 'white',
          }}>
          <Box>
            <Text style={{ fontSize: 16, fontWeight: 600 }}>
              Investor data format
            </Text>
            <Text style={{ fontSize: 13, fontWeight: 400, color: '#757575' }}>
              Here you can create Investor data format.
            </Text>
          </Box>
          <Button
            extra={{ variant: 'contained', color: 'primary' }}
            icons={<AddOutlinedIcon />}
            style={{ padding: '8px 24px', borderRadius: 1 }}
            onClick={onClick}>
            <Text style={{ color: 'white', fontSize: 13 }}>
              Add data format
            </Text>
          </Button>
        </Box>
        <Tables
          extra={{
            rows: data,
            columns: tableHeaders,
            RowComponent: TableRowComponet,
            page: Page,
            setPage: setPage,
            rowsPerPage: rowsPerPage,
            setrowsPerPage: setrowsPerPage,
            Next: Next,
            Previous: Previous,
            currPage: currPage,
            setcurrPage: setcurrPage,
            setNext: setNext,
            setPrevious: setPrevious,
          }}
        />
      </Box>
    </Box>
  );
}

export default InvestorDataFormat;
