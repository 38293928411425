import React from 'react';
import Box from './Box';
import Text from './Text';
import Image from './Image';
import AvtarImage from '../../assets/images/Avatar.png';
import CallIcon from '../../assets/images/call.png';
import EmailIcon from '../../assets/images/sms.png';
import LocationIcon from '../../assets/images/location.png';
import { Grid } from '@mui/material';
// import ProfileContext from '../../context/ProfileContext';
import { ROLE } from '../../types/routeObject';
import StringUtils from '../../utils/stringUtil';
function ProjectDetailsCard(props: any) {
  const { user } = props;
  // const user: User = ProfileData?.user;
  return (
    <Box
      style={{
        padding: '8px 8px 60px 8px',
        boxShadow: '0px 1px 3px 0px #1018281A',
        borderRadius: 2,
        width: '39%',
      }}>
      <Box
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          background: '#E4F7FF',
          padding: 2,
          gap: 4,
          borderBottom: '8px solid #FFFFFF',
        }}>
        <Image
          src={
            user.profileImg && user.profileImg != ''
              ? user.profileImg
              : AvtarImage
          }
          style={{ width: 60, height: 60, objectFit: 'cover' }}
        />
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Text style={{ color: '#101828', fontWeight: 600, fontSize: 20 }}>
            {StringUtils.capitalizeFirstLetter(user?.name ?? 'N/A')}
          </Text>
          <Text style={{ color: '#667085', fontSize: 14 }}>
            {user?.roleId == ROLE.EXECUTIVE
              ? 'Sales Manager'
              : user?.roleId == ROLE.ADMIN
                ? 'Admin'
                : 'N/A'}
          </Text>
        </Box>
      </Box>
      <Box style={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Box
                style={{
                  marginLeft: 1,
                  display: 'flex',
                  width: '90px',
                  marginRight: 1,
                  textDecorationColor: 'red',
                }}>
                <Image
                  src={CallIcon}
                  style={{
                    width: 18,
                    height: 18,
                    objectFit: 'cover',
                    color: '#667085',
                  }}
                />
                <Text
                  style={{
                    color: '#667085',
                    marginLeft: '12px',
                    fontSize: 14,
                  }}>
                  Phone
                </Text>
              </Box>
              <Text style={{ marginRight: '12px' }}>:</Text>
              <Text style={{ fontSize: 14 }}>{user?.phoneNumber ?? 'N/A'}</Text>
            </Box>
          </Grid>
          <Grid item>
            <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box
                style={{
                  marginLeft: 1,
                  display: 'flex',
                  width: '90px',
                  marginRight: 1,
                  marginTop: 1,
                }}>
                <Image
                  src={EmailIcon}
                  style={{ width: 18, height: 18, objectFit: 'cover' }}
                />
                <Text
                  style={{
                    color: '#667085',
                    marginLeft: '12px',
                    fontSize: 14,
                  }}>
                  Email
                </Text>
              </Box>
              <Text style={{ marginRight: '12px', marginTop: 5 }}>:</Text>
              <Text
                style={{
                  fontSize: 14,
                  wordWrap: 'break-word',
                  width: '230px',
                  marginTop: 7,
                }}>
                {user?.email ?? 'N/A'}
              </Text>
            </Box>
          </Grid>
          <Grid item>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Box
                style={{
                  marginLeft: 1,
                  display: 'flex',
                  width: '90px',
                  marginRight: 1,
                }}>
                <Image
                  src={LocationIcon}
                  style={{ width: 18, height: 18, objectFit: 'cover' }}
                />
                <Text
                  style={{
                    color: '#667085',
                    marginLeft: '12px',
                    fontSize: 14,
                  }}>
                  Location
                </Text>
              </Box>
              <Text style={{ marginRight: '12px' }}>:</Text>
              <Text style={{ fontSize: 14 }}>
                {StringUtils.capitalizeFirstLetter(
                  user?.city?.cityName ?? 'N/A'
                )}
              </Text>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ProjectDetailsCard;
