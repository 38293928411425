import TodoList from './TodoList';

class Todo {
  data: TodoList[]; // Adjust the type here
  total_pages: number | null;
  next: number | null;
  prev: number | null;

  constructor(data: any) {
    this.data = (Array.isArray(data.records) ? data.records : []).map(
      (todo: Record<string, unknown>) => new TodoList(todo)
    );
    this.total_pages = parseInt(data.totalPages) || null;
    this.next = parseInt(data.nextPage) || null;
    this.prev = parseInt(data.prevPage) || null;
  }

  static fromJson(json: Record<string, unknown>): Todo {
    return new Todo(json);
  }

  toJson(stringify: boolean = false): Record<string, unknown> | string {
    const jsonData = {
      data: this.data,
      total_pages: this.total_pages,
      next: this.next,
      prev: this.prev,
    };

    return stringify ? JSON.stringify(jsonData) : jsonData;
  }
}

export default Todo;
