import React, { useContext, useState } from 'react';
import Box from '../single_component/Box';
import theme from '../../utils/Theme';
import Props from '../../types/props';
import { Tab, Tabs } from '@mui/material';
import DocumentTab from './DocumentTab';
import Button from '../single_component/Button';
import CommonUtils from '../../utils/commonUtils';
// import UploadDoucuments from '../single_component/UploadDocuments';
import ProjectContext from '../../context/ProjectContext';
// import { toast } from 'react-toastify';
// import FormHeadIcon from '../common_component/FormHeadIcon';
// import gallery from '../../assets/images/gallery.png';

function AddProject3({ ...props }: Props) {
  const [Document, setDocument] = useState<Record<string, File>>({});
  const { btnDisableLoader } = props.extra;
  const projectData = props?.extra?.projectData;
  const { addProjectBanner } = useContext(ProjectContext);
  console.error(projectData, addProjectBanner, 'addProjectBanner');

  // const [projectBanner, setProjectBanner] = useState('');
  const technicalDocuments = projectData?.documents?.filter(
    (doc: any) => doc.category === 'technical'
  );
  const landDocuments = projectData?.documents?.filter(
    (doc: any) => doc.category === 'land'
  );
  const financialDocuments = projectData?.documents?.filter(
    (doc: any) => doc.category === 'financial'
  );
  const kycDocuments = projectData?.documents?.filter(
    (doc: any) => doc.category === 'kyc'
  );
  const projectDataDoc = projectData?.documents?.filter(
    (doc: any) => doc?.category === 'project'
  );
  console.error(
    technicalDocuments,
    landDocuments,
    financialDocuments,
    kycDocuments,
    projectDataDoc,
    'Documents'
  );

  const [files, setFiles] = useState<Record<string, any>>({
    technical: [
      {
        documentName: '',
        fileName: '',
      },
    ],
    land: [
      {
        documentName: '',
        fileName: '',
      },
    ],
    financial: [
      {
        documentName: '',
        fileName: '',
      },
    ],
    kyc: [
      {
        documentName: '',
        fileName: '',
      },
    ],
    project: [
      {
        documentName: '',
        fileName: '',
      },
    ],
  });
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event: any, newValue: number) => {
    console.error('newValue', newValue);
    setSelectedTab(newValue);
  };
  const handleChangeDocument = (
    index: number,
    field: string,
    category: string,
    value: unknown
  ) => {
    setFiles(prevFiles => {
      const filesArray = [...prevFiles[category]];

      filesArray.forEach((values: any, ind: number) => {
        if (ind === index) {
          if (field === 'file') {
            const tempDocument = Document;
            const newFileName =
              `${category}-files` + Object.keys(tempDocument).length;
            tempDocument[newFileName] = value as File;
            setDocument({ ...tempDocument });
            values.fileName = value;
            values.file = newFileName;
          } else if (field === 'text') {
            values.documentName = value;
          }
        }
      });

      return {
        ...prevFiles,
        [category]: filesArray,
      };
    });
  };
  const addMoreField = (category: string) => {
    const fileArray = files[category];

    fileArray?.push({
      documentName: '',
      fileName: '',
    });

    files[category] = fileArray;

    setFiles(prevFiles => {
      return {
        ...prevFiles,
        fileArray,
      };
    });
  };

  // const bodyFormData = new FormData();
  //   bodyFormData.append('projectId', projectData.projectId);
  //   bodyFormData.append('imagePath', projectBanner);
  //   try {
  //     const response = await addProjectBanner(bodyFormData);
  //     if (response?.success) {
  //       toast.success(response?.message);
  //     }
  //   } catch (error) {
  //     console.error('Error occurred while adding MIS:', error);
  //   }

  const handleSubmit = () => {
    const filteredDocs = CommonUtils.filterNonEmptyDocuments(files);
    props?.extra?.onNext({
      documents: JSON.stringify(filteredDocs),
      docFiles: Document,
    });
  };

  return (
    <Box>
      <Box
        {...props}
        style={{
          backgroundColor: 'white',
          boxShadow: ' 0px 2px 10px 0px #10496030',
          borderRadius: 2,
          marginTop: 5,
        }}>
        <Box>
          <Box
            style={{
              backgroundColor: '#E5F7FF',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px',
            }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              centered
              sx={{
                paddingInline: 5,

                '& .MuiTabs-flexContainer': { justifyContent: 'space-between' },
                '& .MuiTabs-root': {
                  borderTopLeftRadius: '12px !important',
                  borderTopRightRadius: '12px !important',
                },
                '& .MuiTabs-indicator': { background: '#25A9E0', height: 3 },
              }}>
              <Tab
                label="Technical Documents"
                sx={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              />
              <Tab
                label="Land Documents"
                sx={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              />
              <Tab
                label="Financial Documents"
                sx={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              />
              <Tab
                label="KYC & Corporate Documents"
                sx={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              />
              <Tab
                label="Project Data"
                sx={{
                  fontSize: 13,
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              />
            </Tabs>
          </Box>
          {selectedTab === 0 && (
            <Box>
              <DocumentTab
                extra={{
                  state: files,
                  handleChangeDocument,
                  DocumentType: 'technical',
                  addMoreField,
                  editDocuments: technicalDocuments,
                }}
              />
            </Box>
          )}
          {selectedTab === 1 && (
            <Box>
              <DocumentTab
                extra={{
                  state: files,
                  handleChangeDocument,
                  DocumentType: 'land',
                  addMoreField,
                  editDocuments: landDocuments,
                }}
              />
            </Box>
          )}
          {selectedTab === 2 && (
            <Box>
              <DocumentTab
                extra={{
                  state: files,
                  handleChangeDocument,
                  DocumentType: 'financial',
                  addMoreField,
                  editDocuments: financialDocuments,
                }}
              />
            </Box>
          )}
          {selectedTab === 3 && (
            <Box>
              <DocumentTab
                extra={{
                  state: files,
                  handleChangeDocument,
                  DocumentType: 'kyc',
                  addMoreField,
                  editDocuments: kycDocuments,
                }}
              />
            </Box>
          )}
          {selectedTab === 4 && (
            <Box>
              <DocumentTab
                extra={{
                  state: files,
                  handleChangeDocument,
                  DocumentType: 'project',
                  addMoreField,
                  editDocuments: projectDataDoc,
                }}
              />
              {/* <Box style={{ padding: '1.6em' }}>
                <FormHeadIcon
                  label="Project gallery"
                  extra={{
                    LeftIcon: gallery,
                    page: 'ProjectDetails',
                  }}></FormHeadIcon>
                <UploadDoucuments
                  label="Upload Project cover here"
                  extra={{ onChange: (e: any) => setProjectBanner(e[0]) }}
                  style={{
                    width: 500,
                    height: 200,
                    background: theme.palette.secondary.light,
                    borderColor: theme.palette.secondary.main,
                    justifyContent: 'center',
                  }}
                />
                <UploadDoucuments
                  label="Upload Project cover here"
                  extra={{ onChange: (e: any) => setProjectBanner(e[0]) }}
                  style={{
                    width: 100,
                    height: 100,
                    background: theme.palette.secondary.light,
                    borderColor: theme.palette.secondary.main,
                    justifyContent: 'center',
                  }}
                />
              </Box> */}
            </Box>
          )}
        </Box>
      </Box>
      <Box style={{ marginTop: 4 }}>
        {props?.extra?.page === undefined && (
          <Button
            extra={{ variant: 'outlined' }}
            onClick={props?.extra?.onPrevious}
            style={{ width: '43%', marginRight: '2%', paddingBlock: 1 }}>
            Back
          </Button>
        )}
        <Button
          extra={{
            variant: 'contained',
            btnDisableLoader: btnDisableLoader,
          }}
          onClick={handleSubmit}
          style={{ width: '43%', marginLeft: '2%', paddingBlock: 1 }}>
          Save
        </Button>
      </Box>
    </Box>
  );
}

export default AddProject3;
