import React from 'react';
import Box from '../single_component/Box';
import TextLabel from '../single_component/TextLabel';
import Image from '../single_component/Image';
import FileChip from '../single_component/FileChip';
import DeleteIcon from '@mui/icons-material/Delete';
import downloadIcon from '../../assets/images/download.png';
import Props from '../../types/props';
import Text from '../single_component/Text';
import StringUtils from '../../utils/stringUtil';

function DocumentBox({ ...props }: Props) {
  const {
    textlabel,
    chiplabel,
    GrayColor,
    url,
    type,
    handleDeleteDocuments,
    component,
    labelWidth,
  } = props.extra ?? {};

  const handleDownload = () => {
    const fileExtension = url.split('.').pop()?.toLowerCase();
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (this.status === 200) {
        const blob = new Blob([this.response], {
          type: 'application/octet-stream',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${chiplabel ?? 'document'}.${fileExtension}`;
        link.click();
        window.URL.revokeObjectURL(link.href);
      }
    };
    xhr.send();
  };
  return (
    <Box style={props.style}>
      {GrayColor === 'Grayforform' ? (
        <Text
          style={{
            fontSize: 13,
            color: '#8b8b8b',
            marginBottom: 2,
            marginTop: 10,
            wordWrap: 'break-word',
          }}>
          <TextLabel>{StringUtils.capitalizeFirstLetter(textlabel)}</TextLabel>
        </Text>
      ) : (
        <TextLabel>{StringUtils.capitalizeFirstLetter(textlabel)}</TextLabel>
      )}
      <Box
        style={{
          display: 'flex',
          padding: 1,
          justifyContent: 'space-between',
          backgroundColor: '#f1f1f1',
          borderRadius: 1,
          border: '1px solid #E0E0E0',
          width: '100%',
        }}>
        <FileChip label={chiplabel} extra={{ url, labelWidth }} />
        <Box style={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {type && (
            <Box style={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              {component}{' '}
              <DeleteIcon
                sx={{ cursor: 'pointer', color: '#104960' }}
                onClick={handleDeleteDocuments}
              />
            </Box>
          )}
          {type === 'editMandate' ||
            ((url ?? '') !== '' && (
              <button
                style={{ border: 'none', outline: 'none' }}
                onClick={handleDownload}>
                <Image
                  src={downloadIcon}
                  style={{
                    width: 32,
                    height: 32,
                    objectFit: 'cover',
                    cursor: 'pointer',
                  }}
                />
              </button>
            ))}
        </Box>
      </Box>
    </Box>
  );
}

export default DocumentBox;
