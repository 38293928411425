import React from 'react';
import Props from '../../types/props';
import { Box as MuiBox } from '@mui/material';

function Box(props: Props = {}) {
  return (
    <MuiBox sx={props?.style} onClick={props?.onClick}>
      {props?.children ?? ''}
    </MuiBox>
  );
}

export default Box;
