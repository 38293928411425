import React, { useContext, useEffect, useState } from 'react';
import Box from '../../components/single_component/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ProjectDetails from '../componets/ProjectDetails';
import theme from '../../utils/Theme';
import TransactionsContex from '../../context/TransactionsContex';
import ProjectContext from '../../context/ProjectContext';
import { useLocation, useParams } from 'react-router-dom';
import TransactionTables from '../componets/TransactionTables';

function ViewProjectContainer() {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const { getTransaction, Transaction } = useContext(TransactionsContex);

  const { getProject, ProjectData, documents } = useContext(ProjectContext);

  const { id } = useParams();
  const [Page, setPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [Next, setNext] = useState();
  const [Previous, setPrevious] = useState();
  const [currPage, setcurrPage] = useState(1);

  useEffect(() => {
    setPage(Transaction?.totalPages);
    setNext(Transaction?.nextPage);
    setPrevious(Transaction?.prevPage);
  }, [Transaction]);

  useEffect(() => {
    getTransaction(Page, rowsPerPage, {
      filter: {
        projectId: id,
      },
    });
    getProject(id);
  }, [currPage, rowsPerPage]);

  const handleTabChange = (event: any, newValue: number) => {
    setSelectedTab(newValue);
  };

  const location = useLocation();
  const groupName = location.state.groupName ?? 'N/A';
  return (
    <Box
      style={{
        width: '85%',
        margin: 'auto',
        boxShadow: ' 0px 2px 10px 0px #1049601A',
        borderRadius: 2,
      }}>
      <Box style={{ background: 'white', borderRadius: 2 }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          centered
          sx={{
            backgroundColor: '#E5F7FF',
            // paddingInline: 5,
            '& .MuiTabs-flexContainer': { justifyContent: 'flex-start' },
            '& .MuiTabs-indicator': { background: '#25A9E0', height: 3 },
          }}>
          <Tab
            label="Project Details"
            sx={{
              fontSize: 13,
              fontWeight: 600,
              color: theme.palette.primary.main,
              padding: '12px 80px',
            }}
          />
          <Tab
            label="Transactions Details"
            sx={{
              fontSize: 13,
              fontWeight: 600,
              color: theme.palette.primary.main,
              padding: '12px 80px',
            }}
          />
        </Tabs>

        {selectedTab === 0 && (
          <ProjectDetails
            extra={{
              ProjectData: ProjectData ?? {},
              documents,
              projectId: id,
              groupName,
            }}
          />
        )}
        {selectedTab === 1 && (
          <TransactionTables
            extra={{
              data: Transaction.records ?? [],
              page: 'Project',
              Page,
              setPage,
              rowsPerPage,
              setrowsPerPage,
              Next,
              setNext,
              Previous,
              setPrevious,
              currPage,
              setcurrPage,
            }}
          />
        )}
      </Box>
    </Box>
  );
}

export default ViewProjectContainer;
