import React from 'react';
import SalesDevGroupContainer from '../Container/SalesDevGroupContainer';
import Box from '../../components/single_component/Box';
import DeveloperGroupProvider from '../../providers/DeveloperGroupProvider';

function SalesDeveloperGroup() {
  return (
    <DeveloperGroupProvider>
      <Box
        style={{
          backgroundColor: '#F2FAFE',
          minHeight: '100vh',
          // cursor: 'pointer'
        }}>
        <SalesDevGroupContainer />
      </Box>
    </DeveloperGroupProvider>
  );
}

export default SalesDeveloperGroup;
