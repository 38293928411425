import React from 'react';
import AuthProvider from './providers/AuthProvider';
import { Klaxon } from './route';
import BreadCrumbProvider from './providers/BreadCrumbProvider';
import ProfileProvider from './providers/ProfileProvider';
import TransactionProvider from './providers/TransactionProvider';

function App() {
  return (
    <AuthProvider>
      <TransactionProvider>
        <BreadCrumbProvider>
          <ProfileProvider>
            <Klaxon />
          </ProfileProvider>
        </BreadCrumbProvider>
      </TransactionProvider>
    </AuthProvider>
  );
}

export default App;
