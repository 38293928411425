import React from 'react';
import SaleToDoCon from '../Container/SaleToDoCon';
import TodoProvider from '../../providers/TodoProvider';

function SalesTodo() {
  return (
    <TodoProvider>
      <SaleToDoCon />
    </TodoProvider>
  );
}

export default SalesTodo;
