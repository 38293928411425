import { toast } from 'react-toastify';

enum NotificationTheme {
  ERROR,
  SUCCESS,
  WARN,
  INFO,
}

class NotificationUtil {
  static showToast(
    message: string,
    type: NotificationTheme = NotificationTheme.ERROR
  ) {
    if (type === NotificationTheme.ERROR) {
      toast.error(message);
    } else if (type === NotificationTheme.SUCCESS) {
      toast.success(message);
    } else {
      toast.error(message);
    }
  }
}

export default NotificationUtil;
