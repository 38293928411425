import React, { useState } from 'react';
import Props from '../../types/props';
import Box from '../single_component/Box';
import DocumentBox from '../common_component/DocumentBox';
import Text from '../single_component/Text';
import Button from '../single_component/Button';
import StringUtils from '../../utils/stringUtil';
import { Modal } from '@mui/material';
import { Close } from '@mui/icons-material';
import ViewSpvTabDocuments from '../Forms/ViewSpvTabDocuments';

function DeveloperGroup({ ...props }: Props) {
  const { setToggle, data } = props.extra;
  const [viewPromoter, setViewPromoter] = useState(false);
  const [viewPromoterDocs, setViewPromoterDocs] = useState(false);
  const [selectedPromoter, setSelectedPromoter] = useState(0);
  return (
    <Box style={{ width: '90%', margin: 'auto', paddingBottom: '8rem' }}>
      <Box style={{ backgroundColor: '#FFF', borderRadius: 1.5 }}>
        <Box>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              marginTop: 1.5,
            }}>
            <Text
              style={{
                width: 123,
                fontSize: 14,
                color: '#667085',
                fontWeight: 'lighter',
              }}>
              Group name
            </Text>
            <Text>:</Text>
            <Text
              style={{
                color: 'black',
                fontWeight: 'normal',
                fontSize: 14,
              }}>
              {StringUtils.capitalizeFirstLetter(data && data?.name)}
            </Text>
          </Box>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              marginTop: 1.5,
            }}>
            <Text
              style={{
                width: 123,
                fontSize: 14,
                color: '#667085',
                fontWeight: 'lighter',
              }}>
              Location
            </Text>
            <Text>:</Text>
            <Text
              style={{
                color: 'black',
                fontWeight: 'normal',
                fontSize: 14,
              }}>
              {data?.city?.name ?? 'N/A'}
            </Text>
          </Box>
          {/* {data?.promoters &&
            data?.promoters.map((value: any, index: number) => {
              return (
                <Box
                  style={{
                    display: 'flex',
                    gap: '10px',
                    flexDirection: 'column',
                    borderBottom: '1px solid #E0E0E0',
                    paddingBottom: 1,
                    paddingTop: 1,
                  }}
                  key={index}>
                  <Box style={{ display: 'flex', gap: '10px' }}>
                    <Text
                      style={{
                        width: 130,
                        fontSize: '14px',
                        color: '#667085',
                      }}>
                      Name
                    </Text>
                    <Text>:</Text>
                    <Text>
                      {StringUtils.capitalizeFirstLetter(
                        value && value.promoterName
                      )}
                    </Text>
                  </Box>
                  <Box style={{ display: 'flex', gap: '10px' }}>
                    <Text
                      style={{
                        width: 130,
                        fontSize: '14px',
                        color: '#667085',
                      }}>
                      Email ID
                    </Text>
                    <Text>:</Text>
                    <Text>{value && value.promoterEmail}</Text>
                  </Box>
                  <Box style={{ display: 'flex', gap: '10px' }}>
                    <Text
                      style={{
                        width: 130,
                        fontSize: '14px',
                        color: '#667085',
                      }}>
                      Phone Number
                    </Text>
                    <Text>:</Text>
                    <Text>+91 {value && value.promoterPhone}</Text>
                  </Box>
                </Box>
              );
            })} */}
        </Box>
      </Box>

      {data?.groupLogo && (
        <DocumentBox
          extra={{
            textlabel: 'Group logo',
            chiplabel: 'Group logo',
            url: data?.groupLogo ?? '',
          }}
        />
      )}
      {data?.groupProfile && (
        <DocumentBox
          extra={{
            textlabel: 'Group profile',
            chiplabel: 'Group profile',
            url: data?.groupProfile ?? '',
          }}
        />
      )}
      <Box>
        <Button
          onClick={() => {
            setViewPromoter(true);
          }}
          style={{ padding: '8px', borderRadius: 2, marginTop: 2 }}
          extra={{
            variant: 'outlined',
            color: 'primary',
          }}
          // icons={<AddOutlined />}
        >
          <Text style={{ color: 'black', fontSize: 13 }}>View Promoters</Text>
        </Button>
      </Box>
      <Button
        style={{ width: '90%', padding: 1.5, position: 'absolute', bottom: 10 }}
        onClick={() => setToggle(false)}
        extra={{
          variant: 'contained',
        }}>
        <Text style={{ fontSize: 14 }}>Done</Text>
      </Button>
      <Modal
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        open={viewPromoter}
        onClose={() => setViewPromoter(false)}>
        <Box
          style={{
            width: '80%',
            background: '#fff',
            padding: 4,
            position: 'relative',
          }}>
          <Close
            onClick={() => setViewPromoter(false)}
            sx={{
              position: 'absolute',
              fontSize: 30,
              right: 15,
              top: 15,
              color: 'red',
              cursor: 'pointer',
            }}
          />
          <Box
            style={{
              background: 'rgb(218, 237, 255)',
              marginRight: 2,
              padding: 1,
              borderRadius: 2,
            }}>
            <Text>Promoters</Text>
          </Box>
          <Box
            style={{
              overflowY: 'scroll',
              overflowX: 'hidden',
              maxHeight: '350px',
            }}>
            {data?.promoters?.length > 0 ? (
              data?.promoters?.map((value: any, index: number) => {
                return (
                  <Box
                    style={{
                      display: 'flex',
                      gap: '10px',
                      width: '100%',
                      // flexDirection: 'column',
                      alignItems: 'center',
                      borderBottom: '1px solid #E0E0E0',
                      paddingBottom: 1,
                      paddingTop: 1,
                      margin: '10px 10px',
                    }}
                    key={index}>
                    <Box style={{ display: 'flex', gap: '10px', width: 250 }}>
                      <Text
                        style={{
                          marginTop: '2px',
                          fontSize: '14px',
                          color: '#667085',
                        }}>
                        Name
                      </Text>
                      <Text>:</Text>
                      <Text>
                        {value?.promoterName
                          ? StringUtils.capitalizeFirstLetter(
                              value && value?.promoterName
                            )
                          : 'N/A'}
                      </Text>
                    </Box>
                    <Box style={{ display: 'flex', gap: '10px', width: 250 }}>
                      <Text
                        style={{
                          // width: 130,
                          fontSize: '14px',
                          color: '#667085',
                          marginTop: '2px',
                        }}>
                        Email ID
                      </Text>
                      <Text>:</Text>
                      <Text>
                        {value?.promoterEmail ? value?.promoterEmail : 'N/A'}
                      </Text>
                    </Box>
                    <Box style={{ display: 'flex', gap: '10px', width: 280 }}>
                      <Text
                        style={{
                          // width: 130,
                          fontSize: '14px',
                          color: '#667085',
                          marginTop: '2px',
                        }}>
                        Phone Number
                      </Text>
                      <Text>:</Text>
                      <Text>
                        {' '}
                        {value?.promoterPhone
                          ? `+91${value?.promoterPhone}`
                          : 'N/A'}
                      </Text>
                    </Box>
                    <Button
                      onClick={() => {
                        setViewPromoterDocs(true);
                        setSelectedPromoter(index);
                      }}
                      style={{ padding: '8px', borderRadius: 2, marginLeft: 4 }}
                      extra={{
                        variant: 'outlined',
                        color: 'primary',
                      }}
                      // icons={<AddOutlined />}
                    >
                      <Text style={{ color: 'black', fontSize: 13 }}>
                        View documents
                      </Text>
                    </Button>
                  </Box>
                );
              })
            ) : (
              <Text style={{ textAlign: 'center', marginTop: '20px' }}>
                Promoter Not Found
              </Text>
            )}
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        open={viewPromoterDocs}
        onClose={() => setViewPromoterDocs(false)}>
        <Box
          style={{
            width: '80%',
            background: '#fff',
            padding: 4,
            position: 'relative',
          }}>
          <Close
            onClick={() => setViewPromoterDocs(false)}
            sx={{
              position: 'absolute',
              fontSize: 30,
              right: 15,
              top: 15,
              color: 'red',
              cursor: 'pointer',
            }}
          />
          <Text
            style={{
              textTransform: 'capitalize',
              fontWeight: 'bold',
              margin: 0,
            }}>
            {data?.promoters?.length > 0 &&
              data?.promoters[selectedPromoter]?.promoterName}
          </Text>
          <ViewSpvTabDocuments
            extra={{
              document: {
                documents:
                  data?.promoters?.length > 0
                    ? data?.promoters[selectedPromoter]?.PromoterDocuments
                    : [],
              },
              tabName: 'promoter',
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
}

export default DeveloperGroup;
