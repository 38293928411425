import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Props from '../../types/props';
import TextLabel from '../single_component/TextLabel';
import Box from '../single_component/Box';

export default function CheckboxLabels({ ...props }: Props) {
  const { headingLabel, options, onSelect } = props.extra || {};
  return (
    <FormGroup>
      <TextLabel>{headingLabel}</TextLabel>
      <Box>
        {options.map((option: any, index: number) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={option?.selected}
                onChange={(e: any) => {
                  onSelect(e.target.checked, index);
                }}
              />
            }
            label={option.name}
            sx={{
              '& .MuiSvgIcon-root': { fontSize: 20 },
              '& .MuiFormControlLabel-label': {
                fontSize: 13,
              },
            }}
          />
        ))}
      </Box>
    </FormGroup>
  );
}
