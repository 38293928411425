import React, { useContext } from 'react';
import Box from '../single_component/Box';
import FileChip from '../single_component/FileChip';
import StatusChip from '../common_component/StatusChip';
// import DashboardAction from '../Action/InitiateTransactionAdmin';
// import Sidebar from '../common_component/Sidebar';
import EyeAction from '../single_component/EyeAction';
import Props from '../../types/props';
import { TableCell, TableRow } from '@mui/material';
import Text from '../single_component/Text';
import PluseTwo from '../common_component/PluseTwo';
import moment from 'moment';
import Tables from '../single_component/Tables';
import StringUtils from '../../utils/stringUtil';
import NotfoundPage from '../../components/single_component/NotfoundPage';
import Loader from '../../components/single_component/Loader';
import SalesExecutiveContext from '../../context/SalesExecutiveContext';
import Button from '../single_component/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import Sidebar from '../common_component/Sidebar';
import ActiveFilter from '../Filter/ActiveFilter';

function SalesExectiveTransTable({ ...props }: Props) {
  // const [ApprovedSidebar, setApprovedSidebar] = useState(false);
  const {
    userId,
    handleSubmit,
    onCancel,
    transactionStatus,
    handleChipClick,
    DealSize,
    setDealSize,
    selectedChip,
    setSelectedChip,
    Date,
    setDate,
    btnDisableLoader,
    sefilterToggle,
    filterToggle,
    transactionsPage,
    settransactionsPage,
    transactionsRowPerPage,
    settransactionsRowPerPage,
    transactionsCurrentPage,
    settransactionsCurrentPage,
    transactionsNext,
    settransactionsNext,
    transactionsPrevious,
    settransactionsPrevious,
  } = props.extra || {};
  const onCancelFilter = () => {
    sefilterToggle(true);
  };
  // const [Toggle, setToggle] = useState(false);

  const { loading } = useContext(SalesExecutiveContext);

  const TableRowComponet = (props: any) => {
    const { row } = props;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: '80%' }}>
        <TableCell>
          <Text style={{ fontSize: 14 }}>
            {moment(row.joiningDate).format('DD MMM,YY')}
          </Text>
        </TableCell>

        <TableCell>
          {row.dealSize ? <Text> ₹ {row.dealSize}</Text> : <Text>N/A</Text>}
        </TableCell>

        <TableCell>
          <Text>
            {' '}
            {StringUtils.capitalizeFirstLetter(row.transcBrief ?? 'N/A')}
          </Text>
        </TableCell>
        <TableCell>
          <Box
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <Text style={{ fontSize: 14 }}>
              {row?.investors?.length > 0
                ? row?.investors[0]?.name ?? 'N/A'
                : 'N/A'}
            </Text>
            {row?.investors?.length > 1 && (
              <PluseTwo
                extra={{ OverPluse: `+${row?.investors?.length - 1}` }}
              />
            )}
          </Box>
        </TableCell>

        <TableCell>
          {row?.mandate != '' && row?.mandate ? (
            <FileChip
              label={row?.documentName !== '' ? row?.documentName : 'Mandate'}
              extra={{ url: row?.mandate ?? 'N/A' }}
            />
          ) : (
            <Text>N/A</Text>
          )}
        </TableCell>

        <TableCell>
          {row.remark ? (
            <Text>
              {' '}
              {StringUtils.capitalizeFirstLetter(row.remark ?? 'N/A')}
            </Text>
          ) : (
            <Text>N/A</Text>
          )}
        </TableCell>
        <TableCell>
          <StatusChip label={row.transcStatus} />
        </TableCell>
        <TableCell>
          <EyeAction
            extra={{
              label: row?.transcStatus,
              data: row ?? {},
              page: 'Sales-Executive',
              userId: userId,
            }}
          />
        </TableCell>
      </TableRow>
    );
  };

  const tableHeaders = [
    { id: 'Date', name: 'Date' },
    {
      id: 'DealSize',
      name: 'Deal Size',
    },
    {
      id: 'Brief',
      name: 'Brief',
    },
    {
      id: 'PotentialInvestors',
      name: 'Potential Investors',
    },
    {
      id: 'Documents',
      name: 'Uploaded',
    },
    {
      id: 'Remarks',
      name: 'Remarks',
    },
    {
      id: 'Status',
      name: 'Status',
    },
    { id: 'Action', name: 'Action' },
  ];

  return (
    <Box style={{ width: '100%', margin: 'auto' }}>
      <Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '12px 24px 0px 24px ',
            background: 'white',
            alignItems: 'center',
          }}>
          <Box>
            <Box style={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
                Transactions
              </Text>
            </Box>
            <Text style={{ fontSize: 14, color: '#667085' }}>
              {' '}
              Here you can see all the active transactions with the status and
              complete view.
            </Text>
          </Box>
          <Button
            extra={{
              variant: 'outlined',
              color: 'tertiary',
            }}
            icons={<FilterListIcon />}
            style={{ padding: '8px 24px' }}
            onClick={onCancelFilter}>
            <Text style={{ color: 'black', fontSize: 13 }}>Add Filters</Text>
          </Button>
        </Box>

        <Sidebar
          label="Filter"
          extra={{
            anchor: 'right',
            Toggle: filterToggle,
            setToggle: sefilterToggle,
            description: 'Filter the data as per your need ',
            width: 400,
          }}>
          <ActiveFilter
            extra={{
              handleSubmit,
              onCancel,
              transactionStatus,
              handleChipClick,
              DealSize,
              setSelectedChip,
              setDealSize,
              selectedChip,
              Date,
              setDate,
              btnDisableLoader,
            }}
          />
        </Sidebar>
      </Box>

      {loading ? (
        <Loader />
      ) : props.extra.data.length !== 0 ? (
        <Tables
          extra={{
            rows: props.extra.data ?? [],
            columns: tableHeaders,
            RowComponent: TableRowComponet,
            page: transactionsPage,
            setPage: settransactionsPage,
            rowsPerPage: transactionsRowPerPage,
            setrowsPerPage: settransactionsRowPerPage,
            Next: transactionsNext,
            Previous: transactionsPrevious,
            currPage: transactionsCurrentPage,
            setcurrPage: settransactionsCurrentPage,
            setNext: settransactionsNext,
            setPrevious: settransactionsPrevious,
          }}
        />
      ) : (
        <div>
          <NotfoundPage />
        </div>
      )}
    </Box>
  );
}

export default SalesExectiveTransTable;
