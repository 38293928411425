import React from 'react';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Props from '../../types/props';
import Box from './Box';
import Text from './Text';
import { Link, Modal } from '@mui/material';
import Image from './Image';
import { Document, Page, pdfjs } from 'react-pdf';
import { toast } from 'react-toastify';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function FileChip(props: Props = {}) {
  const { url, labelWidth } = props?.extra || {};
  const fileExtension =
    url && typeof url === 'string' ? url?.split('.').pop()?.toLowerCase() : '';

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    if (
      !(
        fileExtension === 'png' ||
        fileExtension === 'jpg' ||
        fileExtension === 'jpeg' ||
        fileExtension === 'gif' ||
        fileExtension === 'pdf'
      )
    )
      toast.warning('this file cannot be preview'), setOpen(false);
  };
  const handleClose = () => setOpen(false);
  return (
    <Box {...props}>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
          width: '100%',
        }}>
        <Box
          style={{
            borderRadius: '25px',
            backgroundColor: '#E0E0E0',
            minWidth: 35,
            height: 35,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <InsertDriveFileOutlinedIcon
            style={{ width: 19, height: 19 }}></InsertDriveFileOutlinedIcon>
        </Box>
        {url?.length > 0 ? (
          <Link
            component="button"
            variant="body2"
            onClick={handleOpen}
            style={{
              fontWeight: 500,
              fontSize: 13,
              // color: theme.palette.secondary.main,
              cursor: 'pointer',
              wordWrap: 'break-word',
              maxWidth: '400px',
              textAlign: 'left',
              width: labelWidth ? labelWidth : '100%',
              // width:'100%'
            }}>
            {props?.children ?? ''} {props?.label ?? ''}
          </Link>
        ) : (
          <Text
            style={{
              fontSize: 13,
              cursor: 'pointer',
            }}>
            {props?.children ?? ''} {props?.label ?? ''}
          </Text>
        )}
      </Box>
      <div>
        {(fileExtension === 'png' ||
          fileExtension === 'jpg' ||
          fileExtension === 'jpeg' ||
          fileExtension === 'gif' ||
          fileExtension === 'pdf') && (
          <Modal open={open} onClose={handleClose}>
            <Box
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}>
              {fileExtension === 'png' ||
              fileExtension === 'jpg' ||
              fileExtension === 'jpeg' ||
              fileExtension === 'gif' ? (
                <Image className="file-box" src={`${url}`} />
              ) : (
                fileExtension === 'pdf' && (
                  <Box>
                    <Document file={url ?? ''} className={'pdfPage'}>
                      <Page pageNumber={1} scale={1} />
                    </Document>
                  </Box>
                )
              )}
            </Box>
          </Modal>
        )}
      </div>
    </Box>
  );
}
