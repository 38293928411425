import React from 'react';
import Props from '../../types/props';
import Box from '../single_component/Box';
import TextLabel from '../single_component/TextLabel';
import DropdownSelect from '../single_component/DropdownSelect';

function Dropdown({ ...props }: Props) {
  const { placeholder, options } = props.extra;
  return (
    <Box style={{ width: '100%', backgroundColor: 'white' }}>
      <TextLabel> {props.label}</TextLabel>
      <DropdownSelect
        value={props.value}
        name={props.name}
        id={props.id}
        readOnly={props.readOnly}
        disabled={props.disabled}
        onBlur={props.onBlur}
        onChange={props.onChange}
        extra={{
          options,
          placeholder,
        }}
        style={{ width: '100%', height: 40 }}
      />
    </Box>
  );
}

export default Dropdown;
