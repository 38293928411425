import React from 'react';
import Props from '../../types/props';
import Text from '../single_component/Text';

function ErrorText(props: Props = {}) {
  return (
    <Text style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
      {props?.children ?? ''}
    </Text>
  );
}

export default ErrorText;
