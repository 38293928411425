import React from 'react';
import Props from '../../types/props';
import Box from '../single_component/Box';
import TextLabel from '../single_component/TextLabel';
import Input from '../single_component/Input';

function TextInputDropdown({ children, ...props }: Props) {
  const digitRexAcceptDecimal = /^[/d]|[^0-9.]/;
  return (
    <Box
      style={{
        width: '100%',
        marginTop: 0,
        marginBottom: 1,
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      }}>
      <Box style={{ flexGrow: 8 }}>
        <TextLabel> {props.label}</TextLabel>
        <Input
          id={props.id}
          onInput={(e: any) => {
            e.target.value = e.target.value
              .trim()
              .replace(digitRexAcceptDecimal, '');
          }}
          name={props.name}
          className={props.className}
          onBlur={props.onBlur}
          placeholder={props.placeholder}
          onChange={props.onChange}
          value={props.value}
          style={{ ...props.style, width: props.style?.width ?? '100%' }}
          type={props.type}
        />
      </Box>
      <Box
        style={{
          width: '100px',
          position: 'relative',
          top: '11px',
          right: '5px',
        }}>
        {children}
      </Box>
    </Box>
  );
}

export default TextInputDropdown;
