import React, { useContext, useState } from 'react';
import Box from './Box';
import Sidebar from '../common_component/Sidebar';
import AddContactPerson from '../Forms/AddContactPerson';
import Button from './Button';
import Text from './Text';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Props from '../../types/props';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableCell, TableRow } from '@mui/material';
import TablesWithoutPagination from '../common_component/TablesWithoutPagination';
import StringUtils from '../../utils/stringUtil';
import { toast } from 'react-toastify';
import InvestorContext from '../../context/InvestorContext';

function ContactPersonAddNew({ ...props }: Props) {
  const [Toggle, setToggle] = useState(false);
  const [selectedContactPerson, setSelectedContactPerson] = useState(null);
  const [Page, setPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [Next, setNext] = useState();
  const [Previous, setPrevious] = useState();
  const [currPage, setcurrPage] = useState(1);

  const { data, userId } = props.extra;
  const { deleteContactPerson } = useContext(InvestorContext);
  const onClick = () => {
    setToggle(!Toggle);
  };
  const handleDeleteContactPerson = async (id: number) => {
    try {
      const response = await deleteContactPerson(id, userId);
      if (response.success) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const tableHeaders = [
    {
      id: 'ContactPerson',
      name: 'Contact person',
    },
    {
      id: 'EmailID',
      name: 'Email ID',
    },
    {
      id: 'PhoneNumber',
      name: 'Phone Number',
    },
    {
      id: 'Action',
      name: 'Action',
    },
  ];
  const TableRowComponet = (props: any) => {
    const { row, index } = props;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: '80%' }}>
        <TableCell>
          <Box
            style={{
              display: 'flex',
              columnGap: 4,
              alignItems: 'center',
              minWidth: 200,
            }}>
            <Text
              style={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                background: '#E5F7FF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {row?.contPerName[0].toUpperCase()}
            </Text>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ color: '#101828', fontWeight: 500, fontSize: 14 }}>
                {StringUtils.capitalizeFirstLetter(row?.contPerName)}
              </Text>
            </Box>
          </Box>
        </TableCell>

        <TableCell>
          <Text> {row?.contPerEmail}</Text>
        </TableCell>
        <TableCell>
          <Text> {row?.contPerPhone}</Text>
        </TableCell>
        <TableCell>
          <EditIcon
            sx={{ color: '#104960' }}
            onClick={() => {
              setSelectedContactPerson(index);
              onClick();
            }}
          />
          <DeleteIcon
            sx={{ color: '#104960', marginLeft: '1rem' }}
            onClick={() => handleDeleteContactPerson(row?.iContactPersonId)}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box>
      <Box
        style={{
          paddingBottom: 2,
          borderBottom: '1px solid #E0E0E0',
          alignItems: 'center',
          background: 'white',
          borderRadius: 1,
        }}>
        <TablesWithoutPagination
          extra={{
            rows: data,
            columns: tableHeaders,
            RowComponent: TableRowComponet,
            page: Page,
            setPage: setPage,
            rowsPerPage: rowsPerPage,
            setrowsPerPage: setrowsPerPage,
            Next: Next,
            Previous: Previous,
            currPage: currPage,
            setcurrPage: setcurrPage,
            setNext: setNext,
            setPrevious: setPrevious,
          }}
        />

        <Box style={{ marginLeft: 4 }}>
          <Button
            extra={{
              variant: 'outlined',
              color: 'primary',
              borderRadius: '8px',
              border: 1,
            }}
            style={{ padding: '8px 60px' }}
            icons={<AddOutlinedIcon />}
            onClick={() => {
              setSelectedContactPerson(null);
              onClick();
            }}>
            <Text style={{ color: '#104960', fontSize: 13, fontWeight: 500 }}>
              Add contact person
            </Text>
          </Button>
        </Box>
      </Box>

      <Sidebar
        label="Contact person"
        extra={{
          Toggle,
          setToggle,
          anchor: 'right',
          description: 'Here you can add contact person.',
          width: 400,
        }}>
        <AddContactPerson
          extra={{ setToggle, Toggle, userId, data, selectedContactPerson }}
        />
      </Sidebar>
    </Box>
  );
}

export default ContactPersonAddNew;
