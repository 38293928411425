import React, { useContext } from 'react';
import TextInput from '../common_component/TextInput';
import { Grid } from '@mui/material';
import Button from '../single_component/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Text from '../single_component/Text';
import Props from '../../types/props';
import Box from '../single_component/Box';
import TextLabel from '../single_component/TextLabel';
import UploadInput from '../../components/single_component/UploadInput';
import DocumentBox from '../common_component/DocumentBox';
import ProjectContext from '../../context/ProjectContext';
import { toast } from 'react-toastify';

function DocumentTab({ ...props }: Props) {
  const { deleteMoreDocument, getProject } = useContext(ProjectContext);
  const {
    editDocuments,
    handleChangeDocument,
    DocumentType,
    state,
    addMoreField,
    handledocdelete,
  } = props.extra;

  const prefilledDocument = editDocuments ?? [];
  const addNewDoc = state[DocumentType] ?? [];
  // const projectData = props?.extra?.projectData ;
  // const technicalDocuments = projectData?.documents?.filter((doc:any) => doc.category === 'technical');

  const handleDeleteDocument = async (docId: number, projectId: number) => {
    try {
      const response = await deleteMoreDocument(docId);
      if (response?.success) {
        toast.success(response?.message);
        getProject(projectId);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('Error occurred while adding file:', error);
    }
  };
  return (
    <Grid
      container
      columnSpacing={2}
      style={{ paddingInline: 22, paddingBlock: 10 }}>
      {prefilledDocument &&
        prefilledDocument.map((document: any, index: number) => {
          return (
            <>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <TextInput
                  label="Document Name"
                  placeholder="Enter Document Name"
                  value={document.documentName}
                  onChange={(e: any) => {
                    handleChangeDocument(
                      index,
                      'text',
                      DocumentType,
                      e.target.value
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}>
                  {editDocuments && editDocuments.length > 0 ? (
                    <DocumentBox
                      extra={{
                        type: 'editMandate',
                        url: editDocuments[index]?.documentFile,
                        textlabel: 'Upload Document',
                        labelWidth: props?.extra?.labelWidth ?? '',
                        chiplabel: editDocuments[index]?.documentName,
                        handleDeleteDocuments: () =>
                          handledocdelete
                            ? handledocdelete(editDocuments[index].documentId)
                            : handleDeleteDocument(
                                editDocuments[index].documentId,
                                document.projectId
                              ),
                      }}
                    />
                  ) : (
                    <Box>
                      <TextLabel>Upload Document</TextLabel>

                      <UploadInput
                        extra={{
                          docName: document?.fileName ?? '',
                          onchange: (e: any) => {
                            if (e.length > 0) {
                              handleChangeDocument(
                                index,
                                'file',
                                DocumentType,
                                e[0]
                              );
                            }
                          },
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            </>
          );
        })}
      {addNewDoc &&
        addNewDoc.map((document: any, index: number) => {
          console.error('document', document);
          return (
            <>
              <Grid item xs={12} sm={6} md={4} mt={1} lg={6}>
                <TextInput
                  label="Document Name"
                  placeholder="Enter Document Name"
                  value={document.documentName}
                  onChange={(e: any) => {
                    handleChangeDocument(
                      index,
                      'text',
                      DocumentType,
                      e.target.value
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={6}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginTop: '6.5px',
                  }}>
                  <Box>
                    <Text
                      style={{
                        color: 'black',
                        marginBottom: '-6px',
                        marginTop: 10,
                        fontSize: 13,
                        fontWeight: 500,
                      }}>
                      Upload Document
                    </Text>

                    <UploadInput
                      extra={{
                        docName: document?.fileName ?? '',
                        onchange: (e: any) => {
                          if (e.length > 0) {
                            handleChangeDocument(
                              index,
                              'file',
                              DocumentType,
                              e[0]
                            );
                          }
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </>
          );
        })}
      <Grid item xs={12} sm={6} md={4} lg={6} style={{ marginBottom: 6 }}>
        <Button
          extra={{ variant: 'outlined' }}
          onClick={() => addMoreField(DocumentType)}
          icons={<AddOutlinedIcon />}
          style={{ marginTop: 1 }}>
          <Text style={{ fontSize: 12, fontWeight: 600 }}>
            Add Another Document
          </Text>
        </Button>
      </Grid>
    </Grid>
  );
}

export default DocumentTab;
