class City {
  name: string | null;

  constructor(data: Record<string, unknown>) {
    this.name = (data?.cityName ?? 'N/A').toString();
  }

  static fromJson(json: Record<string, unknown>): City {
    return new City(json);
  }

  toJson(stringify: boolean = false): Record<string, unknown> | string {
    const jsonData = {
      name: this.name,
    };
    return stringify ? JSON.stringify(jsonData) : jsonData;
  }
}

export default City;
