import React, { useContext } from 'react';
import Box from './Box';
import Text from './Text';
import Button from './Button';
import Sidebar from '../common_component/Sidebar';
import ActiveMarketForm from '../Forms/ActiveMarketForm';
import EditIcon from '@mui/icons-material/BorderColor';
import { useNavigate } from 'react-router-dom';
import Card from './Card';
import Props from '../../types/props';
import moment from 'moment';
import StringUtils from '../../utils/stringUtil';
import ProfileContext from '../../context/ProfileContext';
import Image from './Image';

function EditProfileDetail({ ...props }: Props) {
  const [Toggle, setToggle] = React.useState(false);
  const { ProfileData } = useContext(ProfileContext);

  const { user } = ProfileData || {};
  console.error('users', user);

  const { totalInvestor, totalSalesExecutive, totalTransaction } =
    props?.extra?.ProfileData ?? {};
  console.error(props?.extra?.ProfileData);
  const navigate = useNavigate();
  return (
    <Box
      style={{
        boxShadow: ' 0px 1px 3px 0px #1018281A',
        border: 1,
        borderRadius: 1,
        borderColor: '#E7E7E7',
        background: '#FFFFFF',
      }}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingInline: 6,
          paddingBlock: 1.5,
          borderBottom: 1,
          borderColor: '#E7E7E7',
        }}>
        <Text style={{ fontSize: 16, fontWeight: 600 }}>
          {StringUtils.capitalizeFirstLetter(user?.name)}{' '}
        </Text>
        <Button
          extra={{ variant: 'outlined', color: 'primary' }}
          style={{ paddingBlock: 1, paddingInline: 2, borderRadius: 1 }}
          icons={<EditIcon style={{ fontSize: 16 }} />}
          onClick={() => navigate('/editprofile')}>
          <Text style={{ color: '#104960', fontWeight: 500, fontSize: 13 }}>
            Edit Details
          </Text>
        </Button>
      </Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '60px',
          padding: '32px 40px',
        }}>
        {/* <Image
          alt="DK"
          style={{
            height: 236,
            minWidth: 236,
            background: '#E5F7FF',
            boxShadow: '0px 2px 2px 0px #1049601A',
            borderRadius: '8px',
          }}
        /> */}

        <Box
          style={{
            width: 236,
            height: 236,
            borderRadius: 5,
            backgroundColor: '#E5F7FF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {user?.profileImg ? (
            <Image
              alt={user?.name}
              src={user?.profileImg}
              style={{
                height: '100%',
                width: '100%',
              }}
            />
          ) : (
            <Text
              style={{
                fontSize: 64,
                color: '#104960',
                fontWeight: 600,
              }}>
              {StringUtils.getInitials(user?.name ?? '')}
            </Text>
          )}
        </Box>
        <Box style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
          <Box style={{ display: 'flex', gap: '20px' }}>
            <Text
              style={{
                color: 'rgb(102, 112, 133)',
                marginRight: 130,
                width: 130,
              }}>
              Name
            </Text>
            <Text>:</Text>
            <Text>{StringUtils.capitalizeFirstLetter(user && user.name)}</Text>
          </Box>
          <Box style={{ display: 'flex', gap: '20px' }}>
            <Text
              style={{
                color: 'rgb(102, 112, 133)',
                marginRight: 130,
                width: 130,
              }}>
              Email
            </Text>
            <Text>:</Text>
            <Text>{user && user.email}</Text>
          </Box>
          <Box style={{ display: 'flex', gap: '20px' }}>
            <Text
              style={{
                color: 'rgb(102, 112, 133)',
                marginRight: 130,
                width: 130,
              }}>
              Phone
            </Text>
            <Text>:</Text>
            <Text>{user && user.phoneNumber}</Text>
          </Box>
          <Box style={{ display: 'flex', gap: '20px' }}>
            <Text
              style={{
                color: 'rgb(102, 112, 133)',
                marginRight: 130,
                width: 130,
              }}>
              Location
            </Text>
            <Text>:</Text>
            <Text>
              {StringUtils.capitalizeFirstLetter(user && user?.city?.name)}
            </Text>
          </Box>
          <Box style={{ display: 'flex', gap: '20px' }}>
            <Text
              style={{
                color: 'rgb(102, 112, 133)',
                marginRight: 130,
                width: 130,
              }}>
              Joining date
            </Text>
            <Text>:</Text>
            <Text>
              {user && moment(user.joiningDate).format('DD MMM,YYYY')}
            </Text>
          </Box>
          <Box style={{ display: 'flex', gap: '20px' }}>
            <Text
              style={{
                color: 'rgb(102, 112, 133)',
                marginRight: 130,
                width: 130,
              }}>
              Role
            </Text>
            <Text>:</Text>
            <Text>
              {user && user.roleId == 1 ? 'Admin' : 'Sales Executive'}
            </Text>
          </Box>
        </Box>
        <Sidebar
          label="Active markets"
          extra={{
            Toggle,
            setToggle,
            anchor: 'right',
            description: 'Here you can add or edit active markets.',
            width: 400,
          }}>
          <ActiveMarketForm extra={{ setToggle, Toggle }} />
        </Sidebar>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '0px 40px 30px',
        }}>
        <Card
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBlock: 1.5,
            paddingInline: 2,
            background: '#F2FAFE',
            borderRadius: 1,
            boxShadow: '0px 2px 2px 0px #1049601A',
            width: '30%',
            alignItems: 'center',
          }}>
          <Text style={{ fontSize: 14, fontWeight: 600 }}>Total Investors</Text>
          <Text style={{ fontWeight: 600, fontSize: 20 }}>{totalInvestor}</Text>
        </Card>
        <Card
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBlock: 1.5,
            paddingInline: 2,
            background: '#F2FAFE',
            borderRadius: 1,
            boxShadow: '0px 2px 2px 0px #1049601A',
            width: '30%',
            alignItems: 'center',
          }}>
          <Text style={{ fontSize: 14, fontWeight: 600 }}>
            Total Sales Executives
          </Text>
          <Text style={{ fontWeight: 600, fontSize: 20 }}>
            {totalSalesExecutive}
          </Text>
        </Card>
        <Card
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBlock: 1.5,
            paddingInline: 2,
            background: '#F2FAFE',
            borderRadius: 1,
            boxShadow: '0px 2px 2px 0px #1049601A',
            width: '30%',
            alignItems: 'center',
          }}>
          <Text style={{ fontSize: 14, fontWeight: 600 }}>
            Total Transactions
          </Text>
          <Text style={{ fontWeight: 600, fontSize: 20 }}>
            {totalTransaction}
          </Text>
        </Card>
      </Box>
    </Box>
  );
}

export default EditProfileDetail;
