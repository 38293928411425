import React from 'react';
import Props from '../../types/props';
import Box from '../single_component/Box';
import CheckIcon from '@mui/icons-material/Check';
import TextLabel from '../single_component/TextLabel';
import Chip from '../single_component/Chip';

function MultipleChip({ ...props }: Props) {
  const { options, clickable, selectedChip, handleClick } = props.extra;
  return (
    <Box style={props.style}>
      <TextLabel>{props.label}</TextLabel>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          rowGap: 1,
          columnGap: 2,
          flexWrap: 'wrap',
        }}>
        {options.map((value: any, index: number) => {
          return (
            <Chip
              style={{
                width: '30%',
                marginTop: 5,
                color: selectedChip == index ? 'white' : 'black',
                fontSize: 13,
                marginBottom: 5,
                fontWeight: 'lighter',
              }}
              key={index}
              onClick={() => handleClick(index)}
              value={value}
              extra={{
                variant: selectedChip == index ? 'filled' : 'outlined',
                clickable: clickable,
                color: selectedChip == index ? 'primary' : 'default',
                icons:
                  selectedChip == index ? <CheckIcon fontSize="small" /> : null,
              }}
              label={value}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default MultipleChip;
