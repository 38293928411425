import React from 'react';
import Props from '../../types/props';
import {
  Radio as RadioButton,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import TextLabel from '../single_component/TextLabel';

function Radio({ ...props }: Props) {
  const { Selected, setSelected, options } = props.extra;
  return (
    <Box style={props.style}>
      <TextLabel>{props.label}</TextLabel>
      <RadioGroup
        aria-label="gender"
        name={props.name}
        value={Selected}
        onChange={e => {
          setSelected(e.target.value);
        }}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        {options.map((value: any, index: React.Key | null | undefined) => {
          return (
            <FormControlLabel
              key={index}
              value={value.name}
              control={<RadioButton size="small" />}
              label={<Text style={{ fontSize: 13 }}>{value.name}</Text>}
            />
          );
        })}
      </RadioGroup>
    </Box>
  );
}

export default Radio;
