import React from 'react';
import Props from '../../types/props';
import Box from '../single_component/Box';
import Links from '../common_component/Links';

function Menu(props: Props) {
  const { menuData } = props.extra;
  return (
    <nav style={{ marginLeft: -180 }}>
      <Box {...props} style={{ display: 'flex' }}>
        {menuData.map((value: any, index: number) => {
          return (
            <Links
              key={index}
              className={`header-link`}
              href={value.url}
              style={{ marginLeft: '10px', marginRight: '10px' }}>
              {value.name}
            </Links>
          );
        })}
      </Box>
    </nav>
  );
}

export default Menu;
