import DesignatedPartner from './DesignatedPartner';
import Account from './Account';
import InvestmentType from './investmentType';
import InvestmentCriteria from './investmnetCriteria';
import Investor from './investor';
import Location from './location';
import Unit from './unit';

class MasterData {
  investmentTypes: InvestmentType[];
  locations: Location[];
  investorTypes: string[];
  dealTypes: string[];
  priorities: string[];
  transactionStatus: string[];
  investors: Investor[];
  investmentCriteria: InvestmentCriteria[];
  units: Unit[];
  designatedPartners: DesignatedPartner[];
  designatedAccount: Account[];

  constructor(data: Record<string, unknown>) {
    this.investmentTypes = (
      Array.isArray(data.investment_type) ? data.investment_type : []
    ).map((cp: Record<string, unknown>) => new InvestmentType(cp));
    this.locations = (Array.isArray(data.locations) ? data.locations : []).map(
      (cp: Record<string, unknown>) => new Location(cp)
    );
    this.investorTypes = Array.isArray(data.investor_type)
      ? data.investor_type
      : [];
    this.dealTypes = Array.isArray(data.deal_type) ? data.deal_type : [];
    this.priorities = Array.isArray(data.priority) ? data.priority : [];
    this.transactionStatus = Array.isArray(data.transaction_status)
      ? data.transaction_status
      : [];
    this.units = (Array.isArray(data.units) ? data.units : []).map(
      (cp: Record<string, unknown>) => new Unit(cp)
    );
    this.designatedPartners = (
      Array.isArray(data.designatedPartner) ? data.designatedPartner : []
    ).map((cp: Record<string, unknown>) => new DesignatedPartner(cp));
    this.investmentCriteria = (
      Array.isArray(data.investment_criteria) ? data.investment_criteria : []
    ).map((cp: Record<string, unknown>) => new InvestmentCriteria(cp));
    this.investors = (Array.isArray(data.investors) ? data.investors : []).map(
      (cp: Record<string, unknown>) => new Investor(cp)
    );
    this.designatedAccount = Array.isArray(data.designatedAccount)
      ? data.designatedAccount
      : [];
    console.error(this.designatedAccount, ' this.designatedAccount');
  }

  static fromJson(json: Record<string, unknown>): MasterData {
    return new MasterData(json);
  }
  static emptyObject(): MasterData {
    return new MasterData({
      investmentTypes: [],
      locations: [],
      investorTypes: [],
      dealTypes: [],
      priorities: [],
      investmentCriteria: [],
    });
  }

  toJson(stringify: boolean = false): Record<string, unknown> | string {
    const jsonData = {
      investmentTypes: this.investmentTypes.map(it => it.toJson(false)),
      locations: this.locations.map(loc => loc.toJson(false)),
      investorTypes: this.investorTypes,
      dealTypes: this.dealTypes,
      priorities: this.priorities,
      investmentCriteria: this.investmentCriteria.map(ic => ic.toJson(false)),
    };

    return stringify ? JSON.stringify(jsonData) : jsonData;
  }
}

export default MasterData;
