import City from './City';
interface Promoter {
  promoterName: string;
  promoterEmail: string;
  promoterPhone: string;
}

class DeveloperGroup {
  name: string | null;
  phoneNumber: string | null;
  groupLogo: string | null;
  groupProfile: string | null;
  email: string | null;
  promoters: Promoter[] | null; // Updated property for promoters
  city: City;
  developerId: string | null;

  constructor(data: Record<string, unknown>) {
    this.developerId = (data.developerId ?? 'N/A').toString();
    this.name = (data.name ?? 'N/A').toString();
    this.phoneNumber = (data.phoneNumber ?? 'N/A').toString();
    this.groupLogo = (data.groupLogo ?? '').toString();
    this.groupProfile = (data.groupProfile ?? '').toString();
    this.email = (data.email ?? 'N/A').toString();
    this.promoters = this.parsePromoters(
      data.promoters as Record<string, unknown>[]
    ); // Parse promoters array
    this.city = new City(data.city as any);
  }

  private parsePromoters(
    promotersData: Record<string, unknown>[] | undefined
  ): Promoter[] | null {
    if (!promotersData) return null;
    return promotersData.map(promoter => ({
      promoterName: promoter.promoterName?.toString() ?? 'N/A',
      promoterEmail: promoter.promoterEmail?.toString() ?? 'N/A',
      promoterPhone: promoter.promoterPhone?.toString() ?? 'N/A',
      promoterId: promoter.promoterId?.toString() ?? 'N/A',
      type: promoter.type?.toString() ?? 'N/A',
      PromoterDocuments: promoter.PromoterDocuments ?? [],
    }));
  }

  static fromJson(json: Record<string, unknown>): DeveloperGroup {
    return new DeveloperGroup(json);
  }

  toJson(stringify: boolean = false): Record<string, unknown> | string {
    const jsonData = {
      name: this.name,
      phoneNumber: this.phoneNumber,
      groupLogo: this.groupLogo,
      groupProfile: this.groupProfile,
      email: this.email,
      promoters: this.promoters,
      city: this.city,
      developerId: this.developerId,
    };
    return stringify ? JSON.stringify(jsonData) : jsonData;
  }
}

export default DeveloperGroup;
