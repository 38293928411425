import React from 'react';
import Box from '../components/single_component/Box';
import ActiveTransactionContainer from '../container/ActiveTransactionContainer';
import TransactionProvider from '../providers/TransactionProvider';
import DashBoardProvider from '../providers/DashboardProvider';

function ActiveTransaction() {
  return (
    <TransactionProvider>
      <DashBoardProvider>
        <Box
          style={{
            backgroundColor: '#F2FAFE',
            paddingTop: 6,
            paddingBottom: 6,
          }}>
          <ActiveTransactionContainer />
        </Box>
      </DashBoardProvider>
    </TransactionProvider>
  );
}

export default ActiveTransaction;
