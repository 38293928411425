import React from 'react';
import Box from '../single_component/Box';
import Props from '../../types/props';
import Text from '../single_component/Text';
function PluseTwo({ children, ...props }: Props) {
  const { OverPluse } = props.extra;
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
      }}>
      {children}
      <Box
        style={{
          width: '25px',
          height: '25px',
          fontWeight: 'bold',
          background: '#E5F7FF',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          marginLeft: 1,
          justifyContent: 'center',
        }}>
        <Text style={{ fontWeight: 600, fontSize: 12 }}> {OverPluse}</Text>
      </Box>
    </Box>
  );
}

export default PluseTwo;
