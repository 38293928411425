import React, { useState } from 'react';
import Props from '../types/props';
import DeveloperGroupContext from '../context/DeveloperGroupContext';
import DeveloperGroupService from '../services/DeveloperService';

export default function DeveloperGroupProvider(props: Props) {
  const [DeveloperGroupData, setDeveloperGroupData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadGroupData, setloadGroupData] = useState(false);

  const addDeveloperGroup = async (data: any) => {
    setLoading(true);
    const response = await new DeveloperGroupService().addDeveloperGroup(
      `/developer/add`,
      data
    );
    setLoading(false);
    return response;
  };

  const deleteDeveloperGroupDocs = async (
    projectId: number,
    deletedoc: string
  ) => {
    setLoading(true);
    const response = await new DeveloperGroupService().deleteDeveloperGroupDocs(
      projectId,
      deletedoc
    );
    setLoading(false);
    return response;
  };

  const editDeveloperGroup = async (developer_id: number, data: any) => {
    setLoading(true);
    const response = await new DeveloperGroupService().editDeveloperGroup(
      `/developer/edit/${developer_id}`,
      data
    );
    setLoading(false);
    return response;
  };
  const addDeveloperFormat = async (data: any) => {
    const response = await new DeveloperGroupService().addDeveloperFormat(
      `/developer/addFormat`,
      data
    );
    return response;
  };

  const getDeveloperGroup = async (
    query: string,
    data: Record<string, unknown>
  ) => {
    setLoading(true);
    const response = await new DeveloperGroupService().fetchDeveloperGroup(
      query,
      data
    );
    setDeveloperGroupData(response);
    setLoading(false);
  };
  const deleteDevPromoterById = async (promoterId: any) => {
    setLoading(true);
    const response = await new DeveloperGroupService().deleteDevPromoterById(
      promoterId
    );
    setLoading(false);
    return response;
  };
  const deleteDevPromoterDocId = async (docId: any) => {
    setLoading(true);
    const response = await new DeveloperGroupService().deleteDevPromoterDocId(
      docId
    );
    setLoading(false);
    return response;
  };

  // useEffect(() => {
  //   getDeveloperGroup(`/developer/getAll`, {
  //     page: 1,
  //     size: 10,
  //   });
  // }, []);

  return (
    <DeveloperGroupContext.Provider
      value={{
        DeveloperGroupData,
        addDeveloperGroup,
        editDeveloperGroup,
        getDeveloperGroup,
        deleteDeveloperGroupDocs,
        setLoading,
        loading,
        addDeveloperFormat,
        loadGroupData,
        setloadGroupData,
        deleteDevPromoterById,
        deleteDevPromoterDocId,
      }}>
      {props?.children ?? ''}
    </DeveloperGroupContext.Provider>
  );
}
