import React from 'react';
import Box from '../../components/single_component/Box';
import ViewProjectContainer from '../Container/ViewProjectContainer';
import ProjectProvider from '../../providers/ProjectProvider';
import TransactionProvider from '../../providers/TransactionProvider';

function ViewProject() {
  return (
    <ProjectProvider>
      <TransactionProvider>
        <Box
          style={{
            backgroundColor: '#F2FAFE',
            paddingTop: 5,
            paddingBottom: 10,
            minHeight: '100vh',
          }}>
          <ViewProjectContainer />
        </Box>
      </TransactionProvider>
    </ProjectProvider>
  );
}

export default ViewProject;
