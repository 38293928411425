import React from 'react';
import Box from '../components/single_component/Box';
import SalesExecutiveContainer from '../container/SalesExecutiveContainer';
import SalesExecutiveProvider from '../providers/SalesExecutiveProvider';
import TodoProvider from '../providers/TodoProvider';
import TransactionProvider from '../providers/TransactionProvider';

function SalesExecutive() {
  return (
    <TodoProvider>
      <TransactionProvider>
        <SalesExecutiveProvider>
          <Box
            style={{
              backgroundColor: '#F2FAFE',
              paddingTop: '56px',
            }}>
            <SalesExecutiveContainer />
          </Box>
        </SalesExecutiveProvider>
      </TransactionProvider>
    </TodoProvider>
  );
}

export default SalesExecutive;
