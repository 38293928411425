import React from 'react';
import Props from '../types/props';
import MailService from '../services/MailService';
import MailContext from '../context/MailContext';

export default function MailProvider(props: Props) {
  const sendMail = async (data: any) => {
    const response = await new MailService().sendMail(data);
    return response;
  };

  return (
    <MailContext.Provider
      value={{
        sendMail,
      }}>
      {props.children}
    </MailContext.Provider>
  );
}
