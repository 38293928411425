import React, { useContext, useState, useEffect } from 'react';
import Props from '../types/props';
import Box from '../components/single_component/Box';
import CardContainer from '../components/Dashboard/CardContainer';
import WelcomeBox from '../components/common_component/WelcomeBox';
import { Outlet } from 'react-router-dom';
import StatusChip from '../components/common_component/StatusChip';
import Sidebar from '../components/common_component/Sidebar';
import PluseTwo from '../components/common_component/PluseTwo';
import Tables from '../components/single_component/Tables';
import { TableCell, TableRow } from '@mui/material';
import Text from '../components/single_component/Text';
import moment from 'moment';
import DashboardContext from '../context/Dashboard';
import Button from '../components/single_component/Button';
import DashboardFilter from '../components/Filter/DashboardFilter';
import FilterListIcon from '@mui/icons-material/FilterList';
import EyeAction from '../components/single_component/EyeAction';
import AuthContext from '../context/AuthContext';
import DateUtils from '../utils/dateUtils';
import StringUtils from '../utils/stringUtil';
import NotfoundPage from '../components/single_component/NotfoundPage';
import Loader from '../components/single_component/Loader';
const durationNames = [
  "Today's",
  'Weekly',
  'Monthly',
  'Quarterly',
  'Half-Yearly',
  'Yearly',
];
function DashboardContainer({ ...props }: Props) {
  const [SideBarFilter, setSideBarFilter] = useState(false);

  const { DashBoardData, getDashboardData } = useContext(DashboardContext);
  const { transaction } = DashBoardData;
  const [page, setPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [Next, setNext] = useState();
  const [Previous, setPrevious] = useState();
  const [currPage, setcurrPage] = useState(1);

  const [DealSize, setDealSize] = useState('');
  const [selectedChip, setSelectedChip] = useState(-1);
  const { masterData } = useContext(AuthContext);
  const data = masterData?.value();
  const [Date, setDate] = useState('');
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);
  const { loading } = useContext(DashboardContext);

  const [duration, setDuration] = useState<any>(
    sessionStorage.getItem('duration') || 2
  );

  useEffect(() => {
    sessionStorage.setItem('duration', duration);
  }, [duration]);

  const handleSubmit = async () => {
    setbtnDisableLoader(true);

    const dealsize = DealSize;
    const date = DateUtils.formatDate(Date, 'YYYY-MM-DD');
    const transcStatus = data?.transactionStatus[selectedChip] ?? '';

    await getDashboardData(
      `admin/dashboard?page=${currPage}&size=${rowsPerPage}`,
      {
        filter: {
          duration,
          ...(transcStatus && { transcStatus }),
          ...(date && date !== 'Invalid date' && { date }),
          ...(dealsize && { dealsize }),
        },
      }
    );
    setbtnDisableLoader(false);
    setSideBarFilter(false);
  };

  const handleChipClick = (index: number) => {
    setSelectedChip(index);
  };

  const onClick = () => {
    setSideBarFilter(!SideBarFilter);
  };

  useEffect(() => {
    setPage(DashBoardData?.totalPages);
    setNext(DashBoardData?.nextPage);
    setPrevious(DashBoardData?.prevPage);
  }, [DashBoardData]);

  useEffect(() => {
    handleSubmit();
  }, [currPage, rowsPerPage, duration]);

  const TableRowComponet = (props: any) => {
    const { row } = props;

    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: '80%' }}>
        <TableCell>
          {row?.date ? (
            <Text style={{ fontSize: 14 }}>
              {moment(row?.date).format('DD MMM YYYY')}
            </Text>
          ) : (
            <Text style={{ fontSize: 14 }}>N/A</Text>
          )}
        </TableCell>
        <TableCell>
          <Text style={{ fontSize: 14 }}>
            {row?.project?.projectName ?? 'N/A'}
          </Text>
        </TableCell>
        <TableCell>
          <Text style={{ fontSize: 14 }}>
            {row?.project?.spv?.developer?.name ?? 'N/A'}
          </Text>
        </TableCell>
        <TableCell>
          {row?.dealSize ? (
            <Text> ₹ {row?.dealSize}</Text>
          ) : (
            <Text style={{ fontSize: 14 }}>N/A</Text>
          )}
        </TableCell>
        <TableCell>
          <Box
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}>
            <Text style={{ fontSize: 14 }}>
              {(row?.investors ?? []).length > 0
                ? StringUtils.capitalizeFirstLetter(
                    row?.investors[0]?.name ?? ''
                  )
                : 'N/A'}
            </Text>
            {row?.investors?.length > 1 && (
              <PluseTwo
                extra={{ OverPluse: `+${row?.investors?.length - 1}` }}
              />
            )}
          </Box>
        </TableCell>
        <TableCell>
          <StatusChip label={row?.transcStatus} />
        </TableCell>
        <TableCell>
          <EyeAction
            extra={{
              label: row?.transcStatus,
              data: row ?? {},
              page: 'Dashboard',
              adminPage: 'adminPage',
            }}
          />
        </TableCell>
      </TableRow>
    );
  };

  const tableHeaders = [
    { name: 'Date', id: 'Date' },
    { name: 'Project Name', id: 'ProjectName' },
    { name: 'Developer Name', id: 'DeveloperName' },
    { name: 'Deal Size', id: 'DealSize' },
    { name: 'Potential Investors', id: 'PotentialInvestor' },
    { name: 'Status', id: 'Status' },
    { name: 'Action', id: 'Action' },
  ];

  return (
    <Box style={{ backgroundColor: '#F2FAFE', paddingBottom: '4%' }}>
      <Box {...props} style={{ width: '85%', margin: 'auto' }}>
        <WelcomeBox
          extra={{
            duration,
            setDuration,
            durationNames,
          }}
        />

        <CardContainer extra={{ data: DashBoardData }} />

        <Box
          style={{
            width: '100%',
            alignSelf: 'center',
            boxShadow: '0px 2px 10px 0px #10496030',
            border: '1px solid #EDEDED',
          }}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '12px 24px',
              background: 'white',
            }}>
            <Box>
              <Text style={{ fontWeight: 'bold' }}>Transactions</Text>
              <Text style={{ fontSize: 14, color: '#667085' }}>
                Here you can view all transactions that have been done till
                date.
              </Text>
            </Box>

            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                extra={{
                  variant: 'outlined',
                  color: 'tertiary',
                }}
                style={{ padding: '8px 24px', borderRadius: 1.5 }}
                icons={<FilterListIcon />}
                onClick={onClick}>
                <Text
                  style={{
                    color: 'black',
                    fontSize: 13,
                    fontWeight: 'bolder',
                  }}>
                  Add Filters
                </Text>
              </Button>
            </Box>

            <Sidebar
              label="Filter"
              extra={{
                anchor: 'right',
                Toggle: SideBarFilter,
                setToggle: setSideBarFilter,
                description: 'Filter the data as per your need',
                width: 400,
              }}>
              <DashboardFilter
                extra={{
                  handleChipClick,
                  setSelectedChip,
                  handleSubmit,
                  onCancel: onClick,
                  setDate,
                  setDealSize,
                  Date,
                  DealSize,
                  selectedChip,
                  btnDisableLoader: btnDisableLoader,
                }}
              />
            </Sidebar>
          </Box>

          {loading ? (
            <Loader />
          ) : transaction?.length > 0 ? (
            <Tables
              extra={{
                rows: transaction ?? [],
                columns: tableHeaders,
                RowComponent: TableRowComponet,
                page: page,
                setPage: setPage,
                rowsPerPage: rowsPerPage,
                setrowsPerPage: setrowsPerPage,
                Next: Next,
                Previous: Previous,
                currPage: currPage,
                setcurrPage: setcurrPage,
                setNext: setNext,
                setPrevious: setPrevious,
              }}
            />
          ) : (
            <div
              style={{
                marginTop: '10px',
              }}>
              <NotfoundPage />
            </div>
          )}
        </Box>

        <Outlet />
      </Box>
    </Box>
  );
}

export default DashboardContainer;
