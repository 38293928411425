import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import Props from '../../types/props';
import TextLabel from '../single_component/TextLabel';
import Box from '../single_component/Box';

export default function MultiselectChips({ ...props }: Props) {
  const { headingLabel, options, onSelect } = props.extra || {};
  const [selectedChips, setSelectedChips] = useState<number[]>([]);
  const handleChipClick = (index: number) => {
    const newSelectedChips = selectedChips.includes(index)
      ? selectedChips.filter((chipIndex: number) => chipIndex !== index)
      : [...selectedChips, index];
    setSelectedChips(newSelectedChips);
    onSelect(newSelectedChips);
  };
  return (
    <FormControl>
      <TextLabel>{headingLabel}</TextLabel>
      <Box style={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        {options.map((option: any, index: number) => (
          <Chip
            key={index}
            label={option.name}
            onClick={() => handleChipClick(index)}
            color={selectedChips.includes(index) ? 'primary' : 'default'}
            sx={{
              '& .MuiSvgIcon-root': { fontSize: 20 },
              '& .MuiChip-label': { fontSize: 13 },
            }}
          />
        ))}
      </Box>
    </FormControl>
  );
}
