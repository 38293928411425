import { Typography } from '@mui/material';
import React from 'react';
import Props from '../../types/props';

function Text(props: Props = {}) {
  return (
    <Typography
      style={props?.style}
      className={props?.className}
      onClick={props?.onClick}>
      {props?.children ?? ''}
    </Typography>
  );
}

export default Text;
