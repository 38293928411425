import React, { useContext, useState } from 'react';
import Box from '../single_component/Box';
import Props from '../../types/props';
import TwoButton from '../common_component/TwoButton';
import TextInput from '../common_component/TextInput';
// import UploadInput from '../single_component/UploadInput';
import SpvContext from '../../context/SpvContext';
import { toast } from 'react-toastify';
import { AddSpvFormSchema, digitRexAcceptDecimal } from '../Validation/AddTask';
import { useFormik } from 'formik';
import ErrorText from '../common_component/ErrorText';
// import NewDocUpload from '../common_component/NewDocUpload';
import Button from '../single_component/Button';
import { Close } from '@mui/icons-material';
import Text from '../single_component/Text';
import { Modal } from '@mui/material';
import SpvTabDocuments from './SpvTabDocuments';
type Doc = {
  documentName: string;
  documentFile: unknown;
};
function AddSpvForm({ ...props }: Props) {
  const { addSpv, editSpv, spvData, isEditSpv, deleteSpv } =
    useContext(SpvContext);
  const { setToggle, id } = props.extra;
  const [addNewDoc, setAddNewDoc] = useState<Doc[]>([]);
  const [spvDocuments, setSpvDocuments] = useState({
    documents: '',
    docFiles: [],
  });
  const [documentModal, setDocumentModal] = useState(false);
  const onCancel = () => {
    setToggle(false);
  };
  const [documents, setDocuments] = useState(spvData?.SpvDocuments);
  // const [testDocs,setTestDocs] = useState<any>([]);
  const handleAddNewDoc = () => {
    // setAddNewDoc([...addNewDoc, { documentName: '', documentFile: '' }]);
    setDocumentModal(true);
  };
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);
  const initialValues = isEditSpv
    ? {
        Name: spvData?.spvName == null ? '' : String(spvData?.spvName),
        AuthorizedShareCapital:
          spvData?.authSharedCap == null ? '' : String(spvData?.authSharedCap),
        Value:
          spvData?.paidSharedCap == null ? '' : String(spvData?.paidSharedCap),
      }
    : {
        Name: '',
        AuthorizedShareCapital: '',
        Value: '',
      };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    // setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: AddSpvFormSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async values => {
      setbtnDisableLoader(true);
      const bodyFormData = new FormData();
      isEditSpv && bodyFormData.append('spvId', spvData?.spvId);
      bodyFormData.append('developerId', id);
      values.Name && bodyFormData.append('spvName', values.Name);
      // bodyFormData.append('directorName', values.DirectorName);
      values.AuthorizedShareCapital &&
        bodyFormData.append('authSharedCap', values.AuthorizedShareCapital);
      values.Value && bodyFormData.append('paidSharedCap', values.Value);
      spvDocuments?.documents &&
        bodyFormData.append('documents', spvDocuments?.documents);
      Object.keys(spvDocuments.docFiles).forEach((doc: any) => {
        bodyFormData.append(doc, spvDocuments.docFiles[doc]);
      });
      // addNewDoc.length > 0 &&
      //   bodyFormData.append('documents', JSON.stringify(addNewDoc));
      // addNewDoc.forEach(doc => {
      //   if (doc.documentFile != '') {
      //     bodyFormData.append(`${doc.documentName}`, doc.documentFile as File);
      //   }
      // });
      try {
        let response;
        if (isEditSpv) {
          response = await editSpv(bodyFormData);
        } else {
          response = await addSpv(bodyFormData);
        }
        setbtnDisableLoader(false);
        if (response.success) {
          toast.success(response.message);
          setToggle(false);
        } else {
          toast.error(response.message);
        }
      } catch (e) {
        // setToggle(false);
        console.error(e);
      }
    },
  });
  // const handleNewDoc = (index: number, field: keyof Doc, value: string) => {
  //   const updatedPromoters = [...addNewDoc];
  //   updatedPromoters[index][field] = value;
  //   setAddNewDoc(updatedPromoters);
  // };

  const handleDeleteDocuments = async (documentIdToDelete: number) => {
    try {
      const response = await deleteSpv(documentIdToDelete, id);

      if (response.success) {
        toast.success(response.message);
        // setToggle(false);
        const updatedDocuments = documents.filter(
          (doc: any) => doc.documentId !== documentIdToDelete
        );
        setDocuments(updatedDocuments);
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      setToggle(false);
      console.error(e);
    }
  };
  const handleOnNextTestDocs = (obj: any) => {
    setSpvDocuments(obj);
    handleSubmit();
  };
  return (
    <>
      <Box
        style={{
          width: '90%',
          margin: 'auto',
          marginTop: 2,
          paddingBottom: 12,
        }}>
        <Box>
          <TextInput
            type="text"
            label="SPV Name "
            placeholder="Enter SPV Name"
            name="Name"
            id="Name"
            value={values.Name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.Name && errors.Name ? (
            <ErrorText>{errors.Name}</ErrorText>
          ) : null}

          <TextInput
            label="Authorized share capital"
            name="AuthorizedShareCapital"
            id="AuthorizedShareCapital"
            placeholder="Enter Authorized share capital"
            value={values.AuthorizedShareCapital}
            onChange={handleChange}
            onBlur={handleBlur}
            onInput={(e: any) => {
              e.target.value = e.target.value
                .trim()
                .replace(digitRexAcceptDecimal, '');
            }}
          />
          {touched.AuthorizedShareCapital && errors.AuthorizedShareCapital ? (
            <ErrorText>{errors.AuthorizedShareCapital}</ErrorText>
          ) : null}
          <TextInput
            onInput={(e: any) => {
              e.target.value = e.target.value
                .trim()
                .replace(digitRexAcceptDecimal, '');
            }}
            label="Paid up share capital "
            name="Value"
            id="Value"
            placeholder="Paid up share capital "
            value={values.Value}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.Value && errors.Value ? (
            <ErrorText>{errors.Value}</ErrorText>
          ) : null}

          {/* {isEditSpv
          ? documents?.map((doc: any, index: number) => (
              <Box key={index}>
                <NewDocUpload
                  extra={{
                    name: `${doc.documentName}`,
                    id: `${doc.documentName}`,
                    onHoverTitle: `${doc.documentName}`,
                    textlabel: `${doc.documentName}`,
                    chiplabel: `${
                      doc.documentFile ? '1 (file)' : `${doc.documentName}`
                    }`,
                    field: 'DirectorDocument',
                    setFieldValue,
                    handleBlur,
                    onClick: () => handleDeleteDocuments(doc?.documentId),
                    deleteDoc: true,
                  }}
                />
              </Box>
            ))
          : null} */}

          {/* {addNewDoc?.map((doc, index) => (
          <Box
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <TextInput
              name="Value"
              style={{ width: '90%' }}
              id="Value"
              placeholder="Enter document name"
              onChange={(e: any) => {
                handleNewDoc(index, 'documentName', e.target.value);
              }}
            />
            <Box
              style={{
                borderRadius: 2,
                borderTop: '1px solid #bfbfbf',
                borderBottom: '1px solid #bfbfbf',
                borderRight: '1px solid #bfbfbf',
                paddingLeft: '20px',
                marginTop: '-2.1px',
                marginLeft: '-38px',
                paddingRight: '10px',
              }}>
              <UploadInput
                name={'documentFile'}
                id={'documentFile'}
                onBlur={handleBlur}
                extra={{
                  padding: '4px',
                  isModify: true,
                  onchange: (e: any) => {
                    handleNewDoc(index, 'documentFile', e[0]);
                  },
                  style: { marginTop: '-6px' },
                }}
              />
            </Box>
          </Box>
        ))} */}

          <Button
            onClick={() => handleAddNewDoc()}
            style={{ padding: '8px', borderRadius: 2, marginTop: 2 }}
            extra={{
              variant: 'outlined',
              color: 'primary',
            }}
            // icons={<AddOutlined />}
          >
            <Text style={{ color: 'black', fontSize: 13 }}>
              {isEditSpv ? 'Manage documents' : 'Add documents'}
            </Text>
          </Button>
          <TwoButton
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              bottom: 10,
              position: 'absolute',
              width: '90%',
            }}
            extra={{
              onclickFirst: onCancel,
              //this logic should be remove
              OnClickSecond: () => {
                const filteredArray = addNewDoc.filter(
                  item => item.documentFile !== ''
                );
                setAddNewDoc(filteredArray);
                //
                handleSubmit();
              },
              firstVariant: 'outlined',
              secondVariant: 'contained',
              firstButtonText: 'Cancel',
              secondButtonText: isEditSpv ? 'Save' : 'Add',
              btnDisableLoader: btnDisableLoader,
            }}
          />
        </Box>
      </Box>
      <Modal
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        open={documentModal}
        onClose={() => setDocumentModal(false)}>
        <Box
          style={{
            width: '80%',
            background: '#fff',
            padding: 4,
            position: 'relative',
          }}>
          <Close
            onClick={() => setDocumentModal(false)}
            sx={{
              position: 'absolute',
              fontSize: 30,
              right: 15,
              top: 15,
              color: 'red',
              cursor: 'pointer',
            }}
          />
          <SpvTabDocuments
            extra={{
              onNext: handleOnNextTestDocs,
              document: isEditSpv ? { documents: documents ?? [] } : undefined,
              handledocdelete: handleDeleteDocuments,
            }}
          />
        </Box>
      </Modal>
    </>
  );
}

export default AddSpvForm;
