import React from 'react';
import Box from './Box';
import Text from './Text';
import Button from './Button';
import AddOutlined from '@mui/icons-material/AddOutlined';
import Sidebar from '../common_component/Sidebar';
import InvestmentThesis from '../Forms/InvestmentThesis';
import Chip from './Chip';
import Props from '../../types/props';
import StringUtils from '../../utils/stringUtil';
import moment from 'moment';

function InvestorThesis({ ...props }: Props) {
  const [Toggle, setToggle] = React.useState(false);

  const { data, userId } = props.extra;

  console.error(data, 'datas');
  const onClick = () => {
    setToggle(!Toggle);
  };

  return (
    <Box
      style={{
        background: 'white',
        padding: 2,
        borderRadius: 3,
        boxShadow: '0px 2px 10px 0px #10496030',
      }}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderColor: '#E7E7E7',
          alignItems: 'center',
        }}>
        <Box>
          <Text style={{ fontSize: 16, fontWeight: 600 }}>
            Investment Thesis
          </Text>
          <Text style={{ fontSize: 13, fontWeight: 400, color: '#757575' }}>
            Here you can view and add investment thesis.
          </Text>
        </Box>
        <Button
          extra={{ variant: 'contained', color: 'primary' }}
          icons={<AddOutlined />}
          style={{ padding: '8px 24px', borderRadius: 1 }}
          onClick={onClick}>
          <Text style={{ color: 'white', fontWeight: 500, fontSize: 14 }}>
            Add investment thesis
          </Text>
        </Button>
      </Box>
      <Box>
        <Box style={{ margin: '24px 24px 5px' }}>
          {data.map((value: any, index: number) => (
            <Box key={index}>
              <Chip
                style={{
                  background: '#E5F7FF',
                }}
                extra={{
                  variant: 'outlined',
                }}
                label={moment(value?.meetingDate, 'DD-MM-YYYY').format(
                  'MMM DD, YYYY'
                )}
              />
              <Box
                style={{
                  marginLeft: 4,
                  padding: 2,
                  borderLeft: 1,
                  borderColor: 'gray',
                }}>
                <Text style={{ fontSize: 14 }}>
                  {StringUtils.capitalizeFirstLetter(value?.text)}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Sidebar
        label="Investment thesis"
        extra={{
          Toggle,
          setToggle,
          anchor: 'right',
          description: 'Filter the data as per you need',
          width: 400,
        }}>
        <InvestmentThesis extra={{ setToggle, userId }} />
      </Sidebar>
    </Box>
  );
}
export default InvestorThesis;
