import React from 'react';
import Props from '../../types/props';
import { Drawer } from '@mui/material';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
function Sidebar(props: Props = {}) {
  return (
    <Drawer
      anchor={props?.extra?.anchor ?? ''}
      open={props?.extra?.Toggle ?? ''}
      onClose={() => props?.extra?.setToggle(!props?.extra?.Toggle ?? '')}>
      <Box
        style={{
          width: props?.extra?.width ?? '',
        }}>
        <Box
          style={{
            backgroundColor: '#104960',
            width: '100%',
            padding: 2,
            position: 'sticky',
          }}>
          <Box
            onClick={() => {
              props?.extra?.setToggle(false);
            }}>
            <HighlightOffOutlinedIcon
              style={{
                position: 'absolute',
                right: 15,
                top: 10,
                color: 'white',
                cursor: 'pointer',
              }}></HighlightOffOutlinedIcon>
          </Box>

          <Text style={{ color: 'white', fontSize: 16 }}>
            {props?.label ?? ''}
          </Text>
          <Text
            style={{
              color: 'white',
              fontSize: 12,
            }}>
            {props?.extra?.description ?? ''}
          </Text>
        </Box>

        <Box style={{ maxHeight: '88vh', overflowY: 'auto' }}>
          {props?.children ?? ''}
        </Box>
      </Box>
    </Drawer>
  );
}

export default Sidebar;
