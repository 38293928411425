import React, { useContext, useEffect, useState } from 'react';
import Box from '../single_component/Box';
import TwoButton from '../common_component/TwoButton';
import Props from '../../types/props';
// import Button from '../single_component/Button';
import DeletableChip from '../single_component/DeletableChip';
import Image from '../single_component/Image';
import deleteIcon from '../../assets/close.png';
import { useFormik } from 'formik';
import InvestorContext from '../../context/InvestorContext';
import { toast } from 'react-toastify';
import ErrorText from '../common_component/ErrorText';
// import { activeMarketsValidation } from '../Validation/AddTask';
import TextInput from '../common_component/TextInput';
import Button from '../single_component/Button';

function AddSalesExecutive({ ...props }: Props) {
  const { setToggle, locations, userId, updateMasterData } = props.extra;
  const { addActiveMarkets } = useContext(InvestorContext);
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);

  const onCancel = () => {
    setToggle(false);
  };

  useEffect(() => {
    const temp = locations?.map((item: any) => {
      return item?.name;
    });
    setActiveLocChips(temp);
  }, []);
  const [activeLocValues, setActiveLocation] = useState({
    activeLocation: '',
  });
  const [activeLocChips, setActiveLocChips] = useState<any>([]);
  console.error('activeLocChips', activeLocChips);

  const handleActiveLocation = (e: any) => {
    setActiveLocation({
      ...activeLocValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddActiveLocaChip = () => {
    if (activeLocValues.activeLocation.trim() !== '') {
      setActiveLocChips([...activeLocChips, activeLocValues.activeLocation]);
      setActiveLocation({
        ...activeLocValues,
        activeLocation: '',
      });
    }
  };

  const handleDeleteActiveLocChip = (index: any) => {
    const newChips = [...activeLocChips];
    newChips.splice(index, 1);
    setActiveLocChips(newChips);
  };

  const { touched, errors, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      activeMarkets: '',
    },
    // validationSchema: activeMarketsValidation,
    onSubmit: async values => {
      setbtnDisableLoader(true);
      console.error(values);

      const response = await addActiveMarkets(userId, {
        activeMarkets: activeLocChips,
        investorId: parseInt(userId),
      });
      setbtnDisableLoader(false);
      if (response.success) {
        setToggle(false);
        updateMasterData();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    },
  });

  return (
    <Box style={{ width: '90%', margin: 'auto', marginTop: 2 }}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
        }}>
        <TextInput
          label="Active markets"
          placeholder="Enter Location preference"
          id="activeLocation"
          name="activeLocation"
          onBlur={handleBlur}
          value={activeLocValues.activeLocation}
          onChange={handleActiveLocation}
        />
        <Button
          extra={{ variant: 'contained' }}
          style={{ marginTop: 2.5, padding: '8px 30px' }}
          onClick={handleAddActiveLocaChip}>
          Add
        </Button>
      </Box>
      <Box>
        {activeLocChips.map((chip: any, index: number) => (
          <DeletableChip
            key={index}
            label={chip}
            onClick={() => handleDeleteActiveLocChip(index)}
            style={{ margin: '4px', background: '#E5F7FF' }}
            extra={{
              variant: 'outlined',
              icons: (
                <Image
                  src={deleteIcon}
                  style={{ cursor: 'pointer', width: '15px' }}
                />
              ),
            }}
          />
        ))}
      </Box>
      {touched.activeMarkets && errors.activeMarkets ? (
        <ErrorText>{errors.activeMarkets}</ErrorText>
      ) : null}
      <TwoButton
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bottom: 10,
          position: 'absolute',
          width: '90%',
        }}
        extra={{
          onclickFirst: onCancel,
          OnClickSecond: handleSubmit,
          firstVariant: 'outlined',
          secondVariant: 'contained',
          firstButtonText: 'Cancel',
          secondButtonText: 'Add',
          btnDisableLoader,
        }}
      />
    </Box>
  );
}

export default AddSalesExecutive;
