import React, { useState } from 'react';
import Props from '../types/props';
import SpvContext from '../context/SpvContext';
import SpvServices from '../services/SpvServices';

export default function SpvProvider(props: Props) {
  const [SpvData, setSpvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addSPV, setAddSpv] = useState(false);
  const [spvData, setspvData] = useState({});
  const [isEditSpv, setisEditSpv] = useState(false);
  // const getSpv = async (id: number) => {
  //   setLoading(true);
  //   const data = await new SpvServices().getSpv(`spv/get/${id}`);
  //   setSpvData(data as any);
  //   setLoading(false);
  // };

  const getSpv = async (id: number) => {
    try {
      setLoading(true);
      const data = await new SpvServices().getSpv(`spv/get/${id}`);
      setSpvData(data as any);
    } catch (error) {
      console.error('Error fetching SPV data:', error);
    } finally {
      setLoading(false);
    }
  };

  const addSpv = async (data: any) => {
    const spvId = data?.get('developerId');
    console.error(spvId);
    setLoading(true);
    const response = await new SpvServices().addSpv(data);
    setLoading(false);
    if (response) {
      getSpv(spvId);
    }
    return response;
  };
  const editSpv = async (data: any) => {
    const spvId = data?.get('developerId');
    setLoading(true);
    const response = await new SpvServices().editSpv(data);
    setLoading(false);
    if (response) {
      getSpv(spvId);
    }
    return response;
  };
  const deleteSpv = async (docId: number, data: any) => {
    console.error(data);
    setLoading(true);
    const response = await new SpvServices().deleteSpv(docId);
    setLoading(false);
    if (response) {
      getSpv(data);
    }
    return response;
  };

  return (
    <SpvContext.Provider
      value={{
        getSpv,
        addSpv,
        SpvData,
        setLoading,
        loading,
        addSPV,
        setAddSpv,
        spvData,
        setspvData,
        isEditSpv,
        setisEditSpv,
        editSpv,
        deleteSpv,
      }}>
      {props.children}
    </SpvContext.Provider>
  );
}
