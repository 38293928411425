import React from 'react';
import { MenuItem, Select } from '@mui/material';
import Props from '../../types/props';
import Text from './Text';

function DropdownSelectBold({ ...props }: Props) {
  const { extra, style } = props;
  const { placeholder, options } = extra;

  return (
    <Select
      value={props.value}
      name={props.name}
      id={props.id}
      sx={style}
      defaultValue={options[0]}
      onBlur={props.onBlur}
      onChange={(e: any) => {
        if (props.onChange) {
          props.onChange(e);
        }
      }}
      renderValue={options => {
        if (options.length === 0) {
          return (
            <Text style={{ fontSize: 13, color: '#bbbbbb' }}>
              {placeholder}
            </Text>
          );
        }
        return (
          <Text style={{ fontSize: 13, fontWeight: 'bold' }}>{options}</Text>
        );
      }}>
      {options.map((value: Record<string, unknown>, index: number) => {
        return (
          <MenuItem value={index} key={index}>
            <Text style={{ fontSize: 13, fontWeight: '600' }}>
              {(value['name'] ?? '').toString()}
            </Text>
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default DropdownSelectBold;
