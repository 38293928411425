import React, { useContext, useState } from 'react';
import Box from '../single_component/Box';
import Props from '../../types/props';
import TwoButton from '../common_component/TwoButton';
import Dpicker from '../single_component/Dpicker';
import SubjectInput from '../single_component/SubjectInput';
import InvestorContext from '../../context/InvestorContext';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import ErrorText from '../common_component/ErrorText';
import { addInvestorThesisValidation } from '../Validation/AddTask';
import DateUtils from '../../utils/dateUtils';

function text({ ...props }: Props) {
  const { setToggle, userId } = props.extra;
  console.error(props.extra);
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);

  const onCancel = () => {
    setToggle(false);
  };
  const [Date, setDate] = React.useState('');

  const { addInvestorThesis } = useContext(InvestorContext);

  const {
    handleBlur,
    values,
    errors,
    setFieldValue,
    touched,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      text: '',
      meetingDate: '',
    },
    validationSchema: addInvestorThesisValidation,
    onSubmit: async values => {
      setbtnDisableLoader(true);
      try {
        const response = await addInvestorThesis(userId, {
          text: values.text,
          meetingDate: DateUtils.formatDate(values.meetingDate, 'DD-MM-YYYY'),
        });
        setbtnDisableLoader(false);
        if (response.success) {
          setToggle(false);
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      } catch (e) {
        setbtnDisableLoader(false);
        console.error(e);
      }
    },
  });

  return (
    <Box style={{ width: '90%', margin: 'auto', marginTop: 2 }}>
      <Box>
        <SubjectInput
          label="Investment thesis"
          name="text"
          value={values.text}
          onChange={handleChange}
          onBlur={handleBlur}
          id="text"
          style={{ width: '100%' }}
          placeholder="Enter Investment thesis"
        />
        {touched.text && errors.text ? (
          <ErrorText>{errors.text}</ErrorText>
        ) : null}

        <Dpicker
          name="meetingDate"
          id="meetingDate"
          onBlur={handleBlur}
          extra={{
            placeholder: 'Choose meeting date',
            setDate: (e: any) => {
              setFieldValue('meetingDate', e);
              setDate(e);
            },
          }}
          value={Date}
          style={{ width: '100%', marginTop: 2, marginBottom: 1 }}
          label="Meeting Date"
        />
        {touched.meetingDate && errors.meetingDate ? (
          <ErrorText>{errors.meetingDate}</ErrorText>
        ) : null}

        <TwoButton
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bottom: 10,
            position: 'absolute',
            width: '90%',
          }}
          extra={{
            onclickFirst: onCancel,
            OnClickSecond: handleSubmit,
            firstVariant: 'outlined',
            secondVariant: 'contained',
            firstButtonText: 'Cancel',
            secondButtonText: 'Add',
            btnDisableLoader: btnDisableLoader,
          }}
        />
      </Box>
    </Box>
  );
}

export default text;
