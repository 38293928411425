import React from 'react';
import Box from './Box';
import TextInput from '../common_component/TextInput';
import Button from './Button';
import Props from '../../types/props';
import Text from './Text';
import FilterListIcon from '@mui/icons-material/FilterList';
function InvestorSearchFilterCon({ ...props }: Props) {
  const { Toggle, setToggle, onSearchKeyChange } = props.extra;
  const onClick = () => {
    setToggle(!Toggle);
  };

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 24px 14px 24px ',
        background: 'white',
      }}>
      <Box style={{ width: 452, height: 48 }}>
        <TextInput
          onChange={e => {
            onSearchKeyChange(e.currentTarget.value);
          }}
          placeholder="Search"
        />
      </Box>
      <Button
        extra={{
          variant: 'outlined',
          color: 'tertiary',
        }}
        style={{ padding: '8px 28px', borderRadius: 1.5 }}
        onClick={onClick}
        icons={<FilterListIcon />}>
        <Text style={{ color: 'black', fontSize: 13 }}>Add Filters</Text>
      </Button>
    </Box>
  );
}

export default InvestorSearchFilterCon;
