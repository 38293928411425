import React, { useEffect, useContext, useState } from 'react';
import Box from '../components/single_component/Box';
import DeveloperDataTable from '../components/Sales Executive/DeveloperDataTable';
import DeveloperGroupTable from '../components/Sales Executive/DeveloperGroupTable';
import ProfileCard from '../components/Sales Executive/ProfileCard';
import SalesExectiveTransTable from '../components/Sales Executive/SalesExectiveTransTable';
import TodoSales from '../components/Sales Executive/TodoSales';
import { useParams } from 'react-router-dom';
import SalesExecutiveContext from '../context/SalesExecutiveContext';
import TransactionsContex from '../context/TransactionsContex';
import TodoContext from '../context/TodoContext';
import DateUtils from '../utils/dateUtils';

function SalesExecutiveContainer() {
  const { id } = useParams();

  const { getSalesExecutiveById, SalesData } = useContext(
    SalesExecutiveContext
  );

  const { Transaction, getTransaction } = useContext(TransactionsContex);
  const { getTodo, TodoData, todoPagModule } = useContext(TodoContext);

  const { salesExecutive, developerGroup, developerDataFormat } = SalesData;

  const username = salesExecutive?.name;

  const [page, setPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [next, setNext] = useState();
  const [previous, setPrevious] = useState();
  const [currPage, setcurrPage] = useState(1);
  const [developerGroupPage, setdeveloperGroupPage] = React.useState(1);
  const [developerGrouprowsPerPage, setdeveloperGrouprowsPerPage] =
    React.useState(10);
  const [developerGroupNext, setdeveloperGroupNext] = React.useState();
  const [developerGroupPrevious, setdeveloperGroupPrevious] = React.useState();
  const [developerGroupcurrPage, setdeveloperGroupcurrPage] = React.useState(1);

  useEffect(() => {
    getTodo(currPage, rowsPerPage, { assignedTo: id });
  }, [currPage, rowsPerPage]);

  useEffect(() => {
    getSalesExecutiveById(
      id,
      developerGroupcurrPage,
      developerGrouprowsPerPage
    );
  }, [developerGroupcurrPage, developerGrouprowsPerPage]);

  useEffect(() => {
    setPage(todoPagModule?.data?.total_pages);
    setPrevious(todoPagModule?.data?.prev);
    setNext(todoPagModule?.data?.next);
  }, [todoPagModule]);

  const options = [
    {
      id: 2,
      name: 10,
    },
    {
      id: 3,
      name: 20,
    },
    {
      id: 4,
      name: 30,
    },
  ];
  const [transactionsPage, settransactionsPage] = useState(1);
  const [transactionsRowPerPage, settransactionsRowPerPage] = useState(10);
  const [transactionsCurrentPage, settransactionsCurrentPage] = useState(1);
  const [transactionsNext, settransactionsNext] = useState();
  const [transactionsPrevious, settransactionsPrevious] = useState();
  // {transactionsPage, settransactionsPage,transactionsRowPerPage, settransactionsRowPerPage,transactionsCurrentPage, settransactionsCurrentPage,transactionsNext, settransactionsNext,transactionsPrevious, settransactionsPrevious}
  const [DealSize, setDealSize] = useState('');
  const [selectedChip, setSelectedChip] = useState(-1);
  const [Date, setDate] = useState('');
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);
  const [filterToggle, sefilterToggle] = useState(false);

  const onCancel = () => {
    sefilterToggle(true);
  };
  const transactionStatus = [
    'Initiated',
    'Raised',
    'Approved',
    'Hold',
    'Cancelled',
  ];
  console.error('transactionStatus', transactionStatus);
  const transcStatus = transactionStatus?.[selectedChip] ?? '';

  const dealsize = DealSize;
  const date = DateUtils.formatDate(Date, 'YYYY-MM-DD');
  const handleSubmit = async () => {
    setbtnDisableLoader(true);
    console.error('[transcStatus] ', [transcStatus]);

    try {
      const response = await getTransaction(
        transactionsCurrentPage,
        transactionsRowPerPage,
        {
          filter: {
            ...(transcStatus && { transcStatus: [transcStatus] }),
            ...(date && { date }),
            ...(dealsize && { dealsize }),
            createdBy: id,
          },
        }
      );
      setbtnDisableLoader(false);
      if (response.success) {
        sefilterToggle(false);
      } else {
        sefilterToggle(false);
      }
    } catch (e) {
      setbtnDisableLoader(false);
      console.error(e);
    }
  };
  const handleChipClick = (index: number) => {
    setSelectedChip(index);
  };

  useEffect(() => {
    settransactionsPage(Transaction?.totalPages);
    settransactionsNext(Transaction?.nextPage);
    settransactionsPrevious(Transaction?.prevPage);
  }, [Transaction]);

  useEffect(() => {
    getTransaction(transactionsCurrentPage, transactionsRowPerPage, {
      filter: {
        createdBy: id,
        ...(transcStatus && { transcStatus: [transcStatus] }),
        ...(date && { date }),
        ...(dealsize && { dealsize }),
      },
    });
  }, [transactionsCurrentPage, transactionsRowPerPage]);
  return (
    <Box>
      <ProfileCard
        extra={{
          data: salesExecutive ?? {},
          developerCount: developerGroup?.length ?? 0,
        }}
      />

      <Box
        style={{
          width: '85%',
          margin: 'auto',
          marginTop: 6,
          boxShadow: ' 0px 2px 10px 0px #10496030',
          borderRadius: 2,
        }}>
        <DeveloperDataTable extra={{ data: developerDataFormat ?? [] }} />
      </Box>

      <Box style={{ marginTop: 6 }}>
        <DeveloperGroupTable
          extra={{
            data: developerGroup ?? [],
            setdeveloperGroupcurrPage,
            developerGroupcurrPage,
            setdeveloperGroupPrevious,
            developerGroupPrevious,
            setdeveloperGroupNext,
            developerGroupNext,
            setdeveloperGrouprowsPerPage,
            setdeveloperGroupPage,
            developerGroupPage,
            developerGrouprowsPerPage,
          }}
        />
      </Box>
      <Box
        style={{
          width: '85%',
          margin: 'auto',
          marginTop: 6,
          boxShadow: ' 0px 2px 10px 0px #10496030',
          borderRadius: 2,
        }}>
        <SalesExectiveTransTable
          extra={{
            data: Transaction?.records ?? [],
            userId: id,
            handleSubmit,
            onCancel,
            transactionStatus,
            handleChipClick,
            DealSize,
            setDealSize,
            selectedChip,
            setSelectedChip,
            Date,
            setDate,
            btnDisableLoader,
            sefilterToggle,
            filterToggle,
            transactionsPage,
            settransactionsPage,
            transactionsRowPerPage,
            settransactionsRowPerPage,
            transactionsCurrentPage,
            settransactionsCurrentPage,
            transactionsNext,
            settransactionsNext,
            transactionsPrevious,
            settransactionsPrevious,
          }}
        />
      </Box>
      <Box style={{ marginTop: 6, paddingBottom: 10 }}>
        <TodoSales
          extra={{
            TodoData: TodoData ?? [],
            userId: id,
            username: username,
            previous,
            setcurrPage,
            currPage,
            page,
            rowsPerPage,
            setrowsPerPage,
            options,
            next,
          }}
        />
      </Box>
    </Box>
  );
}

export default SalesExecutiveContainer;
