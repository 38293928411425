import React, { ReactNode } from 'react';
// import Box from './Box';
import { Link as ReactLink, useLocation, useMatches } from 'react-router-dom';
import { Breadcrumbs, Stack, Link } from '@mui/material';
import Text from './Text';

function BreadCrumb() {
  const matches = useMatches();
  // Assuming you have a type or interface like this
  interface Match {
    id?: string;
    params?: Record<string, unknown>;
    pathname?: string;
    handle?: {
      crumb?: (data: any) => React.ReactNode; // Adjust the type of data as needed
    };
    data?: Record<string, string>; // Adjust the type of data as needed
  }

  interface BreadCrumbObject {
    name: ReactNode;
    location: string;
  }

  // ...
  const location = useLocation();
  const crumbs: BreadCrumbObject[] = (matches as Match[])
    .filter(match => Boolean(match.handle?.crumb))
    .map(match =>
      match?.handle?.crumb
        ? {
            name: match?.handle?.crumb(location?.state ?? {}),
            location: match?.pathname ?? '',
          }
        : { name: '', location: '' }
    );
  console.error('crumbs', matches);

  const getLinkElement = (segment: BreadCrumbObject, index: number) => {
    return index < crumbs.length ? (
      <Link underline="hover" key="1" color="inherit">
        <ReactLink
          to={`${segment.location}`}
          style={{ textDecoration: 'none' }}>
          <Text
            style={{
              fontSize: 14,
              color: '#757575',
              textDecorationLine: 'none',
            }}>
            {segment.name}
          </Text>
        </ReactLink>
      </Link>
    ) : (
      <Text
        style={{
          fontSize: 14,
          color: '#757575',
          textDecorationLine: 'none',
        }}>
        {segment.name}
      </Text>
    );
  };

  return (
    <div
      className={`${
        crumbs.length > 0 ? 'BreadCrumbStyle' : 'RemoveBreadCrumbStyle'
      }`}>
      <Stack spacing={2}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {(crumbs.length > 0
            ? [{ name: 'Dashboard', location: '/' }, ...crumbs]
            : crumbs
          ).map((segment, index) => getLinkElement(segment, index))}
        </Breadcrumbs>
      </Stack>
    </div>
  );
}

export default BreadCrumb;
