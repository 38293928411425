import React from 'react';
import { Chip as MuiChip } from '@mui/material';
import Props from '../../types/props';

function DeletableChip({ ...props }: Props) {
  const { variant, color, clickable } = props.extra;
  return (
    <MuiChip
      icon={props.extra.icons}
      label={props.label}
      variant={variant}
      clickable={clickable}
      style={{
        display: 'inline-flex',
        justifyContent: 'space-between',
        ...props.style,
        paddingRight: 12,
        flexDirection: 'row-reverse',
      }}
      sx={{
        '& .MuiChip-icon': {
          fontFamily: 'Inter',
          order: 3,
        },
      }}
      color={color}
      onClick={props.onClick}
    />
  );
}

export default DeletableChip;
