import React, { useContext, useEffect } from 'react';
import Box from '../components/single_component/Box';
import InvestorDowloadCard from '../components/single_component/InvestorDowloadCard';
import ContactPersonAddNew from '../components/single_component/ContactPersonAddNew';
import InvestorThesis from '../components/single_component/InvestorThesis';
import DealDetails from '../components/single_component/DealDetails';
import InvestorDataFormat from '../components/single_component/InvestorDataFormat';
import { useLocation, useParams } from 'react-router-dom';
import InvestorContext from '../context/InvestorContext';
import AuthContext from '../context/AuthContext';
import BreadCrumbContext from '../context/BreadCrumbContext';

function InvestorDetailContainer() {
  const { id } = useParams();

  const { getInvestorDetail, InvestorDetail, oneInvestorDownload } =
    useContext(InvestorContext);
  const { masterData, init } = useContext(AuthContext);

  const { setPathParams } = useContext(BreadCrumbContext);

  const masterDataValue = masterData?.value();

  const { investorDetails, dataFormat, thesis } = InvestorDetail ?? {};

  const { iContactPeople } = investorDetails ?? {};

  const location = useLocation();

  useEffect(() => {
    getInvestorDetail(id);
    setPathParams({ ':id': location?.state?.investorName ?? '' });
  }, []);
  return (
    <Box>
      <Box style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <InvestorDowloadCard
          extra={{
            data: investorDetails ?? {},
            masterDataValue,
            userId: id,
            oneInvestorDownload: oneInvestorDownload,
            updateMasterData: init,
          }}
        />
        <ContactPersonAddNew
          extra={{ data: iContactPeople ?? [], userId: id }}
        />
        <InvestorThesis extra={{ data: thesis ?? [], userId: id }} />
        <DealDetails extra={{ data: investorDetails ?? {}, masterDataValue }} />
        <InvestorDataFormat extra={{ data: dataFormat ?? [], userId: id }} />
      </Box>
    </Box>
  );
}

export default InvestorDetailContainer;
