import { Moment } from 'moment';
import DateUtils from '../utils/dateUtils';
import DesignatedAccount from './designatedAccount';

class DesignatedPartner {
  partnerId: number;
  gstId: number;
  accountHolder: string;
  accountNumber: string;
  bankName: string;
  ifscCode: string;
  branchAddress: string;
  designatedAccounts: DesignatedAccount[];
  createdBy: number; // Assuming userId is of type number
  updatedBy: number; // Assuming userId is of type number
  createdAt: Moment | null;
  updatedAt: Moment | null;
  selected: boolean;

  constructor(data: Record<string, unknown>) {
    this.partnerId = parseInt((data.partnerId ?? 'N/A').toString());
    this.gstId = parseInt((data.gstId ?? 'N/A').toString());
    this.accountHolder = (data.accountHolder ?? 'N/A').toString();
    this.accountNumber = (data.accountNumber ?? 'N/A').toString();
    this.bankName = (data.bankName ?? 'N/A').toString();
    this.ifscCode = (data.ifscCode ?? 'N/A').toString();
    this.designatedAccounts = (
      Array.isArray(data.designatedAccounts) ? data.designatedAccounts : []
    ).map((cp: Record<string, unknown>) => new DesignatedAccount(cp));
    this.branchAddress = (data.branchAddress ?? 'N/A').toString();
    this.createdBy = parseInt((data.createdBy ?? 'N/A').toString());
    this.updatedBy = parseInt((data.updatedBy ?? 'N/A').toString());
    this.createdAt = DateUtils.parseDate((data.createdAt ?? 'N/A').toString());
    this.updatedAt = DateUtils.parseDate((data.updatedAt ?? 'N/A').toString());
    this.selected = false;
  }

  static fromJson(json: Record<string, unknown>): DesignatedPartner {
    return new DesignatedPartner(json);
  }

  toJson(stringify: boolean = false): Record<string, unknown> | string {
    const jsonData = {
      partnerId: this.partnerId,
      gstId: this.gstId,
      accountHolder: this.accountHolder,
      accountNumber: this.accountNumber,
      bankName: this.bankName,
      ifscCode: this.ifscCode,
      branchAddress: this.branchAddress,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      createdAt: this.createdAt?.toISOString(),
      updatedAt: this.updatedAt?.toISOString(),
    };

    return stringify ? JSON.stringify(jsonData) : jsonData;
  }
}

export default DesignatedPartner;
