export default class Message {
  required(fieldName: string): string {
    return `${fieldName} is required`;
  }
  length(fieldName: string, min?: number, max?: number): string {
    let msg = `${fieldName} must have`;

    if (min || max) {
      if (min) {
        msg += ` at least ${min} characters`;
      }
      if (max) {
        msg += `and maxmimun ${max} characters`;
      }
    } else {
      msg += ' valid length';
    }
    return msg;
  }

  invalid(fieldName: string): string {
    return `${fieldName} format is invalid`;
  }
  alphabets(fieldName: string): string {
    return `${fieldName} contain only alphabets`;
  }
  phone(fieldName: string): string {
    return `${fieldName} should be 10 digits`;
  }
}
