import React, { useContext } from 'react';
import Box from '../single_component/Box';
import Props from '../../types/props';
import Dpicker from '../single_component/Dpicker';
import MultipleChip from '../common_component/MultipleChip';
import TwoButton from '../common_component/TwoButton';
import TextInput from '../common_component/TextInput';

import AuthContext from '../../context/AuthContext';

function DashboardFilter({ ...props }: Props) {
  const { masterData } = useContext(AuthContext);
  const data = masterData?.value();
  const {
    btnDisableLoader,
    Date,
    DealSize,
    selectedChip,
    handleChipClick,
    handleSubmit,
    setSelectedChip,
    setDate,
    setDealSize,
  } = props.extra;
  const handleClearFilter = () => {
    setDealSize('');
    setDate('select date');
    setSelectedChip(-1);
  };
  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <Box style={{ width: '90%', alignSelf: 'center' }}>
        <Dpicker
          value={Date}
          extra={{ setDate, placeholder: 'Choose date' }}
          style={{ width: '100%', marginTop: 2, marginBottom: 1 }}
          label="Date"
        />
        <TextInput
          placeholder="Enter deal Size"
          value={DealSize}
          onInput={(e: any) => {
            e.target.value = e.target.value
              .trim()
              .replace(/^[/d-]|[-+]-|[^0-9-]+|^[\d]+[-][\d]+[-+]/, '');
          }}
          label="Deal Size"
          onChange={(e: any) => setDealSize(e.target.value)}
        />
        <MultipleChip
          style={{ width: '100%', marginTop: 2, marginBottom: 1 }}
          label="Status"
          extra={{
            handleClick: handleChipClick,
            options: data?.transactionStatus,
            variant: 'outlined',
            clickable: true,
            selectedChip: selectedChip,
          }}
        />

        <TwoButton
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bottom: 10,
            position: 'absolute',
            width: '90%',
          }}
          extra={{
            onclickFirst: handleClearFilter,
            OnClickSecond: handleSubmit,
            firstVariant: 'outlined',
            secondVariant: 'contained',
            firstButtonText: 'Clear Filter',
            secondButtonText: 'Apply',
            btnDisableLoader: btnDisableLoader,
          }}
        />
      </Box>
    </Box>
  );
}

export default DashboardFilter;
