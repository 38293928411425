import React from 'react';
import Props from '../../types/props';

function Image(props: Props = {}) {
  return (
    <img
      className={props?.className}
      onClick={props?.onClick}
      src={props?.src ?? ''}
      style={props?.style}
      alt={'No image here'}
    />
  );
}

export default Image;
