import React, { useCallback, useState } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import Props from '../../types/props';
import Image from './Image';
import DownloadIcon from '../../assets/images/uploadIcon.png';
import addIcon from '../../assets/images/addIcon.png';
import Box from './Box';
import TextLabel from './TextLabel';

const FileDropzone = (props: Props = {}) => {
  const { padding, onchange, isModify, style, isAddIcon, docName, noFile } =
    props?.extra ?? {};
  const [fileName, setFileName] = useState<string | null>(null);
  const dropzoneOptions: DropzoneOptions = {
    onDrop: useCallback((acceptedFiles: File[]) => {
      console.error('Dropped files:', acceptedFiles);

      if (acceptedFiles.length > 0) {
        setFileName(acceptedFiles[0].name);
        if (onchange) onchange(acceptedFiles);
      }
    }, []),
    maxFiles: 1,
  };

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneOptions);
  if (docName !== '' && docName !== undefined && docName?.name != fileName)
    setFileName(docName?.name);
  return (
    <Box style={{ width: isModify ? 'auto' : '100%', overflow: 'hidden' }}>
      <TextLabel> {props?.label ?? ''}</TextLabel>
      <div
        style={{ ...style, padding: padding && padding }}
        {...getRootProps()}
        className={`${
          isDragActive || fileName ? 'FileDropzoneDrop' : 'FileDropzone'
        }`}>
        <Image
          src={isAddIcon ? addIcon : DownloadIcon}
          className="uploadImage"
          style={{
            width: 20,
            height: 20,
            objectFit: 'cover',
            alignSelf: 'flex-start',
          }}
        />
        <input
          {...getInputProps({ onChange: onchange })}
          // className="FileDropzone"
          name={props?.name ?? ''}
          id={props?.id ?? ''}
          onBlur={props?.onBlur}
        />
        {noFile === undefined && fileName ? (
          <p
            style={{
              color: 'black',
              width: '80%',
              fontFamily: 'revert',
              fontSize: 13,
            }}>
            {fileName}
          </p>
        ) : isModify ? null : (
          <p
            style={{
              color: 'rgb(178 178 178)',
              width: '80%',
              fontFamily: 'revert',
              fontSize: 13,
            }}>
            {'Drag & drop to upload or  '}{' '}
            <span style={{ fontSize: 12, fontWeight: 600, color: '#737373' }}>
              {' '}
              Browse
            </span>
          </p>
        )}
      </div>
    </Box>
  );
};

export default FileDropzone;
