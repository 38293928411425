import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import axios from 'axios';

interface LocationMapProps {
  initialPosition: google.maps.LatLngLiteral;
  isLoaded: boolean;
  onAddressChange: (address: google.maps.places.PlaceResult) => void;
}

const LocationMap: React.FC<LocationMapProps> = ({
  initialPosition,
  onAddressChange,
  isLoaded,
}) => {
  const [position, setPosition] =
    useState<google.maps.LatLngLiteral>(initialPosition);
  const [markerPosition, setMarkerPosition] =
    useState<google.maps.LatLngLiteral>(initialPosition);
  const [zoom, setZoom] = useState<number>(15); // Default zoom level

  // Update position and zoom when initialPosition changes
  useEffect(() => {
    // setPosition(initialPosition);
    setMarkerPosition(initialPosition);
    setZoom(18); // Set zoom to a closer view
  }, [initialPosition]);

  // Fetch address when position changes
  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.lng}&key=AIzaSyBEujiUSLLR_Tfhms6hb7ohSeQkHfjSPlE`
        );
        const formattedAddress = response.data.results[0];
        if (formattedAddress) {
          onAddressChange(formattedAddress);
        } else {
          console.error('No address found for this location.');
        }
      } catch (error) {
        console.error('Error fetching address:', error);
      }
    };
    fetchAddress();
  }, [position]);

  // Handle map load
  const handleMapLoad = (map: google.maps.Map) => {
    map.addListener('click', (event: google.maps.MapMouseEvent) => {
      const newPosition = {
        lat: event.latLng?.lat() ?? initialPosition.lat,
        lng: event.latLng?.lng() ?? initialPosition.lng,
      };
      setPosition(newPosition);
    });
  };

  // Handle location change (e.g., when the marker is dragged)
  const onLocationChange = (event: google.maps.MapMouseEvent) => {
    const newPosition = {
      lat: event.latLng?.lat() ?? initialPosition.lat,
      lng: event.latLng?.lng() ?? initialPosition.lng,
    };
    setPosition(newPosition);
    setZoom(18); // Zoom in when a new location is selected
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '400px' }}
      center={initialPosition}
      zoom={zoom}
      onLoad={handleMapLoad}
      onClick={onLocationChange}
      options={{ disableDefaultUI: true }}>
      <Marker
        position={markerPosition}
        draggable
        onDragEnd={onLocationChange}
      />
    </GoogleMap>
  ) : (
    <div>Loading...</div>
  );
};

export default LocationMap;
