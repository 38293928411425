import React from 'react';
import Props from '../../types/props';
import { Typography } from '@mui/material';
import Text from './Text';

function TextLabel({ children = null, ...props }: Props = {}) {
  return (
    <Typography
      {...props.textProps}
      style={{
        color: 'black',
        marginBottom: 2,
        marginTop: 10,
      }}>
      <Text style={{ fontSize: 13, fontWeight: 500 }}>{children}</Text>
    </Typography>
  );
}

export default TextLabel;
