import React from 'react';
import Props from '../../types/props';
import Circle from '@mui/icons-material/Circle';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import TextLabelGray from '../single_component/TextLabelGray';

function LabelWithList({ ...props }: Props) {
  const { options } = props.extra;
  return (
    <Box>
      <TextLabelGray style={{ fontSize: 14 }}>{props.label}</TextLabelGray>
      <Box style={{ display: 'flex', gap: 2 }}>
        {options?.map((value: any, index: React.Key | null | undefined) => {
          return (
            <Box
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                flexWrap: 'wrap',
              }}>
              <Circle style={{ fontSize: '8', color: 'gray' }} />
              <Text style={{ fontSize: 14 }}>{value?.name}</Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default LabelWithList;
