import React, { useContext, useState, useEffect } from 'react';
import Tables from '../components/single_component/Tables';
import Box from '../components/single_component/Box';
import Sidebar from '../components/common_component/Sidebar';
import TeamFilter from '../components/Filter/TeamFilter';
import AddSalesExecutive from '../components/Forms/AddSalesExecutive';
import { TableCell, TableRow } from '@mui/material';
import MyTeamAvtaar from '../components/My-Team/MyTeamAvtaar';
import Text from '../components/single_component/Text';
import ContactDetails from '../components/common_component/ContactDetails';
import moment from 'moment';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '../components/single_component/Button';
import TextInput from '../components/common_component/TextInput';
import SalesExecutiveContext from '../context/SalesExecutiveContext';
import DateUtils from '../utils/dateUtils';
import StringUtils from '../utils/stringUtil';
import NotfoundPage from '../components/single_component/NotfoundPage';
import Loader from '../components/single_component/Loader';
import _ from 'lodash';
import { ROUTES } from '../types/routeObject';

function MyTeamContainer() {
  const [Toggle, setToggle] = useState(false);
  const [SidebarFilter, setSidebarFilter] = useState(false);
  const [date, setDate] = useState(null);
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);
  const [filter, setFilter] = useState({
    search_key: '',
  });

  const { SalesExecutive, getSalesExecutive } = useContext(
    SalesExecutiveContext
  );

  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [Next, setNext] = useState();
  const [Page, setPage] = useState(1);
  const [Previous, setPrevious] = useState();
  const [currPage, setcurrPage] = useState(1);
  const { loading } = useContext(SalesExecutiveContext);

  useEffect(() => {
    setPage(SalesExecutive?.totalPages);
    setNext(SalesExecutive?.nextPage);
    setPrevious(SalesExecutive?.prevPage);
  }, [SalesExecutive]);

  const setSearchKey = (key: string) => {
    setFilter({ ...filter, search_key: key });
  };

  const handleSearch = _.debounce(date => {
    getSalesExecutive(currPage, rowsPerPage, { ...filter, joiningDate: date });
  }, 300);

  useEffect(() => {
    console.error('filtering data', currPage, rowsPerPage, filter);
    let joinDate: string = '';
    if (date != null)
      joinDate =
        DateUtils.formatDate(date ?? '', 'YYYY-MM-DD')?.toString() ?? '';
    handleSearch(joinDate);
    return () => {
      handleSearch.cancel();
    };
  }, [currPage, rowsPerPage, filter]);

  const onClick = () => {
    setToggle(!Toggle);
  };

  const handleSubmit = async () => {
    setbtnDisableLoader(true);
    const formatedDate =
      DateUtils.formatDate(date ?? '', 'YYYY-MM-DD')?.toString() ?? '';
    let filter;
    if (formatedDate !== 'Invalid date') {
      filter = { joiningDate: formatedDate };
    }
    try {
      const responce = await getSalesExecutive(currPage, rowsPerPage, {
        filter,
      });
      setbtnDisableLoader(false);
      if (responce) {
        setSidebarFilter(false);
      } else {
        setSidebarFilter(false);
      }
    } catch (e) {
      console.error(e);
      setSidebarFilter(false);
      setbtnDisableLoader(false);
    }
  };

  const handleClick = () => {
    setSidebarFilter(true);
  };

  const onCancel = () => {
    setSidebarFilter(false);
  };

  const TableRowComponet = (props: any) => {
    const { row } = props;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: '80%' }}>
        <TableCell>
          <MyTeamAvtaar
            extra={{
              location: ROUTES.SALESEXECUTIVE.replace(':id', row.userId),
              name: StringUtils.capitalizeFirstLetter(row.name),
              stateData: {
                executiveName: StringUtils.capitalizeFirstLetter(row.name),
              },
              locate: StringUtils.capitalizeFirstLetter(row.city.cityName),
            }}
          />
        </TableCell>

        <TableCell>
          <ContactDetails
            extra={{ phoneNumber: row.phoneNumber, email: row.email }}
          />
        </TableCell>
        <TableCell>
          <Text style={{ fontSize: 14 }}>{row.phoneNumber}</Text>
        </TableCell>
        <TableCell>
          <Text style={{ fontSize: 14 }}>
            {moment(row.joiningDate).format('DD MMM,YYYY')}
          </Text>
        </TableCell>
        <TableCell>
          <Text style={{ fontSize: 14 }}>{row.userId}</Text>
        </TableCell>
        <TableCell>
          <Text>{row.developers && row.developers.length}</Text>
        </TableCell>
      </TableRow>
    );
  };

  const tableHeaders = [
    { name: 'Sales Executive', id: 'Sales Executive' },
    { name: 'Contact', id: 'Contact' },
    { name: 'Phone Number', id: 'PhoneNumber' },
    { name: 'Date of Joining', id: 'DOJ' },
    { name: 'Executive ID', id: 'ExecutiveId' },
    { name: 'Number of developers', id: 'NumofDeveloper' },
  ];

  return (
    <Box
      style={{
        width: '85%',
        margin: 'auto',
        boxShadow: '0px 2px 10px 0px #10496030',
        border: '1px solid #EDEDED',
      }}>
      <Box style={{ backgroundColor: 'white' }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '12px 24px 0px 24px ',
          }}>
          <Box>
            <Text style={{ fontWeight: 'bold' }}>My Team</Text>
            <Text style={{ fontSize: 14, color: '#667085' }}>
              Here you can see all the sales executives and their details.
            </Text>
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              extra={{ variant: 'contained', color: 'primary' }}
              style={{ padding: '8px 24px', borderRadius: 1 }}
              icons={<AddOutlinedIcon />}
              onClick={onClick}>
              <Text style={{ color: 'white', fontSize: 13, fontWeight: 500 }}>
                Add Sales Executive
              </Text>
            </Button>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '12px 24px 0px 24px ',
          }}>
          <Box style={{ width: 452, height: 48 }}>
            <TextInput
              placeholder="Search"
              onChange={(e: any) => setSearchKey(e.target.value)}
            />
          </Box>

          <Button
            extra={{
              variant: 'outlined',
              color: 'tertiary',
            }}
            style={{ padding: '8px 24px', borderRadius: 1.5 }}
            icons={<FilterListIcon />}
            onClick={handleClick}>
            <Text style={{ color: 'black', fontSize: 13 }}>Add Filters</Text>
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Loader />
      ) : SalesExecutive?.records?.length != 0 ? (
        <Tables
          extra={{
            rows: SalesExecutive.records,
            columns: tableHeaders,
            RowComponent: TableRowComponet,
            page: Page,
            setPage: setPage,
            rowsPerPage: rowsPerPage,
            setrowsPerPage: setrowsPerPage,
            Next: Next,
            Previous: Previous,
            currPage: currPage,
            setcurrPage: setcurrPage,
            setNext: setNext,
            setPrevious: setPrevious,
          }}
        />
      ) : (
        <div
          style={{
            marginTop: '10px',
          }}>
          <NotfoundPage />
        </div>
      )}

      <Sidebar
        label="Add Sales Executive"
        extra={{
          Toggle,
          setToggle,
          anchor: 'right',
          description:
            'Here you can add sales executive by filling the details.',
          width: 400,
        }}>
        <AddSalesExecutive
          extra={{
            setToggle,
          }}
        />
      </Sidebar>

      <Sidebar
        label="Filter"
        extra={{
          Toggle: SidebarFilter,
          setToggle: setSidebarFilter,
          anchor: 'right',
          description: 'Filter the data as per your need',
          width: 400,
        }}>
        <TeamFilter
          extra={{
            setToggle: setSidebarFilter,
            setDate,
            date,
            handleSubmit: handleSubmit,
            onCancel: onCancel,
            btnDisableLoader: btnDisableLoader,
          }}
        />
      </Sidebar>
    </Box>
  );
}

export default MyTeamContainer;
