export enum PREFERENCEKEYS {
  AUTHTOKEN = 'authToken',
  AUTHORIZED = 'authorized',
  ROLE = 'userRole',
  MASTERDATA = 'masterData',
}
export const getAuthToken = () =>
  localStorage.getItem(PREFERENCEKEYS.AUTHTOKEN);
export const setAuthToken = (token: string) =>
  localStorage.setItem(PREFERENCEKEYS.AUTHTOKEN, token);
