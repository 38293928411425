import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Props from '../../types/props';
import Box from '../single_component/Box';

export default function TablesWithoutPagination(props: Props = {}) {
  const { rows, columns, RowComponent } = props?.extra ?? {};

  return (
    <Box
      style={{
        backgroundColor: 'white',
        paddingTop: 1,
        paddingBottom: 2,
      }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table" sx={{ p: '18px' }}>
          <TableHead>
            <TableRow>
              {(columns ?? []).map((column: any) => (
                <TableCell
                  key={column?.id ?? ''}
                  style={{
                    minWidth: 100,
                    fontWeight: 'bold',
                    backgroundColor: '#daedff',
                  }}>
                  {column?.name ?? ''}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row: any, index: React.Key | null | undefined) => (
                <RowComponent row={row} index={index} key={index} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
