import React from 'react';
import Text from '../single_component/Text';
import Box from '../single_component/Box';
import Circle from '@mui/icons-material/Circle';
import Priority from '../common_component/Priority';
import Props from '../../types/props';
import StringUtils from '../../utils/stringUtil';
import moment from 'moment';

function SalesToDolist({ ...props }: Props) {
  const { value } = props.extra;
  return (
    <Box
      {...props}
      style={{
        gap: 2,
        borderBottom: '1px solid #EDEDED ',
        padding: '12px 14px',
      }}>
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box style={{ display: 'flex', gap: 2 }}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 1,
              }}>
              <Circle sx={{ fontSize: 8 }} color="primary" />
              <Text style={{ fontSize: 14, fontWeight: 'bolder' }}>
                {StringUtils.capitalizeFirstLetter(value.subject)}
              </Text>
            </Box>
            <Text style={{ color: '#757575', fontWeight: 500, fontSize: 13 }}>
              {moment(value.startDate, 'YYYY-MM-DD').format('MMM DD, YYYY')} -{' '}
              {moment(value.endDate, 'YYYY-MM-DD').format('MMM DD, YYYY')}
            </Text>
          </Box>
          <Priority label={value.priority} />
        </Box>

        <Box style={{ marginTop: 0.7 }}>
          <Text style={{ fontSize: 13, color: '#104960' }}>
            {StringUtils.capitalizeFirstLetter(value.note)}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default SalesToDolist;
