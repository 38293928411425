import Axios from '../config/axios';
import ApiResponse from '../utils/ApiResponse';

class ProjectServices extends Axios {
  constructor() {
    super();
  }

  async editProject(id: number, data: any) {
    const response = await this.patch<JSON>(`/project/${id}`, data);
    const apiResponse = new ApiResponse(response);
    const success = apiResponse.getSuccess();
    const message = apiResponse.getMessage();
    const responseData = apiResponse.getData();
    return { success, message, responseData };
  }
  async deleteMoreDocument(docId: number) {
    const response = await this.delete<JSON>(`/project/document/${docId}`);
    const apiResponse = new ApiResponse(response);
    const message = apiResponse.getMessage();
    const success = apiResponse.getSuccess();
    return { message, success };
  }
  async addProject(data: any) {
    const response = await this.post<JSON>(`/project/add`, data);
    const apiResponse = new ApiResponse(response);
    const success = apiResponse.getSuccess();
    const message = apiResponse.getMessage();
    const responseData = apiResponse.getData();
    return { success, message, responseData };
  }
  async deleteProjectDocument(projectId: number, deletekey: string) {
    const response = await this.delete<JSON>(
      `/project/document/${projectId}/${deletekey}`
    );
    const apiResponse = new ApiResponse(response);
    const success = apiResponse.getSuccess();
    const message = apiResponse.getMessage();
    const responseData = apiResponse.getData();
    return { success, message, responseData };
  }
  async deleteProjectImage(imageId: number) {
    const response = await this.delete<JSON>(
      `/project_gallery/delete/image/${imageId}`
    );
    const apiResponse = new ApiResponse(response);
    const success = apiResponse.getSuccess();
    const message = apiResponse.getMessage();
    const responseData = apiResponse.getData();
    return { success, message, responseData };
  }
  async deleteSpocs(spocsId: number) {
    const response = await this.delete<JSON>(`/project/spoc/${spocsId}`);
    const apiResponse = new ApiResponse(response);
    const success = apiResponse.getSuccess();
    const message = apiResponse.getMessage();
    const responseData = apiResponse.getData();
    return { success, message, responseData };
  }
  async getProject(query: string) {
    const response = await this.get<JSON>(`/${query}`);
    const apiResponse = new ApiResponse(response);
    const data = apiResponse.getData();
    return data;
  }
  async addMis(data: any) {
    const response = await this.post<JSON>('project/mis', data);
    const apiResponse = new ApiResponse(response);
    const success = apiResponse.getSuccess();
    const message = apiResponse.getMessage();
    return { success, message };
  }
  async addProjectBanner(data: any) {
    const response = await this.post<JSON>('project_gallery/add/image', data);
    const apiResponse = new ApiResponse(response);
    const success = apiResponse.getSuccess();
    const message = apiResponse.getMessage();
    return { success, message };
  }
  async getProjectImagesById(query: string) {
    const response = await this.get<JSON>(`project_gallery/get/image/${query}`);
    const apiResponse = new ApiResponse(response);
    const data = apiResponse.getData();
    return data;
  }
}

export default ProjectServices;
