enum ROUTES {
  HOME = '/',
  LOGIN = '/login',
  INVERSTORS = '/investors',
  MYTEAM = '/myteam',
  TODO = '/todo',
  ACTIVE = '/active',
  COMPLETED = '/completed',
  PROFILE = '/profile',
  EDITPROFILE = '/editprofile',
  SALESEXECUTIVE = 'sales-executive/:id',
  INVESTOR_DETAILS = 'investor-details/:id',
  NOTFOUND = '/not-found',
  DEVELOPER_GROUP = '/developer-group',
  VIEW_DEVELOPER_GROUP = ':id',
  VIEW_SPV = 'view-spv/:spvId',
  VIEW_PROJECT = 'view-project/:id',
  ADD_PROJECT = '/add-project',
  EDIT_PROJECT = '/edit-project',
}

const adminMenu: Array<HeaderMenu> = [
  {
    url: ROUTES.HOME,
    name: 'Dashboard',
  },
  {
    url: ROUTES.INVERSTORS,
    name: 'Investors',
  },
  {
    url: ROUTES.MYTEAM,
    name: 'My Team',
  },
  {
    url: ROUTES.TODO,
    name: "To-Do's",
  },
];

const executiveMenu: Array<HeaderMenu> = [
  {
    url: ROUTES.HOME,
    name: 'Dashboard',
  },
  {
    url: ROUTES.DEVELOPER_GROUP,
    name: 'Developer Group',
  },
  {
    url: ROUTES.TODO,
    name: "To-Do's",
  },
];

const getHeaderMenu = (role: number): Array<HeaderMenu> => {
  let headerMenu: Array<HeaderMenu> = [];
  if (role == ROLE.ADMIN) {
    headerMenu = adminMenu;
  } else if (role == ROLE.EXECUTIVE) {
    headerMenu = executiveMenu;
  }
  return headerMenu;
};

enum ROLE {
  ADMIN = 1,
  EXECUTIVE = 2,
  PUBLIC = 0,
}

interface HeaderMenu {
  url: string;
  name: string;
}

interface RouteObject {
  path: string;
  index?: boolean;
  component: JSX.Element;
  authenticated: boolean;
  role: number;
  handle?: object;
  redirectPath?: string;
  children?: object[];
}

export { type RouteObject, ROUTES, ROLE, getHeaderMenu };
