import Axios from '../config/axios';
import ApiResponse from '../utils/ApiResponse';

class DashboardService extends Axios {
  constructor() {
    super();
  }

  async fetchUserData(query: string, filter: Record<string, unknown>) {
    const response = await this.post<JSON>(query, filter);
    const apiResponse = new ApiResponse(response);
    const data = apiResponse.getData();
    return data;
  }
}

export default DashboardService;
