import React, { useContext, useState } from 'react';
import Sidebar from '../common_component/Sidebar';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Props from '../../types/props';
import StatusTransaction from '../Forms/StatusTransaction';
import Cancelled from '../Forms/Cancelled';
import Box from './Box';
import AuthContext from '../../context/AuthContext';
import IntiateTransactionSales from '../Action/IntiateTransactionSales';
import KlaxonInvoiceAdmin from '../Action/KlaxonInvoiceAdmin';
import MailProvider from '../../providers/MailProvider';

function EyeAction(props: Props = {}) {
  const { role } = useContext(AuthContext);
  const roleType = role?.value();
  const [Toggle, setToggle] = useState(false);
  const { label, page, data, userId, adminPage } = props.extra ?? {};
  const ApprovePage = 'ApprovePage';
  return (
    <Box style={{ cursor: 'pointer' }}>
      <RemoveRedEyeOutlinedIcon
        color="action"
        style={{ color: 'black' }}
        onClick={() => setToggle(!Toggle)}
      />
      {label == 'Cancelled' ? (
        <Sidebar
          label="Cancelled Transaction"
          extra={{
            anchor: 'right',
            Toggle,
            setToggle,
            description:
              'Here you can see the details and status of the transaction.',
            width: 400,
          }}>
          <Cancelled extra={{ setToggle, label, data }} />
        </Sidebar>
      ) : null}
      {label == 'Initiated' ? (
        <Sidebar
          label="Transaction"
          extra={{
            anchor: 'right',
            Toggle,
            setToggle,
            description:
              'Here you can see the details and status of the transaction.',
            width: 400,
          }}>
          <IntiateTransactionSales
            extra={{
              setToggle,
              label,
              data,
              transcId: data?.transcId,
              projectId: data?.projectId,
              roleType,
              page,
              userId,
            }}
          />
        </Sidebar>
      ) : null}
      {label == 'Approved' ? (
        <Sidebar
          label="Klaxon Invoice"
          extra={{
            anchor: 'right',
            Toggle,
            setToggle,
            width: 800,
          }}>
          <MailProvider>
            <KlaxonInvoiceAdmin
              extra={{
                setToggle,
                label,
                page,
                transcId: data?.transcId,
                data,
                ApprovePage,
              }}
            />
          </MailProvider>
        </Sidebar>
      ) : null}

      {label == 'Raised' ? (
        <Sidebar
          label="Klaxon Invoice"
          extra={{
            anchor: 'right',
            Toggle,
            setToggle,
            width: 800,
          }}>
          <MailProvider>
            <KlaxonInvoiceAdmin
              extra={{ setToggle, label, page, transcId: data?.transcId, data }}
            />
          </MailProvider>
        </Sidebar>
      ) : null}

      {label == 'Hold' ? (
        <Sidebar
          label={label == 'Hold' ? 'Hold Transaction' : 'Transaction'}
          extra={{
            anchor: 'right',
            Toggle,
            setToggle,
            description:
              'Here you can see the details and status of the transaction.',
            width: 400,
          }}>
          <StatusTransaction
            extra={{
              setToggle,
              data,
              label,
              transcId: data?.transcId,
              projectId: data?.projectId,
              page,
              userId,
              adminPage,
              ApprovePage,
            }}
          />
        </Sidebar>
      ) : null}
    </Box>
  );
}

export default EyeAction;
