import React, { useEffect } from 'react';
import Props from '../types/props';
import AuthContext from '../context/AuthContext';
import AuthProps from '../types/AuthProps';
import { useStateManager } from '../hooks/useStateManager';
import { ROLE } from '../types/routeObject';
import { PREFERENCEKEYS } from '../services/PreferenceService';
import AuthService from '../services/AuthService';
import MasterData from '../models/masterData';

export default function AuthProvider(props: Props) {
  const initApp = async () => {
    const token = localStorage.getItem('authToken');
    if (token !== null && (token ?? '' != '')) {
      defaultState.authorized?.setValue(true);
      const data = await new AuthService().getMasterData();
      defaultState.masterData?.setValue(data);
    }
  };
  const defaultState: AuthProps = {
    authorized: useStateManager(false),
    role: useStateManager(ROLE.PUBLIC, PREFERENCEKEYS.ROLE),
    token: useStateManager('', PREFERENCEKEYS.AUTHTOKEN),
    masterData: useStateManager(
      MasterData.emptyObject(),
      PREFERENCEKEYS.MASTERDATA,
      true
    ),
    notificationToken: useStateManager<string>(''),
    init: initApp,
  };

  useEffect(() => {
    initApp();
  }, []);

  return (
    <AuthContext.Provider value={defaultState}>
      {props.children}
    </AuthContext.Provider>
  );
}
