import React from 'react';
import InvestorDetailContainer from '../container/InvestorDetailContainer';
import Box from '../components/single_component/Box';
import InvestorProvider from '../providers/InvestorProvider';

function InvestorDetails() {
  return (
    <InvestorProvider>
      <Box
        style={{
          padding: '56px 100px',
          boxShadow: ' 0px 1px 3px 0px #1018281A',
          border: 1,
          borderRadius: 1,
          borderColor: '#E7E7E7',
          background: '#F2FAFE',
        }}>
        <InvestorDetailContainer />
      </Box>
    </InvestorProvider>
  );
}

export default InvestorDetails;
