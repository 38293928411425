import React, { useContext, useState } from 'react';
import Box from '../single_component/Box';
import TextWithLabelGray from '../common_component/TextWithLabelGray';
import LabelWithList from '../common_component/LabelWithList';
import Text from '../single_component/Text';
import { toast } from 'react-toastify';
import Props from '../../types/props';
import DocumentBox from '../common_component/DocumentBox';
import StatusChip from '../common_component/StatusChip';
import TwoButton from '../common_component/TwoButton';
import theme from '../../utils/Theme';
import UploadInput from '../../components/single_component/UploadInput';
// import { Dialog } from '@mui/material';
import Sidebar from '../common_component/Sidebar';
import KlaxonInvoice from '../Action/KlaxonInvoice';
// import Image from '../single_component/Image';
// import AlertIcon from '../../assets/images/Alert.png';
// import Button from '../single_component/Button';
import Link from '@mui/material/Link';
import HoldTransaction from '../Forms/HoldTransaction';
import DateUtils from '../../utils/dateUtils';
import CancelTransactionDialog from '../common_component/cancelTransactionDialog';
import { ROLE } from '../../types/routeObject';
import Button from '../single_component/Button';
import TransactionsContex from '../../context/TransactionsContex';
import SalesDashboardContext from '../../context/SalesDashBoardContext';
import DashboardContext from '../../context/Dashboard';

function IntiateTransactionSales({ ...props }: Props) {
  const {
    deleteTransactionDocument,
    addTransactionMandate,
    getTransaction,
    getAllTransaction,
  } = useContext(TransactionsContex);
  const { getDashboardData: getAdminDashboardData } =
    useContext(DashboardContext);
  const { getDashboardData } = useContext(SalesDashboardContext);

  const { setToggle, label, data, projectId, transcId, roleType, page } =
    props.extra;
  const [showDialog, setshowDialog] = useState(false);
  const [Hold, setHold] = useState(false);
  const [Raise, setRaise] = useState(false);
  const [documents, setDocuments] = useState(data?.mandate);

  const handleDeleteDocuments = async (documentIdToDelete: number) => {
    setDocuments(null);
    try {
      const response = await deleteTransactionDocument(
        documentIdToDelete,
        true
      );
      if (response.success) {
        toast.success(response.message);
        if (page == 'Sales-Dashboard') {
          getDashboardData(`sales/dashboard`, {});
        }
        if (page == 'Sales-Executive') {
          getTransaction(1, 10, {
            filter: {
              createdBy: props.extra?.userId,
            },
          });
        }
        if (page == 'Project') {
          getTransaction(1, 10, {
            filter: {
              projectId,
            },
          });
        }
        if (page == 'Sales-Executive') {
          getAllTransaction(1, 10, {
            filter: {
              createdBy: props.extra?.userId,
            },
          });
        }
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      setToggle(false);
      console.error(e);
    }
  };

  const onCancel = () => {
    {
      label == 'Hold' ? setToggle(false) : setHold(true);
    }
  };
  const handleMandateFileupload = async (
    transactionId: number,
    Mandate: any
  ) => {
    const bodyFormData = new FormData();
    bodyFormData.append('mandate', Mandate);
    if (Mandate) {
      try {
        const response = await addTransactionMandate(
          transactionId,
          bodyFormData
        );
        if (response?.success) {
          toast.success(response?.message);
          if (page == 'Sales-Dashboard') {
            getDashboardData(`sales/dashboard`, {});
          }
          if (page == 'Sales-Executive') {
            getTransaction(1, 10, {
              filter: {
                createdBy: props.extra?.userId,
              },
            });
          }
          if (page == 'Dashboard') {
            getAdminDashboardData(`/admin/dashboard`, {});
          }
          if (page == 'Project') {
            getTransaction(1, 10, {
              filter: {
                projectId,
              },
            });
          }
          if (page == 'Sales-Executive') {
            getAllTransaction(1, 10, {
              filter: {
                createdBy: props.extra?.userId,
              },
            });
          }
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        console.error('Error occurred while adding file:', error);
      }
    }
  };
  const handleSubmit = () => {
    setRaise(true);
  };
  const closeDialog = () => {
    setshowDialog(false);
  };
  const OpenDialog = () => {
    setshowDialog(true);
  };
  return (
    <Box
      style={{
        width: '90%',
        margin: 'auto',
        paddingTop: 1,
        paddingBottom: 13,
      }}>
      <Box style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <TextWithLabelGray
          label="Date of transaction"
          extra={{
            description: DateUtils.formatDate(data?.createdAt, ' MMM DD,YYYY'),
          }}
        />
        <TextWithLabelGray
          label="Deal Size"
          extra={{
            description: `₹ ${data?.dealSize}`,
          }}
        />
      </Box>
      <Box style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Text style={{ color: '#8b8b8b', fontSize: 14 }}>
          Transaction status
        </Text>
        <StatusChip label={data?.transcStatus} />
      </Box>
      <TextWithLabelGray
        label="Brief"
        extra={{
          description: data?.transcBrief,
        }}
      />
      <LabelWithList
        label="Potential Investors"
        extra={{
          options: data?.investors,
        }}
      />
      <Box>
        {documents ? (
          <DocumentBox
            extra={{
              type: 'editMandate',
              url: data?.mandate,
              chiplabel: data?.documentName ?? 'Mandate',
              textlabel: 'Documents',
              GrayColor: 'Grayforform',
              handleDeleteDocuments: () => handleDeleteDocuments(data.transcId),
              component: (
                <UploadInput
                  name="mandate"
                  extra={{
                    isModify: true,
                    onchange: (e: any) => {
                      handleMandateFileupload(data.transcId, e[0]);
                    },
                  }}
                />
              ),
            }}
          />
        ) : (
          <UploadInput
            label="Upload the document"
            id="mandate"
            name="mandate"
            extra={{
              isModify: true,
              onchange: (e: any) => {
                handleMandateFileupload(data.transcId, e[0]);
              },
            }}
          />
        )}
        <TextWithLabelGray
          label="Remark"
          extra={{
            description: data?.remark,
          }}
        />
      </Box>

      {roleType == ROLE.EXECUTIVE ? (
        <Link
          component="button"
          variant="body2"
          onClick={OpenDialog}
          style={{
            fontWeight: 500,
            fontSize: 13,
            color: theme.palette.secondary.main,
            cursor: 'pointer',
            position: 'relative',
            left: '233px',
            top: '1em',
          }}>
          Cancel transaction
        </Link>
      ) : null}

      {roleType == ROLE.ADMIN ? (
        <Button
          style={{
            width: '90%',
            padding: 1.5,
            position: 'absolute',
            bottom: 10,
          }}
          onClick={() => setToggle(false)}
          extra={{
            variant: 'contained',
          }}>
          <Text style={{ fontSize: 12 }}>Done</Text>
        </Button>
      ) : (
        <TwoButton
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bottom: 10,
            position: 'absolute',
            width: '90%',
          }}
          extra={{
            onclickFirst: onCancel,
            OnClickSecond: handleSubmit,
            firstVariant: 'outlined',
            secondVariant: 'contained',
            firstButtonText: label == 'Hold' ? 'Cancel' : 'Hold',
            secondButtonText: 'Raise transaction',
          }}
        />
      )}
      <CancelTransactionDialog
        extra={{
          projectId: data?.projectId,
          transcId,
          showDialog,
          closeDialog,
          page,
        }}
      />

      <Sidebar
        label="Hold Transaction"
        extra={{
          anchor: 'right',
          Toggle: Hold,
          setToggle: setHold,
          description: 'Hold your transaction here by filling the form',
          width: 400,
          heigt: '100%',
        }}>
        <HoldTransaction
          extra={{
            setToggle,
            setHold,
            OpenDialog,
            transcId: data?.transcId,
            projectId: data?.projectId,
            page,
          }}
        />
      </Sidebar>

      <Sidebar
        label="Klaxon Invoice"
        extra={{
          anchor: 'right',
          Toggle: Raise,
          setToggle: setRaise,
          width: 800,
        }}>
        <KlaxonInvoice
          extra={{
            setToggle,
            label,
            transcId: data?.transcId,
            projectId: data?.projectId,
            page,
          }}
        />
      </Sidebar>
    </Box>
  );
}

export default IntiateTransactionSales;
