import React, { useState } from 'react';
import Props from '../../types/props';
import { useFormik } from 'formik';
import { addInvestorContactFormSchema } from '../Validation/AddTask';
import Box from '../single_component/Box';
import TextInput from '../common_component/TextInput';
import ErrorText from '../common_component/ErrorText';
import Radio from '../common_component/Radio';
import CheckboxLabels from '../common_component/AddCheckbox';
import Button from '../single_component/Button';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Text from '../single_component/Text';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

type ContactPerson = {
  contPerName: string;
  contPerEmail: string;
  contPerPhone: string;
};

function InvestorContactForm(props: Props) {
  const [Selected, setSelected] = useState('');

  const masterData = props?.extra?.masterData ?? {};

  const [investorType, setinvestorType] = useState('');

  const investorTypes =
    masterData?.investorTypes?.map((item: any, index: number) => {
      return {
        id: index + 1,
        name: item,
      };
    }) ?? [];

  const [investmentTypes, setinvestmentTypes] = useState(
    masterData?.investmentTypes?.map((item: any) => {
      return {
        id: item.id,
        name: item.name,
        selected: false,
      };
    }) ?? []
  );

  const [contactPersons, setContacPerson] = useState<ContactPerson[]>([
    {
      contPerName: '',
      contPerEmail: '',
      contPerPhone: '',
    },
  ]);

  const contactFormState = {
    investorName: '',
    investorType: '',
    investmentType: '',
    contactPersons: [
      {
        contPerName: '',
        contPerEmail: '',
        contPerPhone: '',
      },
    ],
  };

  const handleInvestmentType = (value: boolean, index: number) => {
    const temp = [...investmentTypes];
    temp[index]['selected'] = !temp[index]['selected'];
    setinvestmentTypes([...temp]);
    const anySelected = investmentTypes.some(
      (item: any) => item.selected === true
    );
    setFieldValue('investmentType', anySelected == true ? value : '');
  };

  const [HideShow, setHideShow] = useState(true);

  const HideShowHandle = () => {
    setHideShow(false);
  };
  const HideShowHandleSave = (investorType: any) => {
    setHideShow(true);
    setinvestorType('');
    if (investorType.length > 0) {
      setFieldValue('investmentType', investorType);
    }
  };
  const handleDeleteContactPerson = (index: number) => {
    if (contactPersons.length === 1) {
      return;
    }
    const updatedContactPerson = [...contactPersons];
    updatedContactPerson.splice(index, 1);
    setContacPerson(updatedContactPerson);
  };
  const [selectedInvestors, setSelectedInvestors] = useState<number[]>([]);

  const addInvestmentTypes = (value: string) => {
    if (value.length > 0) {
      const temp = [...investmentTypes];
      temp.push({
        id: -1,
        name: value,
        selected: true,
      });
      setinvestmentTypes([...temp]);
    }
  };

  const onInvestorSelect = (index: number) => {
    const checkedInvestors = selectedInvestors;
    const passedIndex: number = parseInt(index.toString());
    const checkedIndex = selectedInvestors.indexOf(passedIndex);
    checkedIndex == -1
      ? checkedInvestors.push(passedIndex)
      : checkedInvestors.splice(checkedIndex, 1);
    setSelectedInvestors([...checkedInvestors]);
  };

  const handleContactPersonChange = (
    index: number,
    field: keyof ContactPerson,
    value: string
  ) => {
    const updatedContactPerson = [...contactPersons];
    updatedContactPerson[index][field] = value;
    setContacPerson(updatedContactPerson);
    setFieldValue('contactPersons', updatedContactPerson);
  };

  const handleAddContactPerson = () => {
    setContacPerson([
      ...contactPersons,
      { contPerName: '', contPerEmail: '', contPerPhone: '' },
    ]);
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: contactFormState,
    validationSchema: addInvestorContactFormSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async values => {
      const investmentIds = [];
      const investmentNames = [];
      for (let i = 0; i < investmentTypes.length; i++) {
        const value = investmentTypes[i];
        if (value.id != -1 && value.selected) {
          investmentIds.push(value.id);
        } else {
          if (value.selected) investmentNames.push(value.name);
        }
      }
      props?.extra?.onNext({
        name: values.investorName,
        investorType:
          investorTypes.find((investorTypes: any) => {
            console.error('comparing', investorTypes.id, values.investorType);
            return investorTypes.name === values.investorType;
          })?.id ?? 0,
        investmentTypes: investmentIds ?? '',
        investmentTypesNames: investmentNames ?? '',
        contactPerson: values.contactPersons,
      });
      console.error('values are', investmentIds);
    },
  });

  return (
    <Box>
      <Box style={{ marginBottom: 10 }}>
        <TextInput
          label="Investor name"
          placeholder="Enter Investor name "
          id="investorName"
          name="investorName"
          value={values.investorName}
          onChange={handleChange}
          onBlur={handleBlur}
          onInput={(e: any) => {
            e.target.value = e.target.value.replace(/[0-9]/g, '');
          }}
        />
        {touched.investorName && errors.investorName ? (
          <ErrorText>{errors.investorName}</ErrorText>
        ) : null}
        <Radio
          label="Investor type"
          name="investorType"
          id="investorType"
          value={values.investorType}
          onBlur={handleBlur}
          extra={{
            setSelected: (e: any) => {
              setFieldValue('investorType', e);
              setSelected(e);
            },
            Selected: Selected,
            options: investorTypes,
          }}
        />
        {touched.investorType && errors.investorType ? (
          <ErrorText>{errors.investorType}</ErrorText>
        ) : null}

        <CheckboxLabels
          label="investmentType"
          id="investmentType"
          name="investmentType"
          onBlur={handleBlur}
          extra={{
            onSelect: (value: boolean, index: number) => {
              // setFieldValue('investmentType', value === true ? value : '');
              handleInvestmentType(value, index);
              onInvestorSelect(index);

              // setCheck([...check, index]);
            },
            headingLabel: 'Investment type',
            options: investmentTypes,
            selectedInvestors,

            // check: check
          }}
        />
        {touched.investmentType && errors.investmentType ? (
          <ErrorText>{errors.investmentType}</ErrorText>
        ) : null}

        <Box>
          {HideShow ? (
            <Button
              extra={{ variant: 'outlined', color: 'primary' }}
              style={{ padding: '6px 60px' }}
              icons={<AddOutlinedIcon />}
              onClick={HideShowHandle}>
              <Text style={{ color: '#104960', fontSize: 13, fontWeight: 500 }}>
                Add new type
              </Text>
            </Button>
          ) : (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2,
              }}>
              <TextInput
                placeholder="Enter type here"
                value={investorType}
                onChange={(e: any) => {
                  setinvestorType(e.target.value);
                }}
              />
              <Button
                extra={{ variant: 'outlined' }}
                style={{ padding: '7px 40px' }}
                onClick={() => {
                  addInvestmentTypes(investorType);
                  HideShowHandleSave(investorType);
                }}>
                Save
              </Button>
            </Box>
          )}
        </Box>

        {contactPersons.map((promoter, index) => (
          <Box key={index}>
            {contactPersons.length > 1 && (
              <Box
                style={{
                  textAlign: 'right',
                  marginTop: '1rem',
                  marginBottom: '-30px',
                }}
                onClick={() => handleDeleteContactPerson(index)}>
                <RemoveCircleOutlineIcon />
              </Box>
            )}
            <TextInput
              type="text"
              label="Contact Person"
              id={`contactPersons[${index}].contPerName`}
              placeholder="Enter Contact person name"
              name={`contactPersons[${index}].contPerName`}
              value={promoter.contPerName}
              onChange={(e: any) => {
                handleContactPersonChange(index, 'contPerName', e.target.value);
                handleChange(e);
              }}
              onBlur={handleBlur}
            />
            {touched.contactPersons &&
            touched.contactPersons[index] &&
            touched.contactPersons[index].contPerName &&
            errors.contactPersons &&
            errors.contactPersons[index] &&
            (errors.contactPersons[index] as any)?.contPerName ? (
              <ErrorText>
                {(errors.contactPersons[index] as any)?.contPerName}
              </ErrorText>
            ) : null}
            <TextInput
              type="email"
              label="Email ID"
              id={`contactPersons[${index}].contPerEmail`}
              placeholder="Enter Contact person email"
              name={`contactPersons[${index}].contPerEmail`}
              value={promoter.contPerEmail}
              onChange={(e: any) => {
                handleContactPersonChange(
                  index,
                  'contPerEmail',
                  e.target.value
                );
                handleChange(e);
              }}
              onBlur={handleBlur}
            />
            {touched.contactPersons &&
            touched.contactPersons[index] &&
            touched.contactPersons[index].contPerEmail &&
            errors.contactPersons &&
            errors.contactPersons[index] &&
            (errors.contactPersons[index] as any)?.contPerEmail ? (
              <ErrorText>
                {(errors.contactPersons[index] as any)?.contPerEmail}
              </ErrorText>
            ) : null}

            <TextInput
              type="tel"
              label="Phone Number"
              id={`contactPersons[${index}].contPerPhone`}
              placeholder="Enter Contact person phone number"
              name={`contactPersons[${index}].contPerPhone`}
              value={promoter.contPerPhone}
              onChange={(e: any) => {
                handleContactPersonChange(
                  index,
                  'contPerPhone',
                  e.target.value
                );
                handleChange(e);
              }}
              onInput={(e: any) => {
                e.target.value = e.target.value
                  .trim()
                  .replace(/[a-zA-Z\s]/g, '');
              }}
              onBlur={handleBlur}
              maxLength={10}
              minLength={10}
            />
            {touched.contactPersons &&
            touched.contactPersons[index] &&
            touched.contactPersons[index].contPerPhone &&
            errors.contactPersons &&
            errors.contactPersons[index] &&
            (errors.contactPersons[index] as any)?.contPerPhone ? (
              <ErrorText>
                {(errors.contactPersons[index] as any)?.contPerPhone}
              </ErrorText>
            ) : null}
          </Box>
        ))}

        <Button
          extra={{ variant: 'outlined', color: 'primary' }}
          style={{ padding: '6px 10px', marginTop: 1.5, width: '65%' }}
          onClick={handleAddContactPerson}
          icons={<AddOutlinedIcon />}>
          <Text style={{ color: '#104960', fontSize: 13, fontWeight: 500 }}>
            Add Another Contact Person
          </Text>
        </Button>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bottom: 0,
          position: 'absolute',
          width: '99%',
          marginLeft: -2.5,
          background: 'white',
          paddingTop: '12px',
          paddingBottom: '8px',
          borderTopRightRadius: 3,
        }}>
        <Button
          extra={{ variant: 'outlined' }}
          onClick={props?.extra?.onPrevious}
          style={{ width: '43%', marginRight: '2%', paddingBlock: 1 }}>
          Cancel
        </Button>
        <Button
          extra={{ variant: 'contained' }}
          onClick={handleSubmit}
          style={{ width: '43%', marginLeft: '2%', paddingBlock: 1 }}>
          Continue
        </Button>
      </Box>
    </Box>
  );
}
export default InvestorContactForm;
