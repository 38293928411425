import React, { useState, useContext } from 'react';
import TextInput from '../common_component/TextInput';
import Props from '../../types/props';
import Box from '../single_component/Box';
import Dropdown from '../common_component/Dropdown';
import Radio from '../common_component/Radio';
import Dpicker from '../single_component/Dpicker';
import MultipleChip from '../common_component/MultipleChip';
import TwoButton from '../common_component/TwoButton';
import TodoContext from '../../context/TodoContext';
import { toast } from 'react-toastify';
import SalesExecutiveContext from '../../context/SalesExecutiveContext';
import moment from 'moment';
import AuthContext from '../../context/AuthContext';
import { useFormik } from 'formik';
import ErrorText from '../common_component/ErrorText';
import { AddTaskSchema } from '../Validation/AddTask';
import ProfileContext from '../../context/ProfileContext';

function AddTask({ ...props }: Props) {
  const { ProfileData } = useContext(ProfileContext);
  const { masterData } = useContext(AuthContext);
  const [selected, setselected] = useState('');
  const { setToggle, page } = props.extra;
  const [selectedChip, setSelectedChip] = React.useState(-1);
  const { addTodo } = useContext(TodoContext);
  const { SalesExecutive } = useContext(SalesExecutiveContext);

  const user = ProfileData.user;
  const data = masterData?.value();

  const onCancel = () => {
    setToggle(false);
  };

  const handleChipClick = (index: number) => {
    setSelectedChip(index);
    setFieldValue('priority', index);
  };
  const options = [
    {
      id: 1,
      name: 'Self',
    },
    {
      id: 2,
      name: 'Sales Executive',
    },
  ];
  const [btnDisableLoader, setbtnDisableLoader] = useState(false);
  const initialValues = {
    subject: '',
    notes: '',
    selectedOption: '',
    selectedSalesExecutive: '',
    startDate: '',
    endDate: '',
    priority: '',
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: AddTaskSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async values => {
      setbtnDisableLoader(true);
      const finalData = {
        subject: values.subject,
        note: values.notes,
        assignedTo:
          values.selectedOption == 'Self'
            ? user.userId
            : SalesExecutive?.records[selected]?.userId,
        startDate: moment(values.startDate).format('YYYY-MM-DD'),
        endDate: moment(values.endDate).format('YYYY-MM-DD'),
        priority: data?.priorities[selectedChip] ?? '',
      };
      const response = await addTodo(
        values.selectedOption == 'Self'
          ? user.name
          : SalesExecutive?.records[selected]?.name,
        finalData
      );

      setbtnDisableLoader(false);
      if (response.success) {
        setToggle(false);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    },
  });

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: 8 }}>
      <Box style={{ width: '90%', alignSelf: 'center' }}>
        <TextInput
          label="Subject"
          type="text"
          name="subject"
          id="subject"
          value={values.subject}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter subject here"></TextInput>
        {touched.subject && errors.subject ? (
          <ErrorText>{errors.subject}</ErrorText>
        ) : null}
        <TextInput
          label="Note"
          type="text"
          name="notes"
          id="notes"
          value={values.notes}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter Notes here"></TextInput>
        {touched.notes && errors.notes ? (
          <ErrorText>{errors.notes}</ErrorText>
        ) : null}
        {page !== 'sales-executive' ? (
          <Box>
            <Radio
              style={{ marginTop: 2 }}
              label="Assign to "
              extra={{
                Selected: values.selectedOption,
                setSelected: (value: any) => {
                  setFieldValue('selectedOption', value);
                },
                options,
              }}
            />
            {touched.selectedOption && errors.selectedOption ? (
              <ErrorText>{errors.selectedOption}</ErrorText>
            ) : null}
            {values.selectedOption == 'Self' ? null : (
              <Dropdown
                label="Sales Executive"
                name="selectedSalesExecutive"
                id="selectedSalesExecutive"
                onChange={(e: any) => {
                  setFieldValue('selectedSalesExecutive', e.target.value);
                  setselected(e.target.value);
                }}
                value={(SalesExecutive.records[selected] ?? {})['name'] ?? ''}
                extra={{
                  options: SalesExecutive?.records,
                  placeholder: 'Choose sales executive',
                }}
              />
            )}
            {touched.selectedSalesExecutive && errors.selectedSalesExecutive ? (
              <ErrorText>{errors.selectedSalesExecutive}</ErrorText>
            ) : null}
          </Box>
        ) : null}

        <Dpicker
          name="startDate"
          id="startDate"
          disablePast={true}
          extra={{
            setDate: (e: any) => {
              setFieldValue('startDate', e);
            },
            placeholder: 'Choose date',
            maxDate: values.endDate,
          }}
          value={values.startDate}
          onBlur={handleBlur}
          style={{ width: '100%', marginTop: 2, marginBottom: 1 }}
          label="Start Date"
        />
        {touched.startDate && errors.startDate ? (
          <ErrorText>{errors.startDate}</ErrorText>
        ) : null}
        <Dpicker
          name="endDate"
          id="endDate"
          value={values.endDate}
          extra={{
            setDate: (e: any) => {
              setFieldValue('endDate', e);
            },
            placeholder: 'Choose date',
            minDate: values.startDate,
          }}
          style={{ width: '100%', marginTop: 2, marginBottom: 1 }}
          label="End Date"
        />
        {touched.endDate && errors.endDate ? (
          <ErrorText>{errors.endDate}</ErrorText>
        ) : null}
        <MultipleChip
          style={{ width: '100%', marginTop: 2, marginBottom: 1 }}
          label="Priority of the task"
          name="priority"
          value={values.priority}
          onBlur={handleBlur}
          id="priority"
          extra={{
            handleClick: handleChipClick,
            options: data?.priorities,
            variant: 'outlined',
            clickable: true,
            selectedChip: selectedChip,
          }}
        />
        {touched.priority && errors.priority ? (
          <ErrorText>{errors.priority}</ErrorText>
        ) : null}

        <Box
          style={{
            backgroundColor: 'white',
            bottom: 0,
            position: 'absolute',
            width: '90%',
            marginBottom: '8px',
          }}>
          <TwoButton
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            extra={{
              onclickFirst: onCancel,
              OnClickSecond: handleSubmit,
              firstVariant: 'outlined',
              secondVariant: 'contained',
              firstButtonText: 'Cancel',
              secondButtonText: 'Add',
              btnDisableLoader: btnDisableLoader,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default AddTask;
