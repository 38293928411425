import React, { useContext } from 'react';
import Box from '../single_component/Box';
import Dropdown from '../common_component/Dropdown';
import TwoButton from '../common_component/TwoButton';
import Props from '../../types/props';
import AuthContext from '../../context/AuthContext';
import CheckboxLabels from '../common_component/MultiselectChip';

function InvestorFilter({ ...props }: Props) {
  const { masterData } = useContext(AuthContext);
  const master = masterData?.value();
  const {
    selected,
    setselected,
    setinvestment,
    setinvestor,
    selectinvestment,
    selectinvestor,
    btnDisableLoader,
    handleFilter,
  } = props?.extra || {};

  const handleClearFilter = () => {
    setselected(-1);
    setinvestment(selectinvestment.splice(0, selectinvestment.length));
    setinvestor(selectinvestor.splice(0, selectinvestment.length));
  };
  return (
    <Box style={{ width: '90%', margin: 'auto', marginTop: 2 }}>
      <Dropdown
        label="Location"
        value={(master?.locations[selected] ?? {})['name'] ?? ''}
        extra={{ options: master?.locations, placeholder: 'Select location' }}
        onChange={(e: any) => {
          setselected(e.target.value);
        }}
      />

      <CheckboxLabels
        style={{ width: '100%', marginTop: 2, marginBottom: 1 }}
        label="Investment type"
        extra={{
          onSelect: (index: any) => {
            setinvestment(index);
          },
          headingLabel: 'Investment type',
          options: master?.investmentTypes,
        }}
      />
      <CheckboxLabels
        style={{ width: '100%', marginTop: 2, marginBottom: 1 }}
        label="Investment type"
        extra={{
          onSelect: (index: any) => {
            setinvestor(index);
          },
          headingLabel: 'Investor type',
          options: master?.investorTypes.map((e: any) => ({ name: e })),
        }}
      />
      <TwoButton
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bottom: 10,
          position: 'absolute',
          width: '90%',
        }}
        extra={{
          onclickFirst: handleClearFilter,
          OnClickSecond: handleFilter,
          firstVariant: 'outlined',
          secondVariant: 'contained',
          firstButtonText: 'Clear Filter',
          secondButtonText: 'Apply',
          btnDisableLoader: btnDisableLoader,
        }}
      />
    </Box>
  );
}

export default InvestorFilter;
