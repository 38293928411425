import React from 'react';
import AvtartImage from '../../assets/images/Avatar.png';
import Box from '../single_component/Box';
import Text from '../single_component/Text';
import ProfileAvtaar from '../single_component/ProfileAvtaar';
import { useNavigate } from 'react-router';
import Props from '../../types/props';

function MyTeamAvtaar({ ...props }: Props) {
  const navigate = useNavigate();
  const { name, locate, location, stateData } = props.extra;
  return (
    <Box
      style={{
        display: 'flex',
        columnGap: 2,
        alignItems: 'center',
        // minWidth: 200,
        cursor: 'pointer',
      }}
      onClick={() => {
        navigate(`/${location}`, { state: stateData });
      }}>
      <ProfileAvtaar alt="Remy Sharp" src={AvtartImage} />
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Text style={{ color: '#101828', fontWeight: 600, fontSize: 14 }}>
          {name}
        </Text>
        <Text
          style={{
            textAlign: 'left',
            fontWeight: 500,
            fontSize: 12,
            color: '#667085',
          }}>
          {locate}
        </Text>
      </Box>
    </Box>
  );
}

export default MyTeamAvtaar;
